
	import {Options} from "vue-property-decorator";
	import TelehealthProviderBase from "@/components/telehealth/TelehealthProviderBase.vue";
	import {TELEHEALTH_MODE} from "@/components/telehealth/TelehealthMode";
	import ChatView from "@/components/telehealth/ChatView.vue";
	import VideoView from "@/components/telehealth/VideoView.vue";
	import TelehealthWrapper from "@/components/telehealth/TelehealthWrapper.vue";
	import TelehealthHeader from "@/components/telehealth/TelehealthHeader.vue";
	import {ClinicAPI} from "@/lib/services/Api";
	import ChatViewClinicUser from "@/views/clinic_user/appointment/components/ChatViewClinicUser.vue";
	import {Appointment} from "@/lib/models/Appointment";
	import OpenTokTelehealth from "@/lib/telehealth/opentok/OpenTokTelehealth";
	import {
		AttachmentCreateTransfer, MessageCreateTransfer,
		PatientUserDto,
		UserType,
	} from "@/open_api/generated";
	import VideoViewTopControls from "@/views/clinic_user/appointment/components/VideoViewTopControls.vue";
	import PatientInfoHeader from "@/views/clinic_user/appointment/components/PatientInfoHeader.vue";
	import AppointmentDetails from "@/views/clinic_user/appointment/components/AppointmentDetails.vue";
	import PatientDetails from "@/views/clinic_user/appointment/components/PatientDetails.vue";
	import {ErrorResponse} from "@/lib/models/Errors/ErrorResponse";
	import NotificationService from "@/components/Notification/NotificationService";
	import {NotificationSeverity, NotificationType, NotifyEvent} from "@/lib/types/Notifier";
	import {alertController, IonPage, modalController, toastController} from "@ionic/vue";
	import {reactive} from "vue";
	import BaseButton from "@/components/Buttons/BaseButton.vue";
	import PulsingIndicator from "@/components/Cosmetic/PulsingIndicator.vue";
	import ProviderChatView from "@/views/clinic_user/appointment/components/ProviderChatView.vue";
	import {defaultToastOpts} from "@/lib/utils/helpers/ionic";
	import {ChatItemType, MessageOrigin} from "@/lib/telehealth/models/telehealth.types";
	import EncounterNoteService from "@/lib/patient/EncounterNoteService";
	import DocumentService from "@/lib/document/service/DocumentService";
	import RemoteFeatureSetService from "@/lib/integration/remote/service/RemoteFeatureSetService";
	import ChartUploadDocumentReviewModal from "@/views/clinic_user/appointment/modal/ChartUploadDocumentReviewModal.vue";
	import {ChatItem} from "@/views/patient_user/telehealth/types";
	import MessageCreateTransferFactory from "@/lib/messaging/factory/MessageCreateTransferFactory";
	import MessagingService from "@/lib/messaging/service/MessagingService";
	import AttachmentCreateTransferFactory from "@/lib/messaging/factory/AttachmentCreateTransferFactory";
	import FileUtil from "@/lib/utils/FileUtil";

	@Options({
		components: {
			ProviderChatView,
			BaseButton,
			IonPage,
			PatientDetails,
			AppointmentDetails,
			VideoViewTopControls,
			ChatViewClinicUser,
			TelehealthWrapper,
			TelehealthHeader,
			VideoView,
			ChatView,
			PatientInfoHeader,
			PulsingIndicator,
		},
	})
	export default class TelehealthClinicUser extends TelehealthProviderBase
	{
		public appointmentData: Appointment = null;
		public patientRemoteProfile: PatientUserDto = null;
		public doesEncounterNoteEndpointExist = false;
		public doesSaveDocumentToChartExist = false;
		private appointmentDataLoaded = false;
		private transcriptSent = false;

		// ==========================================================================
		// Vue life cycle hooks
		// ==========================================================================

		public async onCreated(): Promise<void>
		{
			this.telehealthEngine = reactive(new OpenTokTelehealth()) as OpenTokTelehealth;
			this.telehealthEngine.setClientInfo(await this.getlocalClientData());

			try
			{
				if (this.telehealthMode === TELEHEALTH_MODE.AQS)
				{
					const response = await ClinicAPI().getLocalQueuedAppointment(this.queuedAppointmentId);
					this.appointmentData = new Appointment(response.data);
				}
				else
				{
					const response = await ClinicAPI().getClinicUserAppointment(this.appointmentId);
					this.appointmentData = new Appointment(response.data);
				}

				// load user profile information.
				if (this.appointmentData.patientId)
				{
					this.patientProfile = (await ClinicAPI().getPatientProfile(this.appointmentData.patientId)).data;
				}
				this.patientRemoteProfile = (await ClinicAPI().getRemotePatientProfile(this.appointmentData.remotePatientId)).data;

				this.appointmentDataLoaded = true;
			}
			catch (error)
			{
				this.showLoadFailureAlert(error);
			}

			this.loadFeatureFlags();
		}

		// ==========================================================================
		// Public Methods
		// ==========================================================================

		/**
		 * Sends the chat transcript to the patient if enabled.
		 * It is safe to call this method multiple times. It will only send the transcript once.
		 * This method will also do nothing if the chat transcript feature is disabled.
		 */
		public async sendChatTranscriptIfEnabled(): Promise<void>
		{
			const clinicProfile = await ClinicAPI().getClinicProfile();
			const sendTranscriptEnabledByClinic = clinicProfile.data.clinic.send_chat_transcript;
			if (this.appointmentData.isChat && sendTranscriptEnabledByClinic && !this.transcriptSent)
			{
				const messagingService = new MessagingService();
				await messagingService.createMessage(await this.createTranscriptMessage());
				this.transcriptSent = true; // prevent duplicate transcripts from being sent in the same session.
			}
		}

		/**
		 * Creates a MessageCreateTransfer that contains the chat transcript.
		 * @returns {Promise<MessageCreateTransfer>} The message create transfer.
		 */
		public async createTranscriptMessage(): Promise<MessageCreateTransfer>
		{
			const subject = this.$mhat("TelehealthTextChat.Transcript.Subject");

			const message = await this.buildTranscriptContent(
				this.patientProfile.first_name,
				this.appointmentData.providerName,
				this.chatItems,
			);

			const recipients = [{id: this.appointmentData.patientId, type: UserType.PatientUser}];
			const attachments = await this.getAttachments(this.patientProfile.id, this.chatItems);

			const messageCreateTransferFactory = new MessageCreateTransferFactory();

			return messageCreateTransferFactory.create(
				subject,
				message,
				recipients,
				null,
				attachments,
			);
		}

		public async onExit(): Promise<void>
		{
			await this.sendChatTranscriptIfEnabled();
			await super.onExit();
		}

		/**
		 * Builds the chat transcript content.
		 * @param patientName - The patient's name.
		 * @param providerName - The provider's name.
		 * @param chatItems - The chat items.
		 * @returns The chat transcript content.
		 */
		private async buildTranscriptContent(patientName: string, providerName: string, chatItems: ChatItem[]): Promise<string>
		{
			const documentService = new DocumentService();

			// The patient's locale is used to build the chat transcript.
			const patientLocale = this.patientProfile.locale;
			const providerLocale = this.$i18n.locale;
			this.$i18n.locale = patientLocale;

			let chatHistory = this.$mhat("TelehealthTextChat.Transcript.Intro", {providerName: providerName});

			for (const item of chatItems)
			{
				const senderName: string = item.messageOrigin.toUpperCase() === MessageOrigin.Received ? patientName : providerName;
				var message = item.message;

				if (item.type === ChatItemType.DOCUMENT)
				{
					const document = await documentService.getDocument(item.message);
					message = this.$mhat("TelehealthTextChat.Transcript.Document", {documentName: document.name});
				}
				chatHistory += `\n${senderName}: ${message}`;
			}

			// Restore the provider's locale.
			this.$i18n.locale = providerLocale;
			return chatHistory;
		}

		public async loadFeatureFlags(): Promise<void>
		{
			const featureService = new RemoteFeatureSetService();

			this.doesEncounterNoteEndpointExist = await featureService.supportsWriteEncounterNote();
			this.doesSaveDocumentToChartExist = await featureService.supportsSaveDocumentToChart();
		}

		public showLoadFailureAlert(error: any): void
		{
			let message: string = error.toString();
			if (error instanceof ErrorResponse)
			{
				message = error.message;
			}

			NotificationService.notify(
				{
					event: NotifyEvent.Generic,
					type: NotificationType.Dismiss,
					severity: NotificationSeverity.Critical,
					title: "Telehealth Appointment Error",
					message,
				});
		}

		public showCallPatientSpinner(): boolean
		{
			const patientClients = this.telehealthEngine.remoteClients.filter((client) =>
			{
				return (client.isPatientUser || client.isKioskUser || client.isOneTimeUser) && client.isPublishing;
			});

			return patientClients.length === 0 || !this.inRoom();
		}

		/**
		 * Writes the chat to the patient's encounter note
		 */
		public async writeChatToEncounterNote(): Promise<void>
		{
			const encounterNoteService = new EncounterNoteService();

			try
			{
				const chatItems = this.chatItems;
				const documentChatItems = chatItems.filter((item) => item.type === ChatItemType.DOCUMENT);
				let shouldContinue = true;

				if (documentChatItems.length > 0)
				{
					// let the user pick which files to upload to Juno
					const modal = await modalController.create({
						component: ChartUploadDocumentReviewModal,
						componentProps: {
							patientId: this.patientProfile.id,
							chatItems: documentChatItems,
						},
					});
					await modal.present();
					shouldContinue = !!(await modal.onDidDismiss()).data;
				}

				if (shouldContinue)
				{
					await encounterNoteService.writeChatItemsToEncounterNote(this.patientProfile.id,
						this.patientProfile.first_name,
						this.appointmentData.providerName,
						this.chatItems);

					const successToast = await toastController.create(Object.assign(defaultToastOpts, {message: "Saved to encounter note"}));
					await successToast.present();
				}
			}
			catch (error)
			{
				NotificationService.notificationDismiss("Failed to write to encounter note", error.message, NotificationSeverity.Critical);
			}
		}

		// ==========================================================================
		// Getters
		// ==========================================================================

		get loaderData(): {title: string, message: string, icon: string}
		{
			const name = this.appointmentData ? this.appointmentData.patientFirstName : "the patient";

			if (this.shouldShowCallAgain)
			{
				return {
					title: "The patient didn't answer",
					message: "Would you like to call them again?",
					icon: "icon-tele-doc",
				};
			}
			else if (this.shouldShowCallOver)
			{
				return {
					title: "The call has ended",
					message: "Would you like to call again?",
					icon: "icon-tele-doc",
				};
			}
			else if (this.patientConnected)
			{
				if (!this.canNotifyPatient)
				{
					return {
						title: "Calling...",
						message: `Waiting for ${name} to accept the call`,
						icon: "icon-tele-doc",
					};
				}
				else if (this.remotePatient.isPublishing)
				{
					return {
						title: "Patient is in the call",
						message: `${name} is in the call! do you want to join them?`,
						icon: "icon-tele-doc",
					};
				}
				else
				{
					return {
						title: `${name} is in the Virtual Waiting Room`,
						message: "Would you like to call them?",
						icon: "icon-tele-doc",
					};
				}
			}
			else if (!this.canNotifyPatient)
			{
				return {
					title: `Waiting for ${name} to join...`,
					message: `We've notified ${name} that their appointment has started. You'll be prompted to join once they've arrived.`,
					icon: "icon-user-question",
				};
			}
			else
			{
				return {
					title: `${name} is not in the Virtual Waiting Room`,
					message: "Would you like to call them?",
					icon: "icon-user-question",
				};
			}
		}

		// true if the call button should be disabled
		get callButtonDisabled(): boolean
		{
			return !this.canNotifyPatient || this.isLoading;
		}

		get shouldShowCallAgain(): boolean
		{
			return this.patientDidntAnswer && !this.providerHangup;
		}

		get shouldShowCallOver(): boolean
		{
			return this.callOver && !this.providerHangup;
		}

		/**
		 * check if there is at least one chat message
		 */
		get hasChatMessages(): boolean
		{
			return !!this.chatItems && this.chatItems.length > 0;
		}

		// ==========================================================================
		// Protected Methods
		// ==========================================================================

		/**
		 * show call rejected alert
		 */
		protected async alertCallRejected(): Promise<void>
		{
			const alert = await alertController.create({
				header: "No Answer",
				message: "The patient did not accept the call",
				buttons: [
					{
						text: "Dismiss",
					},
				],
			});

			await alert.present();
		}

		// ==========================================================================
		// Private Methods
		// ==========================================================================

		/**
		 * Gets the attachments from the chat items.
		 * @param patientId - The patient id.
		 * @param chatItems - The chat items.
		 * @returns The attachments.
		 */
		private async getAttachments(patientId: string, chatItems: ChatItem[]): Promise<AttachmentCreateTransfer[]>
		{
			const documentService = new DocumentService();
			const attachmentCreateTransferFactory = new AttachmentCreateTransferFactory();

			const attachments: AttachmentCreateTransfer[] = [];
			for (const item of chatItems)
			{
				if (item.type === ChatItemType.DOCUMENT)
				{
					const document = await documentService.getDocument(item.message);
					const base64Data = await document.base64Data();
					const documentSize = FileUtil.base64ToBlob(base64Data, document.type).size;

					attachments.push(
						attachmentCreateTransferFactory.create(patientId, document.name, document.type, documentSize, base64Data),
					);
				}
			}
			return attachments;
		}
	}
