import {ErrorResponse} from "@/lib/models/Errors/ErrorResponse";
import {ErrorCode} from "@/open_api/generated";

export class NotImplementedError extends ErrorResponse
{
	private static readonly DEFAULT_MESSAGE = "Not Implemented Error";

	constructor(message: string = NotImplementedError.DEFAULT_MESSAGE, data = {})
	{
		super({ code: ErrorCode.NotImplemented, message, data });
	}
}
