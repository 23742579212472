
import BaseService from "@/lib/services/BaseService";
import {MessageCreateTransfer} from "@/open_api/generated";
import {ClinicAPI} from "@/lib/services/Api";
import {NotImplementedError} from "@/lib/models/Errors/NotImplementedError";

export default class MessagingService extends BaseService
{
	/**
	 * create a new message
	 * @return - the newly created message
	 */
	public async createMessage(message: MessageCreateTransfer): Promise<MessageCreateTransfer>
	{
		return await this.doByUserType(() =>
		{
			throw new NotImplementedError("Messaging as Patient::User not implemented in this service, use MailboxService instead.");
		},
		() => ClinicAPI().clinicMessageCreate(message));
	}
}
