
	import {Options, Prop, Vue} from "vue-property-decorator";
	import { IonButton, IonIcon } from "@ionic/vue";

	@Options({components: {IonButton, IonIcon}})
	export default class Star extends Vue
	{
		@Prop({type: Boolean}) filled: boolean;
	}

