
	import {Options, Prop, Vue} from "vue-property-decorator";
	import {ChatItem} from "@/views/patient_user/telehealth/types";
	import {ClinicAppointmentType, PatientUserDto, PatientUserType} from "@/open_api/generated";
	import {ButtonColor, ButtonColorPattern} from "@/components/Buttons/types";
	import ChatView from "@/components/telehealth/ChatView.vue";
	import PulsingIndicator from "@/components/Cosmetic/PulsingIndicator.vue";
	import BaseButton from "@/components/Buttons/BaseButton.vue";
	import VideoViewTopControls from "@/views/clinic_user/appointment/components/VideoViewTopControls.vue";

	@Options({
		computed: {
			ClinicAppointmentType()
			{
				return ClinicAppointmentType;
			},
		},
		components: {
			ChatView,
			PulsingIndicator,
			BaseButton,
			VideoViewTopControls,
		},
		emits: ["sendMessage", "sendFile", "signalTyping", "exit", "saveToEncounterNote"],
	})
	export default class ProviderChatView extends Vue
	{
		@Prop({type: Array, required: true}) chatItems: ChatItem[];
		@Prop({type: Boolean, default: false}) patientConnected: boolean;
		@Prop({type: Object, required: true}) patientProfile: PatientUserDto;
		@Prop({type: Boolean, default: false}) showTypingIndication: boolean;
		@Prop({type: Boolean, default: false}) showWriteToEncounterButton: boolean;

		public ButtonColor = ButtonColor;
		public ButtonColorPattern = ButtonColorPattern;
		public doesEncounterNoteEndpointExist = false;

		// ==========================================================================
		// Public Methods
		// ==========================================================================

		public sendTextMessage(message: string): void
		{
			this.$emit("sendMessage", message);
		}

		public sendDocumentMessage(file: File): void
		{
			this.$emit("sendFile", file);
		}

		public signalTyping(): void
		{
			this.$emit("signalTyping", null);
		}

		public async saveToEncounterNote(): Promise<void>
		{
			this.$emit("saveToEncounterNote");
		}

		// ==========================================================================
		// Getters
		// ==========================================================================

		/**
		 * check if there is at least one chat message
		 */
		get hasChatMessages(): boolean
		{
			return !!this.chatItems && this.chatItems.length > 0;
		}

		/**
		 * change indicator color based on remote connection status (if some one else is in the chat).
		 */
		get chatStateIndicatorClasses(): any
		{
			return {
				connected: this.patientConnected,
				"not-connected": !this.patientConnected,
			};
		}

		/**
		 * get the message to display above the chat box when in a chat type appointment
		 * @return message
		 */
		get chattingWithMessage(): string
		{
			if (this.patientConnected)
			{
				return `Chatting with ${this.patientProfile.first_name}`;
			}
			else
			{
				return `${this.patientProfile.first_name} isn't in chat yet`;
			}
		}

		/**
		 * Can you send documents as chat messages or not
		 */
		get isDocumentChatAvailable(): boolean
		{
			return !!this.patientProfile && this.patientProfile.patient_type !== PatientUserType.Kiosk;
		}
	}
