import AuthStore from "@/lib/vuex/auth.store";

export default class FileUtil
{
	public static readonly REMOTE_REQUEST_TIMEOUT_MS = 120000; // 2 minutes

	// ==========================================================================
	// Public Class Methods
	// ==========================================================================

	/**
	 * download remote file from an authenticated source and return a base 64 version of it.
	 * @param url - the url from which to fetch the resource.
	 * @return a promise that resolves to the base64 data
	 */
	public static async getAuthenticatedFileAsBase64(url): Promise<string>
	{
		const request = new XMLHttpRequest();
		request.open("GET", url);
		request.setRequestHeader("X-Auth-Token", AuthStore.loginToken.tokenStr);
		return await this.blobToBase64(await this.downloadFile(request));
	}

	/**
	 * convert binary blob to base64 string
	 * @param blob - the binary blob to convert
	 * @return promise that resolves to base 64 string of the binary blob
	 */
	public static blobToBase64(blob: Blob): Promise<string>
	{
		// js bota (binary to ascii) cannot actually operate on binary data, go figure. This is a work around.
		return new Promise((resolve, reject) =>
		{
			const reader = new FileReader();
			reader.onloadend = () =>
			{
				resolve((reader.result as string).split(",")[1]);
			};
			reader.readAsDataURL(blob);
		});
	}

	/**
	 * convert base64 string to binary blob
	 * @param base64      - the base64 string to convert
	 * @param contentType - the content type of the base64 string
	 * @link https://stackoverflow.com/questions/16245767/creating-a-blob-from-a-base64-string-in-javascript
	 */
	public static base64ToBlob(base64: string, contentType: string): Blob
	{
		const sliceSize = 512;
		const byteCharacters = atob(base64);
		const byteArrays = [];
		for (let offset = 0; offset < byteCharacters.length; offset += sliceSize)
		{
			const slice = byteCharacters.slice(offset, offset + sliceSize);

			const byteNumbers = new Array(slice.length);
			for (let i = 0; i < slice.length; i++)
			{
				byteNumbers[i] = slice.charCodeAt(i);
			}

			const byteArray = new Uint8Array(byteNumbers);
			byteArrays.push(byteArray);
		}
		return new Blob(byteArrays, {type: contentType});
	}

	// ==========================================================================
	// Protected Class Methods
	// ==========================================================================

	/**
	 * download a file and return result binary
	 * @param request - the request to execute.
	 * @return promise that resolves to file binary
	 * @protected
	 */
	protected static downloadFile(request: XMLHttpRequest): Promise<any>
	{
		// Should use the FetchAPI. FetchAPI is more effective / modern. However, browser support is not yet sufficient.
		const requestPromise = new Promise((resolve, reject) =>
		{
			request.responseType = "blob";
			request.send();
			request.onload = () =>
			{
				resolve(request.response);
			};
		});

		const timeout = new Promise((resolve, reject) =>
		{
			setTimeout(reject, FileUtil.REMOTE_REQUEST_TIMEOUT_MS);
		});

		return Promise.race([requestPromise, timeout]);
	}
}
