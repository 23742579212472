

	import {ButtonColor, ButtonColorPattern} from "@/components/Buttons/types";
	import {alertController, popoverController} from "@ionic/vue";
	import ModalBaseController from "@/lib/modal/modalBaseController";

	export default class CloseLiveChatModal extends ModalBaseController
	{
		ButtonColor = ButtonColor;
		ButtonColorPattern = ButtonColorPattern;
		public popoverController = popoverController;

		// ==========================================================================
		// Vue lifecycle
		// ==========================================================================

		public beforeUnmount()
		{
			alertController.getTop()
				.then((a) =>
				{
					if (a)
					{
						a.dismiss();
					}
				});
		}

		// ==========================================================================
		// Public Methods
		// ==========================================================================

		// ==========================================================================
		// Getters
		// ==========================================================================
	}
