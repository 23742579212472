

	import {Options, Vue, Prop} from "vue-property-decorator";
	import CorporateFooter from "@/views/patient_user/login/CorporateFooter.vue";
	import WhitelabelPrimaryLogo from "@/views/public/whitelabel/components/WhitelabelPrimaryLogo.vue";
	import {AqsAppointmentDto, AqsAppointmentDtoStatusEnum} from "@/open_api/generated";
	import {AqsPatientAPI} from "@/lib/services/Api";
	import {NotificationSeverity, NotificationType, NotifyEvent, WebNotification} from "@/lib/types/Notifier";
	import MHABackendLogger from "@/lib/utils/MHABackendLogger";
	import LoadingQueue from "@/lib/LoadingQueue";
	import { ordinalAbbreviation } from "@/lib/utils/Internationalization";
	import OrganizationConfigurationStore from "@/lib/vuex/modules/OrganizationConfigurationStore";

	@Options({components: {CorporateFooter, WhitelabelPrimaryLogo}})
	export default class OnDemandBookingWaitingRoom extends Vue
	{
		@Prop({type: String, required: true}) clinicId: string;
		@Prop({type: String, required: true}) queuedAppointmentId: string;
		@Prop({type: String, required: true}) queueId: string;
		public queuedAppointment: AqsAppointmentDto = null;

		private QUEUED_APPOINTMENT_POLL_INTERVAL = 30000;
		private MAX_FAILED_PINGS = 5;
		private queueAppointmentCheckInterval = null;
		private canceledStatuses = [
			AqsAppointmentDtoStatusEnum.DELETED,
			AqsAppointmentDtoStatusEnum.CANCELLED,
			AqsAppointmentDtoStatusEnum.EXPIRED,
		];

		// tracks the number of failed ping attempts
		private failedPingCount = 0;
		private loadingQueue: LoadingQueue = new LoadingQueue();

		public async created(): Promise<void>
		{
			await this.loadQueuedAppointment(false);
			this.checkAppointmentStatus();

			// set up polling for queued appointment
			this.queueAppointmentCheckInterval = window.setInterval(async () =>
			{
				await this.loadQueuedAppointment(true);
				this.checkAppointmentStatus();
			}, this.QUEUED_APPOINTMENT_POLL_INTERVAL);
		}

		public unmounted(): void
		{
			// clean up queued appointment check interval
			if (this.queueAppointmentCheckInterval)
			{
				window.clearInterval(this.queueAppointmentCheckInterval);
			}
		}

		public async onCancel(): Promise<void>
		{
			this.loadingQueue.pushLoadingState();
			WebNotification.$emit({
				event: NotifyEvent.Generic,
				type: NotificationType.Confirm,
				severity: NotificationSeverity.Reminder,
				title: this.$mhat("OnDemandBookingWaitingRoom.CancelAppointmentTitle"),
				message: this.$mhat("OnDemandBookingWaitingRoom.CancelAppointmentConfirmationMessage"),
				confirm: {
					message: this.$mhat("OnDemandBookingWaitingRoom.Yes"),
					callback: async () =>
					{
						try
						{
							await AqsPatientAPI().dequeueQueuedAppointment(
								this.clinicId,
								this.queueId,
								this.queuedAppointmentId);
						}
						finally
						{
							// no matter what happens leave the telehealth session.
							this.loadingQueue.popLoadingState();
							this.$emit("exit");
						}
					},
				},
				dismiss: {
					message: this.$mhat("OnDemandBookingWaitingRoom.No"),
					callback: () =>
					{
						this.loadingQueue.popLoadingState();
					},
				},
			});
		}

		private async loadQueuedAppointment(ping: boolean): Promise<void>
		{
			try
			{
				if (ping)
				{
					this.queuedAppointment = (await AqsPatientAPI().pingQueuedAppointment(
						this.clinicId,
						this.queueId,
						this.queuedAppointmentId)).data;
				}
				else
				{
					this.queuedAppointment = (await AqsPatientAPI().getQueuedAppointment(
						this.clinicId,
						this.queueId,
						this.queuedAppointmentId)).data;
				}

				this.failedPingCount = 0;
			}
			catch (err)
			{
				// some thing went wrong
				this.failedPingCount++;
				MHABackendLogger.error(err);

				// if we fail to ping to many times alert user
				if (this.failedPingCount >= this.MAX_FAILED_PINGS)
				{
					WebNotification.$clear();

					WebNotification.$emit(
						{
							event: NotifyEvent.Generic,
							type: NotificationType.Dismiss,
							severity: NotificationSeverity.Critical,
							title: this.$mhat("OnDemandBookingWaitingRoom.ConnectionErrorTitle"),
							message: this.queuedAppointment.status_message,
							dismiss: {
								message: this.$mhat("OnDemandBookingWaitingRoom.ConnectionErrorAction"),
								callback: () => window.location.reload(),
							},
						});
				}
			}
		}

		private checkAppointmentStatus(): void
		{
			if (this.queuedAppointment && this.queuedAppointment.status === AqsAppointmentDtoStatusEnum.SCHEDULED)
			{
				this.$emit("toExamRoom");
			}
			else if (this.queuedAppointment && this.canceledStatuses.includes(this.queuedAppointment.status))
			{ // appointment canceled
				WebNotification.$emit(
					{
						event: NotifyEvent.Generic,
						type: NotificationType.Dismiss,
						severity: NotificationSeverity.Warning,
						title: this.$mhat("OnDemandBookingWaitingRoom.AppointmentCancelledMessage"),
						message: this.queuedAppointment.status_message,
						dismiss: {
							message: OrganizationConfigurationStore.telehealthGoToOverlayOnCallExit
								? this.$mhat("OnDemandBookingWaitingRoom.Exit")
								: this.$mhat("OnDemandBookingWaitingRoom.GoHome"),
							callback: () => this.$emit("exit"),
						},
					});
			}
		}

		get queuePosition()
		{
			if (this.queuedAppointment)
			{
				return this.queuedAppointment.queue_position;
			}
			return 0;
		}

		get queuePositionText(): string
		{
			const queuePosition = Math.max(this.queuePosition + 1, 0);
			return queuePosition.toString() + ordinalAbbreviation(queuePosition);
		}

		get showFooter(): boolean
		{
			return OrganizationConfigurationStore.telehealthWaitingRoomShowFooter;
		}
	}
