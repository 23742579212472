
	import {ButtonColor, ButtonColorPattern, ButtonSize} from "@/components/Buttons/types";
	import {Options, Prop, Vue} from "vue-property-decorator";
	import CrossFrameCommunicationService from "@/lib/integration/iframe/service/CrossFrameCommunicationService";
	import {CrossFrameMessageType} from "@/lib/integration/iframe/model/CrossFrameMessageType";
	import {Route} from "@/router/router";
	import TextArea from "@/components/Inputs/TextArea.vue";
	import {$mhat} from "@/i18n";
	import PageWrapper from "@/components/Layout/PageWrapper.vue";
	import {AqsPatientAPI, PatientAPI} from "@/lib/services/Api";
	import RegularTelehealthApiService from "@/lib/telehealth/api/RegularTelehealthApiService";
	import BaseSelectionCard from "@/components/Buttons/BaseSelectionCard.vue";
	import ActionButton from "@/components/Buttons/ActionButton.vue";
	import BaseButton from "@/components/Buttons/BaseButton.vue";
	import {ClinicAppointmentTransfer} from "@/open_api/generated";
	import MHABackendLogger from "@/lib/utils/MHABackendLogger";
	import NotificationService from "@/components/Notification/NotificationService";
	import {NotificationSeverity, NotificationType, NotifyEvent} from "@/lib/types/Notifier";
	import BaseModal from "@/components/Modals/BaseModal.vue";
	import ModalBaseController from "@/lib/modal/modalBaseController";

	@Options({
		methods: {$mhat},
		components: {BaseModal, BaseButton, ActionButton, BaseSelectionCard, TextArea, PageWrapper},
		computed: {
			ButtonSize()
			{
				return ButtonSize;
			},
			ButtonColor()
			{
				return ButtonColor;
			},
			ButtonColorPattern()
			{
				return ButtonColorPattern;
			},
		},
	})
	export default class FeedbackOverlay extends ModalBaseController
	{
		@Prop({type: String, required: false}) public clinicId: string;
		@Prop({type: String, required: false}) public queuedAppointmentId: string;
		@Prop({type: String, required: false}) public queueId: string;
		@Prop({type: String, required: false}) public appointmentId: string;

		public preLoading = true;
		public ButtonColor = ButtonColor;
		public ButtonColorPattern = ButtonColorPattern;
		public feedbackText = "";
		public rating;

		private isButtonActive = [false, false, false];

		mounted()
		{
			setTimeout(() =>
			{
				this.preLoading = false;
			}, 40);
		}

		rateFive()
		{
			this.isButtonActive = [true, false, false];
			this.rating = 5;
		}

		rateThree()
		{
			this.isButtonActive = [false, true, false];
			this.rating = 3;
		}

		rateOne()
		{
			this.isButtonActive = [false, false, true];
			this.rating = 1;
		}

		/**
		 * Go back to the home page
		 */
		public onBackToHome(): void
		{
			const crossFrameService = new CrossFrameCommunicationService();
			if (crossFrameService.hasParentFrame)
			{
				// let any parent frames know we want to go back to the home page
				crossFrameService.sendAsyncMessage({type: CrossFrameMessageType.NavigateToHome});
			}
			this.$mhaRouterPush(Route.Home);
		}

		public async submitFeedback(): Promise<void>
		{
			const appointment = await this.getAppointment(this.clinicId, this.queuedAppointmentId, this.queueId, this.appointmentId);
			if (appointment)
			{
				const regularTelehealthApiService = new RegularTelehealthApiService(appointment.patient_clinic_appointment_id);
				appointment.rating = this.rating;
				appointment.feedback = this.feedbackText.valueOf();
				await regularTelehealthApiService.updateClinicAppointment(appointment);
				this.dismissModal();
			}
		}

		// ==========================================================================
		// Getters
		// ==========================================================================

		private async getAppointment(clinicId: string, queuedAppointmentId: string, queueId: string, appointmentId: string): Promise<ClinicAppointmentTransfer>
		{
			// AQS mode
			if (clinicId && queuedAppointmentId && queueId)
			{
				const aqsPatientAPI = AqsPatientAPI();
				return (await aqsPatientAPI.getAssociatedClinicAppointment(clinicId, queueId, queuedAppointmentId)).data;
			}

			// Regular telehealth mode
			else if (appointmentId)
			{
				const patientAPI = PatientAPI();
				return (await patientAPI.getPatientClinicAppointment(appointmentId)).data;
			}

			MHABackendLogger.error("FeedbackOverlay error sending feedback. Missing required fields.");
			NotificationService.notify(
				{
					event: NotifyEvent.Generic,
					type: NotificationType.Confirm,
					severity: NotificationSeverity.Critical,
					title: $mhat("KiiMedicalCareRatingOverlay.ErrorNotificationTitle"),
					message: $mhat("KiiMedicalCareRatingOverlay.ErrorNotificationMessage"),
					confirm: {
						message: this.$mhat("KiiMedicalCareRatingOverlay.ErrorNotificationBackToHome"),
						callback: async () =>
						{
							this.onBackToHome();
						},
					},
				},
			);
		}
	}
