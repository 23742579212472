import { normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-1bf0193e"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "m-4" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ion_button = _resolveComponent("ion-button")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_ion_button, {
      fill: "clear",
      class: "star-button"
    }, {
      default: _withCtx(() => [
        _createElementVNode("i", {
          class: _normalizeClass(["fa-3x", _ctx.filled === true ? 'fa-solid fa-star': 'fa-regular fa-star'])
        }, null, 2)
      ]),
      _: 1
    })
  ]))
}