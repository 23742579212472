
	import {Options, Vue} from "vue-property-decorator";
	import {ErrorResponse} from "@/lib/models/Errors/ErrorResponse";
	import {ErrorCode} from "@/open_api/generated";
	import NotificationService from "@/components/Notification/NotificationService";
	import ConfigStore from "@/lib/vuex/modules/Config.store";
	import BaseButton from "@/components/Buttons/BaseButton.vue";
	import PageWrapper from "@/components/Layout/PageWrapper.vue";
	import {ButtonColor, ButtonColorPattern} from "@/components/Buttons/types";
	import LiveChatService from "@/lib/appointment/aqs/service/patient/LiveChatService";
	import TextArea from "@/components/Inputs/TextArea.vue";
	import {$mhat} from "@/i18n";
	import {AqsPatientAPI} from "@/lib/services/Api";
	import CrossFrameCommunicationService from "@/lib/integration/iframe/service/CrossFrameCommunicationService";
	import {CrossFrameMessageType} from "@/lib/integration/iframe/model/CrossFrameMessageType";
	import { IonSpinner } from "@ionic/vue";

	@Options({
		methods: {$mhat},
		components: {TextArea, PageWrapper, BaseButton, IonSpinner},
	})
	export default class LiveChatBooking extends Vue
	{
		public bookingClosed = false;
		public ButtonColor = ButtonColor;
		public ButtonColorPattern = ButtonColorPattern;
		private reason = null;
		private firstMessage = "";
		private disableStartChatButton = false;

		// ==========================================================================
		// Vue life cycle hooks
		// ==========================================================================

		public async created(): Promise<void>
		{
			const liveChatService = new LiveChatService();
			if (!await liveChatService.isLiveChatQueueAvailable())
			{
				this.crossFrameSendQueueUnavailable();
			}
			await this.attemptReconnectToChat();
		}

		// ==========================================================================
		// Public Methods
		// ==========================================================================

		public async attemptReconnectToChat(): Promise<void>
		{
			const clinicId = ConfigStore.configConstants.fusion_clinic_id;
			const existingChat = await (new LiveChatService()).getExistingChat();
			if (existingChat != null)
			{
				// check if chat was ended by user and don't reconnect if it was
				const aqsPatientAPI = AqsPatientAPI();
				const appointment = (await aqsPatientAPI.getAssociatedClinicAppointment(clinicId, existingChat.queue_id, existingChat.id)).data;
				if (appointment.appointment_ended)
				{
					return;
				}
				// Otherwise rejoin
				this.$mhaRouterReplace(
					await this.Route.Appointments.TelehealthAqs(clinicId, existingChat.queue_id, existingChat.id),
					{
						params: {
							clinicId: clinicId,
							queueId: existingChat.queue_id,
							queuedAppointmentId: existingChat.id,
							firstMessage: this.firstMessage,
						},
					},
				);
			}
		}

		/**
		 * book a live chat and redirect
		 */
		public async bookLiveChat(): Promise<void>
		{
			this.disableStartChatButton = true;
			try
			{
				if (this.reason == null || this.firstMessage === "")
				{
					this.disableStartChatButton = false;
					return;
				}
				const clinicId = ConfigStore.configConstants.fusion_clinic_id;

				const appointment = await (new LiveChatService()).createLiveChatSession(this.reason);

				this.$mhaRouterReplace(
					await this.Route.Appointments.TelehealthAqs(clinicId, appointment.queue_id, appointment.id),
					{
						params: {
							clinicId: clinicId,
							queueId: appointment.queue_id,
							queuedAppointmentId: appointment.id,
							firstMessage: this.firstMessage,
						},
					},
				);
			}
			catch (error)
			{
				if (error instanceof ErrorResponse && error.is(ErrorCode.AqsQueueAvailability))
				{
					this.crossFrameSendQueueUnavailable();
				}
				else
				{
					// Generic error
					console.error(error);
					NotificationService.genericErrorNotification();
				}
				this.disableStartChatButton = false;
			}
		}

		// ==========================================================================
		// Private Methods
		// ==========================================================================

		private crossFrameSendQueueUnavailable(): void
		{
			const crossFrameCommunicationService = new CrossFrameCommunicationService();
			if (crossFrameCommunicationService.hasParentFrame)
			{
				crossFrameCommunicationService.sendAsyncMessage({type: CrossFrameMessageType.ChatUnavailable});
			}
		}
	}
