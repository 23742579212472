
	import {Options} from "vue-property-decorator";
	import WaitingRoomOverlay from "@/views/patient_user/telehealth/components/WaitingRoomOverlay.vue";
	import ChatView from "@/components/telehealth/ChatView.vue";
	import PatientTelehealthHeader from "@/views/patient_user/appointment/components/PatientTelehealthHeader.vue";
	import TelehealthWrapper from "@/components/telehealth/TelehealthWrapper.vue";
	import AppChip from "@/components/Buttons/AppChip/AppChip.vue";
	import TelehealthBase from "@/components/telehealth/TelehealthBase.vue";
	import OnDemandBookingTelehealthWaitingRoomOverlay
		from "@/views/patient_user/odb/telehealth/OnDemandBookingTelehealthWaitingRoomOverlay.vue";
	import OrganizationConfigurationStore from "@/lib/vuex/modules/OrganizationConfigurationStore";
	import {IonPage, modalController} from "@ionic/vue";
	import EventStateSpinner from "@/components/EventState/EventStateSpinner.vue";
	import VideoViewForIonSlides from "@/components/telehealth/VideoViewForIonSlides.vue";
	import FeedbackOverlay from "@/views/patient_user/telehealth/components/FeedbackOverlay.vue";

	@Options({
		components: {
			FeedbackOverlay,
			VideoViewForIonSlides,
			OnDemandBookingTelehealthWaitingRoomOverlay,
			IonPage,
			EventStateSpinner,
			AppChip,
			TelehealthWrapper,
			PatientTelehealthHeader,
			ChatView,
			WaitingRoomOverlay,
		},
	})
	export default class TelehealthPatient extends TelehealthBase
	{
		protected hasBeenPromptedForFeedback = false;
		// ==========================================================================
		// Public Methods
		// ==========================================================================

		public async promptForFeedback(isCancelled)
		{
			if (this.hasBeenPromptedForFeedback) return;
			this.hasBeenPromptedForFeedback = true;
			if (OrganizationConfigurationStore.telehealthShowFeedbackOverlay && !isCancelled)
			{
				await this.showRatingModal();
			}
		}

		public async onExit(isCancelled = false)
		{
			await this.endCall(isCancelled);
			if (!OrganizationConfigurationStore.telehealthGoToOverlayOnCallExit)
			{
				this.$router.back();
			}
		}

		public async endCall(isCancelled = false)
		{
			await super.endCall();
			await this.promptForFeedback(isCancelled);
		}

		public async onCallEnd(remoteId: string): Promise<boolean>
		{
			await super.onCallEnd(remoteId);
			await this.endCall();
			return true;
		}

		// ==========================================================================
		// Protected Methods
		// ==========================================================================

		protected async showRatingModal(): Promise<boolean>
		{
			const endConversationModal = await modalController
				.create({
					component: FeedbackOverlay,
					showBackdrop: true,
					cssClass: "large-modal",
					componentProps: {
						clinicId: this.clinicId,
						queueId: this.queueId,
						queuedAppointmentId: this.queuedAppointmentId,
						appointmentId: this.appointmentId,
					},
				});

			await endConversationModal.present();
			return (await endConversationModal.onDidDismiss()).data;
		}

		// ==========================================================================
		// Getters
		// ==========================================================================

		get shouldShowOverlay(): boolean
		{
			return !this.inRoom() && !(this.isVoipCall && this.isLoading);
		}
	}
