import {getModule, Module, VuexModule} from "vuex-module-decorators";
import store from "@/lib/vuex/store";
import OrganizationStore from "@/lib/vuex/modules/OrganizationStore";
import {
	BookingCompletePageMode,
	TelehealthCallEndBehaviour,
	TelehealthCallExitBehaviour,
} from "@/open_api/generated";

// Use this store to query organization based configuration settings
@Module({dynamic: true, store, name: "organizationConfigurationStore", namespaced: true})
class OrganizationConfigurationStore extends VuexModule
{
	// ==========================================================================
	// Getters [Telehealth]
	// ==========================================================================

	// the call over overlay should be presented as a splash screen.
	get telehealthShowSplashScreenOnCallEnd(): boolean
	{
		return OrganizationStore.isWhiteLabel &&
			OrganizationStore.organizationConfiguration.telehealth.call_end_behaviour === TelehealthCallEndBehaviour.Splash;
	}

	// when routing away form a telehealth call go to the overlay instead of the home page.
	get telehealthGoToOverlayOnCallExit(): boolean
	{
		return OrganizationStore.isWhiteLabel &&
			OrganizationStore.organizationConfiguration.telehealth.call_exit_behaviour === TelehealthCallExitBehaviour.Overlay;
	}

	// true / false show the footer in the telehealth waiting room.
	get telehealthWaitingRoomShowFooter(): boolean
	{
		return !OrganizationStore.isWhiteLabel ||
			OrganizationStore.organizationConfiguration.telehealth.waiting_room.show_footer;
	}

	get telehealthShowFeedbackOverlay(): boolean
	{
		return !OrganizationStore.isWhiteLabel ||
			OrganizationStore.organizationConfiguration.telehealth.call_end_behaviour === TelehealthCallEndBehaviour.Feedback;
	}

	// ==========================================================================
	// Getters [Booking]
	// ==========================================================================

	// if true booking complete page should show splash screen.
	get bookingCompleteShowSplashScreen(): boolean
	{
		return OrganizationStore.isWhiteLabel &&
			OrganizationStore.organizationConfiguration.booking.booking_complete.mode === BookingCompletePageMode.Splash;
	}

	// ==========================================================================
	// Getters [Appointment pages]
	// ==========================================================================

	// true / false show close button on the appointment confirmed page
	get appointmentConfirmedShowCloseButton(): boolean
	{
		return !OrganizationStore.isWhiteLabel ||
			OrganizationStore.organizationConfiguration.appointment.confirmation_page.show_close_button;
	}
}

export default getModule(OrganizationConfigurationStore);
