
	import {Options, Vue, Prop} from "vue-property-decorator";

	import {ButtonColor, ButtonColorPattern} from "@/components/Buttons/types";
	import QueuedAppointmentService from "@/lib/appointment/aqs/service/patient/QueuedAppointmentService";
	import {QueuedAppointmentType} from "@/lib/appointment/aqs/model/QueuedAppointmentType";
	import AppointmentQueueService from "@/lib/appointment/aqs/service/patient/AppointmentQueueService";
	import NotificationService from "@/components/Notification/NotificationService";
	import {NotificationSeverity, NotificationType, NotifyEvent} from "@/lib/types/Notifier";
	import {ErrorResponse} from "@/lib/models/Errors/ErrorResponse";
	import {ErrorCode} from "@/open_api/generated";
	import {modalController} from "@ionic/vue";
	import OnDemandBookingHoursModal from "@/views/patient_user/odb/booking/OnDemandBookingHoursModal.vue";
	import AuthStore from "@/lib/vuex/auth.store";
	import PatientProfileService from "@/lib/patient/PatientProfileService";

	@Options({components: {}})
	export default class AqsAppointmentType extends Vue
	{
		@Prop({type: String, required: true}) clinicId: string;
		@Prop({type: Boolean, default: true}) bookAppointment: boolean;
		@Prop({type: String}) forwardRoute: string;
		@Prop({type: String, required: false}) backRoute: string;
		@Prop({type: Boolean, required: false, default: false}) hideHeader: boolean;
		@Prop({type: String, default: null}) service: string;

		public ButtonColor = ButtonColor;
		public ButtonColorPattern = ButtonColorPattern;
		public QueuedAppointmentType = QueuedAppointmentType;

		private buttonsDisabled = false;

		private serviceMap = {
			medicalAdvice: "Advice",
			coldFluSymptoms: "Symptom",
			minorAilments: "Ailment",
			labWorkDiagnostics: "Referral",
			refill: "Rx: Refill",
			changeDose: "Rx: Dose",
			womensHealth: "Women",
			infantChildMedicalCare: "Infant",
		};

		// ==========================================================================
		// Public Methods
		// ==========================================================================

		/**
		 * called when a type is selected
		 * @param type - the type that was selected
		 */
		public async onTypeSelect(type: QueuedAppointmentType): Promise<void>
		{
			this.buttonsDisabled = true;
			if (this.bookAppointment)
			{
				await this.bookAqsAppointment(type, this.service);
			}
			else
			{
				this.buttonsDisabled = false;
			}
		}

		/**
		 * book a aqs appointment.
		 * @param type - the type to book
		 * @param service - service appointment booked for
		 */
		public async bookAqsAppointment(type: QueuedAppointmentType, service: string): Promise<void>
		{
			let queue = null;
			// Build the reason field
			let reason = type.toString();

			if (service) // Fusion specific, this won't be populated for non fusion clinics
			{
				const patientProfileService = new PatientProfileService();
				const patientProfile = patientProfileService.getPatientProfile(AuthStore.loggedInPatient.id);
				reason = [
					reason,
					patientProfile.locale.toUpperCase(),
					patientProfile.addressProvinceCode,
					this.serviceMap[service],
					patientProfile.fusionEAC,
					patientProfile.fusionOrganization,
				].join(" | ");
			}

			try
			{
				queue = await (new AppointmentQueueService()).getODBQueueForClinic(this.clinicId);
				const appointment = await (new QueuedAppointmentService()).bookQueuedAppointment(
					this.clinicId,
					queue.id,
					type,
					reason,
					null,
					true);

				this.$mhaRouterReplace(
					await this.Route.Appointments.TelehealthAqs(this.clinicId, appointment.queue_id, appointment.id),
					{
						params: {clinicId: this.clinicId, queueId: appointment.queue_id, queuedAppointmentId: appointment.id},
					},
				);
			}
			catch (error)
			{
				if (queue != null && error instanceof ErrorResponse && error.is(ErrorCode.AqsQueueAvailability))
				{
					// Clinic closed. Allow user to see hours
					NotificationService.notify({
						event: NotifyEvent.Generic,
						type: NotificationType.Confirm,
						severity: NotificationSeverity.Warning,
						title: this.$mhat("AqsAppointmentType.ClinicClosedTitle"),
						message: this.$mhat("AqsAppointmentType.ClinicClosedMessage"),
						confirm: {
							message: this.$mhat("AqsAppointmentType.ClinicClosedConfirmMessage"),
							callback: async () =>
							{
								const modal = await modalController.create(
									{
										component: OnDemandBookingHoursModal,
										cssClass: "medium-modal",
										componentProps: {queue},
									});
								await modal.present();
							},
						},
					});
				}
				else
				{
					// Generic error
					NotificationService.notify(
						{
							event: NotifyEvent.Generic,
							type: NotificationType.Dismiss,
							severity: NotificationSeverity.Critical,
							title: this.$mhat("AqsAppointmentType.GenericErrorTitle"),
							message: this.$mhat("AqsAppointmentType.GenericErrorMessage"),
						});
				}
			}
		}
	}
