// tslint:disable
/// <reference path="./custom.d.ts" />
/**
 * MyHealthAccess API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as globalImportUrl from 'url';
import { Configuration } from './configuration';
import globalAxios, { AxiosPromise, AxiosInstance } from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from './base';

/**
 * 
 * @export
 * @interface AnalyticsEvent
 */
export interface AnalyticsEvent {
    /**
     * 
     * @type {string}
     * @memberof AnalyticsEvent
     */
    event: string;
    /**
     * 
     * @type {object}
     * @memberof AnalyticsEvent
     */
    data: object;
}
/**
 * 
 * @export
 * @interface AppointmentBookSuccess
 */
export interface AppointmentBookSuccess {
    /**
     * 
     * @type {string}
     * @memberof AppointmentBookSuccess
     */
    status?: string;
}
/**
 * 
 * @export
 * @interface AppointmentCancelSuccess
 */
export interface AppointmentCancelSuccess {
    /**
     * 
     * @type {string}
     * @memberof AppointmentCancelSuccess
     */
    remote_id?: string;
    /**
     * 
     * @type {boolean}
     * @memberof AppointmentCancelSuccess
     */
    success?: boolean;
}
/**
 * 
 * @export
 * @interface AppointmentTransfer
 */
export interface AppointmentTransfer {
    /**
     * 
     * @type {string}
     * @memberof AppointmentTransfer
     */
    id?: string;
    /**
     * 
     * @type {string}
     * @memberof AppointmentTransfer
     */
    appointment_id?: string;
    /**
     * 
     * @type {string}
     * @memberof AppointmentTransfer
     */
    patient_id?: string;
    /**
     * 
     * @type {string}
     * @memberof AppointmentTransfer
     */
    provider_id?: string;
    /**
     * 
     * @type {string}
     * @memberof AppointmentTransfer
     */
    start_datetime?: string;
    /**
     * 
     * @type {string}
     * @memberof AppointmentTransfer
     */
    start_datetime_utc?: string;
    /**
     * 
     * @type {string}
     * @memberof AppointmentTransfer
     */
    end_datetime?: string;
    /**
     * 
     * @type {string}
     * @memberof AppointmentTransfer
     */
    end_datetime_utc?: string;
    /**
     * 
     * @type {boolean}
     * @memberof AppointmentTransfer
     */
    booked_by_patient?: boolean;
    /**
     * 
     * @type {ClinicAppointmentType}
     * @memberof AppointmentTransfer
     */
    appointment_type?: ClinicAppointmentType;
    /**
     * 
     * @type {boolean}
     * @memberof AppointmentTransfer
     */
    can_cancel?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof AppointmentTransfer
     */
    is_cancelled?: boolean;
    /**
     * 
     * @type {string}
     * @memberof AppointmentTransfer
     */
    notes?: string;
    /**
     * 
     * @type {string}
     * @memberof AppointmentTransfer
     */
    reason?: string;
    /**
     * 
     * @type {number}
     * @memberof AppointmentTransfer
     */
    duration_minutes?: number;
    /**
     * 
     * @type {string}
     * @memberof AppointmentTransfer
     */
    location?: string;
    /**
     * 
     * @type {string}
     * @memberof AppointmentTransfer
     */
    remote_id?: string;
    /**
     * 
     * @type {string}
     * @memberof AppointmentTransfer
     */
    remote_patient_id?: string;
    /**
     * 
     * @type {string}
     * @memberof AppointmentTransfer
     */
    remote_provider_id?: string;
    /**
     * 
     * @type {string}
     * @memberof AppointmentTransfer
     */
    provider_name?: string;
    /**
     * 
     * @type {string}
     * @memberof AppointmentTransfer
     */
    clinic_id?: string;
    /**
     * 
     * @type {string}
     * @memberof AppointmentTransfer
     */
    clinic_name?: string;
    /**
     * 
     * @type {string}
     * @memberof AppointmentTransfer
     */
    type?: string;
    /**
     * 
     * @type {string}
     * @memberof AppointmentTransfer
     */
    current_clinic_time?: string;
    /**
     * 
     * @type {boolean}
     * @memberof AppointmentTransfer
     */
    is_virtual?: boolean;
    /**
     * 
     * @type {string}
     * @memberof AppointmentTransfer
     */
    patient_first_name?: string;
    /**
     * 
     * @type {string}
     * @memberof AppointmentTransfer
     */
    patient_last_name?: string;
    /**
     * 
     * @type {AppointmentTransferLinks}
     * @memberof AppointmentTransfer
     */
    links?: AppointmentTransferLinks;
}
/**
 * 
 * @export
 * @interface AppointmentTransferLinks
 */
export interface AppointmentTransferLinks {
    /**
     * 
     * @type {string}
     * @memberof AppointmentTransferLinks
     */
    e_chart?: string;
    /**
     * 
     * @type {string}
     * @memberof AppointmentTransferLinks
     */
    master_record?: string;
    /**
     * 
     * @type {string}
     * @memberof AppointmentTransferLinks
     */
    r_x?: string;
    /**
     * 
     * @type {string}
     * @memberof AppointmentTransferLinks
     */
    billing?: string;
}
/**
 * 
 * @export
 * @interface AppointmentType
 */
export interface AppointmentType {
    /**
     * 
     * @type {string}
     * @memberof AppointmentType
     */
    id?: string;
    /**
     * 
     * @type {string}
     * @memberof AppointmentType
     */
    description?: string;
    /**
     * 
     * @type {number}
     * @memberof AppointmentType
     */
    duration?: number;
    /**
     * 
     * @type {number}
     * @memberof AppointmentType
     */
    duration_minutes?: number;
    /**
     * 
     * @type {boolean}
     * @memberof AppointmentType
     */
    is_virtual?: boolean;
    /**
     * 
     * @type {ClinicAppointmentType}
     * @memberof AppointmentType
     */
    appointment_type?: ClinicAppointmentType;
}
/**
 * 
 * @export
 * @interface AqsAppointmentDto
 */
export interface AqsAppointmentDto {
    /**
     * 
     * @type {string}
     * @memberof AqsAppointmentDto
     */
    id?: string;
    /**
     * 
     * @type {Date}
     * @memberof AqsAppointmentDto
     */
    created_at?: Date;
    /**
     * 
     * @type {number}
     * @memberof AqsAppointmentDto
     */
    queue_position?: number;
    /**
     * 
     * @type {string}
     * @memberof AqsAppointmentDto
     */
    queue_id?: string;
    /**
     * 
     * @type {string}
     * @memberof AqsAppointmentDto
     */
    integration_patient_id?: string;
    /**
     * 
     * @type {string}
     * @memberof AqsAppointmentDto
     */
    status?: AqsAppointmentDtoStatusEnum;
    /**
     * 
     * @type {string}
     * @memberof AqsAppointmentDto
     */
    status_message?: string;
    /**
     * 
     * @type {AqsAppointmentDtoOnDemand}
     * @memberof AqsAppointmentDto
     */
    on_demand?: AqsAppointmentDtoOnDemand;
    /**
     * 
     * @type {AqsAppointmentDtoExtraInfo}
     * @memberof AqsAppointmentDto
     */
    extra_info?: AqsAppointmentDtoExtraInfo;
    /**
     * 
     * @type {AqsQueuedAppointmentCommunicationType}
     * @memberof AqsAppointmentDto
     */
    communication_type?: AqsQueuedAppointmentCommunicationType;
}

/**
    * @export
    * @enum {string}
    */
export enum AqsAppointmentDtoStatusEnum {
    QUEUED = 'QUEUED',
    EXPIRED = 'EXPIRED',
    CANCELLED = 'CANCELLED',
    DELETED = 'DELETED',
    SCHEDULED = 'SCHEDULED'
}

/**
 * 
 * @export
 * @interface AqsAppointmentDtoExtraInfo
 */
export interface AqsAppointmentDtoExtraInfo {
    /**
     * 
     * @type {AqsQueuedAppointmentCommunicationType}
     * @memberof AqsAppointmentDtoExtraInfo
     */
    extended_communication_type?: AqsQueuedAppointmentCommunicationType;
}
/**
 * 
 * @export
 * @interface AqsAppointmentDtoOnDemand
 */
export interface AqsAppointmentDtoOnDemand {
    /**
     * 
     * @type {Date}
     * @memberof AqsAppointmentDtoOnDemand
     */
    last_seen_at?: Date;
}
/**
 * 
 * @export
 * @interface AqsOnDemandSettingsDto
 */
export interface AqsOnDemandSettingsDto {
    /**
     * 
     * @type {number}
     * @memberof AqsOnDemandSettingsDto
     */
    expiration_threshold_seconds?: number;
}
/**
 * 
 * @export
 * @interface AqsQueueAvailabilityDayDto
 */
export interface AqsQueueAvailabilityDayDto {
    /**
     * 
     * @type {Date}
     * @memberof AqsQueueAvailabilityDayDto
     */
    start?: Date;
    /**
     * 
     * @type {Date}
     * @memberof AqsQueueAvailabilityDayDto
     */
    stop?: Date;
}
/**
 * 
 * @export
 * @interface AqsQueueAvailabilityDto
 */
export interface AqsQueueAvailabilityDto {
    /**
     * 
     * @type {AqsQueueAvailabilityDayDto}
     * @memberof AqsQueueAvailabilityDto
     */
    sunday?: AqsQueueAvailabilityDayDto;
    /**
     * 
     * @type {AqsQueueAvailabilityDayDto}
     * @memberof AqsQueueAvailabilityDto
     */
    monday?: AqsQueueAvailabilityDayDto;
    /**
     * 
     * @type {AqsQueueAvailabilityDayDto}
     * @memberof AqsQueueAvailabilityDto
     */
    tuesday?: AqsQueueAvailabilityDayDto;
    /**
     * 
     * @type {AqsQueueAvailabilityDayDto}
     * @memberof AqsQueueAvailabilityDto
     */
    wednesday?: AqsQueueAvailabilityDayDto;
    /**
     * 
     * @type {AqsQueueAvailabilityDayDto}
     * @memberof AqsQueueAvailabilityDto
     */
    thursday?: AqsQueueAvailabilityDayDto;
    /**
     * 
     * @type {AqsQueueAvailabilityDayDto}
     * @memberof AqsQueueAvailabilityDto
     */
    friday?: AqsQueueAvailabilityDayDto;
    /**
     * 
     * @type {AqsQueueAvailabilityDayDto}
     * @memberof AqsQueueAvailabilityDto
     */
    saturday?: AqsQueueAvailabilityDayDto;
}
/**
 * 
 * @export
 * @interface AqsQueueDto
 */
export interface AqsQueueDto {
    /**
     * 
     * @type {string}
     * @memberof AqsQueueDto
     */
    id?: string;
    /**
     * 
     * @type {string}
     * @memberof AqsQueueDto
     */
    queue_name?: string;
    /**
     * 
     * @type {number}
     * @memberof AqsQueueDto
     */
    queue_limit?: number;
    /**
     * 
     * @type {AqsQueueAvailabilityDto}
     * @memberof AqsQueueDto
     */
    availability?: AqsQueueAvailabilityDto;
    /**
     * 
     * @type {boolean}
     * @memberof AqsQueueDto
     */
    available?: boolean;
    /**
     * 
     * @type {number}
     * @memberof AqsQueueDto
     */
    default_appointment_duration_minutes?: number;
    /**
     * 
     * @type {AqsOnDemandSettingsDto}
     * @memberof AqsQueueDto
     */
    on_demand_settings?: AqsOnDemandSettingsDto;
}
/**
 * 
 * @export
 * @interface AqsQueuedAppointmentBookDto
 */
export interface AqsQueuedAppointmentBookDto {
    /**
     * reason for booking the appointment.
     * @type {string}
     * @memberof AqsQueuedAppointmentBookDto
     */
    reason?: string;
    /**
     * notes to show the doctor about the appointment.
     * @type {string}
     * @memberof AqsQueuedAppointmentBookDto
     */
    notes?: string;
    /**
     * 
     * @type {AqsQueuedAppointmentCommunicationType}
     * @memberof AqsQueuedAppointmentBookDto
     */
    communication_type?: AqsQueuedAppointmentCommunicationType;
}
/**
 * 
 * @export
 * @enum {string}
 */
export enum AqsQueuedAppointmentCommunicationType {
    InPerson = 'in-person',
    Video = 'video',
    Audio = 'audio',
    Chat = 'chat',
    LiveChat = 'live-chat'
}

/**
 * 
 * @export
 * @interface AttachmentCreateTransfer
 */
export interface AttachmentCreateTransfer {
    /**
     * 
     * @type {string}
     * @memberof AttachmentCreateTransfer
     */
    name: string;
    /**
     * depending on the type of user uploading the document this will be a patient id or a clinic id.
     * @type {string}
     * @memberof AttachmentCreateTransfer
     */
    share_with_id?: string;
    /**
     * 
     * @type {string}
     * @memberof AttachmentCreateTransfer
     */
    content_type: string;
    /**
     * 
     * @type {number}
     * @memberof AttachmentCreateTransfer
     */
    size: number;
    /**
     * 
     * @type {string}
     * @memberof AttachmentCreateTransfer
     */
    data: string;
}
/**
 * 
 * @export
 * @interface AuthInfo
 */
export interface AuthInfo {
    /**
     * 
     * @type {string}
     * @memberof AuthInfo
     */
    email: string;
    /**
     * 
     * @type {string}
     * @memberof AuthInfo
     */
    password: string;
}
/**
 * 
 * @export
 * @interface BaseLoginResponse
 */
export interface BaseLoginResponse {
    /**
     * 
     * @type {BaseUserDto}
     * @memberof BaseLoginResponse
     */
    user: BaseUserDto;
    /**
     * 
     * @type {string}
     * @memberof BaseLoginResponse
     */
    login_token: string;
}
/**
 * 
 * @export
 * @interface BaseUserDto
 */
export interface BaseUserDto {
    /**
     * 
     * @type {string}
     * @memberof BaseUserDto
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof BaseUserDto
     */
    email: string;
}
/**
 * 
 * @export
 * @interface BookAppointmentTransfer
 */
export interface BookAppointmentTransfer {
    /**
     * 
     * @type {BookSlotData}
     * @memberof BookAppointmentTransfer
     */
    slot_data?: BookSlotData;
    /**
     * 
     * @type {string}
     * @memberof BookAppointmentTransfer
     */
    provider_id?: string;
    /**
     * 
     * @type {string}
     * @memberof BookAppointmentTransfer
     */
    appointment_type_id?: string;
}
/**
 * 
 * @export
 * @interface BookSlotData
 */
export interface BookSlotData {
    /**
     * 
     * @type {string}
     * @memberof BookSlotData
     */
    datetime?: string;
    /**
     * 
     * @type {string}
     * @memberof BookSlotData
     */
    reason?: string;
}
/**
 * 
 * @export
 * @enum {string}
 */
export enum BookingCompletePageMode {
    Default = 'default',
    Splash = 'splash'
}

/**
 * 
 * @export
 * @enum {string}
 */
export enum ChatMessageType {
    Text = 'text',
    Document = 'document'
}

/**
 * 
 * @export
 * @interface Clinic
 */
export interface Clinic {
    /**
     * 
     * @type {string}
     * @memberof Clinic
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof Clinic
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof Clinic
     */
    icon_path?: string;
    /**
     * 
     * @type {string}
     * @memberof Clinic
     */
    address_1?: string;
    /**
     * 
     * @type {string}
     * @memberof Clinic
     */
    address_2?: string;
    /**
     * 
     * @type {string}
     * @memberof Clinic
     */
    city?: string;
    /**
     * 
     * @type {string}
     * @memberof Clinic
     */
    province_code?: string;
    /**
     * 
     * @type {string}
     * @memberof Clinic
     */
    postal_code?: string;
    /**
     * 
     * @type {string}
     * @memberof Clinic
     */
    primary_phone?: string;
    /**
     * 
     * @type {string}
     * @memberof Clinic
     */
    about?: string;
    /**
     * 
     * @type {string}
     * @memberof Clinic
     */
    patient_booking_notes?: string;
    /**
     * 
     * @type {OperatingHours}
     * @memberof Clinic
     */
    operating_hours?: OperatingHours;
    /**
     * 
     * @type {boolean}
     * @memberof Clinic
     */
    allow_unconfirmed_bookings?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof Clinic
     */
    allow_remote_profile_creation?: boolean;
    /**
     * 
     * @type {ClinicConnectionStatus}
     * @memberof Clinic
     */
    connection_status?: ClinicConnectionStatus;
    /**
     * 
     * @type {ClinicBookingStatus}
     * @memberof Clinic
     */
    booking_status?: ClinicBookingStatus;
    /**
     * 
     * @type {boolean}
     * @memberof Clinic
     */
    patient_can_message?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof Clinic
     */
    allow_dependent_bookings?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof Clinic
     */
    is_virtual?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof Clinic
     */
    ebooking_enabled?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof Clinic
     */
    allow_skipping_provider_selection?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof Clinic
     */
    allow_skipping_appointment_type_selection?: boolean;
    /**
     * 
     * @type {ProviderTransfer}
     * @memberof Clinic
     */
    primary_provider?: ProviderTransfer;
    /**
     * 
     * @type {Array<string>}
     * @memberof Clinic
     */
    required_fields?: Array<string>;
    /**
     * 
     * @type {Array<string>}
     * @memberof Clinic
     */
    provider_names?: Array<string>;
    /**
     * 
     * @type {boolean}
     * @memberof Clinic
     */
    send_chat_transcript?: boolean;
}
/**
 * 
 * @export
 * @interface ClinicAppointmentTransfer
 */
export interface ClinicAppointmentTransfer {
    /**
     * 
     * @type {string}
     * @memberof ClinicAppointmentTransfer
     */
    id?: string;
    /**
     * 
     * @type {string}
     * @memberof ClinicAppointmentTransfer
     */
    patient_user_id?: string;
    /**
     * 
     * @type {string}
     * @memberof ClinicAppointmentTransfer
     */
    patient_clinic_appointment_id?: string;
    /**
     * 
     * @type {boolean}
     * @memberof ClinicAppointmentTransfer
     */
    is_cancelled?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ClinicAppointmentTransfer
     */
    is_virtual?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ClinicAppointmentTransfer
     */
    is_queued_appointment?: boolean;
    /**
     * 
     * @type {Date}
     * @memberof ClinicAppointmentTransfer
     */
    start_datetime?: Date;
    /**
     * 
     * @type {Date}
     * @memberof ClinicAppointmentTransfer
     */
    start_datetime_utc?: Date;
    /**
     * 
     * @type {Date}
     * @memberof ClinicAppointmentTransfer
     */
    end_datetime?: Date;
    /**
     * 
     * @type {Date}
     * @memberof ClinicAppointmentTransfer
     */
    end_datetime_utc?: Date;
    /**
     * 
     * @type {string}
     * @memberof ClinicAppointmentTransfer
     */
    appointment_no?: string;
    /**
     * 
     * @type {string}
     * @memberof ClinicAppointmentTransfer
     */
    demographic_no?: string;
    /**
     * 
     * @type {number}
     * @memberof ClinicAppointmentTransfer
     */
    provider_no?: number;
    /**
     * 
     * @type {string}
     * @memberof ClinicAppointmentTransfer
     */
    app_name?: string;
    /**
     * 
     * @type {string}
     * @memberof ClinicAppointmentTransfer
     */
    queued_appointment_id?: string;
    /**
     * 
     * @type {ClinicAppointmentType}
     * @memberof ClinicAppointmentTransfer
     */
    appointment_type?: ClinicAppointmentType;
    /**
     * 
     * @type {string}
     * @memberof ClinicAppointmentTransfer
     */
    created_at?: string;
    /**
     * 
     * @type {number}
     * @memberof ClinicAppointmentTransfer
     */
    rating?: number;
    /**
     * 
     * @type {string}
     * @memberof ClinicAppointmentTransfer
     */
    feedback?: string;
    /**
     * 
     * @type {boolean}
     * @memberof ClinicAppointmentTransfer
     */
    appointment_ended?: boolean;
}
/**
 * 
 * @export
 * @enum {string}
 */
export enum ClinicAppointmentType {
    Regular = 'regular',
    OneTimeLink = 'one_time_link',
    OnDemandAudioCall = 'on_demand_audio_call',
    Chat = 'chat',
    LiveChat = 'live-chat'
}

/**
 * 
 * @export
 * @enum {string}
 */
export enum ClinicBookingStatus {
    NotConnected = 'not_connected',
    Pending = 'pending',
    Bookable = 'bookable',
    Unavailable = 'unavailable'
}

/**
 * 
 * @export
 * @interface ClinicConnectionRequest
 */
export interface ClinicConnectionRequest {
    /**
     * 
     * @type {ClinicConnectionRequestPatientRequest}
     * @memberof ClinicConnectionRequest
     */
    patient_request: ClinicConnectionRequestPatientRequest;
}
/**
 * 
 * @export
 * @interface ClinicConnectionRequestPatientRequest
 */
export interface ClinicConnectionRequestPatientRequest {
    /**
     * 
     * @type {boolean}
     * @memberof ClinicConnectionRequestPatientRequest
     */
    patient_registered?: boolean;
    /**
     * 
     * @type {string}
     * @memberof ClinicConnectionRequestPatientRequest
     */
    patient_connect_message?: string;
}
/**
 * 
 * @export
 * @enum {string}
 */
export enum ClinicConnectionStatus {
    NoLink = 'no_link',
    PatientRejected = 'patient_rejected',
    ClinicRejected = 'clinic_rejected',
    PendingClinicConfirm = 'pending_clinic_confirm',
    PendingPatientConfirm = 'pending_patient_confirm',
    Confirmed = 'confirmed',
    Verified = 'verified'
}

/**
 * 
 * @export
 * @interface ClinicPatientAccessDto
 */
export interface ClinicPatientAccessDto {
    /**
     * 
     * @type {string}
     * @memberof ClinicPatientAccessDto
     */
    patient_user_id?: string;
    /**
     * 
     * @type {string}
     * @memberof ClinicPatientAccessDto
     */
    clinic_profile_id?: string;
    /**
     * 
     * @type {boolean}
     * @memberof ClinicPatientAccessDto
     */
    can_message_clinic?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ClinicPatientAccessDto
     */
    can_cancel_appointments?: boolean;
    /**
     * 
     * @type {ClinicConnectionStatus}
     * @memberof ClinicPatientAccessDto
     */
    link_status: ClinicConnectionStatus;
    /**
     * 
     * @type {Date}
     * @memberof ClinicPatientAccessDto
     */
    clinic_verified_at?: Date;
    /**
     * 
     * @type {string}
     * @memberof ClinicPatientAccessDto
     */
    verified_by?: string;
    /**
     * 
     * @type {string}
     * @memberof ClinicPatientAccessDto
     */
    verified_by_type?: string;
    /**
     * 
     * @type {string}
     * @memberof ClinicPatientAccessDto
     */
    verifier_user_name?: string;
    /**
     * 
     * @type {Date}
     * @memberof ClinicPatientAccessDto
     */
    clinic_confirmed_at?: Date;
    /**
     * 
     * @type {string}
     * @memberof ClinicPatientAccessDto
     */
    confirmed_by?: string;
    /**
     * 
     * @type {string}
     * @memberof ClinicPatientAccessDto
     */
    confirmed_by_type?: string;
    /**
     * 
     * @type {string}
     * @memberof ClinicPatientAccessDto
     */
    confirming_user_name?: string;
}
/**
 * 
 * @export
 * @enum {string}
 */
export enum ClinicUpdateLinkMethod {
    ConfirmClinicConnection = 'confirm_clinic_connection',
    RejectClinicConnection = 'reject_clinic_connection',
    CancelClinicConnection = 'cancel_clinic_connection',
    RequestClinicConnection = 'request_clinic_connection'
}

/**
 * 
 * @export
 * @interface ClinicUserDto
 */
export interface ClinicUserDto {
    /**
     * 
     * @type {string}
     * @memberof ClinicUserDto
     */
    id?: string;
    /**
     * 
     * @type {string}
     * @memberof ClinicUserDto
     */
    email?: string;
    /**
     * 
     * @type {string}
     * @memberof ClinicUserDto
     */
    first_name?: string;
    /**
     * 
     * @type {string}
     * @memberof ClinicUserDto
     */
    last_name?: string;
    /**
     * 
     * @type {string}
     * @memberof ClinicUserDto
     */
    remote_id?: string;
}
/**
 * 
 * @export
 * @interface ClinicUserLoginResponse
 */
export interface ClinicUserLoginResponse {
    /**
     * 
     * @type {ClinicUserDto}
     * @memberof ClinicUserLoginResponse
     */
    user: ClinicUserDto;
    /**
     * 
     * @type {string}
     * @memberof ClinicUserLoginResponse
     */
    login_token: string;
}
/**
 * 
 * @export
 * @interface Clinics
 */
export interface Clinics {
    /**
     * 
     * @type {Array<Clinic>}
     * @memberof Clinics
     */
    clinics: Array<Clinic>;
}
/**
 * 
 * @export
 * @interface ConversationDto
 */
export interface ConversationDto {
    /**
     * 
     * @type {string}
     * @memberof ConversationDto
     */
    id: string;
    /**
     * 
     * @type {Array<InboxMessage>}
     * @memberof ConversationDto
     */
    messages?: Array<InboxMessage>;
}
/**
 * 
 * @export
 * @interface CreateAccountInfo
 */
export interface CreateAccountInfo {
    /**
     * 
     * @type {string}
     * @memberof CreateAccountInfo
     */
    email: string;
    /**
     * 
     * @type {string}
     * @memberof CreateAccountInfo
     */
    password: string;
    /**
     * 
     * @type {string}
     * @memberof CreateAccountInfo
     */
    password_confirmation?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateAccountInfo
     */
    signup_organization_id?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateAccountInfo
     */
    first_name: string;
    /**
     * 
     * @type {string}
     * @memberof CreateAccountInfo
     */
    middle_name?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateAccountInfo
     */
    last_name: string;
    /**
     * 
     * @type {string}
     * @memberof CreateAccountInfo
     */
    birth_date?: string;
    /**
     * 
     * @type {Sex}
     * @memberof CreateAccountInfo
     */
    sex?: Sex;
    /**
     * 
     * @type {string}
     * @memberof CreateAccountInfo
     */
    health_care_province_code?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateAccountInfo
     */
    health_number?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateAccountInfo
     */
    health_number_version?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateAccountInfo
     */
    cell_phone?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateAccountInfo
     */
    home_phone?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateAccountInfo
     */
    work_phone?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateAccountInfo
     */
    address_1?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateAccountInfo
     */
    address_2?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateAccountInfo
     */
    city?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateAccountInfo
     */
    address_province_code?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateAccountInfo
     */
    country_code?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateAccountInfo
     */
    postal_code?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateAccountInfo
     */
    primary_fax?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateAccountInfo
     */
    time_zone?: string;
}
/**
 * 
 * @export
 * @interface DocumentCreateTransfer
 */
export interface DocumentCreateTransfer {
    /**
     * 
     * @type {string}
     * @memberof DocumentCreateTransfer
     */
    name: string;
    /**
     * depending on the type of user uploading the document this will be a patient id or a clinic id.
     * @type {string}
     * @memberof DocumentCreateTransfer
     */
    share_with_id?: string;
    /**
     * 
     * @type {string}
     * @memberof DocumentCreateTransfer
     */
    content_type: string;
    /**
     * 
     * @type {number}
     * @memberof DocumentCreateTransfer
     */
    size: number;
    /**
     * 
     * @type {string}
     * @memberof DocumentCreateTransfer
     */
    base64_data: string;
}
/**
 * 
 * @export
 * @interface DocumentTransfer
 */
export interface DocumentTransfer {
    /**
     * 
     * @type {string}
     * @memberof DocumentTransfer
     */
    id?: string;
    /**
     * File name.
     * @type {string}
     * @memberof DocumentTransfer
     */
    name?: string;
    /**
     * File mime type.
     * @type {string}
     * @memberof DocumentTransfer
     */
    type?: string;
    /**
     * 
     * @type {string}
     * @memberof DocumentTransfer
     */
    description?: string;
    /**
     * 
     * @type {Date}
     * @memberof DocumentTransfer
     */
    created_at?: Date;
    /**
     * 
     * @type {Date}
     * @memberof DocumentTransfer
     */
    updated_at?: Date;
    /**
     * The url at which the files data can be downloaded. Use the same authentication as you used to get this transfer object.
     * @type {string}
     * @memberof DocumentTransfer
     */
    url?: string;
}
/**
 * 
 * @export
 * @interface EmailConfirmationInfo
 */
export interface EmailConfirmationInfo {
    /**
     * 
     * @type {string}
     * @memberof EmailConfirmationInfo
     */
    confirm_email_token: string;
    /**
     * 
     * @type {string}
     * @memberof EmailConfirmationInfo
     */
    email: string;
}
/**
 * 
 * @export
 * @interface EmailConfirmationResponse
 */
export interface EmailConfirmationResponse {
    /**
     * 
     * @type {string}
     * @memberof EmailConfirmationResponse
     */
    login_token: string;
    /**
     * 
     * @type {PatientUserDto}
     * @memberof EmailConfirmationResponse
     */
    user: PatientUserDto;
    /**
     * 
     * @type {Clinic}
     * @memberof EmailConfirmationResponse
     */
    clinic?: Clinic;
}
/**
 * 
 * @export
 * @interface EncounterNote
 */
export interface EncounterNote {
    /**
     * 
     * @type {string}
     * @memberof EncounterNote
     */
    note: string;
}
/**
 * 
 * @export
 * @enum {string}
 */
export enum ErrorCode {
    Unknown = 'error_unknown',
    Generic = 'error_generic',
    Authentication = 'error_authentication',
    UnconfirmedEmail = 'error_unconfirmed_email',
    Access = 'error_access',
    ClinicAccess = 'error_clinic_access',
    SessionExpired = 'error_session_expired',
    RecordNotFound = 'error_record_not_found',
    DuplicateRecord = 'error_duplicate_record',
    DuplicateConfirmation = 'error_duplicate_confirmation',
    Validation = 'error_validation',
    BookingRuleViolation = 'error_booking_rule_violation',
    RemoteClinicConnection = 'error_remote_clinic_connection',
    IncompleteProfile = 'error_incomplete_profile',
    RemotePatientInactive = 'error_remote_patient_inactive',
    InvalidProvince = 'error_invalid_province',
    InvalidToken = 'error_invalid_token',
    InvalidEmail = 'error_invalid_email',
    InvalidHealthNumber = 'error_invalid_health_number',
    NotImplemented = 'error_not_implemented',
    EligibilityCheckFailed = 'error_eligibility_check_failed',
    KioskAvailability = 'error_kiosk_availability',
    UserFriendly = 'error_user_friendly',
    AqsCommunicationError = 'error_aqs_communication_error',
    AqsQueueAvailability = 'error_aqs_queue_availability',
    AqsQueueCapacity = 'error_aqs_queue_capacity',
    AqsDuplicateBooking = 'error_aqs_duplicate_booking'
}

/**
 * 
 * @export
 * @interface ErrorReport
 */
export interface ErrorReport {
    /**
     * 
     * @type {string}
     * @memberof ErrorReport
     */
    user_name?: string;
    /**
     * 
     * @type {string}
     * @memberof ErrorReport
     */
    date_time: string;
    /**
     * 
     * @type {object}
     * @memberof ErrorReport
     */
    error_info: object;
    /**
     * 
     * @type {object}
     * @memberof ErrorReport
     */
    browser_info: object;
}
/**
 * 
 * @export
 * @interface ErrorResponseInterface
 */
export interface ErrorResponseInterface {
    /**
     * 
     * @type {object}
     * @memberof ErrorResponseInterface
     */
    data: object;
    /**
     * 
     * @type {string}
     * @memberof ErrorResponseInterface
     */
    message: string;
    /**
     * 
     * @type {ErrorCode}
     * @memberof ErrorResponseInterface
     */
    code: ErrorCode;
}
/**
 * 
 * @export
 * @interface GuardianInformationDto
 */
export interface GuardianInformationDto {
    /**
     * 
     * @type {string}
     * @memberof GuardianInformationDto
     */
    last_name?: string;
    /**
     * 
     * @type {string}
     * @memberof GuardianInformationDto
     */
    first_name?: string;
    /**
     * 
     * @type {string}
     * @memberof GuardianInformationDto
     */
    email?: string;
    /**
     * 
     * @type {string}
     * @memberof GuardianInformationDto
     */
    cell_phone?: string;
}
/**
 * 
 * @export
 * @interface InboxMessage
 */
export interface InboxMessage {
    /**
     * 
     * @type {string}
     * @memberof InboxMessage
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof InboxMessage
     */
    conversation_id?: string;
    /**
     * 
     * @type {string}
     * @memberof InboxMessage
     */
    group?: string;
    /**
     * 
     * @type {string}
     * @memberof InboxMessage
     */
    subject?: string;
    /**
     * 
     * @type {string}
     * @memberof InboxMessage
     */
    message?: string;
    /**
     * 
     * @type {boolean}
     * @memberof InboxMessage
     */
    is_read?: boolean;
    /**
     * 
     * @type {Date}
     * @memberof InboxMessage
     */
    created_at?: Date;
    /**
     * 
     * @type {string}
     * @memberof InboxMessage
     */
    meta?: string;
    /**
     * 
     * @type {string}
     * @memberof InboxMessage
     */
    sender_id?: string;
    /**
     * 
     * @type {string}
     * @memberof InboxMessage
     */
    sender_type?: string;
    /**
     * 
     * @type {string}
     * @memberof InboxMessage
     */
    sender_name?: string;
    /**
     * 
     * @type {Array<InboxMessageRecipients>}
     * @memberof InboxMessage
     */
    recipients?: Array<InboxMessageRecipients>;
    /**
     * 
     * @type {Array<MailAttachment>}
     * @memberof InboxMessage
     */
    attachments?: Array<MailAttachment>;
}
/**
 * 
 * @export
 * @interface InboxMessageRecipients
 */
export interface InboxMessageRecipients {
    /**
     * 
     * @type {string}
     * @memberof InboxMessageRecipients
     */
    id?: string;
    /**
     * 
     * @type {string}
     * @memberof InboxMessageRecipients
     */
    name?: string;
    /**
     * 
     * @type {string}
     * @memberof InboxMessageRecipients
     */
    type?: string;
}
/**
 * 
 * @export
 * @interface InlineObject
 */
export interface InlineObject {
    /**
     * 
     * @type {CreateAccountInfo}
     * @memberof InlineObject
     */
    account_info: CreateAccountInfo;
    /**
     * 
     * @type {object}
     * @memberof InlineObject
     */
    clinic_request?: object;
    /**
     * 
     * @type {SignupOptions}
     * @memberof InlineObject
     */
    signup_options?: SignupOptions;
}
/**
 * 
 * @export
 * @interface InlineObject1
 */
export interface InlineObject1 {
    /**
     * 
     * @type {PatientUserDto}
     * @memberof InlineObject1
     */
    profile: PatientUserDto;
}
/**
 * 
 * @export
 * @interface InlineObject10
 */
export interface InlineObject10 {
    /**
     * 
     * @type {string}
     * @memberof InlineObject10
     */
    remote_id?: string;
}
/**
 * 
 * @export
 * @interface InlineObject2
 */
export interface InlineObject2 {
    /**
     * 
     * @type {PatientNotificationSettings}
     * @memberof InlineObject2
     */
    notifications: PatientNotificationSettings;
}
/**
 * 
 * @export
 * @interface InlineObject3
 */
export interface InlineObject3 {
    /**
     * 
     * @type {PatientAccountStatus}
     * @memberof InlineObject3
     */
    account: PatientAccountStatus;
}
/**
 * 
 * @export
 * @interface InlineObject4
 */
export interface InlineObject4 {
    /**
     * 
     * @type {PatientAccountCredentials}
     * @memberof InlineObject4
     */
    user: PatientAccountCredentials;
}
/**
 * 
 * @export
 * @interface InlineObject5
 */
export interface InlineObject5 {
    /**
     * 
     * @type {PasswordResetInfo}
     * @memberof InlineObject5
     */
    reset: PasswordResetInfo;
}
/**
 * 
 * @export
 * @interface InlineObject6
 */
export interface InlineObject6 {
    /**
     * 
     * @type {Array<number>}
     * @memberof InlineObject6
     */
    message_ids?: Array<number>;
}
/**
 * 
 * @export
 * @interface InlineObject7
 */
export interface InlineObject7 {
    /**
     * 
     * @type {PatientUserDto}
     * @memberof InlineObject7
     */
    profile: PatientUserDto;
}
/**
 * 
 * @export
 * @interface InlineObject8
 */
export interface InlineObject8 {
    /**
     * 
     * @type {PatientUserDto}
     * @memberof InlineObject8
     */
    profile: PatientUserDto;
}
/**
 * 
 * @export
 * @interface InlineObject9
 */
export interface InlineObject9 {
    /**
     * 
     * @type {string}
     * @memberof InlineObject9
     */
    session_id?: string;
}
/**
 * 
 * @export
 * @interface InlineResponse200
 */
export interface InlineResponse200 {
    /**
     * 
     * @type {string}
     * @memberof InlineResponse200
     */
    version?: string;
}
/**
 * 
 * @export
 * @interface InlineResponse2001
 */
export interface InlineResponse2001 {
    /**
     * 
     * @type {boolean}
     * @memberof InlineResponse2001
     */
    email_taken?: boolean;
}
/**
 * 
 * @export
 * @interface InlineResponse2002
 */
export interface InlineResponse2002 {
    /**
     * Total message count
     * @type {number}
     * @memberof InlineResponse2002
     */
    count?: number;
}
/**
 * 
 * @export
 * @interface InlineResponse2003
 */
export interface InlineResponse2003 {
    /**
     * 
     * @type {Clinic}
     * @memberof InlineResponse2003
     */
    clinic: Clinic;
}
/**
 * 
 * @export
 * @interface InlineResponse2004
 */
export interface InlineResponse2004 {
    /**
     * 
     * @type {boolean}
     * @memberof InlineResponse2004
     */
    can_cancel_all?: boolean;
}
/**
 * 
 * @export
 * @interface KioskAppointmentRequest
 */
export interface KioskAppointmentRequest {
    /**
     * 
     * @type {object}
     * @memberof KioskAppointmentRequest
     */
    patient_profile: object;
    /**
     * 
     * @type {string}
     * @memberof KioskAppointmentRequest
     */
    reason: string;
}
/**
 * 
 * @export
 * @interface KioskClinicDto
 */
export interface KioskClinicDto {
    /**
     * 
     * @type {string}
     * @memberof KioskClinicDto
     */
    location?: string;
    /**
     * 
     * @type {string}
     * @memberof KioskClinicDto
     */
    clinic_id?: string;
    /**
     * 
     * @type {string}
     * @memberof KioskClinicDto
     */
    provider_id?: string;
    /**
     * 
     * @type {string}
     * @memberof KioskClinicDto
     */
    appointment_type_id?: string;
}
/**
 * 
 * @export
 * @interface KioskClinicsResponse
 */
export interface KioskClinicsResponse extends Array<KioskClinicDto> {
}
/**
 * 
 * @export
 * @interface KioskDto
 */
export interface KioskDto {
    /**
     * 
     * @type {string}
     * @memberof KioskDto
     */
    id?: string;
    /**
     * 
     * @type {string}
     * @memberof KioskDto
     */
    email?: string;
    /**
     * 
     * @type {PharmacyDto}
     * @memberof KioskDto
     */
    pharmacy?: PharmacyDto;
}
/**
 * 
 * @export
 * @interface KioskLoginResponse
 */
export interface KioskLoginResponse {
    /**
     * 
     * @type {KioskDto}
     * @memberof KioskLoginResponse
     */
    user: KioskDto;
    /**
     * 
     * @type {string}
     * @memberof KioskLoginResponse
     */
    login_token: string;
}
/**
 * 
 * @export
 * @interface LogMessage
 */
export interface LogMessage {
    /**
     * 
     * @type {string}
     * @memberof LogMessage
     */
    log_level: LogMessageLogLevelEnum;
    /**
     * 
     * @type {String | object}
     * @memberof LogMessage
     */
    log_message: String | object;
}

/**
    * @export
    * @enum {string}
    */
export enum LogMessageLogLevelEnum {
    Debug = 'debug',
    Info = 'info',
    Warn = 'warn',
    Error = 'error'
}

/**
 * 
 * @export
 * @interface MailAttachment
 */
export interface MailAttachment {
    /**
     * 
     * @type {string}
     * @memberof MailAttachment
     */
    id?: string;
    /**
     * 
     * @type {string}
     * @memberof MailAttachment
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof MailAttachment
     */
    type: string;
    /**
     * 
     * @type {number}
     * @memberof MailAttachment
     */
    size?: number;
    /**
     * 
     * @type {string}
     * @memberof MailAttachment
     */
    url?: string;
}
/**
 * 
 * @export
 * @interface MailData
 */
export interface MailData {
    /**
     * 
     * @type {string}
     * @memberof MailData
     */
    subject: string;
    /**
     * 
     * @type {string}
     * @memberof MailData
     */
    body: string;
}
/**
 * 
 * @export
 * @interface MessageCreateTransfer
 */
export interface MessageCreateTransfer {
    /**
     * 
     * @type {string}
     * @memberof MessageCreateTransfer
     */
    subject: string;
    /**
     * 
     * @type {string}
     * @memberof MessageCreateTransfer
     */
    message: string;
    /**
     * 
     * @type {Array<MessageCreateTransferRecipients>}
     * @memberof MessageCreateTransfer
     */
    recipients: Array<MessageCreateTransferRecipients>;
    /**
     * 
     * @type {string}
     * @memberof MessageCreateTransfer
     */
    conversation_id?: string;
    /**
     * 
     * @type {Array<AttachmentCreateTransfer>}
     * @memberof MessageCreateTransfer
     */
    attachments?: Array<AttachmentCreateTransfer>;
}
/**
 * 
 * @export
 * @interface MessageCreateTransferRecipients
 */
export interface MessageCreateTransferRecipients {
    /**
     * 
     * @type {string}
     * @memberof MessageCreateTransferRecipients
     */
    id?: string;
    /**
     * 
     * @type {string}
     * @memberof MessageCreateTransferRecipients
     */
    type?: string;
}
/**
 * 
 * @export
 * @interface MessagePost
 */
export interface MessagePost {
    /**
     * If provided append message to conversation chain
     * @type {string}
     * @memberof MessagePost
     */
    conversation_id?: string;
    /**
     * 
     * @type {MailData}
     * @memberof MessagePost
     */
    message: MailData;
    /**
     * 
     * @type {Array<MailAttachment>}
     * @memberof MessagePost
     */
    attachments?: Array<MailAttachment>;
}
/**
 * 
 * @export
 * @enum {string}
 */
export enum NotificationType {
    Appointment = 'appointment',
    AppointmentDependent = 'appointment_dependent'
}

/**
 * 
 * @export
 * @interface OTSessionInfo
 */
export interface OTSessionInfo {
    /**
     * 
     * @type {string}
     * @memberof OTSessionInfo
     */
    token: string;
    /**
     * 
     * @type {string}
     * @memberof OTSessionInfo
     */
    session_id: string;
    /**
     * 
     * @type {string}
     * @memberof OTSessionInfo
     */
    token_type: string;
    /**
     * 
     * @type {string}
     * @memberof OTSessionInfo
     */
    clinic_id: string;
    /**
     * 
     * @type {string}
     * @memberof OTSessionInfo
     */
    appointment_id: string;
    /**
     * 
     * @type {string}
     * @memberof OTSessionInfo
     */
    api_key: string;
}
/**
 * 
 * @export
 * @interface OTSessionResponse
 */
export interface OTSessionResponse {
    /**
     * 
     * @type {OTSessionInfo}
     * @memberof OTSessionResponse
     */
    video: OTSessionInfo;
    /**
     * 
     * @type {OTSessionInfo}
     * @memberof OTSessionResponse
     */
    chat: OTSessionInfo;
}
/**
 * 
 * @export
 * @interface OneTimeCodeDto
 */
export interface OneTimeCodeDto {
    /**
     * 
     * @type {string}
     * @memberof OneTimeCodeDto
     */
    code_type: OneTimeCodeDtoCodeTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof OneTimeCodeDto
     */
    code?: string;
    /**
     * 
     * @type {Date}
     * @memberof OneTimeCodeDto
     */
    expire_at_utc?: Date;
}

/**
    * @export
    * @enum {string}
    */
export enum OneTimeCodeDtoCodeTypeEnum {
    PatientAccountIdCode = 'patient_account_id_code'
}

/**
 * 
 * @export
 * @interface OneTimeTelehealthTokenDto
 */
export interface OneTimeTelehealthTokenDto {
    /**
     * 
     * @type {string}
     * @memberof OneTimeTelehealthTokenDto
     */
    email: string;
    /**
     * 
     * @type {string}
     * @memberof OneTimeTelehealthTokenDto
     */
    first_name: string;
    /**
     * 
     * @type {string}
     * @memberof OneTimeTelehealthTokenDto
     */
    last_name: string;
    /**
     * 
     * @type {string}
     * @memberof OneTimeTelehealthTokenDto
     */
    appointment_id: string;
    /**
     * 
     * @type {object}
     * @memberof OneTimeTelehealthTokenDto
     */
    token: object;
}
/**
 * 
 * @export
 * @interface OpenTokSession
 */
export interface OpenTokSession {
    /**
     * 
     * @type {string}
     * @memberof OpenTokSession
     */
    session_id: string;
    /**
     * 
     * @type {string}
     * @memberof OpenTokSession
     */
    patient_user_id: string;
    /**
     * 
     * @type {string}
     * @memberof OpenTokSession
     */
    clinic_user_id: string;
    /**
     * 
     * @type {string}
     * @memberof OpenTokSession
     */
    media_mode?: string;
    /**
     * 
     * @type {string}
     * @memberof OpenTokSession
     */
    created_at?: string;
}
/**
 * 
 * @export
 * @interface OpenTokSessionToken
 */
export interface OpenTokSessionToken {
    /**
     * 
     * @type {string}
     * @memberof OpenTokSessionToken
     */
    token: string;
    /**
     * 
     * @type {string}
     * @memberof OpenTokSessionToken
     */
    session_id: string;
    /**
     * 
     * @type {string}
     * @memberof OpenTokSessionToken
     */
    patient_user_id: string;
    /**
     * 
     * @type {string}
     * @memberof OpenTokSessionToken
     */
    clinic_user_id: string;
    /**
     * 
     * @type {string}
     * @memberof OpenTokSessionToken
     */
    created_at?: string;
}
/**
 * 
 * @export
 * @interface OpenTokSessions
 */
export interface OpenTokSessions {
    /**
     * 
     * @type {OpenTokSession}
     * @memberof OpenTokSessions
     */
    open_tok_sessions: OpenTokSession;
}
/**
 * 
 * @export
 * @interface OperatingHour
 */
export interface OperatingHour {
    /**
     * 
     * @type {string}
     * @memberof OperatingHour
     */
    start: string;
    /**
     * 
     * @type {string}
     * @memberof OperatingHour
     */
    stop: string;
}
/**
 * 
 * @export
 * @interface OperatingHours
 */
export interface OperatingHours {
    /**
     * 
     * @type {OperatingHour}
     * @memberof OperatingHours
     */
    sunday?: OperatingHour;
    /**
     * 
     * @type {OperatingHour}
     * @memberof OperatingHours
     */
    monday?: OperatingHour;
    /**
     * 
     * @type {OperatingHour}
     * @memberof OperatingHours
     */
    tuesday?: OperatingHour;
    /**
     * 
     * @type {OperatingHour}
     * @memberof OperatingHours
     */
    wednesday?: OperatingHour;
    /**
     * 
     * @type {OperatingHour}
     * @memberof OperatingHours
     */
    thursday?: OperatingHour;
    /**
     * 
     * @type {OperatingHour}
     * @memberof OperatingHours
     */
    friday?: OperatingHour;
    /**
     * 
     * @type {OperatingHour}
     * @memberof OperatingHours
     */
    saturday?: OperatingHour;
}
/**
 * 
 * @export
 * @interface OrganizationAssetDto
 */
export interface OrganizationAssetDto {
    /**
     * 
     * @type {string}
     * @memberof OrganizationAssetDto
     */
    id?: string;
    /**
     * 
     * @type {string}
     * @memberof OrganizationAssetDto
     */
    asset_type?: string;
    /**
     * 
     * @type {string}
     * @memberof OrganizationAssetDto
     */
    url?: string;
}
/**
 * 
 * @export
 * @interface OrganizationConfiguration
 */
export interface OrganizationConfiguration {
    /**
     * 
     * @type {OrganizationConfigurationTelehealth}
     * @memberof OrganizationConfiguration
     */
    telehealth?: OrganizationConfigurationTelehealth;
    /**
     * 
     * @type {OrganizationConfigurationBooking}
     * @memberof OrganizationConfiguration
     */
    booking?: OrganizationConfigurationBooking;
    /**
     * 
     * @type {OrganizationConfigurationAppointment}
     * @memberof OrganizationConfiguration
     */
    appointment?: OrganizationConfigurationAppointment;
    /**
     * 
     * @type {OrganizationConfigurationMessaging}
     * @memberof OrganizationConfiguration
     */
    messaging?: OrganizationConfigurationMessaging;
    /**
     * 
     * @type {OrganizationConfigurationRoutes}
     * @memberof OrganizationConfiguration
     */
    routes?: OrganizationConfigurationRoutes;
}
/**
 * 
 * @export
 * @interface OrganizationConfigurationAppointment
 */
export interface OrganizationConfigurationAppointment {
    /**
     * 
     * @type {OrganizationConfigurationAppointmentConfirmationPage}
     * @memberof OrganizationConfigurationAppointment
     */
    confirmation_page?: OrganizationConfigurationAppointmentConfirmationPage;
}
/**
 * 
 * @export
 * @interface OrganizationConfigurationAppointmentConfirmationPage
 */
export interface OrganizationConfigurationAppointmentConfirmationPage {
    /**
     * 
     * @type {boolean}
     * @memberof OrganizationConfigurationAppointmentConfirmationPage
     */
    show_close_button?: boolean;
}
/**
 * 
 * @export
 * @interface OrganizationConfigurationBooking
 */
export interface OrganizationConfigurationBooking {
    /**
     * 
     * @type {OrganizationConfigurationBookingBookingComplete}
     * @memberof OrganizationConfigurationBooking
     */
    booking_complete?: OrganizationConfigurationBookingBookingComplete;
}
/**
 * 
 * @export
 * @interface OrganizationConfigurationBookingBookingComplete
 */
export interface OrganizationConfigurationBookingBookingComplete {
    /**
     * 
     * @type {BookingCompletePageMode}
     * @memberof OrganizationConfigurationBookingBookingComplete
     */
    mode?: BookingCompletePageMode;
}
/**
 * 
 * @export
 * @interface OrganizationConfigurationMessaging
 */
export interface OrganizationConfigurationMessaging {
    /**
     * 
     * @type {string}
     * @memberof OrganizationConfigurationMessaging
     */
    join_telehealth_url?: string;
    /**
     * 
     * @type {string}
     * @memberof OrganizationConfigurationMessaging
     */
    view_appointment_url?: string;
    /**
     * 
     * @type {string}
     * @memberof OrganizationConfigurationMessaging
     */
    view_message_url?: string;
    /**
     * 
     * @type {boolean}
     * @memberof OrganizationConfigurationMessaging
     */
    show_app_store_email_links?: boolean;
}
/**
 * 
 * @export
 * @interface OrganizationConfigurationRoutes
 */
export interface OrganizationConfigurationRoutes {
    /**
     * 
     * @type {string}
     * @memberof OrganizationConfigurationRoutes
     */
    home?: string;
    /**
     * 
     * @type {string}
     * @memberof OrganizationConfigurationRoutes
     */
    login?: string;
}
/**
 * 
 * @export
 * @interface OrganizationConfigurationTelehealth
 */
export interface OrganizationConfigurationTelehealth {
    /**
     * 
     * @type {TelehealthCallEndBehaviour}
     * @memberof OrganizationConfigurationTelehealth
     */
    call_end_behaviour?: TelehealthCallEndBehaviour;
    /**
     * 
     * @type {TelehealthCallExitBehaviour}
     * @memberof OrganizationConfigurationTelehealth
     */
    call_exit_behaviour?: TelehealthCallExitBehaviour;
    /**
     * 
     * @type {OrganizationConfigurationTelehealthWaitingRoom}
     * @memberof OrganizationConfigurationTelehealth
     */
    waiting_room?: OrganizationConfigurationTelehealthWaitingRoom;
}
/**
 * 
 * @export
 * @interface OrganizationConfigurationTelehealthWaitingRoom
 */
export interface OrganizationConfigurationTelehealthWaitingRoom {
    /**
     * 
     * @type {boolean}
     * @memberof OrganizationConfigurationTelehealthWaitingRoom
     */
    show_footer?: boolean;
}
/**
 * 
 * @export
 * @interface OrganizationFontDto
 */
export interface OrganizationFontDto {
    /**
     * 
     * @type {string}
     * @memberof OrganizationFontDto
     */
    id?: string;
    /**
     * 
     * @type {OrganizationFontType}
     * @memberof OrganizationFontDto
     */
    asset_type?: OrganizationFontType;
    /**
     * 
     * @type {string}
     * @memberof OrganizationFontDto
     */
    url?: string;
}
/**
 * 
 * @export
 * @enum {string}
 */
export enum OrganizationFontType {
    Regular = 'org_font_regular',
    Bold = 'org_font_bold',
    Italic = 'org_font_italic'
}

/**
 * 
 * @export
 * @interface OrganizationProfileDto
 */
export interface OrganizationProfileDto {
    /**
     * 
     * @type {string}
     * @memberof OrganizationProfileDto
     */
    id?: string;
    /**
     * 
     * @type {string}
     * @memberof OrganizationProfileDto
     */
    name?: string;
    /**
     * 
     * @type {string}
     * @memberof OrganizationProfileDto
     */
    primary_clinic_id?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof OrganizationProfileDto
     */
    clinic_ids?: Array<string>;
    /**
     * 
     * @type {string}
     * @memberof OrganizationProfileDto
     */
    url_friendly_name?: string;
    /**
     * 
     * @type {boolean}
     * @memberof OrganizationProfileDto
     */
    show_cloudmd_branding?: boolean;
    /**
     * 
     * @type {string}
     * @memberof OrganizationProfileDto
     */
    primary_color?: string;
    /**
     * 
     * @type {string}
     * @memberof OrganizationProfileDto
     */
    secondary_color?: string;
    /**
     * 
     * @type {string}
     * @memberof OrganizationProfileDto
     */
    background_color?: string;
    /**
     * 
     * @type {OrganizationConfiguration}
     * @memberof OrganizationProfileDto
     */
    configuration?: OrganizationConfiguration;
    /**
     * 
     * @type {string}
     * @memberof OrganizationProfileDto
     */
    locale_overrides?: string;
}
/**
 * 
 * @export
 * @interface OrganizationTextDto
 */
export interface OrganizationTextDto {
    /**
     * 
     * @type {string}
     * @memberof OrganizationTextDto
     */
    id?: string;
    /**
     * 
     * @type {string}
     * @memberof OrganizationTextDto
     */
    text_type?: string;
    /**
     * 
     * @type {string}
     * @memberof OrganizationTextDto
     */
    text?: string;
}
/**
 * 
 * @export
 * @interface PasswordResetInfo
 */
export interface PasswordResetInfo {
    /**
     * 
     * @type {string}
     * @memberof PasswordResetInfo
     */
    reset_password_token: string;
    /**
     * 
     * @type {string}
     * @memberof PasswordResetInfo
     */
    password: string;
    /**
     * 
     * @type {string}
     * @memberof PasswordResetInfo
     */
    password_confirmation: string;
}
/**
 * 
 * @export
 * @interface PatientAccountCredentials
 */
export interface PatientAccountCredentials {
    /**
     * 
     * @type {string}
     * @memberof PatientAccountCredentials
     */
    email?: string;
    /**
     * 
     * @type {string}
     * @memberof PatientAccountCredentials
     */
    current_password: string;
    /**
     * 
     * @type {string}
     * @memberof PatientAccountCredentials
     */
    password?: string;
    /**
     * 
     * @type {string}
     * @memberof PatientAccountCredentials
     */
    password_confirmation?: string;
}
/**
 * 
 * @export
 * @interface PatientAccountStatus
 */
export interface PatientAccountStatus {
    /**
     * 
     * @type {boolean}
     * @memberof PatientAccountStatus
     */
    active: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof PatientAccountStatus
     */
    clinic_connected?: boolean;
    /**
     * 
     * @type {Clinic}
     * @memberof PatientAccountStatus
     */
    requested_clinic?: Clinic;
}
/**
 * 
 * @export
 * @interface PatientEmail
 */
export interface PatientEmail {
    /**
     * 
     * @type {string}
     * @memberof PatientEmail
     */
    email: string;
}
/**
 * 
 * @export
 * @interface PatientLoginResponse
 */
export interface PatientLoginResponse {
    /**
     * 
     * @type {PatientUserDto}
     * @memberof PatientLoginResponse
     */
    user: PatientUserDto;
    /**
     * 
     * @type {string}
     * @memberof PatientLoginResponse
     */
    login_token: string;
}
/**
 * 
 * @export
 * @interface PatientLoginResponseAllOf
 */
export interface PatientLoginResponseAllOf {
    /**
     * 
     * @type {PatientUserDto}
     * @memberof PatientLoginResponseAllOf
     */
    user?: PatientUserDto;
}
/**
 * 
 * @export
 * @interface PatientNotification
 */
export interface PatientNotification {
    /**
     * 
     * @type {NotificationType}
     * @memberof PatientNotification
     */
    notification_type: NotificationType;
    /**
     * 
     * @type {PatientNotificationAppointment}
     * @memberof PatientNotification
     */
    data: PatientNotificationAppointment;
}
/**
 * 
 * @export
 * @interface PatientNotificationAppointment
 */
export interface PatientNotificationAppointment {
    /**
     * 
     * @type {string}
     * @memberof PatientNotificationAppointment
     */
    appointment_id?: string;
    /**
     * 
     * @type {string}
     * @memberof PatientNotificationAppointment
     */
    clinic_id?: string;
    /**
     * 
     * @type {string}
     * @memberof PatientNotificationAppointment
     */
    title?: string;
    /**
     * 
     * @type {string}
     * @memberof PatientNotificationAppointment
     */
    message?: string;
}
/**
 * 
 * @export
 * @interface PatientNotificationSettings
 */
export interface PatientNotificationSettings {
    /**
     * 
     * @type {boolean}
     * @memberof PatientNotificationSettings
     */
    sms_notifications: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof PatientNotificationSettings
     */
    email_notifications: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof PatientNotificationSettings
     */
    push_notifications?: boolean;
}
/**
 * 
 * @export
 * @interface PatientUserDto
 */
export interface PatientUserDto {
    /**
     * 
     * @type {string}
     * @memberof PatientUserDto
     */
    id?: string;
    /**
     * 
     * @type {PatientUserType}
     * @memberof PatientUserDto
     */
    patient_type?: PatientUserType;
    /**
     * 
     * @type {string}
     * @memberof PatientUserDto
     */
    email?: string;
    /**
     * 
     * @type {boolean}
     * @memberof PatientUserDto
     */
    email_notifications?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof PatientUserDto
     */
    sms_notifications?: boolean;
    /**
     * 
     * @type {string}
     * @memberof PatientUserDto
     */
    first_name?: string;
    /**
     * 
     * @type {string}
     * @memberof PatientUserDto
     */
    middle_name?: string;
    /**
     * 
     * @type {string}
     * @memberof PatientUserDto
     */
    last_name?: string;
    /**
     * 
     * @type {string}
     * @memberof PatientUserDto
     */
    birth_date?: string;
    /**
     * 
     * @type {Sex}
     * @memberof PatientUserDto
     */
    sex?: Sex;
    /**
     * 
     * @type {boolean}
     * @memberof PatientUserDto
     */
    newborn?: boolean;
    /**
     * 
     * @type {string}
     * @memberof PatientUserDto
     */
    health_number?: string;
    /**
     * 
     * @type {string}
     * @memberof PatientUserDto
     */
    health_number_version?: string;
    /**
     * 
     * @type {string}
     * @memberof PatientUserDto
     */
    health_care_province_code?: string;
    /**
     * 
     * @type {string}
     * @memberof PatientUserDto
     */
    address_1?: string;
    /**
     * 
     * @type {string}
     * @memberof PatientUserDto
     */
    address_2?: string;
    /**
     * 
     * @type {string}
     * @memberof PatientUserDto
     */
    city?: string;
    /**
     * 
     * @type {string}
     * @memberof PatientUserDto
     */
    address_province_code?: string;
    /**
     * 
     * @type {string}
     * @memberof PatientUserDto
     */
    postal_code?: string;
    /**
     * 
     * @type {string}
     * @memberof PatientUserDto
     */
    country_code?: string;
    /**
     * 
     * @type {string}
     * @memberof PatientUserDto
     */
    home_phone?: string;
    /**
     * 
     * @type {string}
     * @memberof PatientUserDto
     */
    cell_phone?: string;
    /**
     * 
     * @type {string}
     * @memberof PatientUserDto
     */
    work_phone?: string;
    /**
     * 
     * @type {string}
     * @memberof PatientUserDto
     */
    primary_fax?: string;
    /**
     * 
     * @type {string}
     * @memberof PatientUserDto
     */
    time_zone?: string;
    /**
     * 
     * @type {boolean}
     * @memberof PatientUserDto
     */
    has_complete_profile?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof PatientUserDto
     */
    is_dependent?: boolean;
    /**
     * 
     * @type {string}
     * @memberof PatientUserDto
     */
    parent_id?: string;
    /**
     * 
     * @type {GuardianInformationDto}
     * @memberof PatientUserDto
     */
    guardian?: GuardianInformationDto;
    /**
     * 
     * @type {string}
     * @memberof PatientUserDto
     */
    locale?: string;
    /**
     * 
     * @type {string}
     * @memberof PatientUserDto
     */
    fusion_organization?: string;
    /**
     * 
     * @type {string}
     * @memberof PatientUserDto
     */
    fusion_eac?: string;
}
/**
 * 
 * @export
 * @enum {string}
 */
export enum PatientUserType {
    Regular = 'regular',
    Dependent = 'dependent',
    Kiosk = 'kiosk'
}

/**
 * 
 * @export
 * @interface PharmacyDto
 */
export interface PharmacyDto {
    /**
     * 
     * @type {string}
     * @memberof PharmacyDto
     */
    id?: string;
    /**
     * 
     * @type {string}
     * @memberof PharmacyDto
     */
    name?: string;
    /**
     * 
     * @type {string}
     * @memberof PharmacyDto
     */
    address_1?: string;
    /**
     * 
     * @type {string}
     * @memberof PharmacyDto
     */
    city?: string;
    /**
     * 
     * @type {string}
     * @memberof PharmacyDto
     */
    address_province_code?: string;
    /**
     * 
     * @type {string}
     * @memberof PharmacyDto
     */
    postal_code?: string;
    /**
     * 
     * @type {string}
     * @memberof PharmacyDto
     */
    country_code?: string;
    /**
     * 
     * @type {string}
     * @memberof PharmacyDto
     */
    primary_phone?: string;
    /**
     * 
     * @type {string}
     * @memberof PharmacyDto
     */
    primary_fax?: string;
    /**
     * 
     * @type {string}
     * @memberof PharmacyDto
     */
    logo_url?: string;
    /**
     * 
     * @type {string}
     * @memberof PharmacyDto
     */
    advertisement_footer_url?: string;
    /**
     * 
     * @type {string}
     * @memberof PharmacyDto
     */
    brand_color?: string;
    /**
     * 
     * @type {string}
     * @memberof PharmacyDto
     */
    url_friendly_name?: string;
    /**
     * 
     * @type {string}
     * @memberof PharmacyDto
     */
    custom_login_message?: string;
}
/**
 * 
 * @export
 * @interface ProviderGroupTransfer
 */
export interface ProviderGroupTransfer {
    /**
     * 
     * @type {string}
     * @memberof ProviderGroupTransfer
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof ProviderGroupTransfer
     */
    group_name: string;
}
/**
 * 
 * @export
 * @interface ProviderGroups
 */
export interface ProviderGroups {
    /**
     * 
     * @type {Array<ProviderGroupTransfer>}
     * @memberof ProviderGroups
     */
    provider_groups: Array<ProviderGroupTransfer>;
}
/**
 * 
 * @export
 * @interface ProviderTransfer
 */
export interface ProviderTransfer {
    /**
     * 
     * @type {string}
     * @memberof ProviderTransfer
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof ProviderTransfer
     */
    remote_id: string;
    /**
     * 
     * @type {string}
     * @memberof ProviderTransfer
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof ProviderTransfer
     */
    first_name: string;
    /**
     * 
     * @type {string}
     * @memberof ProviderTransfer
     */
    last_name: string;
    /**
     * 
     * @type {string}
     * @memberof ProviderTransfer
     */
    prefix: string;
    /**
     * 
     * @type {string}
     * @memberof ProviderTransfer
     */
    suffix: string;
    /**
     * 
     * @type {string}
     * @memberof ProviderTransfer
     */
    specialty: string;
}
/**
 * 
 * @export
 * @interface Providers
 */
export interface Providers {
    /**
     * 
     * @type {Array<ProviderTransfer>}
     * @memberof Providers
     */
    providers: Array<ProviderTransfer>;
}
/**
 * 
 * @export
 * @enum {string}
 */
export enum ProvinceCode {
    AB = 'AB',
    BC = 'BC',
    NB = 'NB',
    MB = 'MB',
    NL = 'NL',
    NS = 'NS',
    NT = 'NT',
    NU = 'NU',
    ON = 'ON',
    PE = 'PE',
    QC = 'QC',
    SK = 'SK',
    YT = 'YT'
}

/**
 * 
 * @export
 * @interface PublicConfigConstants
 */
export interface PublicConfigConstants {
    /**
     * snap clarity booking app link on/off
     * @type {boolean}
     * @memberof PublicConfigConstants
     */
    snap_clarity_enabled?: boolean;
    /**
     * link to snap clarity booking app
     * @type {string}
     * @memberof PublicConfigConstants
     */
    snap_clarity_url?: string;
    /**
     * 
     * @type {string}
     * @memberof PublicConfigConstants
     */
    fusion_clinic_id?: string;
    /**
     * the url of the fusion keycloak server
     * @type {string}
     * @memberof PublicConfigConstants
     */
    fusion_keycloak_site?: string;
    /**
     * the issuer of fusion jwt tokens
     * @type {string}
     * @memberof PublicConfigConstants
     */
    fusion_keycloak_iss?: string;
    /**
     * the client id for the fusion keycloak server
     * @type {string}
     * @memberof PublicConfigConstants
     */
    fusion_keycloak_client_id?: string;
    /**
     * the realm for the fusion keycloak server
     * @type {string}
     * @memberof PublicConfigConstants
     */
    fusion_keycloak_realm?: string;
}
/**
 * 
 * @export
 * @interface PublicTelehealthSessionInfo
 */
export interface PublicTelehealthSessionInfo {
    /**
     * 
     * @type {PublicTelehealthSessionInfoUserInfo}
     * @memberof PublicTelehealthSessionInfo
     */
    user_info: PublicTelehealthSessionInfoUserInfo;
    /**
     * 
     * @type {OTSessionInfo}
     * @memberof PublicTelehealthSessionInfo
     */
    session_token: OTSessionInfo;
}
/**
 * 
 * @export
 * @interface PublicTelehealthSessionInfoUserInfo
 */
export interface PublicTelehealthSessionInfoUserInfo {
    /**
     * 
     * @type {string}
     * @memberof PublicTelehealthSessionInfoUserInfo
     */
    email?: string;
    /**
     * 
     * @type {string}
     * @memberof PublicTelehealthSessionInfoUserInfo
     */
    first_name?: string;
    /**
     * 
     * @type {string}
     * @memberof PublicTelehealthSessionInfoUserInfo
     */
    last_name?: string;
}
/**
 * 
 * @export
 * @interface PushNotification
 */
export interface PushNotification {
    /**
     * 
     * @type {string}
     * @memberof PushNotification
     */
    title: string;
    /**
     * 
     * @type {string}
     * @memberof PushNotification
     */
    message: string;
    /**
     * 
     * @type {string}
     * @memberof PushNotification
     */
    url: string;
    /**
     * 
     * @type {string}
     * @memberof PushNotification
     */
    appointment_id?: string;
}
/**
 * 
 * @export
 * @interface RemoteDocumentCreateTransfer
 */
export interface RemoteDocumentCreateTransfer {
    /**
     * 
     * @type {string}
     * @memberof RemoteDocumentCreateTransfer
     */
    name?: string;
    /**
     * 
     * @type {string}
     * @memberof RemoteDocumentCreateTransfer
     */
    base64_data?: string;
}
/**
 * 
 * @export
 * @interface RemoteDocumentTransfer
 */
export interface RemoteDocumentTransfer {
    /**
     * 
     * @type {string}
     * @memberof RemoteDocumentTransfer
     */
    id?: string;
    /**
     * 
     * @type {string}
     * @memberof RemoteDocumentTransfer
     */
    name?: string;
    /**
     * 
     * @type {string}
     * @memberof RemoteDocumentTransfer
     */
    base64_data?: string;
}
/**
 * 
 * @export
 * @interface ScheduleSlotTransfer
 */
export interface ScheduleSlotTransfer {
    /**
     * 
     * @type {string}
     * @memberof ScheduleSlotTransfer
     */
    start_date_time?: string;
    /**
     * 
     * @type {string}
     * @memberof ScheduleSlotTransfer
     */
    date?: string;
    /**
     * 
     * @type {string}
     * @memberof ScheduleSlotTransfer
     */
    start_time?: string;
    /**
     * 
     * @type {number}
     * @memberof ScheduleSlotTransfer
     */
    duration?: number;
    /**
     * 
     * @type {string}
     * @memberof ScheduleSlotTransfer
     */
    provider_id?: string;
}
/**
 * 
 * @export
 * @interface ScheduleTemplate
 */
export interface ScheduleTemplate {
    /**
     * 
     * @type {string}
     * @memberof ScheduleTemplate
     */
    remote_id: string;
    /**
     * 
     * @type {number}
     * @memberof ScheduleTemplate
     */
    duration_minutes: number;
}
/**
 * 
 * @export
 * @enum {string}
 */
export enum Sex {
    M = 'M',
    F = 'F'
}

/**
 * 
 * @export
 * @interface SignupOptions
 */
export interface SignupOptions {
    /**
     * 
     * @type {boolean}
     * @memberof SignupOptions
     */
    subscribe_to_cloudmd_marketing?: boolean;
}
/**
 * 
 * @export
 * @enum {string}
 */
export enum TelehealthCallEndBehaviour {
    Default = 'default',
    Splash = 'splash',
    Feedback = 'feedback'
}

/**
 * 
 * @export
 * @enum {string}
 */
export enum TelehealthCallExitBehaviour {
    Default = 'default',
    Overlay = 'overlay'
}

/**
 * 
 * @export
 * @interface TelehealthChatMessageDto
 */
export interface TelehealthChatMessageDto {
    /**
     * 
     * @type {string}
     * @memberof TelehealthChatMessageDto
     */
    id?: string;
    /**
     * 
     * @type {string}
     * @memberof TelehealthChatMessageDto
     */
    message?: string;
    /**
     * 
     * @type {ChatMessageType}
     * @memberof TelehealthChatMessageDto
     */
    message_type?: ChatMessageType;
    /**
     * 
     * @type {string}
     * @memberof TelehealthChatMessageDto
     */
    sender_id?: string;
    /**
     * 
     * @type {UserType}
     * @memberof TelehealthChatMessageDto
     */
    sender_type?: UserType;
    /**
     * 
     * @type {string}
     * @memberof TelehealthChatMessageDto
     */
    created_at?: string;
}
/**
 * 
 * @export
 * @interface TelehealthLogEventDto
 */
export interface TelehealthLogEventDto {
    /**
     * 
     * @type {string}
     * @memberof TelehealthLogEventDto
     */
    event_type: string;
    /**
     * 
     * @type {string}
     * @memberof TelehealthLogEventDto
     */
    log_level: TelehealthLogEventDtoLogLevelEnum;
    /**
     * 
     * @type {string}
     * @memberof TelehealthLogEventDto
     */
    message: string;
    /**
     * 
     * @type {string}
     * @memberof TelehealthLogEventDto
     */
    from_user_id: string;
    /**
     * 
     * @type {UserType}
     * @memberof TelehealthLogEventDto
     */
    from_user_type: UserType;
    /**
     * 
     * @type {string}
     * @memberof TelehealthLogEventDto
     */
    to_user_id: string;
    /**
     * 
     * @type {UserType}
     * @memberof TelehealthLogEventDto
     */
    to_user_type: UserType;
}

/**
    * @export
    * @enum {string}
    */
export enum TelehealthLogEventDtoLogLevelEnum {
    Info = 'info',
    Warn = 'warn',
    Error = 'error'
}

/**
 * 
 * @export
 * @interface TelehealthPingSessionDto
 */
export interface TelehealthPingSessionDto {
    /**
     * 
     * @type {string}
     * @memberof TelehealthPingSessionDto
     */
    status: string;
}
/**
 * 
 * @export
 * @enum {string}
 */
export enum UserType {
    PatientUser = 'Patient::User',
    ClinicUser = 'Clinic::User',
    KioskUser = 'Kiosk::User',
    MyHealthAccessPublicOneTimeTelehealthToken = 'MyHealthAccess::Public::OneTimeTelehealthToken'
}


/**
 * AccountApi - axios parameter creator
 * @export
 */
export const AccountApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Confirm account email address
         * @param {EmailConfirmationInfo} email_confirmation_info Email confirmation credentials
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        confirmEmail(email_confirmation_info: EmailConfirmationInfo, options: any = {}): RequestArgs {
            // verify required parameter 'email_confirmation_info' is not null or undefined
            if (email_confirmation_info === null || email_confirmation_info === undefined) {
                throw new RequiredError('email_confirmation_info','Required parameter email_confirmation_info was null or undefined when calling confirmEmail.');
            }
            const localVarPath = `/patient/self/account/confirm.json`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication apiAuth required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? configuration.apiKey("X-Auth-Token")
                    : configuration.apiKey;
                localVarHeaderParameter["X-Auth-Token"] = localVarApiKeyValue;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};
            const needsSerialization = (<any>"EmailConfirmationInfo" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(email_confirmation_info !== undefined ? email_confirmation_info : {}) : (email_confirmation_info || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Generate a reset password instruction email
         * @param {PatientEmail} patient_email Account Email Address
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        forgotPassword(patient_email: PatientEmail, options: any = {}): RequestArgs {
            // verify required parameter 'patient_email' is not null or undefined
            if (patient_email === null || patient_email === undefined) {
                throw new RequiredError('patient_email','Required parameter patient_email was null or undefined when calling forgotPassword.');
            }
            const localVarPath = `/patient/self/account/forgot.json`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication apiAuth required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? configuration.apiKey("X-Auth-Token")
                    : configuration.apiKey;
                localVarHeaderParameter["X-Auth-Token"] = localVarApiKeyValue;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};
            const needsSerialization = (<any>"PatientEmail" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(patient_email !== undefined ? patient_email : {}) : (patient_email || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update account password using a reset token
         * @param {InlineObject5} inline_object5 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        resetPassword(inline_object5: InlineObject5, options: any = {}): RequestArgs {
            // verify required parameter 'inline_object5' is not null or undefined
            if (inline_object5 === null || inline_object5 === undefined) {
                throw new RequiredError('inline_object5','Required parameter inline_object5 was null or undefined when calling resetPassword.');
            }
            const localVarPath = `/patient/self/account/reset.json`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication apiAuth required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? configuration.apiKey("X-Auth-Token")
                    : configuration.apiKey;
                localVarHeaderParameter["X-Auth-Token"] = localVarApiKeyValue;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};
            const needsSerialization = (<any>"InlineObject5" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(inline_object5 !== undefined ? inline_object5 : {}) : (inline_object5 || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Send confirmation email
         * @param {PatientEmail} patient_email Email
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        sendConfirmEmail(patient_email: PatientEmail, options: any = {}): RequestArgs {
            // verify required parameter 'patient_email' is not null or undefined
            if (patient_email === null || patient_email === undefined) {
                throw new RequiredError('patient_email','Required parameter patient_email was null or undefined when calling sendConfirmEmail.');
            }
            const localVarPath = `/patient/self/account/send_confirmation_instructions.json`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication apiAuth required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? configuration.apiKey("X-Auth-Token")
                    : configuration.apiKey;
                localVarHeaderParameter["X-Auth-Token"] = localVarApiKeyValue;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};
            const needsSerialization = (<any>"PatientEmail" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(patient_email !== undefined ? patient_email : {}) : (patient_email || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * AccountApi - functional programming interface
 * @export
 */
export const AccountApiFp = function(configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Confirm account email address
         * @param {EmailConfirmationInfo} email_confirmation_info Email confirmation credentials
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        confirmEmail(email_confirmation_info: EmailConfirmationInfo, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<EmailConfirmationResponse> {
            const localVarAxiosArgs = AccountApiAxiosParamCreator(configuration).confirmEmail(email_confirmation_info, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Generate a reset password instruction email
         * @param {PatientEmail} patient_email Account Email Address
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        forgotPassword(patient_email: PatientEmail, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<PatientEmail> {
            const localVarAxiosArgs = AccountApiAxiosParamCreator(configuration).forgotPassword(patient_email, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Update account password using a reset token
         * @param {InlineObject5} inline_object5 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        resetPassword(inline_object5: InlineObject5, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<PatientLoginResponse> {
            const localVarAxiosArgs = AccountApiAxiosParamCreator(configuration).resetPassword(inline_object5, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Send confirmation email
         * @param {PatientEmail} patient_email Email
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        sendConfirmEmail(patient_email: PatientEmail, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<PatientEmail> {
            const localVarAxiosArgs = AccountApiAxiosParamCreator(configuration).sendConfirmEmail(patient_email, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
    }
};

/**
 * AccountApi - factory interface
 * @export
 */
export const AccountApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    return {
        /**
         * 
         * @summary Confirm account email address
         * @param {EmailConfirmationInfo} email_confirmation_info Email confirmation credentials
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        confirmEmail(email_confirmation_info: EmailConfirmationInfo, options?: any) {
            return AccountApiFp(configuration).confirmEmail(email_confirmation_info, options)(axios, basePath);
        },
        /**
         * 
         * @summary Generate a reset password instruction email
         * @param {PatientEmail} patient_email Account Email Address
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        forgotPassword(patient_email: PatientEmail, options?: any) {
            return AccountApiFp(configuration).forgotPassword(patient_email, options)(axios, basePath);
        },
        /**
         * 
         * @summary Update account password using a reset token
         * @param {InlineObject5} inline_object5 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        resetPassword(inline_object5: InlineObject5, options?: any) {
            return AccountApiFp(configuration).resetPassword(inline_object5, options)(axios, basePath);
        },
        /**
         * 
         * @summary Send confirmation email
         * @param {PatientEmail} patient_email Email
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        sendConfirmEmail(patient_email: PatientEmail, options?: any) {
            return AccountApiFp(configuration).sendConfirmEmail(patient_email, options)(axios, basePath);
        },
    };
};

/**
 * AccountApi - object-oriented interface
 * @export
 * @class AccountApi
 * @extends {BaseAPI}
 */
export class AccountApi extends BaseAPI {
    /**
     * 
     * @summary Confirm account email address
     * @param {EmailConfirmationInfo} email_confirmation_info Email confirmation credentials
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccountApi
     */
    public confirmEmail(email_confirmation_info: EmailConfirmationInfo, options?: any) {
        return AccountApiFp(this.configuration).confirmEmail(email_confirmation_info, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @summary Generate a reset password instruction email
     * @param {PatientEmail} patient_email Account Email Address
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccountApi
     */
    public forgotPassword(patient_email: PatientEmail, options?: any) {
        return AccountApiFp(this.configuration).forgotPassword(patient_email, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @summary Update account password using a reset token
     * @param {InlineObject5} inline_object5 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccountApi
     */
    public resetPassword(inline_object5: InlineObject5, options?: any) {
        return AccountApiFp(this.configuration).resetPassword(inline_object5, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @summary Send confirmation email
     * @param {PatientEmail} patient_email Email
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccountApi
     */
    public sendConfirmEmail(patient_email: PatientEmail, options?: any) {
        return AccountApiFp(this.configuration).sendConfirmEmail(patient_email, options)(this.axios, this.basePath);
    }

}


/**
 * AqsClinicUserApi - axios parameter creator
 * @export
 */
export const AqsClinicUserApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Record a new chat message for the queued appointment
         * @param {string} queue_id the queue containing the queued appointment
         * @param {string} appointment_id the queued appointment id
         * @param {TelehealthChatMessageDto} telehealth_chat_message_dto Message
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addClinicUserChatMessageToQueuedAppointment(queue_id: string, appointment_id: string, telehealth_chat_message_dto: TelehealthChatMessageDto, options: any = {}): RequestArgs {
            // verify required parameter 'queue_id' is not null or undefined
            if (queue_id === null || queue_id === undefined) {
                throw new RequiredError('queue_id','Required parameter queue_id was null or undefined when calling addClinicUserChatMessageToQueuedAppointment.');
            }
            // verify required parameter 'appointment_id' is not null or undefined
            if (appointment_id === null || appointment_id === undefined) {
                throw new RequiredError('appointment_id','Required parameter appointment_id was null or undefined when calling addClinicUserChatMessageToQueuedAppointment.');
            }
            // verify required parameter 'telehealth_chat_message_dto' is not null or undefined
            if (telehealth_chat_message_dto === null || telehealth_chat_message_dto === undefined) {
                throw new RequiredError('telehealth_chat_message_dto','Required parameter telehealth_chat_message_dto was null or undefined when calling addClinicUserChatMessageToQueuedAppointment.');
            }
            const localVarPath = `/clinic_user/self/clinic/aqs/queue/{queue_id}/appointment/{appointment_id}/chat/message/`
                .replace(`{${"queue_id"}}`, encodeURIComponent(String(queue_id)))
                .replace(`{${"appointment_id"}}`, encodeURIComponent(String(appointment_id)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication apiAuth required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? configuration.apiKey("X-Auth-Token")
                    : configuration.apiKey;
                localVarHeaderParameter["X-Auth-Token"] = localVarApiKeyValue;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};
            const needsSerialization = (<any>"TelehealthChatMessageDto" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(telehealth_chat_message_dto !== undefined ? telehealth_chat_message_dto : {}) : (telehealth_chat_message_dto || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get all chat messages for this appointment
         * @param {string} queue_id the queue containing the queued appointment
         * @param {string} appointment_id the queued appointment id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getClinicUserQueuedAppointmentChatMessages(queue_id: string, appointment_id: string, options: any = {}): RequestArgs {
            // verify required parameter 'queue_id' is not null or undefined
            if (queue_id === null || queue_id === undefined) {
                throw new RequiredError('queue_id','Required parameter queue_id was null or undefined when calling getClinicUserQueuedAppointmentChatMessages.');
            }
            // verify required parameter 'appointment_id' is not null or undefined
            if (appointment_id === null || appointment_id === undefined) {
                throw new RequiredError('appointment_id','Required parameter appointment_id was null or undefined when calling getClinicUserQueuedAppointmentChatMessages.');
            }
            const localVarPath = `/clinic_user/self/clinic/aqs/queue/{queue_id}/appointment/{appointment_id}/chat/messages/`
                .replace(`{${"queue_id"}}`, encodeURIComponent(String(queue_id)))
                .replace(`{${"appointment_id"}}`, encodeURIComponent(String(appointment_id)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication apiAuth required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? configuration.apiKey("X-Auth-Token")
                    : configuration.apiKey;
                localVarHeaderParameter["X-Auth-Token"] = localVarApiKeyValue;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary get the telehealth session for the queued appointment
         * @param {string} queue_id the queue containing the queued appointment
         * @param {string} queued_appointment_id the queued appointment id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSession(queue_id: string, queued_appointment_id: string, options: any = {}): RequestArgs {
            // verify required parameter 'queue_id' is not null or undefined
            if (queue_id === null || queue_id === undefined) {
                throw new RequiredError('queue_id','Required parameter queue_id was null or undefined when calling getSession.');
            }
            // verify required parameter 'queued_appointment_id' is not null or undefined
            if (queued_appointment_id === null || queued_appointment_id === undefined) {
                throw new RequiredError('queued_appointment_id','Required parameter queued_appointment_id was null or undefined when calling getSession.');
            }
            const localVarPath = `/clinic_user/self/clinic/aqs/queue/{queue_id}/queued_appointment/{queued_appointment_id}/session`
                .replace(`{${"queue_id"}}`, encodeURIComponent(String(queue_id)))
                .replace(`{${"queued_appointment_id"}}`, encodeURIComponent(String(queued_appointment_id)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication apiAuth required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? configuration.apiKey("X-Auth-Token")
                    : configuration.apiKey;
                localVarHeaderParameter["X-Auth-Token"] = localVarApiKeyValue;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Log a telehealth session event
         * @param {string} queue_id the queue containing the queued appointment
         * @param {string} queued_appointment_id the queued appointment id
         * @param {TelehealthLogEventDto} telehealth_log_event_dto Log event data
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        logEventToProviderAqsTelehealthSession(queue_id: string, queued_appointment_id: string, telehealth_log_event_dto: TelehealthLogEventDto, options: any = {}): RequestArgs {
            // verify required parameter 'queue_id' is not null or undefined
            if (queue_id === null || queue_id === undefined) {
                throw new RequiredError('queue_id','Required parameter queue_id was null or undefined when calling logEventToProviderAqsTelehealthSession.');
            }
            // verify required parameter 'queued_appointment_id' is not null or undefined
            if (queued_appointment_id === null || queued_appointment_id === undefined) {
                throw new RequiredError('queued_appointment_id','Required parameter queued_appointment_id was null or undefined when calling logEventToProviderAqsTelehealthSession.');
            }
            // verify required parameter 'telehealth_log_event_dto' is not null or undefined
            if (telehealth_log_event_dto === null || telehealth_log_event_dto === undefined) {
                throw new RequiredError('telehealth_log_event_dto','Required parameter telehealth_log_event_dto was null or undefined when calling logEventToProviderAqsTelehealthSession.');
            }
            const localVarPath = `/clinic_user/self/clinic/aqs/queue/{queue_id}/queued_appointment/{queued_appointment_id}/session/log_event`
                .replace(`{${"queue_id"}}`, encodeURIComponent(String(queue_id)))
                .replace(`{${"queued_appointment_id"}}`, encodeURIComponent(String(queued_appointment_id)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication apiAuth required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? configuration.apiKey("X-Auth-Token")
                    : configuration.apiKey;
                localVarHeaderParameter["X-Auth-Token"] = localVarApiKeyValue;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};
            const needsSerialization = (<any>"TelehealthLogEventDto" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(telehealth_log_event_dto !== undefined ? telehealth_log_event_dto : {}) : (telehealth_log_event_dto || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * AqsClinicUserApi - functional programming interface
 * @export
 */
export const AqsClinicUserApiFp = function(configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Record a new chat message for the queued appointment
         * @param {string} queue_id the queue containing the queued appointment
         * @param {string} appointment_id the queued appointment id
         * @param {TelehealthChatMessageDto} telehealth_chat_message_dto Message
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addClinicUserChatMessageToQueuedAppointment(queue_id: string, appointment_id: string, telehealth_chat_message_dto: TelehealthChatMessageDto, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<TelehealthChatMessageDto> {
            const localVarAxiosArgs = AqsClinicUserApiAxiosParamCreator(configuration).addClinicUserChatMessageToQueuedAppointment(queue_id, appointment_id, telehealth_chat_message_dto, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Get all chat messages for this appointment
         * @param {string} queue_id the queue containing the queued appointment
         * @param {string} appointment_id the queued appointment id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getClinicUserQueuedAppointmentChatMessages(queue_id: string, appointment_id: string, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<TelehealthChatMessageDto>> {
            const localVarAxiosArgs = AqsClinicUserApiAxiosParamCreator(configuration).getClinicUserQueuedAppointmentChatMessages(queue_id, appointment_id, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary get the telehealth session for the queued appointment
         * @param {string} queue_id the queue containing the queued appointment
         * @param {string} queued_appointment_id the queued appointment id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSession(queue_id: string, queued_appointment_id: string, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<OTSessionInfo> {
            const localVarAxiosArgs = AqsClinicUserApiAxiosParamCreator(configuration).getSession(queue_id, queued_appointment_id, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Log a telehealth session event
         * @param {string} queue_id the queue containing the queued appointment
         * @param {string} queued_appointment_id the queued appointment id
         * @param {TelehealthLogEventDto} telehealth_log_event_dto Log event data
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        logEventToProviderAqsTelehealthSession(queue_id: string, queued_appointment_id: string, telehealth_log_event_dto: TelehealthLogEventDto, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<void> {
            const localVarAxiosArgs = AqsClinicUserApiAxiosParamCreator(configuration).logEventToProviderAqsTelehealthSession(queue_id, queued_appointment_id, telehealth_log_event_dto, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
    }
};

/**
 * AqsClinicUserApi - factory interface
 * @export
 */
export const AqsClinicUserApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    return {
        /**
         * 
         * @summary Record a new chat message for the queued appointment
         * @param {string} queue_id the queue containing the queued appointment
         * @param {string} appointment_id the queued appointment id
         * @param {TelehealthChatMessageDto} telehealth_chat_message_dto Message
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addClinicUserChatMessageToQueuedAppointment(queue_id: string, appointment_id: string, telehealth_chat_message_dto: TelehealthChatMessageDto, options?: any) {
            return AqsClinicUserApiFp(configuration).addClinicUserChatMessageToQueuedAppointment(queue_id, appointment_id, telehealth_chat_message_dto, options)(axios, basePath);
        },
        /**
         * 
         * @summary Get all chat messages for this appointment
         * @param {string} queue_id the queue containing the queued appointment
         * @param {string} appointment_id the queued appointment id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getClinicUserQueuedAppointmentChatMessages(queue_id: string, appointment_id: string, options?: any) {
            return AqsClinicUserApiFp(configuration).getClinicUserQueuedAppointmentChatMessages(queue_id, appointment_id, options)(axios, basePath);
        },
        /**
         * 
         * @summary get the telehealth session for the queued appointment
         * @param {string} queue_id the queue containing the queued appointment
         * @param {string} queued_appointment_id the queued appointment id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSession(queue_id: string, queued_appointment_id: string, options?: any) {
            return AqsClinicUserApiFp(configuration).getSession(queue_id, queued_appointment_id, options)(axios, basePath);
        },
        /**
         * 
         * @summary Log a telehealth session event
         * @param {string} queue_id the queue containing the queued appointment
         * @param {string} queued_appointment_id the queued appointment id
         * @param {TelehealthLogEventDto} telehealth_log_event_dto Log event data
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        logEventToProviderAqsTelehealthSession(queue_id: string, queued_appointment_id: string, telehealth_log_event_dto: TelehealthLogEventDto, options?: any) {
            return AqsClinicUserApiFp(configuration).logEventToProviderAqsTelehealthSession(queue_id, queued_appointment_id, telehealth_log_event_dto, options)(axios, basePath);
        },
    };
};

/**
 * AqsClinicUserApi - object-oriented interface
 * @export
 * @class AqsClinicUserApi
 * @extends {BaseAPI}
 */
export class AqsClinicUserApi extends BaseAPI {
    /**
     * 
     * @summary Record a new chat message for the queued appointment
     * @param {string} queue_id the queue containing the queued appointment
     * @param {string} appointment_id the queued appointment id
     * @param {TelehealthChatMessageDto} telehealth_chat_message_dto Message
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AqsClinicUserApi
     */
    public addClinicUserChatMessageToQueuedAppointment(queue_id: string, appointment_id: string, telehealth_chat_message_dto: TelehealthChatMessageDto, options?: any) {
        return AqsClinicUserApiFp(this.configuration).addClinicUserChatMessageToQueuedAppointment(queue_id, appointment_id, telehealth_chat_message_dto, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @summary Get all chat messages for this appointment
     * @param {string} queue_id the queue containing the queued appointment
     * @param {string} appointment_id the queued appointment id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AqsClinicUserApi
     */
    public getClinicUserQueuedAppointmentChatMessages(queue_id: string, appointment_id: string, options?: any) {
        return AqsClinicUserApiFp(this.configuration).getClinicUserQueuedAppointmentChatMessages(queue_id, appointment_id, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @summary get the telehealth session for the queued appointment
     * @param {string} queue_id the queue containing the queued appointment
     * @param {string} queued_appointment_id the queued appointment id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AqsClinicUserApi
     */
    public getSession(queue_id: string, queued_appointment_id: string, options?: any) {
        return AqsClinicUserApiFp(this.configuration).getSession(queue_id, queued_appointment_id, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @summary Log a telehealth session event
     * @param {string} queue_id the queue containing the queued appointment
     * @param {string} queued_appointment_id the queued appointment id
     * @param {TelehealthLogEventDto} telehealth_log_event_dto Log event data
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AqsClinicUserApi
     */
    public logEventToProviderAqsTelehealthSession(queue_id: string, queued_appointment_id: string, telehealth_log_event_dto: TelehealthLogEventDto, options?: any) {
        return AqsClinicUserApiFp(this.configuration).logEventToProviderAqsTelehealthSession(queue_id, queued_appointment_id, telehealth_log_event_dto, options)(this.axios, this.basePath);
    }

}


/**
 * AqsPatientApi - axios parameter creator
 * @export
 */
export const AqsPatientApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Record a new chat message for the queued appointment
         * @param {string} clinic_id Clinic
         * @param {string} queue_id The queue containing the appointment
         * @param {string} appointment_id The appointment to record a message for
         * @param {TelehealthChatMessageDto} telehealth_chat_message_dto Message
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addPatientChatMessageToQueuedAppointment(clinic_id: string, queue_id: string, appointment_id: string, telehealth_chat_message_dto: TelehealthChatMessageDto, options: any = {}): RequestArgs {
            // verify required parameter 'clinic_id' is not null or undefined
            if (clinic_id === null || clinic_id === undefined) {
                throw new RequiredError('clinic_id','Required parameter clinic_id was null or undefined when calling addPatientChatMessageToQueuedAppointment.');
            }
            // verify required parameter 'queue_id' is not null or undefined
            if (queue_id === null || queue_id === undefined) {
                throw new RequiredError('queue_id','Required parameter queue_id was null or undefined when calling addPatientChatMessageToQueuedAppointment.');
            }
            // verify required parameter 'appointment_id' is not null or undefined
            if (appointment_id === null || appointment_id === undefined) {
                throw new RequiredError('appointment_id','Required parameter appointment_id was null or undefined when calling addPatientChatMessageToQueuedAppointment.');
            }
            // verify required parameter 'telehealth_chat_message_dto' is not null or undefined
            if (telehealth_chat_message_dto === null || telehealth_chat_message_dto === undefined) {
                throw new RequiredError('telehealth_chat_message_dto','Required parameter telehealth_chat_message_dto was null or undefined when calling addPatientChatMessageToQueuedAppointment.');
            }
            const localVarPath = `/patient/self/clinic/{clinic_id}/aqs/queue/{queue_id}/appointment/{appointment_id}/chat/message/`
                .replace(`{${"clinic_id"}}`, encodeURIComponent(String(clinic_id)))
                .replace(`{${"queue_id"}}`, encodeURIComponent(String(queue_id)))
                .replace(`{${"appointment_id"}}`, encodeURIComponent(String(appointment_id)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication apiAuth required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? configuration.apiKey("X-Auth-Token")
                    : configuration.apiKey;
                localVarHeaderParameter["X-Auth-Token"] = localVarApiKeyValue;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};
            const needsSerialization = (<any>"TelehealthChatMessageDto" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(telehealth_chat_message_dto !== undefined ? telehealth_chat_message_dto : {}) : (telehealth_chat_message_dto || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Book a new AQS appointment
         * @param {string} clinic_id Clinic to get providers for
         * @param {string} queue_id Queue to book the appointment in
         * @param {AqsQueuedAppointmentBookDto} aqs_queued_appointment_book_dto appointment booking info
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createAppointment(clinic_id: string, queue_id: string, aqs_queued_appointment_book_dto: AqsQueuedAppointmentBookDto, options: any = {}): RequestArgs {
            // verify required parameter 'clinic_id' is not null or undefined
            if (clinic_id === null || clinic_id === undefined) {
                throw new RequiredError('clinic_id','Required parameter clinic_id was null or undefined when calling createAppointment.');
            }
            // verify required parameter 'queue_id' is not null or undefined
            if (queue_id === null || queue_id === undefined) {
                throw new RequiredError('queue_id','Required parameter queue_id was null or undefined when calling createAppointment.');
            }
            // verify required parameter 'aqs_queued_appointment_book_dto' is not null or undefined
            if (aqs_queued_appointment_book_dto === null || aqs_queued_appointment_book_dto === undefined) {
                throw new RequiredError('aqs_queued_appointment_book_dto','Required parameter aqs_queued_appointment_book_dto was null or undefined when calling createAppointment.');
            }
            const localVarPath = `/patient/self/clinic/{clinic_id}/aqs/queue/{queue_id}/appointment/`
                .replace(`{${"clinic_id"}}`, encodeURIComponent(String(clinic_id)))
                .replace(`{${"queue_id"}}`, encodeURIComponent(String(queue_id)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication apiAuth required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? configuration.apiKey("X-Auth-Token")
                    : configuration.apiKey;
                localVarHeaderParameter["X-Auth-Token"] = localVarApiKeyValue;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};
            const needsSerialization = (<any>"AqsQueuedAppointmentBookDto" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(aqs_queued_appointment_book_dto !== undefined ? aqs_queued_appointment_book_dto : {}) : (aqs_queued_appointment_book_dto || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary cancel an appointment from the aqs server
         * @param {string} clinic_id Clinic
         * @param {string} queue_id The queue containing the appointment you want to cancel
         * @param {string} appointment_id The appointment to cancel
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        dequeueQueuedAppointment(clinic_id: string, queue_id: string, appointment_id: string, options: any = {}): RequestArgs {
            // verify required parameter 'clinic_id' is not null or undefined
            if (clinic_id === null || clinic_id === undefined) {
                throw new RequiredError('clinic_id','Required parameter clinic_id was null or undefined when calling dequeueQueuedAppointment.');
            }
            // verify required parameter 'queue_id' is not null or undefined
            if (queue_id === null || queue_id === undefined) {
                throw new RequiredError('queue_id','Required parameter queue_id was null or undefined when calling dequeueQueuedAppointment.');
            }
            // verify required parameter 'appointment_id' is not null or undefined
            if (appointment_id === null || appointment_id === undefined) {
                throw new RequiredError('appointment_id','Required parameter appointment_id was null or undefined when calling dequeueQueuedAppointment.');
            }
            const localVarPath = `/patient/self/clinic/{clinic_id}/aqs/queue/{queue_id}/appointment/{appointment_id}/`
                .replace(`{${"clinic_id"}}`, encodeURIComponent(String(clinic_id)))
                .replace(`{${"queue_id"}}`, encodeURIComponent(String(queue_id)))
                .replace(`{${"appointment_id"}}`, encodeURIComponent(String(appointment_id)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication apiAuth required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? configuration.apiKey("X-Auth-Token")
                    : configuration.apiKey;
                localVarHeaderParameter["X-Auth-Token"] = localVarApiKeyValue;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary get the clinic appointment associated with this queued appointment
         * @param {string} clinic_id Clinic
         * @param {string} queue_id The queue containing the queued appointment
         * @param {string} appointment_id The appointment who\&#39;s clinic appointment you want
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAssociatedClinicAppointment(clinic_id: string, queue_id: string, appointment_id: string, options: any = {}): RequestArgs {
            // verify required parameter 'clinic_id' is not null or undefined
            if (clinic_id === null || clinic_id === undefined) {
                throw new RequiredError('clinic_id','Required parameter clinic_id was null or undefined when calling getAssociatedClinicAppointment.');
            }
            // verify required parameter 'queue_id' is not null or undefined
            if (queue_id === null || queue_id === undefined) {
                throw new RequiredError('queue_id','Required parameter queue_id was null or undefined when calling getAssociatedClinicAppointment.');
            }
            // verify required parameter 'appointment_id' is not null or undefined
            if (appointment_id === null || appointment_id === undefined) {
                throw new RequiredError('appointment_id','Required parameter appointment_id was null or undefined when calling getAssociatedClinicAppointment.');
            }
            const localVarPath = `/patient/self/clinic/{clinic_id}/aqs/queue/{queue_id}/appointment/{appointment_id}/clinic_appointment`
                .replace(`{${"clinic_id"}}`, encodeURIComponent(String(clinic_id)))
                .replace(`{${"queue_id"}}`, encodeURIComponent(String(queue_id)))
                .replace(`{${"appointment_id"}}`, encodeURIComponent(String(appointment_id)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication apiAuth required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? configuration.apiKey("X-Auth-Token")
                    : configuration.apiKey;
                localVarHeaderParameter["X-Auth-Token"] = localVarApiKeyValue;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get the live chat queue for this clinic
         * @param {string} clinic_id Clinic to the odb queue for
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getLiveChatQueue(clinic_id: string, options: any = {}): RequestArgs {
            // verify required parameter 'clinic_id' is not null or undefined
            if (clinic_id === null || clinic_id === undefined) {
                throw new RequiredError('clinic_id','Required parameter clinic_id was null or undefined when calling getLiveChatQueue.');
            }
            const localVarPath = `/patient/self/clinic/{clinic_id}/aqs/live_chat_queue`
                .replace(`{${"clinic_id"}}`, encodeURIComponent(String(clinic_id)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication apiAuth required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? configuration.apiKey("X-Auth-Token")
                    : configuration.apiKey;
                localVarHeaderParameter["X-Auth-Token"] = localVarApiKeyValue;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get the odb queue for this clinic
         * @param {string} clinic_id Clinic to the odb queue for
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOdbQueue(clinic_id: string, options: any = {}): RequestArgs {
            // verify required parameter 'clinic_id' is not null or undefined
            if (clinic_id === null || clinic_id === undefined) {
                throw new RequiredError('clinic_id','Required parameter clinic_id was null or undefined when calling getOdbQueue.');
            }
            const localVarPath = `/patient/self/clinic/{clinic_id}/aqs/odb_queue`
                .replace(`{${"clinic_id"}}`, encodeURIComponent(String(clinic_id)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication apiAuth required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? configuration.apiKey("X-Auth-Token")
                    : configuration.apiKey;
                localVarHeaderParameter["X-Auth-Token"] = localVarApiKeyValue;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get all chat messages for this appointment
         * @param {string} clinic_id Clinic
         * @param {string} queue_id The queue containing the appointment
         * @param {string} appointment_id The appointment to record a message for
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPatientQueuedAppointmentChatMessages(clinic_id: string, queue_id: string, appointment_id: string, options: any = {}): RequestArgs {
            // verify required parameter 'clinic_id' is not null or undefined
            if (clinic_id === null || clinic_id === undefined) {
                throw new RequiredError('clinic_id','Required parameter clinic_id was null or undefined when calling getPatientQueuedAppointmentChatMessages.');
            }
            // verify required parameter 'queue_id' is not null or undefined
            if (queue_id === null || queue_id === undefined) {
                throw new RequiredError('queue_id','Required parameter queue_id was null or undefined when calling getPatientQueuedAppointmentChatMessages.');
            }
            // verify required parameter 'appointment_id' is not null or undefined
            if (appointment_id === null || appointment_id === undefined) {
                throw new RequiredError('appointment_id','Required parameter appointment_id was null or undefined when calling getPatientQueuedAppointmentChatMessages.');
            }
            const localVarPath = `/patient/self/clinic/{clinic_id}/aqs/queue/{queue_id}/appointment/{appointment_id}/chat/messages/`
                .replace(`{${"clinic_id"}}`, encodeURIComponent(String(clinic_id)))
                .replace(`{${"queue_id"}}`, encodeURIComponent(String(queue_id)))
                .replace(`{${"appointment_id"}}`, encodeURIComponent(String(appointment_id)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication apiAuth required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? configuration.apiKey("X-Auth-Token")
                    : configuration.apiKey;
                localVarHeaderParameter["X-Auth-Token"] = localVarApiKeyValue;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get an appointment from the aqs server
         * @param {string} clinic_id Clinic
         * @param {string} queue_id The queue containing the appointment you want
         * @param {string} appointment_id The appointment to get
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getQueuedAppointment(clinic_id: string, queue_id: string, appointment_id: string, options: any = {}): RequestArgs {
            // verify required parameter 'clinic_id' is not null or undefined
            if (clinic_id === null || clinic_id === undefined) {
                throw new RequiredError('clinic_id','Required parameter clinic_id was null or undefined when calling getQueuedAppointment.');
            }
            // verify required parameter 'queue_id' is not null or undefined
            if (queue_id === null || queue_id === undefined) {
                throw new RequiredError('queue_id','Required parameter queue_id was null or undefined when calling getQueuedAppointment.');
            }
            // verify required parameter 'appointment_id' is not null or undefined
            if (appointment_id === null || appointment_id === undefined) {
                throw new RequiredError('appointment_id','Required parameter appointment_id was null or undefined when calling getQueuedAppointment.');
            }
            const localVarPath = `/patient/self/clinic/{clinic_id}/aqs/queue/{queue_id}/appointment/{appointment_id}/`
                .replace(`{${"clinic_id"}}`, encodeURIComponent(String(clinic_id)))
                .replace(`{${"queue_id"}}`, encodeURIComponent(String(queue_id)))
                .replace(`{${"appointment_id"}}`, encodeURIComponent(String(appointment_id)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication apiAuth required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? configuration.apiKey("X-Auth-Token")
                    : configuration.apiKey;
                localVarHeaderParameter["X-Auth-Token"] = localVarApiKeyValue;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get all appointments for this user in this queue from the AQS server
         * @param {string} clinic_id Clinic
         * @param {string} queue_id The queue containing the appointments you want
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getQueuedAppointments(clinic_id: string, queue_id: string, options: any = {}): RequestArgs {
            // verify required parameter 'clinic_id' is not null or undefined
            if (clinic_id === null || clinic_id === undefined) {
                throw new RequiredError('clinic_id','Required parameter clinic_id was null or undefined when calling getQueuedAppointments.');
            }
            // verify required parameter 'queue_id' is not null or undefined
            if (queue_id === null || queue_id === undefined) {
                throw new RequiredError('queue_id','Required parameter queue_id was null or undefined when calling getQueuedAppointments.');
            }
            const localVarPath = `/patient/self/clinic/{clinic_id}/aqs/queue/{queue_id}/appointments/`
                .replace(`{${"clinic_id"}}`, encodeURIComponent(String(clinic_id)))
                .replace(`{${"queue_id"}}`, encodeURIComponent(String(queue_id)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication apiAuth required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? configuration.apiKey("X-Auth-Token")
                    : configuration.apiKey;
                localVarHeaderParameter["X-Auth-Token"] = localVarApiKeyValue;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get all AQS queues for this clinic
         * @param {string} clinic_id Clinic to get the queues for
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getQueues(clinic_id: string, options: any = {}): RequestArgs {
            // verify required parameter 'clinic_id' is not null or undefined
            if (clinic_id === null || clinic_id === undefined) {
                throw new RequiredError('clinic_id','Required parameter clinic_id was null or undefined when calling getQueues.');
            }
            const localVarPath = `/patient/self/clinic/{clinic_id}/aqs/queues`
                .replace(`{${"clinic_id"}}`, encodeURIComponent(String(clinic_id)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication apiAuth required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? configuration.apiKey("X-Auth-Token")
                    : configuration.apiKey;
                localVarHeaderParameter["X-Auth-Token"] = localVarApiKeyValue;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Create a new telehealth session for the queued appointment
         * @param {string} clinic_id Clinic
         * @param {string} queue_id The queue containing the appointment
         * @param {string} appointment_id The appointment to create a session for
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTelehealthSession(clinic_id: string, queue_id: string, appointment_id: string, options: any = {}): RequestArgs {
            // verify required parameter 'clinic_id' is not null or undefined
            if (clinic_id === null || clinic_id === undefined) {
                throw new RequiredError('clinic_id','Required parameter clinic_id was null or undefined when calling getTelehealthSession.');
            }
            // verify required parameter 'queue_id' is not null or undefined
            if (queue_id === null || queue_id === undefined) {
                throw new RequiredError('queue_id','Required parameter queue_id was null or undefined when calling getTelehealthSession.');
            }
            // verify required parameter 'appointment_id' is not null or undefined
            if (appointment_id === null || appointment_id === undefined) {
                throw new RequiredError('appointment_id','Required parameter appointment_id was null or undefined when calling getTelehealthSession.');
            }
            const localVarPath = `/patient/self/clinic/{clinic_id}/aqs/queue/{queue_id}/appointment/{appointment_id}/session`
                .replace(`{${"clinic_id"}}`, encodeURIComponent(String(clinic_id)))
                .replace(`{${"queue_id"}}`, encodeURIComponent(String(queue_id)))
                .replace(`{${"appointment_id"}}`, encodeURIComponent(String(appointment_id)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication apiAuth required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? configuration.apiKey("X-Auth-Token")
                    : configuration.apiKey;
                localVarHeaderParameter["X-Auth-Token"] = localVarApiKeyValue;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Log a telehealth session event
         * @param {string} clinic_id Clinic
         * @param {string} queue_id The queue containing the appointment
         * @param {string} appointment_id The appointment to create a session for
         * @param {TelehealthLogEventDto} telehealth_log_event_dto Log event data
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        logEventToPatientAqsTelehealthSession(clinic_id: string, queue_id: string, appointment_id: string, telehealth_log_event_dto: TelehealthLogEventDto, options: any = {}): RequestArgs {
            // verify required parameter 'clinic_id' is not null or undefined
            if (clinic_id === null || clinic_id === undefined) {
                throw new RequiredError('clinic_id','Required parameter clinic_id was null or undefined when calling logEventToPatientAqsTelehealthSession.');
            }
            // verify required parameter 'queue_id' is not null or undefined
            if (queue_id === null || queue_id === undefined) {
                throw new RequiredError('queue_id','Required parameter queue_id was null or undefined when calling logEventToPatientAqsTelehealthSession.');
            }
            // verify required parameter 'appointment_id' is not null or undefined
            if (appointment_id === null || appointment_id === undefined) {
                throw new RequiredError('appointment_id','Required parameter appointment_id was null or undefined when calling logEventToPatientAqsTelehealthSession.');
            }
            // verify required parameter 'telehealth_log_event_dto' is not null or undefined
            if (telehealth_log_event_dto === null || telehealth_log_event_dto === undefined) {
                throw new RequiredError('telehealth_log_event_dto','Required parameter telehealth_log_event_dto was null or undefined when calling logEventToPatientAqsTelehealthSession.');
            }
            const localVarPath = `/patient/self/clinic/{clinic_id}/aqs/queue/{queue_id}/appointment/{appointment_id}/session/log_event`
                .replace(`{${"clinic_id"}}`, encodeURIComponent(String(clinic_id)))
                .replace(`{${"queue_id"}}`, encodeURIComponent(String(queue_id)))
                .replace(`{${"appointment_id"}}`, encodeURIComponent(String(appointment_id)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication apiAuth required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? configuration.apiKey("X-Auth-Token")
                    : configuration.apiKey;
                localVarHeaderParameter["X-Auth-Token"] = localVarApiKeyValue;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};
            const needsSerialization = (<any>"TelehealthLogEventDto" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(telehealth_log_event_dto !== undefined ? telehealth_log_event_dto : {}) : (telehealth_log_event_dto || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Ping telehealth session
         * @param {string} clinic_id Clinic
         * @param {string} queue_id The queue containing the appointment
         * @param {string} appointment_id The appointment being pinged
         * @param {TelehealthPingSessionDto} telehealth_ping_session_dto Ping session data
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        pingAqsTelehealthSession(clinic_id: string, queue_id: string, appointment_id: string, telehealth_ping_session_dto: TelehealthPingSessionDto, options: any = {}): RequestArgs {
            // verify required parameter 'clinic_id' is not null or undefined
            if (clinic_id === null || clinic_id === undefined) {
                throw new RequiredError('clinic_id','Required parameter clinic_id was null or undefined when calling pingAqsTelehealthSession.');
            }
            // verify required parameter 'queue_id' is not null or undefined
            if (queue_id === null || queue_id === undefined) {
                throw new RequiredError('queue_id','Required parameter queue_id was null or undefined when calling pingAqsTelehealthSession.');
            }
            // verify required parameter 'appointment_id' is not null or undefined
            if (appointment_id === null || appointment_id === undefined) {
                throw new RequiredError('appointment_id','Required parameter appointment_id was null or undefined when calling pingAqsTelehealthSession.');
            }
            // verify required parameter 'telehealth_ping_session_dto' is not null or undefined
            if (telehealth_ping_session_dto === null || telehealth_ping_session_dto === undefined) {
                throw new RequiredError('telehealth_ping_session_dto','Required parameter telehealth_ping_session_dto was null or undefined when calling pingAqsTelehealthSession.');
            }
            const localVarPath = `/patient/self/clinic/{clinic_id}/aqs/queue/{queue_id}/appointment/{appointment_id}/session/ping`
                .replace(`{${"clinic_id"}}`, encodeURIComponent(String(clinic_id)))
                .replace(`{${"queue_id"}}`, encodeURIComponent(String(queue_id)))
                .replace(`{${"appointment_id"}}`, encodeURIComponent(String(appointment_id)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication apiAuth required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? configuration.apiKey("X-Auth-Token")
                    : configuration.apiKey;
                localVarHeaderParameter["X-Auth-Token"] = localVarApiKeyValue;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};
            const needsSerialization = (<any>"TelehealthPingSessionDto" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(telehealth_ping_session_dto !== undefined ? telehealth_ping_session_dto : {}) : (telehealth_ping_session_dto || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Ping an appointment on the AQS server
         * @param {string} clinic_id Clinic
         * @param {string} queue_id The queue containing the appointment you want to ping
         * @param {string} appointment_id The appointment to ping
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        pingQueuedAppointment(clinic_id: string, queue_id: string, appointment_id: string, options: any = {}): RequestArgs {
            // verify required parameter 'clinic_id' is not null or undefined
            if (clinic_id === null || clinic_id === undefined) {
                throw new RequiredError('clinic_id','Required parameter clinic_id was null or undefined when calling pingQueuedAppointment.');
            }
            // verify required parameter 'queue_id' is not null or undefined
            if (queue_id === null || queue_id === undefined) {
                throw new RequiredError('queue_id','Required parameter queue_id was null or undefined when calling pingQueuedAppointment.');
            }
            // verify required parameter 'appointment_id' is not null or undefined
            if (appointment_id === null || appointment_id === undefined) {
                throw new RequiredError('appointment_id','Required parameter appointment_id was null or undefined when calling pingQueuedAppointment.');
            }
            const localVarPath = `/patient/self/clinic/{clinic_id}/aqs/queue/{queue_id}/appointment/{appointment_id}/ping`
                .replace(`{${"clinic_id"}}`, encodeURIComponent(String(clinic_id)))
                .replace(`{${"queue_id"}}`, encodeURIComponent(String(queue_id)))
                .replace(`{${"appointment_id"}}`, encodeURIComponent(String(appointment_id)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication apiAuth required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? configuration.apiKey("X-Auth-Token")
                    : configuration.apiKey;
                localVarHeaderParameter["X-Auth-Token"] = localVarApiKeyValue;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * AqsPatientApi - functional programming interface
 * @export
 */
export const AqsPatientApiFp = function(configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Record a new chat message for the queued appointment
         * @param {string} clinic_id Clinic
         * @param {string} queue_id The queue containing the appointment
         * @param {string} appointment_id The appointment to record a message for
         * @param {TelehealthChatMessageDto} telehealth_chat_message_dto Message
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addPatientChatMessageToQueuedAppointment(clinic_id: string, queue_id: string, appointment_id: string, telehealth_chat_message_dto: TelehealthChatMessageDto, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<TelehealthChatMessageDto> {
            const localVarAxiosArgs = AqsPatientApiAxiosParamCreator(configuration).addPatientChatMessageToQueuedAppointment(clinic_id, queue_id, appointment_id, telehealth_chat_message_dto, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Book a new AQS appointment
         * @param {string} clinic_id Clinic to get providers for
         * @param {string} queue_id Queue to book the appointment in
         * @param {AqsQueuedAppointmentBookDto} aqs_queued_appointment_book_dto appointment booking info
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createAppointment(clinic_id: string, queue_id: string, aqs_queued_appointment_book_dto: AqsQueuedAppointmentBookDto, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<AqsAppointmentDto> {
            const localVarAxiosArgs = AqsPatientApiAxiosParamCreator(configuration).createAppointment(clinic_id, queue_id, aqs_queued_appointment_book_dto, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary cancel an appointment from the aqs server
         * @param {string} clinic_id Clinic
         * @param {string} queue_id The queue containing the appointment you want to cancel
         * @param {string} appointment_id The appointment to cancel
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        dequeueQueuedAppointment(clinic_id: string, queue_id: string, appointment_id: string, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<AqsAppointmentDto> {
            const localVarAxiosArgs = AqsPatientApiAxiosParamCreator(configuration).dequeueQueuedAppointment(clinic_id, queue_id, appointment_id, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary get the clinic appointment associated with this queued appointment
         * @param {string} clinic_id Clinic
         * @param {string} queue_id The queue containing the queued appointment
         * @param {string} appointment_id The appointment who\&#39;s clinic appointment you want
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAssociatedClinicAppointment(clinic_id: string, queue_id: string, appointment_id: string, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<ClinicAppointmentTransfer> {
            const localVarAxiosArgs = AqsPatientApiAxiosParamCreator(configuration).getAssociatedClinicAppointment(clinic_id, queue_id, appointment_id, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Get the live chat queue for this clinic
         * @param {string} clinic_id Clinic to the odb queue for
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getLiveChatQueue(clinic_id: string, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<AqsQueueDto> {
            const localVarAxiosArgs = AqsPatientApiAxiosParamCreator(configuration).getLiveChatQueue(clinic_id, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Get the odb queue for this clinic
         * @param {string} clinic_id Clinic to the odb queue for
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOdbQueue(clinic_id: string, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<AqsQueueDto> {
            const localVarAxiosArgs = AqsPatientApiAxiosParamCreator(configuration).getOdbQueue(clinic_id, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Get all chat messages for this appointment
         * @param {string} clinic_id Clinic
         * @param {string} queue_id The queue containing the appointment
         * @param {string} appointment_id The appointment to record a message for
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPatientQueuedAppointmentChatMessages(clinic_id: string, queue_id: string, appointment_id: string, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<TelehealthChatMessageDto>> {
            const localVarAxiosArgs = AqsPatientApiAxiosParamCreator(configuration).getPatientQueuedAppointmentChatMessages(clinic_id, queue_id, appointment_id, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Get an appointment from the aqs server
         * @param {string} clinic_id Clinic
         * @param {string} queue_id The queue containing the appointment you want
         * @param {string} appointment_id The appointment to get
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getQueuedAppointment(clinic_id: string, queue_id: string, appointment_id: string, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<AqsAppointmentDto> {
            const localVarAxiosArgs = AqsPatientApiAxiosParamCreator(configuration).getQueuedAppointment(clinic_id, queue_id, appointment_id, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Get all appointments for this user in this queue from the AQS server
         * @param {string} clinic_id Clinic
         * @param {string} queue_id The queue containing the appointments you want
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getQueuedAppointments(clinic_id: string, queue_id: string, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<AqsAppointmentDto>> {
            const localVarAxiosArgs = AqsPatientApiAxiosParamCreator(configuration).getQueuedAppointments(clinic_id, queue_id, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Get all AQS queues for this clinic
         * @param {string} clinic_id Clinic to get the queues for
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getQueues(clinic_id: string, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<AqsQueueDto>> {
            const localVarAxiosArgs = AqsPatientApiAxiosParamCreator(configuration).getQueues(clinic_id, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Create a new telehealth session for the queued appointment
         * @param {string} clinic_id Clinic
         * @param {string} queue_id The queue containing the appointment
         * @param {string} appointment_id The appointment to create a session for
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTelehealthSession(clinic_id: string, queue_id: string, appointment_id: string, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<OTSessionInfo> {
            const localVarAxiosArgs = AqsPatientApiAxiosParamCreator(configuration).getTelehealthSession(clinic_id, queue_id, appointment_id, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Log a telehealth session event
         * @param {string} clinic_id Clinic
         * @param {string} queue_id The queue containing the appointment
         * @param {string} appointment_id The appointment to create a session for
         * @param {TelehealthLogEventDto} telehealth_log_event_dto Log event data
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        logEventToPatientAqsTelehealthSession(clinic_id: string, queue_id: string, appointment_id: string, telehealth_log_event_dto: TelehealthLogEventDto, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<void> {
            const localVarAxiosArgs = AqsPatientApiAxiosParamCreator(configuration).logEventToPatientAqsTelehealthSession(clinic_id, queue_id, appointment_id, telehealth_log_event_dto, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Ping telehealth session
         * @param {string} clinic_id Clinic
         * @param {string} queue_id The queue containing the appointment
         * @param {string} appointment_id The appointment being pinged
         * @param {TelehealthPingSessionDto} telehealth_ping_session_dto Ping session data
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        pingAqsTelehealthSession(clinic_id: string, queue_id: string, appointment_id: string, telehealth_ping_session_dto: TelehealthPingSessionDto, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<void> {
            const localVarAxiosArgs = AqsPatientApiAxiosParamCreator(configuration).pingAqsTelehealthSession(clinic_id, queue_id, appointment_id, telehealth_ping_session_dto, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Ping an appointment on the AQS server
         * @param {string} clinic_id Clinic
         * @param {string} queue_id The queue containing the appointment you want to ping
         * @param {string} appointment_id The appointment to ping
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        pingQueuedAppointment(clinic_id: string, queue_id: string, appointment_id: string, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<AqsAppointmentDto> {
            const localVarAxiosArgs = AqsPatientApiAxiosParamCreator(configuration).pingQueuedAppointment(clinic_id, queue_id, appointment_id, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
    }
};

/**
 * AqsPatientApi - factory interface
 * @export
 */
export const AqsPatientApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    return {
        /**
         * 
         * @summary Record a new chat message for the queued appointment
         * @param {string} clinic_id Clinic
         * @param {string} queue_id The queue containing the appointment
         * @param {string} appointment_id The appointment to record a message for
         * @param {TelehealthChatMessageDto} telehealth_chat_message_dto Message
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addPatientChatMessageToQueuedAppointment(clinic_id: string, queue_id: string, appointment_id: string, telehealth_chat_message_dto: TelehealthChatMessageDto, options?: any) {
            return AqsPatientApiFp(configuration).addPatientChatMessageToQueuedAppointment(clinic_id, queue_id, appointment_id, telehealth_chat_message_dto, options)(axios, basePath);
        },
        /**
         * 
         * @summary Book a new AQS appointment
         * @param {string} clinic_id Clinic to get providers for
         * @param {string} queue_id Queue to book the appointment in
         * @param {AqsQueuedAppointmentBookDto} aqs_queued_appointment_book_dto appointment booking info
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createAppointment(clinic_id: string, queue_id: string, aqs_queued_appointment_book_dto: AqsQueuedAppointmentBookDto, options?: any) {
            return AqsPatientApiFp(configuration).createAppointment(clinic_id, queue_id, aqs_queued_appointment_book_dto, options)(axios, basePath);
        },
        /**
         * 
         * @summary cancel an appointment from the aqs server
         * @param {string} clinic_id Clinic
         * @param {string} queue_id The queue containing the appointment you want to cancel
         * @param {string} appointment_id The appointment to cancel
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        dequeueQueuedAppointment(clinic_id: string, queue_id: string, appointment_id: string, options?: any) {
            return AqsPatientApiFp(configuration).dequeueQueuedAppointment(clinic_id, queue_id, appointment_id, options)(axios, basePath);
        },
        /**
         * 
         * @summary get the clinic appointment associated with this queued appointment
         * @param {string} clinic_id Clinic
         * @param {string} queue_id The queue containing the queued appointment
         * @param {string} appointment_id The appointment who\&#39;s clinic appointment you want
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAssociatedClinicAppointment(clinic_id: string, queue_id: string, appointment_id: string, options?: any) {
            return AqsPatientApiFp(configuration).getAssociatedClinicAppointment(clinic_id, queue_id, appointment_id, options)(axios, basePath);
        },
        /**
         * 
         * @summary Get the live chat queue for this clinic
         * @param {string} clinic_id Clinic to the odb queue for
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getLiveChatQueue(clinic_id: string, options?: any) {
            return AqsPatientApiFp(configuration).getLiveChatQueue(clinic_id, options)(axios, basePath);
        },
        /**
         * 
         * @summary Get the odb queue for this clinic
         * @param {string} clinic_id Clinic to the odb queue for
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOdbQueue(clinic_id: string, options?: any) {
            return AqsPatientApiFp(configuration).getOdbQueue(clinic_id, options)(axios, basePath);
        },
        /**
         * 
         * @summary Get all chat messages for this appointment
         * @param {string} clinic_id Clinic
         * @param {string} queue_id The queue containing the appointment
         * @param {string} appointment_id The appointment to record a message for
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPatientQueuedAppointmentChatMessages(clinic_id: string, queue_id: string, appointment_id: string, options?: any) {
            return AqsPatientApiFp(configuration).getPatientQueuedAppointmentChatMessages(clinic_id, queue_id, appointment_id, options)(axios, basePath);
        },
        /**
         * 
         * @summary Get an appointment from the aqs server
         * @param {string} clinic_id Clinic
         * @param {string} queue_id The queue containing the appointment you want
         * @param {string} appointment_id The appointment to get
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getQueuedAppointment(clinic_id: string, queue_id: string, appointment_id: string, options?: any) {
            return AqsPatientApiFp(configuration).getQueuedAppointment(clinic_id, queue_id, appointment_id, options)(axios, basePath);
        },
        /**
         * 
         * @summary Get all appointments for this user in this queue from the AQS server
         * @param {string} clinic_id Clinic
         * @param {string} queue_id The queue containing the appointments you want
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getQueuedAppointments(clinic_id: string, queue_id: string, options?: any) {
            return AqsPatientApiFp(configuration).getQueuedAppointments(clinic_id, queue_id, options)(axios, basePath);
        },
        /**
         * 
         * @summary Get all AQS queues for this clinic
         * @param {string} clinic_id Clinic to get the queues for
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getQueues(clinic_id: string, options?: any) {
            return AqsPatientApiFp(configuration).getQueues(clinic_id, options)(axios, basePath);
        },
        /**
         * 
         * @summary Create a new telehealth session for the queued appointment
         * @param {string} clinic_id Clinic
         * @param {string} queue_id The queue containing the appointment
         * @param {string} appointment_id The appointment to create a session for
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTelehealthSession(clinic_id: string, queue_id: string, appointment_id: string, options?: any) {
            return AqsPatientApiFp(configuration).getTelehealthSession(clinic_id, queue_id, appointment_id, options)(axios, basePath);
        },
        /**
         * 
         * @summary Log a telehealth session event
         * @param {string} clinic_id Clinic
         * @param {string} queue_id The queue containing the appointment
         * @param {string} appointment_id The appointment to create a session for
         * @param {TelehealthLogEventDto} telehealth_log_event_dto Log event data
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        logEventToPatientAqsTelehealthSession(clinic_id: string, queue_id: string, appointment_id: string, telehealth_log_event_dto: TelehealthLogEventDto, options?: any) {
            return AqsPatientApiFp(configuration).logEventToPatientAqsTelehealthSession(clinic_id, queue_id, appointment_id, telehealth_log_event_dto, options)(axios, basePath);
        },
        /**
         * 
         * @summary Ping telehealth session
         * @param {string} clinic_id Clinic
         * @param {string} queue_id The queue containing the appointment
         * @param {string} appointment_id The appointment being pinged
         * @param {TelehealthPingSessionDto} telehealth_ping_session_dto Ping session data
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        pingAqsTelehealthSession(clinic_id: string, queue_id: string, appointment_id: string, telehealth_ping_session_dto: TelehealthPingSessionDto, options?: any) {
            return AqsPatientApiFp(configuration).pingAqsTelehealthSession(clinic_id, queue_id, appointment_id, telehealth_ping_session_dto, options)(axios, basePath);
        },
        /**
         * 
         * @summary Ping an appointment on the AQS server
         * @param {string} clinic_id Clinic
         * @param {string} queue_id The queue containing the appointment you want to ping
         * @param {string} appointment_id The appointment to ping
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        pingQueuedAppointment(clinic_id: string, queue_id: string, appointment_id: string, options?: any) {
            return AqsPatientApiFp(configuration).pingQueuedAppointment(clinic_id, queue_id, appointment_id, options)(axios, basePath);
        },
    };
};

/**
 * AqsPatientApi - object-oriented interface
 * @export
 * @class AqsPatientApi
 * @extends {BaseAPI}
 */
export class AqsPatientApi extends BaseAPI {
    /**
     * 
     * @summary Record a new chat message for the queued appointment
     * @param {string} clinic_id Clinic
     * @param {string} queue_id The queue containing the appointment
     * @param {string} appointment_id The appointment to record a message for
     * @param {TelehealthChatMessageDto} telehealth_chat_message_dto Message
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AqsPatientApi
     */
    public addPatientChatMessageToQueuedAppointment(clinic_id: string, queue_id: string, appointment_id: string, telehealth_chat_message_dto: TelehealthChatMessageDto, options?: any) {
        return AqsPatientApiFp(this.configuration).addPatientChatMessageToQueuedAppointment(clinic_id, queue_id, appointment_id, telehealth_chat_message_dto, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @summary Book a new AQS appointment
     * @param {string} clinic_id Clinic to get providers for
     * @param {string} queue_id Queue to book the appointment in
     * @param {AqsQueuedAppointmentBookDto} aqs_queued_appointment_book_dto appointment booking info
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AqsPatientApi
     */
    public createAppointment(clinic_id: string, queue_id: string, aqs_queued_appointment_book_dto: AqsQueuedAppointmentBookDto, options?: any) {
        return AqsPatientApiFp(this.configuration).createAppointment(clinic_id, queue_id, aqs_queued_appointment_book_dto, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @summary cancel an appointment from the aqs server
     * @param {string} clinic_id Clinic
     * @param {string} queue_id The queue containing the appointment you want to cancel
     * @param {string} appointment_id The appointment to cancel
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AqsPatientApi
     */
    public dequeueQueuedAppointment(clinic_id: string, queue_id: string, appointment_id: string, options?: any) {
        return AqsPatientApiFp(this.configuration).dequeueQueuedAppointment(clinic_id, queue_id, appointment_id, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @summary get the clinic appointment associated with this queued appointment
     * @param {string} clinic_id Clinic
     * @param {string} queue_id The queue containing the queued appointment
     * @param {string} appointment_id The appointment who\&#39;s clinic appointment you want
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AqsPatientApi
     */
    public getAssociatedClinicAppointment(clinic_id: string, queue_id: string, appointment_id: string, options?: any) {
        return AqsPatientApiFp(this.configuration).getAssociatedClinicAppointment(clinic_id, queue_id, appointment_id, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @summary Get the live chat queue for this clinic
     * @param {string} clinic_id Clinic to the odb queue for
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AqsPatientApi
     */
    public getLiveChatQueue(clinic_id: string, options?: any) {
        return AqsPatientApiFp(this.configuration).getLiveChatQueue(clinic_id, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @summary Get the odb queue for this clinic
     * @param {string} clinic_id Clinic to the odb queue for
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AqsPatientApi
     */
    public getOdbQueue(clinic_id: string, options?: any) {
        return AqsPatientApiFp(this.configuration).getOdbQueue(clinic_id, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @summary Get all chat messages for this appointment
     * @param {string} clinic_id Clinic
     * @param {string} queue_id The queue containing the appointment
     * @param {string} appointment_id The appointment to record a message for
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AqsPatientApi
     */
    public getPatientQueuedAppointmentChatMessages(clinic_id: string, queue_id: string, appointment_id: string, options?: any) {
        return AqsPatientApiFp(this.configuration).getPatientQueuedAppointmentChatMessages(clinic_id, queue_id, appointment_id, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @summary Get an appointment from the aqs server
     * @param {string} clinic_id Clinic
     * @param {string} queue_id The queue containing the appointment you want
     * @param {string} appointment_id The appointment to get
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AqsPatientApi
     */
    public getQueuedAppointment(clinic_id: string, queue_id: string, appointment_id: string, options?: any) {
        return AqsPatientApiFp(this.configuration).getQueuedAppointment(clinic_id, queue_id, appointment_id, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @summary Get all appointments for this user in this queue from the AQS server
     * @param {string} clinic_id Clinic
     * @param {string} queue_id The queue containing the appointments you want
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AqsPatientApi
     */
    public getQueuedAppointments(clinic_id: string, queue_id: string, options?: any) {
        return AqsPatientApiFp(this.configuration).getQueuedAppointments(clinic_id, queue_id, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @summary Get all AQS queues for this clinic
     * @param {string} clinic_id Clinic to get the queues for
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AqsPatientApi
     */
    public getQueues(clinic_id: string, options?: any) {
        return AqsPatientApiFp(this.configuration).getQueues(clinic_id, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @summary Create a new telehealth session for the queued appointment
     * @param {string} clinic_id Clinic
     * @param {string} queue_id The queue containing the appointment
     * @param {string} appointment_id The appointment to create a session for
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AqsPatientApi
     */
    public getTelehealthSession(clinic_id: string, queue_id: string, appointment_id: string, options?: any) {
        return AqsPatientApiFp(this.configuration).getTelehealthSession(clinic_id, queue_id, appointment_id, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @summary Log a telehealth session event
     * @param {string} clinic_id Clinic
     * @param {string} queue_id The queue containing the appointment
     * @param {string} appointment_id The appointment to create a session for
     * @param {TelehealthLogEventDto} telehealth_log_event_dto Log event data
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AqsPatientApi
     */
    public logEventToPatientAqsTelehealthSession(clinic_id: string, queue_id: string, appointment_id: string, telehealth_log_event_dto: TelehealthLogEventDto, options?: any) {
        return AqsPatientApiFp(this.configuration).logEventToPatientAqsTelehealthSession(clinic_id, queue_id, appointment_id, telehealth_log_event_dto, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @summary Ping telehealth session
     * @param {string} clinic_id Clinic
     * @param {string} queue_id The queue containing the appointment
     * @param {string} appointment_id The appointment being pinged
     * @param {TelehealthPingSessionDto} telehealth_ping_session_dto Ping session data
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AqsPatientApi
     */
    public pingAqsTelehealthSession(clinic_id: string, queue_id: string, appointment_id: string, telehealth_ping_session_dto: TelehealthPingSessionDto, options?: any) {
        return AqsPatientApiFp(this.configuration).pingAqsTelehealthSession(clinic_id, queue_id, appointment_id, telehealth_ping_session_dto, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @summary Ping an appointment on the AQS server
     * @param {string} clinic_id Clinic
     * @param {string} queue_id The queue containing the appointment you want to ping
     * @param {string} appointment_id The appointment to ping
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AqsPatientApi
     */
    public pingQueuedAppointment(clinic_id: string, queue_id: string, appointment_id: string, options?: any) {
        return AqsPatientApiFp(this.configuration).pingQueuedAppointment(clinic_id, queue_id, appointment_id, options)(this.axios, this.basePath);
    }

}


/**
 * AqsPublicApi - axios parameter creator
 * @export
 */
export const AqsPublicApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Get the odb queue for this clinic
         * @param {string} clinic_id Clinic to the odb queue for
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOdbQueuePublic(clinic_id: string, options: any = {}): RequestArgs {
            // verify required parameter 'clinic_id' is not null or undefined
            if (clinic_id === null || clinic_id === undefined) {
                throw new RequiredError('clinic_id','Required parameter clinic_id was null or undefined when calling getOdbQueuePublic.');
            }
            const localVarPath = `/public/clinic/{clinic_id}/aqs/odb_queue`
                .replace(`{${"clinic_id"}}`, encodeURIComponent(String(clinic_id)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get all AQS queues for this clinic
         * @param {string} clinic_id Clinic to get the queues for
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getQueuesPublic(clinic_id: string, options: any = {}): RequestArgs {
            // verify required parameter 'clinic_id' is not null or undefined
            if (clinic_id === null || clinic_id === undefined) {
                throw new RequiredError('clinic_id','Required parameter clinic_id was null or undefined when calling getQueuesPublic.');
            }
            const localVarPath = `/public/clinic/{clinic_id}/aqs/queues`
                .replace(`{${"clinic_id"}}`, encodeURIComponent(String(clinic_id)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * AqsPublicApi - functional programming interface
 * @export
 */
export const AqsPublicApiFp = function(configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Get the odb queue for this clinic
         * @param {string} clinic_id Clinic to the odb queue for
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOdbQueuePublic(clinic_id: string, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<AqsQueueDto> {
            const localVarAxiosArgs = AqsPublicApiAxiosParamCreator(configuration).getOdbQueuePublic(clinic_id, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Get all AQS queues for this clinic
         * @param {string} clinic_id Clinic to get the queues for
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getQueuesPublic(clinic_id: string, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<AqsQueueDto>> {
            const localVarAxiosArgs = AqsPublicApiAxiosParamCreator(configuration).getQueuesPublic(clinic_id, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
    }
};

/**
 * AqsPublicApi - factory interface
 * @export
 */
export const AqsPublicApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    return {
        /**
         * 
         * @summary Get the odb queue for this clinic
         * @param {string} clinic_id Clinic to the odb queue for
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOdbQueuePublic(clinic_id: string, options?: any) {
            return AqsPublicApiFp(configuration).getOdbQueuePublic(clinic_id, options)(axios, basePath);
        },
        /**
         * 
         * @summary Get all AQS queues for this clinic
         * @param {string} clinic_id Clinic to get the queues for
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getQueuesPublic(clinic_id: string, options?: any) {
            return AqsPublicApiFp(configuration).getQueuesPublic(clinic_id, options)(axios, basePath);
        },
    };
};

/**
 * AqsPublicApi - object-oriented interface
 * @export
 * @class AqsPublicApi
 * @extends {BaseAPI}
 */
export class AqsPublicApi extends BaseAPI {
    /**
     * 
     * @summary Get the odb queue for this clinic
     * @param {string} clinic_id Clinic to the odb queue for
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AqsPublicApi
     */
    public getOdbQueuePublic(clinic_id: string, options?: any) {
        return AqsPublicApiFp(this.configuration).getOdbQueuePublic(clinic_id, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @summary Get all AQS queues for this clinic
     * @param {string} clinic_id Clinic to get the queues for
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AqsPublicApi
     */
    public getQueuesPublic(clinic_id: string, options?: any) {
        return AqsPublicApiFp(this.configuration).getQueuesPublic(clinic_id, options)(this.axios, this.basePath);
    }

}


/**
 * ClinicApi - axios parameter creator
 * @export
 */
export const ClinicApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Record a new chat message for the appointment
         * @param {string} appointment_id Id of the appointment to which the chat message will be added
         * @param {TelehealthChatMessageDto} telehealth_chat_message_dto Message
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addClinicUserChatMessageToAppointment(appointment_id: string, telehealth_chat_message_dto: TelehealthChatMessageDto, options: any = {}): RequestArgs {
            // verify required parameter 'appointment_id' is not null or undefined
            if (appointment_id === null || appointment_id === undefined) {
                throw new RequiredError('appointment_id','Required parameter appointment_id was null or undefined when calling addClinicUserChatMessageToAppointment.');
            }
            // verify required parameter 'telehealth_chat_message_dto' is not null or undefined
            if (telehealth_chat_message_dto === null || telehealth_chat_message_dto === undefined) {
                throw new RequiredError('telehealth_chat_message_dto','Required parameter telehealth_chat_message_dto was null or undefined when calling addClinicUserChatMessageToAppointment.');
            }
            const localVarPath = `/clinic_user/self/clinic/appointment/{appointment_id}/chat/message/`
                .replace(`{${"appointment_id"}}`, encodeURIComponent(String(appointment_id)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication apiAuth required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? configuration.apiKey("X-Auth-Token")
                    : configuration.apiKey;
                localVarHeaderParameter["X-Auth-Token"] = localVarApiKeyValue;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};
            const needsSerialization = (<any>"TelehealthChatMessageDto" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(telehealth_chat_message_dto !== undefined ? telehealth_chat_message_dto : {}) : (telehealth_chat_message_dto || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Cancel the verification of a patients connection to the clinic
         * @param {string} patient_id the patient id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        cancelVerifyPatientClinicConnection(patient_id: string, options: any = {}): RequestArgs {
            // verify required parameter 'patient_id' is not null or undefined
            if (patient_id === null || patient_id === undefined) {
                throw new RequiredError('patient_id','Required parameter patient_id was null or undefined when calling cancelVerifyPatientClinicConnection.');
            }
            const localVarPath = `/clinic_user/self/clinic/patient/{patient_id}/connection/cancel_verify`
                .replace(`{${"patient_id"}}`, encodeURIComponent(String(patient_id)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication apiAuth required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? configuration.apiKey("X-Auth-Token")
                    : configuration.apiKey;
                localVarHeaderParameter["X-Auth-Token"] = localVarApiKeyValue;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Check to see if the remote Oscar endpoint exists
         * @param {string} endpoint_name Endpoint name
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        checkRemoteEndpoint(endpoint_name: string, options: any = {}): RequestArgs {
            // verify required parameter 'endpoint_name' is not null or undefined
            if (endpoint_name === null || endpoint_name === undefined) {
                throw new RequiredError('endpoint_name','Required parameter endpoint_name was null or undefined when calling checkRemoteEndpoint.');
            }
            const localVarPath = `/clinic_user/self/clinic/check_remote_endpoint/{endpoint_name}`
                .replace(`{${"endpoint_name"}}`, encodeURIComponent(String(endpoint_name)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication apiAuth required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? configuration.apiKey("X-Auth-Token")
                    : configuration.apiKey;
                localVarHeaderParameter["X-Auth-Token"] = localVarApiKeyValue;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary get a document
         * @param {string} document_id document id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        clinicGetDocument(document_id: string, options: any = {}): RequestArgs {
            // verify required parameter 'document_id' is not null or undefined
            if (document_id === null || document_id === undefined) {
                throw new RequiredError('document_id','Required parameter document_id was null or undefined when calling clinicGetDocument.');
            }
            const localVarPath = `/clinic_user/self/document/{document_id}`
                .replace(`{${"document_id"}}`, encodeURIComponent(String(document_id)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication apiAuth required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? configuration.apiKey("X-Auth-Token")
                    : configuration.apiKey;
                localVarHeaderParameter["X-Auth-Token"] = localVarApiKeyValue;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Send a message
         * @param {MessageCreateTransfer} message_create_transfer Message to send
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        clinicMessageCreate(message_create_transfer: MessageCreateTransfer, options: any = {}): RequestArgs {
            // verify required parameter 'message_create_transfer' is not null or undefined
            if (message_create_transfer === null || message_create_transfer === undefined) {
                throw new RequiredError('message_create_transfer','Required parameter message_create_transfer was null or undefined when calling clinicMessageCreate.');
            }
            const localVarPath = `/clinic_user/self/clinic/message`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication apiAuth required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? configuration.apiKey("X-Auth-Token")
                    : configuration.apiKey;
                localVarHeaderParameter["X-Auth-Token"] = localVarApiKeyValue;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};
            const needsSerialization = (<any>"MessageCreateTransfer" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(message_create_transfer !== undefined ? message_create_transfer : {}) : (message_create_transfer || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Save a document to the patients chart.
         * @param {string} patient_id the patient id
         * @param {object} body Document to save
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        clinicSaveDocumentToPatientChart(patient_id: string, body: object, options: any = {}): RequestArgs {
            // verify required parameter 'patient_id' is not null or undefined
            if (patient_id === null || patient_id === undefined) {
                throw new RequiredError('patient_id','Required parameter patient_id was null or undefined when calling clinicSaveDocumentToPatientChart.');
            }
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body','Required parameter body was null or undefined when calling clinicSaveDocumentToPatientChart.');
            }
            const localVarPath = `/clinic_user/self/clinic/patient/{patient_id}/chart_document/`
                .replace(`{${"patient_id"}}`, encodeURIComponent(String(patient_id)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication apiAuth required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? configuration.apiKey("X-Auth-Token")
                    : configuration.apiKey;
                localVarHeaderParameter["X-Auth-Token"] = localVarApiKeyValue;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};
            const needsSerialization = (<any>"object" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(body !== undefined ? body : {}) : (body || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Add a new document.
         * @param {DocumentCreateTransfer} document_create_transfer Document create transfer
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        clinicUploadDocument(document_create_transfer: DocumentCreateTransfer, options: any = {}): RequestArgs {
            // verify required parameter 'document_create_transfer' is not null or undefined
            if (document_create_transfer === null || document_create_transfer === undefined) {
                throw new RequiredError('document_create_transfer','Required parameter document_create_transfer was null or undefined when calling clinicUploadDocument.');
            }
            const localVarPath = `/clinic_user/self/document/`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication apiAuth required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? configuration.apiKey("X-Auth-Token")
                    : configuration.apiKey;
                localVarHeaderParameter["X-Auth-Token"] = localVarApiKeyValue;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};
            const needsSerialization = (<any>"DocumentCreateTransfer" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(document_create_transfer !== undefined ? document_create_transfer : {}) : (document_create_transfer || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get a clinic appointment
         * @param {string} appointment_id Appointment you wish to fetch
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getClinicAppointment(appointment_id: string, options: any = {}): RequestArgs {
            // verify required parameter 'appointment_id' is not null or undefined
            if (appointment_id === null || appointment_id === undefined) {
                throw new RequiredError('appointment_id','Required parameter appointment_id was null or undefined when calling getClinicAppointment.');
            }
            const localVarPath = `/clinic_user/self/clinic/appointment/{appointment_id}/`
                .replace(`{${"appointment_id"}}`, encodeURIComponent(String(appointment_id)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication apiAuth required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? configuration.apiKey("X-Auth-Token")
                    : configuration.apiKey;
                localVarHeaderParameter["X-Auth-Token"] = localVarApiKeyValue;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get the clinic the logged in clinic user is attached to
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getClinicProfile(options: any = {}): RequestArgs {
            const localVarPath = `/clinic_user/self/clinic`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication apiAuth required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? configuration.apiKey("X-Auth-Token")
                    : configuration.apiKey;
                localVarHeaderParameter["X-Auth-Token"] = localVarApiKeyValue;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Return MyHealthAccess appointment
         * @param {string} appointment_id appointment id of the appointment
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getClinicUserAppointment(appointment_id: string, options: any = {}): RequestArgs {
            // verify required parameter 'appointment_id' is not null or undefined
            if (appointment_id === null || appointment_id === undefined) {
                throw new RequiredError('appointment_id','Required parameter appointment_id was null or undefined when calling getClinicUserAppointment.');
            }
            const localVarPath = `/clinic_user/self/appointment/{appointment_id}`
                .replace(`{${"appointment_id"}}`, encodeURIComponent(String(appointment_id)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication apiAuth required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? configuration.apiKey("X-Auth-Token")
                    : configuration.apiKey;
                localVarHeaderParameter["X-Auth-Token"] = localVarApiKeyValue;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get all chat messages for this appointment
         * @param {string} appointment_id Id of the appointment
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getClinicUserAppointmentChatMessages(appointment_id: string, options: any = {}): RequestArgs {
            // verify required parameter 'appointment_id' is not null or undefined
            if (appointment_id === null || appointment_id === undefined) {
                throw new RequiredError('appointment_id','Required parameter appointment_id was null or undefined when calling getClinicUserAppointmentChatMessages.');
            }
            const localVarPath = `/clinic_user/self/clinic/appointment/{appointment_id}/chat/messages/`
                .replace(`{${"appointment_id"}}`, encodeURIComponent(String(appointment_id)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication apiAuth required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? configuration.apiKey("X-Auth-Token")
                    : configuration.apiKey;
                localVarHeaderParameter["X-Auth-Token"] = localVarApiKeyValue;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Return MyHealthAccess appointment from remote Juno appointment_no
         * @param {number} remote_id Juno appointment no of the local appointment
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getLocalAppointment(remote_id: number, options: any = {}): RequestArgs {
            // verify required parameter 'remote_id' is not null or undefined
            if (remote_id === null || remote_id === undefined) {
                throw new RequiredError('remote_id','Required parameter remote_id was null or undefined when calling getLocalAppointment.');
            }
            const localVarPath = `/clinic_user/self/appointment/{remote_id}/local_from_remote`
                .replace(`{${"remote_id"}}`, encodeURIComponent(String(remote_id)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication apiAuth required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? configuration.apiKey("X-Auth-Token")
                    : configuration.apiKey;
                localVarHeaderParameter["X-Auth-Token"] = localVarApiKeyValue;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Return MyHealthAccess appointment from AQS queued appointment id
         * @param {string} queued_appointment_id AQS queued appointment
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getLocalQueuedAppointment(queued_appointment_id: string, options: any = {}): RequestArgs {
            // verify required parameter 'queued_appointment_id' is not null or undefined
            if (queued_appointment_id === null || queued_appointment_id === undefined) {
                throw new RequiredError('queued_appointment_id','Required parameter queued_appointment_id was null or undefined when calling getLocalQueuedAppointment.');
            }
            const localVarPath = `/clinic_user/self/appointment/aqs/{queued_appointment_id}`
                .replace(`{${"queued_appointment_id"}}`, encodeURIComponent(String(queued_appointment_id)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication apiAuth required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? configuration.apiKey("X-Auth-Token")
                    : configuration.apiKey;
                localVarHeaderParameter["X-Auth-Token"] = localVarApiKeyValue;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get the patients conneciton to the clinic
         * @param {string} patient_id the patient id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPatientClinicConnection(patient_id: string, options: any = {}): RequestArgs {
            // verify required parameter 'patient_id' is not null or undefined
            if (patient_id === null || patient_id === undefined) {
                throw new RequiredError('patient_id','Required parameter patient_id was null or undefined when calling getPatientClinicConnection.');
            }
            const localVarPath = `/clinic_user/self/clinic/patient/{patient_id}/connection/`
                .replace(`{${"patient_id"}}`, encodeURIComponent(String(patient_id)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication apiAuth required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? configuration.apiKey("X-Auth-Token")
                    : configuration.apiKey;
                localVarHeaderParameter["X-Auth-Token"] = localVarApiKeyValue;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get a patient\'s profile
         * @param {string} patient_id the patient id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPatientProfile(patient_id: string, options: any = {}): RequestArgs {
            // verify required parameter 'patient_id' is not null or undefined
            if (patient_id === null || patient_id === undefined) {
                throw new RequiredError('patient_id','Required parameter patient_id was null or undefined when calling getPatientProfile.');
            }
            const localVarPath = `/clinic_user/self/clinic/patient/{patient_id}/`
                .replace(`{${"patient_id"}}`, encodeURIComponent(String(patient_id)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication apiAuth required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? configuration.apiKey("X-Auth-Token")
                    : configuration.apiKey;
                localVarHeaderParameter["X-Auth-Token"] = localVarApiKeyValue;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get a patient\'s remote profile
         * @param {string} patient_id the patient id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPatientRemoteProfile(patient_id: string, options: any = {}): RequestArgs {
            // verify required parameter 'patient_id' is not null or undefined
            if (patient_id === null || patient_id === undefined) {
                throw new RequiredError('patient_id','Required parameter patient_id was null or undefined when calling getPatientRemoteProfile.');
            }
            const localVarPath = `/clinic_user/self/clinic/patient/{patient_id}/remote/profile`
                .replace(`{${"patient_id"}}`, encodeURIComponent(String(patient_id)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication apiAuth required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? configuration.apiKey("X-Auth-Token")
                    : configuration.apiKey;
                localVarHeaderParameter["X-Auth-Token"] = localVarApiKeyValue;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Retrieve or create a provider telehealth session for the specified appointment
         * @param {string} remote_id Appointment the telehealth session is being created for
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getProviderTeleHealthSession(remote_id: string, options: any = {}): RequestArgs {
            // verify required parameter 'remote_id' is not null or undefined
            if (remote_id === null || remote_id === undefined) {
                throw new RequiredError('remote_id','Required parameter remote_id was null or undefined when calling getProviderTeleHealthSession.');
            }
            const localVarPath = `/clinic_user/self/appointment/{remote_id}/telehealth/create`
                .replace(`{${"remote_id"}}`, encodeURIComponent(String(remote_id)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication apiAuth required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? configuration.apiKey("X-Auth-Token")
                    : configuration.apiKey;
                localVarHeaderParameter["X-Auth-Token"] = localVarApiKeyValue;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Telehealth appointment session data
         * @param {string} appointment_id Appointment the telehealth session is for
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getProviderTelehealthSessionData(appointment_id: string, options: any = {}): RequestArgs {
            // verify required parameter 'appointment_id' is not null or undefined
            if (appointment_id === null || appointment_id === undefined) {
                throw new RequiredError('appointment_id','Required parameter appointment_id was null or undefined when calling getProviderTelehealthSessionData.');
            }
            const localVarPath = `/clinic_user/self/appointment/{appointment_id}/session`
                .replace(`{${"appointment_id"}}`, encodeURIComponent(String(appointment_id)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication apiAuth required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? configuration.apiKey("X-Auth-Token")
                    : configuration.apiKey;
                localVarHeaderParameter["X-Auth-Token"] = localVarApiKeyValue;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get a remote patient profile
         * @param {string} remote_id the patient\&#39;s remote id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getRemotePatientProfile(remote_id: string, options: any = {}): RequestArgs {
            // verify required parameter 'remote_id' is not null or undefined
            if (remote_id === null || remote_id === undefined) {
                throw new RequiredError('remote_id','Required parameter remote_id was null or undefined when calling getRemotePatientProfile.');
            }
            const localVarPath = `/clinic_user/self/juno/patient/{remote_id}/`
                .replace(`{${"remote_id"}}`, encodeURIComponent(String(remote_id)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication apiAuth required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? configuration.apiKey("X-Auth-Token")
                    : configuration.apiKey;
                localVarHeaderParameter["X-Auth-Token"] = localVarApiKeyValue;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Link a patient to an EMR record
         * @param {string} patient_id the patient id
         * @param {InlineObject10} [inline_object10] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        linkPatientToClinicEmrRecord(patient_id: string, inline_object10?: InlineObject10, options: any = {}): RequestArgs {
            // verify required parameter 'patient_id' is not null or undefined
            if (patient_id === null || patient_id === undefined) {
                throw new RequiredError('patient_id','Required parameter patient_id was null or undefined when calling linkPatientToClinicEmrRecord.');
            }
            const localVarPath = `/clinic_user/self/clinic/patient/{patient_id}/connection/link_to_emr`
                .replace(`{${"patient_id"}}`, encodeURIComponent(String(patient_id)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication apiAuth required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? configuration.apiKey("X-Auth-Token")
                    : configuration.apiKey;
                localVarHeaderParameter["X-Auth-Token"] = localVarApiKeyValue;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};
            const needsSerialization = (<any>"InlineObject10" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(inline_object10 !== undefined ? inline_object10 : {}) : (inline_object10 || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Log a telehealth session event
         * @param {string} appointment_id Appointment whos telehealth session is going to be logged to
         * @param {TelehealthLogEventDto} telehealth_log_event_dto Log event data
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        logEventToProviderTelehealthSession(appointment_id: string, telehealth_log_event_dto: TelehealthLogEventDto, options: any = {}): RequestArgs {
            // verify required parameter 'appointment_id' is not null or undefined
            if (appointment_id === null || appointment_id === undefined) {
                throw new RequiredError('appointment_id','Required parameter appointment_id was null or undefined when calling logEventToProviderTelehealthSession.');
            }
            // verify required parameter 'telehealth_log_event_dto' is not null or undefined
            if (telehealth_log_event_dto === null || telehealth_log_event_dto === undefined) {
                throw new RequiredError('telehealth_log_event_dto','Required parameter telehealth_log_event_dto was null or undefined when calling logEventToProviderTelehealthSession.');
            }
            const localVarPath = `/clinic_user/self/appointment/{appointment_id}/session/log_event`
                .replace(`{${"appointment_id"}}`, encodeURIComponent(String(appointment_id)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication apiAuth required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? configuration.apiKey("X-Auth-Token")
                    : configuration.apiKey;
                localVarHeaderParameter["X-Auth-Token"] = localVarApiKeyValue;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};
            const needsSerialization = (<any>"TelehealthLogEventDto" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(telehealth_log_event_dto !== undefined ? telehealth_log_event_dto : {}) : (telehealth_log_event_dto || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Logged in clinic user
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        loggedInClinicUser(options: any = {}): RequestArgs {
            const localVarPath = `/clinic_user/self`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication apiAuth required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? configuration.apiKey("X-Auth-Token")
                    : configuration.apiKey;
                localVarHeaderParameter["X-Auth-Token"] = localVarApiKeyValue;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Endpoint to call when the session starts. i.e Send push notifications
         * @param {string} remote_id Appointment the telehealth session is for
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        onSessionStart(remote_id: string, options: any = {}): RequestArgs {
            // verify required parameter 'remote_id' is not null or undefined
            if (remote_id === null || remote_id === undefined) {
                throw new RequiredError('remote_id','Required parameter remote_id was null or undefined when calling onSessionStart.');
            }
            const localVarPath = `/clinic_user/self/appointment/{remote_id}/telehealth/session_started`
                .replace(`{${"remote_id"}}`, encodeURIComponent(String(remote_id)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication apiAuth required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? configuration.apiKey("X-Auth-Token")
                    : configuration.apiKey;
                localVarHeaderParameter["X-Auth-Token"] = localVarApiKeyValue;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Endpoint to call when the session starts. i.e Send push notifications
         * @param {string} appointment_id Appointment the telehealth session is for
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postTelehealthSessionStart(appointment_id: string, options: any = {}): RequestArgs {
            // verify required parameter 'appointment_id' is not null or undefined
            if (appointment_id === null || appointment_id === undefined) {
                throw new RequiredError('appointment_id','Required parameter appointment_id was null or undefined when calling postTelehealthSessionStart.');
            }
            const localVarPath = `/clinic_user/self/appointment/{appointment_id}/session_started`
                .replace(`{${"appointment_id"}}`, encodeURIComponent(String(appointment_id)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication apiAuth required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? configuration.apiKey("X-Auth-Token")
                    : configuration.apiKey;
                localVarHeaderParameter["X-Auth-Token"] = localVarApiKeyValue;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Send push notification to patient
         * @param {string} patient_id the patient id to which you wish to send the push notification
         * @param {PushNotification} push_notification Push Notification
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        sendPushNotification(patient_id: string, push_notification: PushNotification, options: any = {}): RequestArgs {
            // verify required parameter 'patient_id' is not null or undefined
            if (patient_id === null || patient_id === undefined) {
                throw new RequiredError('patient_id','Required parameter patient_id was null or undefined when calling sendPushNotification.');
            }
            // verify required parameter 'push_notification' is not null or undefined
            if (push_notification === null || push_notification === undefined) {
                throw new RequiredError('push_notification','Required parameter push_notification was null or undefined when calling sendPushNotification.');
            }
            const localVarPath = `/clinic_user/self/clinic/patient/{patient_id}/push_notification`
                .replace(`{${"patient_id"}}`, encodeURIComponent(String(patient_id)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication apiAuth required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? configuration.apiKey("X-Auth-Token")
                    : configuration.apiKey;
                localVarHeaderParameter["X-Auth-Token"] = localVarApiKeyValue;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};
            const needsSerialization = (<any>"PushNotification" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(push_notification !== undefined ? push_notification : {}) : (push_notification || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Cancels any pending voip calls notification. i.e. stops the user\'s phone from rining
         * @param {string} appointment_id Appointment the telehealth session is for
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        telehealthCancelPendingCalls(appointment_id: string, options: any = {}): RequestArgs {
            // verify required parameter 'appointment_id' is not null or undefined
            if (appointment_id === null || appointment_id === undefined) {
                throw new RequiredError('appointment_id','Required parameter appointment_id was null or undefined when calling telehealthCancelPendingCalls.');
            }
            const localVarPath = `/clinic_user/self/appointment/{appointment_id}/cancel_voip_call`
                .replace(`{${"appointment_id"}}`, encodeURIComponent(String(appointment_id)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication apiAuth required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? configuration.apiKey("X-Auth-Token")
                    : configuration.apiKey;
                localVarHeaderParameter["X-Auth-Token"] = localVarApiKeyValue;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Verify a patients connection to the clinic
         * @param {string} patient_id the patient id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        verifyPatientClinicConnection(patient_id: string, options: any = {}): RequestArgs {
            // verify required parameter 'patient_id' is not null or undefined
            if (patient_id === null || patient_id === undefined) {
                throw new RequiredError('patient_id','Required parameter patient_id was null or undefined when calling verifyPatientClinicConnection.');
            }
            const localVarPath = `/clinic_user/self/clinic/patient/{patient_id}/connection/verify`
                .replace(`{${"patient_id"}}`, encodeURIComponent(String(patient_id)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication apiAuth required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? configuration.apiKey("X-Auth-Token")
                    : configuration.apiKey;
                localVarHeaderParameter["X-Auth-Token"] = localVarApiKeyValue;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Write an encounter note to the patient\'s eChart
         * @param {string} patient_id the patient id
         * @param {EncounterNote} encounter_note Encounter note
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        writeToEncounterNote(patient_id: string, encounter_note: EncounterNote, options: any = {}): RequestArgs {
            // verify required parameter 'patient_id' is not null or undefined
            if (patient_id === null || patient_id === undefined) {
                throw new RequiredError('patient_id','Required parameter patient_id was null or undefined when calling writeToEncounterNote.');
            }
            // verify required parameter 'encounter_note' is not null or undefined
            if (encounter_note === null || encounter_note === undefined) {
                throw new RequiredError('encounter_note','Required parameter encounter_note was null or undefined when calling writeToEncounterNote.');
            }
            const localVarPath = `/clinic_user/self/clinic/patient/{patient_id}/write_encounter_note`
                .replace(`{${"patient_id"}}`, encodeURIComponent(String(patient_id)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication apiAuth required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? configuration.apiKey("X-Auth-Token")
                    : configuration.apiKey;
                localVarHeaderParameter["X-Auth-Token"] = localVarApiKeyValue;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};
            const needsSerialization = (<any>"EncounterNote" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(encounter_note !== undefined ? encounter_note : {}) : (encounter_note || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ClinicApi - functional programming interface
 * @export
 */
export const ClinicApiFp = function(configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Record a new chat message for the appointment
         * @param {string} appointment_id Id of the appointment to which the chat message will be added
         * @param {TelehealthChatMessageDto} telehealth_chat_message_dto Message
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addClinicUserChatMessageToAppointment(appointment_id: string, telehealth_chat_message_dto: TelehealthChatMessageDto, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<TelehealthChatMessageDto> {
            const localVarAxiosArgs = ClinicApiAxiosParamCreator(configuration).addClinicUserChatMessageToAppointment(appointment_id, telehealth_chat_message_dto, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Cancel the verification of a patients connection to the clinic
         * @param {string} patient_id the patient id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        cancelVerifyPatientClinicConnection(patient_id: string, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<void> {
            const localVarAxiosArgs = ClinicApiAxiosParamCreator(configuration).cancelVerifyPatientClinicConnection(patient_id, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Check to see if the remote Oscar endpoint exists
         * @param {string} endpoint_name Endpoint name
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        checkRemoteEndpoint(endpoint_name: string, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<void> {
            const localVarAxiosArgs = ClinicApiAxiosParamCreator(configuration).checkRemoteEndpoint(endpoint_name, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary get a document
         * @param {string} document_id document id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        clinicGetDocument(document_id: string, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<DocumentTransfer> {
            const localVarAxiosArgs = ClinicApiAxiosParamCreator(configuration).clinicGetDocument(document_id, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Send a message
         * @param {MessageCreateTransfer} message_create_transfer Message to send
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        clinicMessageCreate(message_create_transfer: MessageCreateTransfer, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<InboxMessage> {
            const localVarAxiosArgs = ClinicApiAxiosParamCreator(configuration).clinicMessageCreate(message_create_transfer, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Save a document to the patients chart.
         * @param {string} patient_id the patient id
         * @param {object} body Document to save
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        clinicSaveDocumentToPatientChart(patient_id: string, body: object, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<object> {
            const localVarAxiosArgs = ClinicApiAxiosParamCreator(configuration).clinicSaveDocumentToPatientChart(patient_id, body, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Add a new document.
         * @param {DocumentCreateTransfer} document_create_transfer Document create transfer
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        clinicUploadDocument(document_create_transfer: DocumentCreateTransfer, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<DocumentTransfer> {
            const localVarAxiosArgs = ClinicApiAxiosParamCreator(configuration).clinicUploadDocument(document_create_transfer, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Get a clinic appointment
         * @param {string} appointment_id Appointment you wish to fetch
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getClinicAppointment(appointment_id: string, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<ClinicAppointmentTransfer> {
            const localVarAxiosArgs = ClinicApiAxiosParamCreator(configuration).getClinicAppointment(appointment_id, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Get the clinic the logged in clinic user is attached to
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getClinicProfile(options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<Clinic> {
            const localVarAxiosArgs = ClinicApiAxiosParamCreator(configuration).getClinicProfile(options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Return MyHealthAccess appointment
         * @param {string} appointment_id appointment id of the appointment
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getClinicUserAppointment(appointment_id: string, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<AppointmentTransfer> {
            const localVarAxiosArgs = ClinicApiAxiosParamCreator(configuration).getClinicUserAppointment(appointment_id, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Get all chat messages for this appointment
         * @param {string} appointment_id Id of the appointment
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getClinicUserAppointmentChatMessages(appointment_id: string, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<TelehealthChatMessageDto>> {
            const localVarAxiosArgs = ClinicApiAxiosParamCreator(configuration).getClinicUserAppointmentChatMessages(appointment_id, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Return MyHealthAccess appointment from remote Juno appointment_no
         * @param {number} remote_id Juno appointment no of the local appointment
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getLocalAppointment(remote_id: number, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<AppointmentTransfer> {
            const localVarAxiosArgs = ClinicApiAxiosParamCreator(configuration).getLocalAppointment(remote_id, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Return MyHealthAccess appointment from AQS queued appointment id
         * @param {string} queued_appointment_id AQS queued appointment
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getLocalQueuedAppointment(queued_appointment_id: string, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<AppointmentTransfer> {
            const localVarAxiosArgs = ClinicApiAxiosParamCreator(configuration).getLocalQueuedAppointment(queued_appointment_id, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Get the patients conneciton to the clinic
         * @param {string} patient_id the patient id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPatientClinicConnection(patient_id: string, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<ClinicPatientAccessDto> {
            const localVarAxiosArgs = ClinicApiAxiosParamCreator(configuration).getPatientClinicConnection(patient_id, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Get a patient\'s profile
         * @param {string} patient_id the patient id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPatientProfile(patient_id: string, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<PatientUserDto> {
            const localVarAxiosArgs = ClinicApiAxiosParamCreator(configuration).getPatientProfile(patient_id, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Get a patient\'s remote profile
         * @param {string} patient_id the patient id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPatientRemoteProfile(patient_id: string, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<PatientUserDto> {
            const localVarAxiosArgs = ClinicApiAxiosParamCreator(configuration).getPatientRemoteProfile(patient_id, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Retrieve or create a provider telehealth session for the specified appointment
         * @param {string} remote_id Appointment the telehealth session is being created for
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getProviderTeleHealthSession(remote_id: string, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<OTSessionResponse> {
            const localVarAxiosArgs = ClinicApiAxiosParamCreator(configuration).getProviderTeleHealthSession(remote_id, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Telehealth appointment session data
         * @param {string} appointment_id Appointment the telehealth session is for
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getProviderTelehealthSessionData(appointment_id: string, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<OTSessionInfo> {
            const localVarAxiosArgs = ClinicApiAxiosParamCreator(configuration).getProviderTelehealthSessionData(appointment_id, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Get a remote patient profile
         * @param {string} remote_id the patient\&#39;s remote id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getRemotePatientProfile(remote_id: string, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<PatientUserDto> {
            const localVarAxiosArgs = ClinicApiAxiosParamCreator(configuration).getRemotePatientProfile(remote_id, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Link a patient to an EMR record
         * @param {string} patient_id the patient id
         * @param {InlineObject10} [inline_object10] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        linkPatientToClinicEmrRecord(patient_id: string, inline_object10?: InlineObject10, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<void> {
            const localVarAxiosArgs = ClinicApiAxiosParamCreator(configuration).linkPatientToClinicEmrRecord(patient_id, inline_object10, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Log a telehealth session event
         * @param {string} appointment_id Appointment whos telehealth session is going to be logged to
         * @param {TelehealthLogEventDto} telehealth_log_event_dto Log event data
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        logEventToProviderTelehealthSession(appointment_id: string, telehealth_log_event_dto: TelehealthLogEventDto, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<void> {
            const localVarAxiosArgs = ClinicApiAxiosParamCreator(configuration).logEventToProviderTelehealthSession(appointment_id, telehealth_log_event_dto, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Logged in clinic user
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        loggedInClinicUser(options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<ClinicUserLoginResponse> {
            const localVarAxiosArgs = ClinicApiAxiosParamCreator(configuration).loggedInClinicUser(options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Endpoint to call when the session starts. i.e Send push notifications
         * @param {string} remote_id Appointment the telehealth session is for
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        onSessionStart(remote_id: string, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<void> {
            const localVarAxiosArgs = ClinicApiAxiosParamCreator(configuration).onSessionStart(remote_id, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Endpoint to call when the session starts. i.e Send push notifications
         * @param {string} appointment_id Appointment the telehealth session is for
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postTelehealthSessionStart(appointment_id: string, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<void> {
            const localVarAxiosArgs = ClinicApiAxiosParamCreator(configuration).postTelehealthSessionStart(appointment_id, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Send push notification to patient
         * @param {string} patient_id the patient id to which you wish to send the push notification
         * @param {PushNotification} push_notification Push Notification
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        sendPushNotification(patient_id: string, push_notification: PushNotification, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<void> {
            const localVarAxiosArgs = ClinicApiAxiosParamCreator(configuration).sendPushNotification(patient_id, push_notification, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Cancels any pending voip calls notification. i.e. stops the user\'s phone from rining
         * @param {string} appointment_id Appointment the telehealth session is for
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        telehealthCancelPendingCalls(appointment_id: string, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<void> {
            const localVarAxiosArgs = ClinicApiAxiosParamCreator(configuration).telehealthCancelPendingCalls(appointment_id, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Verify a patients connection to the clinic
         * @param {string} patient_id the patient id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        verifyPatientClinicConnection(patient_id: string, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<void> {
            const localVarAxiosArgs = ClinicApiAxiosParamCreator(configuration).verifyPatientClinicConnection(patient_id, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Write an encounter note to the patient\'s eChart
         * @param {string} patient_id the patient id
         * @param {EncounterNote} encounter_note Encounter note
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        writeToEncounterNote(patient_id: string, encounter_note: EncounterNote, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<void> {
            const localVarAxiosArgs = ClinicApiAxiosParamCreator(configuration).writeToEncounterNote(patient_id, encounter_note, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
    }
};

/**
 * ClinicApi - factory interface
 * @export
 */
export const ClinicApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    return {
        /**
         * 
         * @summary Record a new chat message for the appointment
         * @param {string} appointment_id Id of the appointment to which the chat message will be added
         * @param {TelehealthChatMessageDto} telehealth_chat_message_dto Message
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addClinicUserChatMessageToAppointment(appointment_id: string, telehealth_chat_message_dto: TelehealthChatMessageDto, options?: any) {
            return ClinicApiFp(configuration).addClinicUserChatMessageToAppointment(appointment_id, telehealth_chat_message_dto, options)(axios, basePath);
        },
        /**
         * 
         * @summary Cancel the verification of a patients connection to the clinic
         * @param {string} patient_id the patient id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        cancelVerifyPatientClinicConnection(patient_id: string, options?: any) {
            return ClinicApiFp(configuration).cancelVerifyPatientClinicConnection(patient_id, options)(axios, basePath);
        },
        /**
         * 
         * @summary Check to see if the remote Oscar endpoint exists
         * @param {string} endpoint_name Endpoint name
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        checkRemoteEndpoint(endpoint_name: string, options?: any) {
            return ClinicApiFp(configuration).checkRemoteEndpoint(endpoint_name, options)(axios, basePath);
        },
        /**
         * 
         * @summary get a document
         * @param {string} document_id document id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        clinicGetDocument(document_id: string, options?: any) {
            return ClinicApiFp(configuration).clinicGetDocument(document_id, options)(axios, basePath);
        },
        /**
         * 
         * @summary Send a message
         * @param {MessageCreateTransfer} message_create_transfer Message to send
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        clinicMessageCreate(message_create_transfer: MessageCreateTransfer, options?: any) {
            return ClinicApiFp(configuration).clinicMessageCreate(message_create_transfer, options)(axios, basePath);
        },
        /**
         * 
         * @summary Save a document to the patients chart.
         * @param {string} patient_id the patient id
         * @param {object} body Document to save
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        clinicSaveDocumentToPatientChart(patient_id: string, body: object, options?: any) {
            return ClinicApiFp(configuration).clinicSaveDocumentToPatientChart(patient_id, body, options)(axios, basePath);
        },
        /**
         * 
         * @summary Add a new document.
         * @param {DocumentCreateTransfer} document_create_transfer Document create transfer
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        clinicUploadDocument(document_create_transfer: DocumentCreateTransfer, options?: any) {
            return ClinicApiFp(configuration).clinicUploadDocument(document_create_transfer, options)(axios, basePath);
        },
        /**
         * 
         * @summary Get a clinic appointment
         * @param {string} appointment_id Appointment you wish to fetch
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getClinicAppointment(appointment_id: string, options?: any) {
            return ClinicApiFp(configuration).getClinicAppointment(appointment_id, options)(axios, basePath);
        },
        /**
         * 
         * @summary Get the clinic the logged in clinic user is attached to
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getClinicProfile(options?: any) {
            return ClinicApiFp(configuration).getClinicProfile(options)(axios, basePath);
        },
        /**
         * 
         * @summary Return MyHealthAccess appointment
         * @param {string} appointment_id appointment id of the appointment
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getClinicUserAppointment(appointment_id: string, options?: any) {
            return ClinicApiFp(configuration).getClinicUserAppointment(appointment_id, options)(axios, basePath);
        },
        /**
         * 
         * @summary Get all chat messages for this appointment
         * @param {string} appointment_id Id of the appointment
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getClinicUserAppointmentChatMessages(appointment_id: string, options?: any) {
            return ClinicApiFp(configuration).getClinicUserAppointmentChatMessages(appointment_id, options)(axios, basePath);
        },
        /**
         * 
         * @summary Return MyHealthAccess appointment from remote Juno appointment_no
         * @param {number} remote_id Juno appointment no of the local appointment
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getLocalAppointment(remote_id: number, options?: any) {
            return ClinicApiFp(configuration).getLocalAppointment(remote_id, options)(axios, basePath);
        },
        /**
         * 
         * @summary Return MyHealthAccess appointment from AQS queued appointment id
         * @param {string} queued_appointment_id AQS queued appointment
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getLocalQueuedAppointment(queued_appointment_id: string, options?: any) {
            return ClinicApiFp(configuration).getLocalQueuedAppointment(queued_appointment_id, options)(axios, basePath);
        },
        /**
         * 
         * @summary Get the patients conneciton to the clinic
         * @param {string} patient_id the patient id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPatientClinicConnection(patient_id: string, options?: any) {
            return ClinicApiFp(configuration).getPatientClinicConnection(patient_id, options)(axios, basePath);
        },
        /**
         * 
         * @summary Get a patient\'s profile
         * @param {string} patient_id the patient id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPatientProfile(patient_id: string, options?: any) {
            return ClinicApiFp(configuration).getPatientProfile(patient_id, options)(axios, basePath);
        },
        /**
         * 
         * @summary Get a patient\'s remote profile
         * @param {string} patient_id the patient id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPatientRemoteProfile(patient_id: string, options?: any) {
            return ClinicApiFp(configuration).getPatientRemoteProfile(patient_id, options)(axios, basePath);
        },
        /**
         * 
         * @summary Retrieve or create a provider telehealth session for the specified appointment
         * @param {string} remote_id Appointment the telehealth session is being created for
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getProviderTeleHealthSession(remote_id: string, options?: any) {
            return ClinicApiFp(configuration).getProviderTeleHealthSession(remote_id, options)(axios, basePath);
        },
        /**
         * 
         * @summary Telehealth appointment session data
         * @param {string} appointment_id Appointment the telehealth session is for
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getProviderTelehealthSessionData(appointment_id: string, options?: any) {
            return ClinicApiFp(configuration).getProviderTelehealthSessionData(appointment_id, options)(axios, basePath);
        },
        /**
         * 
         * @summary Get a remote patient profile
         * @param {string} remote_id the patient\&#39;s remote id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getRemotePatientProfile(remote_id: string, options?: any) {
            return ClinicApiFp(configuration).getRemotePatientProfile(remote_id, options)(axios, basePath);
        },
        /**
         * 
         * @summary Link a patient to an EMR record
         * @param {string} patient_id the patient id
         * @param {InlineObject10} [inline_object10] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        linkPatientToClinicEmrRecord(patient_id: string, inline_object10?: InlineObject10, options?: any) {
            return ClinicApiFp(configuration).linkPatientToClinicEmrRecord(patient_id, inline_object10, options)(axios, basePath);
        },
        /**
         * 
         * @summary Log a telehealth session event
         * @param {string} appointment_id Appointment whos telehealth session is going to be logged to
         * @param {TelehealthLogEventDto} telehealth_log_event_dto Log event data
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        logEventToProviderTelehealthSession(appointment_id: string, telehealth_log_event_dto: TelehealthLogEventDto, options?: any) {
            return ClinicApiFp(configuration).logEventToProviderTelehealthSession(appointment_id, telehealth_log_event_dto, options)(axios, basePath);
        },
        /**
         * 
         * @summary Logged in clinic user
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        loggedInClinicUser(options?: any) {
            return ClinicApiFp(configuration).loggedInClinicUser(options)(axios, basePath);
        },
        /**
         * 
         * @summary Endpoint to call when the session starts. i.e Send push notifications
         * @param {string} remote_id Appointment the telehealth session is for
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        onSessionStart(remote_id: string, options?: any) {
            return ClinicApiFp(configuration).onSessionStart(remote_id, options)(axios, basePath);
        },
        /**
         * 
         * @summary Endpoint to call when the session starts. i.e Send push notifications
         * @param {string} appointment_id Appointment the telehealth session is for
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postTelehealthSessionStart(appointment_id: string, options?: any) {
            return ClinicApiFp(configuration).postTelehealthSessionStart(appointment_id, options)(axios, basePath);
        },
        /**
         * 
         * @summary Send push notification to patient
         * @param {string} patient_id the patient id to which you wish to send the push notification
         * @param {PushNotification} push_notification Push Notification
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        sendPushNotification(patient_id: string, push_notification: PushNotification, options?: any) {
            return ClinicApiFp(configuration).sendPushNotification(patient_id, push_notification, options)(axios, basePath);
        },
        /**
         * 
         * @summary Cancels any pending voip calls notification. i.e. stops the user\'s phone from rining
         * @param {string} appointment_id Appointment the telehealth session is for
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        telehealthCancelPendingCalls(appointment_id: string, options?: any) {
            return ClinicApiFp(configuration).telehealthCancelPendingCalls(appointment_id, options)(axios, basePath);
        },
        /**
         * 
         * @summary Verify a patients connection to the clinic
         * @param {string} patient_id the patient id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        verifyPatientClinicConnection(patient_id: string, options?: any) {
            return ClinicApiFp(configuration).verifyPatientClinicConnection(patient_id, options)(axios, basePath);
        },
        /**
         * 
         * @summary Write an encounter note to the patient\'s eChart
         * @param {string} patient_id the patient id
         * @param {EncounterNote} encounter_note Encounter note
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        writeToEncounterNote(patient_id: string, encounter_note: EncounterNote, options?: any) {
            return ClinicApiFp(configuration).writeToEncounterNote(patient_id, encounter_note, options)(axios, basePath);
        },
    };
};

/**
 * ClinicApi - object-oriented interface
 * @export
 * @class ClinicApi
 * @extends {BaseAPI}
 */
export class ClinicApi extends BaseAPI {
    /**
     * 
     * @summary Record a new chat message for the appointment
     * @param {string} appointment_id Id of the appointment to which the chat message will be added
     * @param {TelehealthChatMessageDto} telehealth_chat_message_dto Message
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ClinicApi
     */
    public addClinicUserChatMessageToAppointment(appointment_id: string, telehealth_chat_message_dto: TelehealthChatMessageDto, options?: any) {
        return ClinicApiFp(this.configuration).addClinicUserChatMessageToAppointment(appointment_id, telehealth_chat_message_dto, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @summary Cancel the verification of a patients connection to the clinic
     * @param {string} patient_id the patient id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ClinicApi
     */
    public cancelVerifyPatientClinicConnection(patient_id: string, options?: any) {
        return ClinicApiFp(this.configuration).cancelVerifyPatientClinicConnection(patient_id, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @summary Check to see if the remote Oscar endpoint exists
     * @param {string} endpoint_name Endpoint name
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ClinicApi
     */
    public checkRemoteEndpoint(endpoint_name: string, options?: any) {
        return ClinicApiFp(this.configuration).checkRemoteEndpoint(endpoint_name, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @summary get a document
     * @param {string} document_id document id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ClinicApi
     */
    public clinicGetDocument(document_id: string, options?: any) {
        return ClinicApiFp(this.configuration).clinicGetDocument(document_id, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @summary Send a message
     * @param {MessageCreateTransfer} message_create_transfer Message to send
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ClinicApi
     */
    public clinicMessageCreate(message_create_transfer: MessageCreateTransfer, options?: any) {
        return ClinicApiFp(this.configuration).clinicMessageCreate(message_create_transfer, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @summary Save a document to the patients chart.
     * @param {string} patient_id the patient id
     * @param {object} body Document to save
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ClinicApi
     */
    public clinicSaveDocumentToPatientChart(patient_id: string, body: object, options?: any) {
        return ClinicApiFp(this.configuration).clinicSaveDocumentToPatientChart(patient_id, body, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @summary Add a new document.
     * @param {DocumentCreateTransfer} document_create_transfer Document create transfer
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ClinicApi
     */
    public clinicUploadDocument(document_create_transfer: DocumentCreateTransfer, options?: any) {
        return ClinicApiFp(this.configuration).clinicUploadDocument(document_create_transfer, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @summary Get a clinic appointment
     * @param {string} appointment_id Appointment you wish to fetch
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ClinicApi
     */
    public getClinicAppointment(appointment_id: string, options?: any) {
        return ClinicApiFp(this.configuration).getClinicAppointment(appointment_id, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @summary Get the clinic the logged in clinic user is attached to
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ClinicApi
     */
    public getClinicProfile(options?: any) {
        return ClinicApiFp(this.configuration).getClinicProfile(options)(this.axios, this.basePath);
    }

    /**
     * 
     * @summary Return MyHealthAccess appointment
     * @param {string} appointment_id appointment id of the appointment
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ClinicApi
     */
    public getClinicUserAppointment(appointment_id: string, options?: any) {
        return ClinicApiFp(this.configuration).getClinicUserAppointment(appointment_id, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @summary Get all chat messages for this appointment
     * @param {string} appointment_id Id of the appointment
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ClinicApi
     */
    public getClinicUserAppointmentChatMessages(appointment_id: string, options?: any) {
        return ClinicApiFp(this.configuration).getClinicUserAppointmentChatMessages(appointment_id, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @summary Return MyHealthAccess appointment from remote Juno appointment_no
     * @param {number} remote_id Juno appointment no of the local appointment
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ClinicApi
     */
    public getLocalAppointment(remote_id: number, options?: any) {
        return ClinicApiFp(this.configuration).getLocalAppointment(remote_id, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @summary Return MyHealthAccess appointment from AQS queued appointment id
     * @param {string} queued_appointment_id AQS queued appointment
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ClinicApi
     */
    public getLocalQueuedAppointment(queued_appointment_id: string, options?: any) {
        return ClinicApiFp(this.configuration).getLocalQueuedAppointment(queued_appointment_id, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @summary Get the patients conneciton to the clinic
     * @param {string} patient_id the patient id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ClinicApi
     */
    public getPatientClinicConnection(patient_id: string, options?: any) {
        return ClinicApiFp(this.configuration).getPatientClinicConnection(patient_id, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @summary Get a patient\'s profile
     * @param {string} patient_id the patient id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ClinicApi
     */
    public getPatientProfile(patient_id: string, options?: any) {
        return ClinicApiFp(this.configuration).getPatientProfile(patient_id, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @summary Get a patient\'s remote profile
     * @param {string} patient_id the patient id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ClinicApi
     */
    public getPatientRemoteProfile(patient_id: string, options?: any) {
        return ClinicApiFp(this.configuration).getPatientRemoteProfile(patient_id, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @summary Retrieve or create a provider telehealth session for the specified appointment
     * @param {string} remote_id Appointment the telehealth session is being created for
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ClinicApi
     */
    public getProviderTeleHealthSession(remote_id: string, options?: any) {
        return ClinicApiFp(this.configuration).getProviderTeleHealthSession(remote_id, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @summary Telehealth appointment session data
     * @param {string} appointment_id Appointment the telehealth session is for
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ClinicApi
     */
    public getProviderTelehealthSessionData(appointment_id: string, options?: any) {
        return ClinicApiFp(this.configuration).getProviderTelehealthSessionData(appointment_id, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @summary Get a remote patient profile
     * @param {string} remote_id the patient\&#39;s remote id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ClinicApi
     */
    public getRemotePatientProfile(remote_id: string, options?: any) {
        return ClinicApiFp(this.configuration).getRemotePatientProfile(remote_id, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @summary Link a patient to an EMR record
     * @param {string} patient_id the patient id
     * @param {InlineObject10} [inline_object10] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ClinicApi
     */
    public linkPatientToClinicEmrRecord(patient_id: string, inline_object10?: InlineObject10, options?: any) {
        return ClinicApiFp(this.configuration).linkPatientToClinicEmrRecord(patient_id, inline_object10, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @summary Log a telehealth session event
     * @param {string} appointment_id Appointment whos telehealth session is going to be logged to
     * @param {TelehealthLogEventDto} telehealth_log_event_dto Log event data
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ClinicApi
     */
    public logEventToProviderTelehealthSession(appointment_id: string, telehealth_log_event_dto: TelehealthLogEventDto, options?: any) {
        return ClinicApiFp(this.configuration).logEventToProviderTelehealthSession(appointment_id, telehealth_log_event_dto, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @summary Logged in clinic user
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ClinicApi
     */
    public loggedInClinicUser(options?: any) {
        return ClinicApiFp(this.configuration).loggedInClinicUser(options)(this.axios, this.basePath);
    }

    /**
     * 
     * @summary Endpoint to call when the session starts. i.e Send push notifications
     * @param {string} remote_id Appointment the telehealth session is for
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ClinicApi
     */
    public onSessionStart(remote_id: string, options?: any) {
        return ClinicApiFp(this.configuration).onSessionStart(remote_id, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @summary Endpoint to call when the session starts. i.e Send push notifications
     * @param {string} appointment_id Appointment the telehealth session is for
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ClinicApi
     */
    public postTelehealthSessionStart(appointment_id: string, options?: any) {
        return ClinicApiFp(this.configuration).postTelehealthSessionStart(appointment_id, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @summary Send push notification to patient
     * @param {string} patient_id the patient id to which you wish to send the push notification
     * @param {PushNotification} push_notification Push Notification
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ClinicApi
     */
    public sendPushNotification(patient_id: string, push_notification: PushNotification, options?: any) {
        return ClinicApiFp(this.configuration).sendPushNotification(patient_id, push_notification, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @summary Cancels any pending voip calls notification. i.e. stops the user\'s phone from rining
     * @param {string} appointment_id Appointment the telehealth session is for
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ClinicApi
     */
    public telehealthCancelPendingCalls(appointment_id: string, options?: any) {
        return ClinicApiFp(this.configuration).telehealthCancelPendingCalls(appointment_id, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @summary Verify a patients connection to the clinic
     * @param {string} patient_id the patient id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ClinicApi
     */
    public verifyPatientClinicConnection(patient_id: string, options?: any) {
        return ClinicApiFp(this.configuration).verifyPatientClinicConnection(patient_id, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @summary Write an encounter note to the patient\'s eChart
     * @param {string} patient_id the patient id
     * @param {EncounterNote} encounter_note Encounter note
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ClinicApi
     */
    public writeToEncounterNote(patient_id: string, encounter_note: EncounterNote, options?: any) {
        return ClinicApiFp(this.configuration).writeToEncounterNote(patient_id, encounter_note, options)(this.axios, this.basePath);
    }

}


/**
 * DependentApi - axios parameter creator
 * @export
 */
export const DependentApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Delete a dependent profile record
         * @param {string} dependent_id dependent profile uuid
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteDependent(dependent_id: string, options: any = {}): RequestArgs {
            // verify required parameter 'dependent_id' is not null or undefined
            if (dependent_id === null || dependent_id === undefined) {
                throw new RequiredError('dependent_id','Required parameter dependent_id was null or undefined when calling deleteDependent.');
            }
            const localVarPath = `/patient/self/dependent/{dependent_id}.json`
                .replace(`{${"dependent_id"}}`, encodeURIComponent(String(dependent_id)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication apiAuth required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? configuration.apiKey("X-Auth-Token")
                    : configuration.apiKey;
                localVarHeaderParameter["X-Auth-Token"] = localVarApiKeyValue;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get a list of dependent appointments
         * @param {string} dependent_id dependent profile uuid
         * @param {string} [start_date] Start of end range (inclusive)
         * @param {string} [end_date] End of date range (inclusive)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        dependentAppointments(dependent_id: string, start_date?: string, end_date?: string, options: any = {}): RequestArgs {
            // verify required parameter 'dependent_id' is not null or undefined
            if (dependent_id === null || dependent_id === undefined) {
                throw new RequiredError('dependent_id','Required parameter dependent_id was null or undefined when calling dependentAppointments.');
            }
            const localVarPath = `/patient/self/dependent/{dependent_id}/appointments.json`
                .replace(`{${"dependent_id"}}`, encodeURIComponent(String(dependent_id)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication apiAuth required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? configuration.apiKey("X-Auth-Token")
                    : configuration.apiKey;
                localVarHeaderParameter["X-Auth-Token"] = localVarApiKeyValue;
            }

            if (start_date !== undefined) {
                localVarQueryParameter['start_date'] = (start_date as any instanceof Date) ?
                    (start_date as any).toISOString().substr(0,10) :
                    start_date;
            }

            if (end_date !== undefined) {
                localVarQueryParameter['end_date'] = (end_date as any instanceof Date) ?
                    (end_date as any).toISOString().substr(0,10) :
                    end_date;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Book an appointment for a dependent
         * @param {string} dependent_id dependent profile uuid
         * @param {string} clinic_id Clinic to book appointment slot for
         * @param {BookAppointmentTransfer} book_appointment_transfer Appointment slot
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        dependentClinicAppointmentBook(dependent_id: string, clinic_id: string, book_appointment_transfer: BookAppointmentTransfer, options: any = {}): RequestArgs {
            // verify required parameter 'dependent_id' is not null or undefined
            if (dependent_id === null || dependent_id === undefined) {
                throw new RequiredError('dependent_id','Required parameter dependent_id was null or undefined when calling dependentClinicAppointmentBook.');
            }
            // verify required parameter 'clinic_id' is not null or undefined
            if (clinic_id === null || clinic_id === undefined) {
                throw new RequiredError('clinic_id','Required parameter clinic_id was null or undefined when calling dependentClinicAppointmentBook.');
            }
            // verify required parameter 'book_appointment_transfer' is not null or undefined
            if (book_appointment_transfer === null || book_appointment_transfer === undefined) {
                throw new RequiredError('book_appointment_transfer','Required parameter book_appointment_transfer was null or undefined when calling dependentClinicAppointmentBook.');
            }
            const localVarPath = `/patient/self/dependent/{dependent_id}/clinic/{clinic_id}/appointment/book.json`
                .replace(`{${"dependent_id"}}`, encodeURIComponent(String(dependent_id)))
                .replace(`{${"clinic_id"}}`, encodeURIComponent(String(clinic_id)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication apiAuth required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? configuration.apiKey("X-Auth-Token")
                    : configuration.apiKey;
                localVarHeaderParameter["X-Auth-Token"] = localVarApiKeyValue;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};
            const needsSerialization = (<any>"BookAppointmentTransfer" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(book_appointment_transfer !== undefined ? book_appointment_transfer : {}) : (book_appointment_transfer || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Cancel an appointment for a dependent
         * @param {string} dependent_id dependent profile uuid
         * @param {string} clinic_id Clinic to cancel appointment slot for
         * @param {string} id Juno appointment ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        dependentClinicAppointmentCancel(dependent_id: string, clinic_id: string, id: string, options: any = {}): RequestArgs {
            // verify required parameter 'dependent_id' is not null or undefined
            if (dependent_id === null || dependent_id === undefined) {
                throw new RequiredError('dependent_id','Required parameter dependent_id was null or undefined when calling dependentClinicAppointmentCancel.');
            }
            // verify required parameter 'clinic_id' is not null or undefined
            if (clinic_id === null || clinic_id === undefined) {
                throw new RequiredError('clinic_id','Required parameter clinic_id was null or undefined when calling dependentClinicAppointmentCancel.');
            }
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling dependentClinicAppointmentCancel.');
            }
            const localVarPath = `/patient/self/dependent/{dependent_id}/clinic/{clinic_id}/appointment/{id}/cancel.json`
                .replace(`{${"dependent_id"}}`, encodeURIComponent(String(dependent_id)))
                .replace(`{${"clinic_id"}}`, encodeURIComponent(String(clinic_id)))
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication apiAuth required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? configuration.apiKey("X-Auth-Token")
                    : configuration.apiKey;
                localVarHeaderParameter["X-Auth-Token"] = localVarApiKeyValue;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Reschedule an appointment for a dependent
         * @param {string} dependent_id dependent profile uuid
         * @param {string} clinic_id Clinic to cancel appointment slot for
         * @param {string} appointment_id Juno appointment ID
         * @param {BookAppointmentTransfer} book_appointment_transfer Appointment slot
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        dependentClinicAppointmentReschedule(dependent_id: string, clinic_id: string, appointment_id: string, book_appointment_transfer: BookAppointmentTransfer, options: any = {}): RequestArgs {
            // verify required parameter 'dependent_id' is not null or undefined
            if (dependent_id === null || dependent_id === undefined) {
                throw new RequiredError('dependent_id','Required parameter dependent_id was null or undefined when calling dependentClinicAppointmentReschedule.');
            }
            // verify required parameter 'clinic_id' is not null or undefined
            if (clinic_id === null || clinic_id === undefined) {
                throw new RequiredError('clinic_id','Required parameter clinic_id was null or undefined when calling dependentClinicAppointmentReschedule.');
            }
            // verify required parameter 'appointment_id' is not null or undefined
            if (appointment_id === null || appointment_id === undefined) {
                throw new RequiredError('appointment_id','Required parameter appointment_id was null or undefined when calling dependentClinicAppointmentReschedule.');
            }
            // verify required parameter 'book_appointment_transfer' is not null or undefined
            if (book_appointment_transfer === null || book_appointment_transfer === undefined) {
                throw new RequiredError('book_appointment_transfer','Required parameter book_appointment_transfer was null or undefined when calling dependentClinicAppointmentReschedule.');
            }
            const localVarPath = `/patient/self/dependent/{dependent_id}/clinic/{clinic_id}/appointment/{appointment_id}/reschedule.json`
                .replace(`{${"dependent_id"}}`, encodeURIComponent(String(dependent_id)))
                .replace(`{${"clinic_id"}}`, encodeURIComponent(String(clinic_id)))
                .replace(`{${"appointment_id"}}`, encodeURIComponent(String(appointment_id)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication apiAuth required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? configuration.apiKey("X-Auth-Token")
                    : configuration.apiKey;
                localVarHeaderParameter["X-Auth-Token"] = localVarApiKeyValue;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};
            const needsSerialization = (<any>"BookAppointmentTransfer" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(book_appointment_transfer !== undefined ? book_appointment_transfer : {}) : (book_appointment_transfer || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get appointment type for a clinic
         * @param {string} dependent_id Dependent to get appointment type for
         * @param {string} clinic_id Clinic to get appointment type for
         * @param {string} appointment_type_id Specified appointment type
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        dependentClinicAppointmentType(dependent_id: string, clinic_id: string, appointment_type_id: string, options: any = {}): RequestArgs {
            // verify required parameter 'dependent_id' is not null or undefined
            if (dependent_id === null || dependent_id === undefined) {
                throw new RequiredError('dependent_id','Required parameter dependent_id was null or undefined when calling dependentClinicAppointmentType.');
            }
            // verify required parameter 'clinic_id' is not null or undefined
            if (clinic_id === null || clinic_id === undefined) {
                throw new RequiredError('clinic_id','Required parameter clinic_id was null or undefined when calling dependentClinicAppointmentType.');
            }
            // verify required parameter 'appointment_type_id' is not null or undefined
            if (appointment_type_id === null || appointment_type_id === undefined) {
                throw new RequiredError('appointment_type_id','Required parameter appointment_type_id was null or undefined when calling dependentClinicAppointmentType.');
            }
            const localVarPath = `/patient/self/dependent/{dependent_id}/clinic/{clinic_id}/appointment_types/{appointment_type_id}.json`
                .replace(`{${"dependent_id"}}`, encodeURIComponent(String(dependent_id)))
                .replace(`{${"clinic_id"}}`, encodeURIComponent(String(clinic_id)))
                .replace(`{${"appointment_type_id"}}`, encodeURIComponent(String(appointment_type_id)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication apiAuth required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? configuration.apiKey("X-Auth-Token")
                    : configuration.apiKey;
                localVarHeaderParameter["X-Auth-Token"] = localVarApiKeyValue;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get list of appointment types for a dependent\'s clinic
         * @param {string} dependent_id Dependent connected to specified clinic
         * @param {string} clinic_id Clinic to get providers for
         * @param {string} [provider_id] Provider to view appointment types for
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        dependentClinicAppointmentTypes(dependent_id: string, clinic_id: string, provider_id?: string, options: any = {}): RequestArgs {
            // verify required parameter 'dependent_id' is not null or undefined
            if (dependent_id === null || dependent_id === undefined) {
                throw new RequiredError('dependent_id','Required parameter dependent_id was null or undefined when calling dependentClinicAppointmentTypes.');
            }
            // verify required parameter 'clinic_id' is not null or undefined
            if (clinic_id === null || clinic_id === undefined) {
                throw new RequiredError('clinic_id','Required parameter clinic_id was null or undefined when calling dependentClinicAppointmentTypes.');
            }
            const localVarPath = `/patient/self/dependent/{dependent_id}/clinic/{clinic_id}/appointment_types`
                .replace(`{${"dependent_id"}}`, encodeURIComponent(String(dependent_id)))
                .replace(`{${"clinic_id"}}`, encodeURIComponent(String(clinic_id)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication apiAuth required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? configuration.apiKey("X-Auth-Token")
                    : configuration.apiKey;
                localVarHeaderParameter["X-Auth-Token"] = localVarApiKeyValue;
            }

            if (provider_id !== undefined) {
                localVarQueryParameter['provider_id'] = provider_id;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get a list of slots available for dependent booking
         * @param {string} dependent_id dependent profile uuid
         * @param {string} clinic_id Clinic to get appointment slot list for
         * @param {string} provider_id Provider to get appointment slot list for
         * @param {string} appointment_type_id Appointment type to get slot list for
         * @param {string} start_date Start of end range (inclusive)
         * @param {string} end_date End of date range (inclusive)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        dependentClinicAvailableAppointments(dependent_id: string, clinic_id: string, provider_id: string, appointment_type_id: string, start_date: string, end_date: string, options: any = {}): RequestArgs {
            // verify required parameter 'dependent_id' is not null or undefined
            if (dependent_id === null || dependent_id === undefined) {
                throw new RequiredError('dependent_id','Required parameter dependent_id was null or undefined when calling dependentClinicAvailableAppointments.');
            }
            // verify required parameter 'clinic_id' is not null or undefined
            if (clinic_id === null || clinic_id === undefined) {
                throw new RequiredError('clinic_id','Required parameter clinic_id was null or undefined when calling dependentClinicAvailableAppointments.');
            }
            // verify required parameter 'provider_id' is not null or undefined
            if (provider_id === null || provider_id === undefined) {
                throw new RequiredError('provider_id','Required parameter provider_id was null or undefined when calling dependentClinicAvailableAppointments.');
            }
            // verify required parameter 'appointment_type_id' is not null or undefined
            if (appointment_type_id === null || appointment_type_id === undefined) {
                throw new RequiredError('appointment_type_id','Required parameter appointment_type_id was null or undefined when calling dependentClinicAvailableAppointments.');
            }
            // verify required parameter 'start_date' is not null or undefined
            if (start_date === null || start_date === undefined) {
                throw new RequiredError('start_date','Required parameter start_date was null or undefined when calling dependentClinicAvailableAppointments.');
            }
            // verify required parameter 'end_date' is not null or undefined
            if (end_date === null || end_date === undefined) {
                throw new RequiredError('end_date','Required parameter end_date was null or undefined when calling dependentClinicAvailableAppointments.');
            }
            const localVarPath = `/patient/self/dependent/{dependent_id}/clinic/{clinic_id}/appointments/available.json`
                .replace(`{${"dependent_id"}}`, encodeURIComponent(String(dependent_id)))
                .replace(`{${"clinic_id"}}`, encodeURIComponent(String(clinic_id)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication apiAuth required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? configuration.apiKey("X-Auth-Token")
                    : configuration.apiKey;
                localVarHeaderParameter["X-Auth-Token"] = localVarApiKeyValue;
            }

            if (provider_id !== undefined) {
                localVarQueryParameter['provider_id'] = provider_id;
            }

            if (appointment_type_id !== undefined) {
                localVarQueryParameter['appointment_type_id'] = appointment_type_id;
            }

            if (start_date !== undefined) {
                localVarQueryParameter['start_date'] = (start_date as any instanceof Date) ?
                    (start_date as any).toISOString().substr(0,10) :
                    start_date;
            }

            if (end_date !== undefined) {
                localVarQueryParameter['end_date'] = (end_date as any instanceof Date) ?
                    (end_date as any).toISOString().substr(0,10) :
                    end_date;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get single provider record for a dependent
         * @param {string} dependent_id 
         * @param {string} clinic_id Clinic provider belongs to
         * @param {string} provider_id Provider to get info for
         * @param {boolean} [is_remote] Find provider based on remote_id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        dependentClinicProvider(dependent_id: string, clinic_id: string, provider_id: string, is_remote?: boolean, options: any = {}): RequestArgs {
            // verify required parameter 'dependent_id' is not null or undefined
            if (dependent_id === null || dependent_id === undefined) {
                throw new RequiredError('dependent_id','Required parameter dependent_id was null or undefined when calling dependentClinicProvider.');
            }
            // verify required parameter 'clinic_id' is not null or undefined
            if (clinic_id === null || clinic_id === undefined) {
                throw new RequiredError('clinic_id','Required parameter clinic_id was null or undefined when calling dependentClinicProvider.');
            }
            // verify required parameter 'provider_id' is not null or undefined
            if (provider_id === null || provider_id === undefined) {
                throw new RequiredError('provider_id','Required parameter provider_id was null or undefined when calling dependentClinicProvider.');
            }
            const localVarPath = `/patient/self/dependent/{dependent_id}/clinic/{clinic_id}/providers/{provider_id}/info.json`
                .replace(`{${"dependent_id"}}`, encodeURIComponent(String(dependent_id)))
                .replace(`{${"clinic_id"}}`, encodeURIComponent(String(clinic_id)))
                .replace(`{${"provider_id"}}`, encodeURIComponent(String(provider_id)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication apiAuth required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? configuration.apiKey("X-Auth-Token")
                    : configuration.apiKey;
                localVarHeaderParameter["X-Auth-Token"] = localVarApiKeyValue;
            }

            if (is_remote !== undefined) {
                localVarQueryParameter['is_remote'] = is_remote;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get a provider group for a clinic
         * @param {string} dependent_id 
         * @param {string} clinic_id Clinic to get the provider group from
         * @param {string} provider_group_id Specified provider group
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        dependentClinicProviderGroup(dependent_id: string, clinic_id: string, provider_group_id: string, options: any = {}): RequestArgs {
            // verify required parameter 'dependent_id' is not null or undefined
            if (dependent_id === null || dependent_id === undefined) {
                throw new RequiredError('dependent_id','Required parameter dependent_id was null or undefined when calling dependentClinicProviderGroup.');
            }
            // verify required parameter 'clinic_id' is not null or undefined
            if (clinic_id === null || clinic_id === undefined) {
                throw new RequiredError('clinic_id','Required parameter clinic_id was null or undefined when calling dependentClinicProviderGroup.');
            }
            // verify required parameter 'provider_group_id' is not null or undefined
            if (provider_group_id === null || provider_group_id === undefined) {
                throw new RequiredError('provider_group_id','Required parameter provider_group_id was null or undefined when calling dependentClinicProviderGroup.');
            }
            const localVarPath = `/patient/self/dependent/{dependent_id}/clinic/{clinic_id}/provider_group/{provider_group_id}.json`
                .replace(`{${"dependent_id"}}`, encodeURIComponent(String(dependent_id)))
                .replace(`{${"clinic_id"}}`, encodeURIComponent(String(clinic_id)))
                .replace(`{${"provider_group_id"}}`, encodeURIComponent(String(provider_group_id)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication apiAuth required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? configuration.apiKey("X-Auth-Token")
                    : configuration.apiKey;
                localVarHeaderParameter["X-Auth-Token"] = localVarApiKeyValue;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get list of available providers from a provider group for this patient
         * @param {string} dependent_id 
         * @param {string} clinic_id Clinic to get provider groups for
         * @param {string} provider_group_id The group to get the providers from
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        dependentClinicProviderGroupProviders(dependent_id: string, clinic_id: string, provider_group_id: string, options: any = {}): RequestArgs {
            // verify required parameter 'dependent_id' is not null or undefined
            if (dependent_id === null || dependent_id === undefined) {
                throw new RequiredError('dependent_id','Required parameter dependent_id was null or undefined when calling dependentClinicProviderGroupProviders.');
            }
            // verify required parameter 'clinic_id' is not null or undefined
            if (clinic_id === null || clinic_id === undefined) {
                throw new RequiredError('clinic_id','Required parameter clinic_id was null or undefined when calling dependentClinicProviderGroupProviders.');
            }
            // verify required parameter 'provider_group_id' is not null or undefined
            if (provider_group_id === null || provider_group_id === undefined) {
                throw new RequiredError('provider_group_id','Required parameter provider_group_id was null or undefined when calling dependentClinicProviderGroupProviders.');
            }
            const localVarPath = `/patient/self/dependent/{dependent_id}/clinic/{clinic_id}/provider_group/{provider_group_id}/providers.json`
                .replace(`{${"dependent_id"}}`, encodeURIComponent(String(dependent_id)))
                .replace(`{${"clinic_id"}}`, encodeURIComponent(String(clinic_id)))
                .replace(`{${"provider_group_id"}}`, encodeURIComponent(String(provider_group_id)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication apiAuth required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? configuration.apiKey("X-Auth-Token")
                    : configuration.apiKey;
                localVarHeaderParameter["X-Auth-Token"] = localVarApiKeyValue;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get list of available clinic provider groups for this patient
         * @param {string} dependent_id 
         * @param {string} clinic_id Clinic to get provider groups for
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        dependentClinicProviderGroups(dependent_id: string, clinic_id: string, options: any = {}): RequestArgs {
            // verify required parameter 'dependent_id' is not null or undefined
            if (dependent_id === null || dependent_id === undefined) {
                throw new RequiredError('dependent_id','Required parameter dependent_id was null or undefined when calling dependentClinicProviderGroups.');
            }
            // verify required parameter 'clinic_id' is not null or undefined
            if (clinic_id === null || clinic_id === undefined) {
                throw new RequiredError('clinic_id','Required parameter clinic_id was null or undefined when calling dependentClinicProviderGroups.');
            }
            const localVarPath = `/patient/self/dependent/{dependent_id}/clinic/{clinic_id}/provider_groups.json`
                .replace(`{${"dependent_id"}}`, encodeURIComponent(String(dependent_id)))
                .replace(`{${"clinic_id"}}`, encodeURIComponent(String(clinic_id)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication apiAuth required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? configuration.apiKey("X-Auth-Token")
                    : configuration.apiKey;
                localVarHeaderParameter["X-Auth-Token"] = localVarApiKeyValue;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get list of available clinic provider groups that a provider is in
         * @param {string} dependent_id 
         * @param {string} clinic_id Clinic to get provider groups for
         * @param {string} provider_id The id of the provider to get groups for
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        dependentClinicProviderGroupsByProvider(dependent_id: string, clinic_id: string, provider_id: string, options: any = {}): RequestArgs {
            // verify required parameter 'dependent_id' is not null or undefined
            if (dependent_id === null || dependent_id === undefined) {
                throw new RequiredError('dependent_id','Required parameter dependent_id was null or undefined when calling dependentClinicProviderGroupsByProvider.');
            }
            // verify required parameter 'clinic_id' is not null or undefined
            if (clinic_id === null || clinic_id === undefined) {
                throw new RequiredError('clinic_id','Required parameter clinic_id was null or undefined when calling dependentClinicProviderGroupsByProvider.');
            }
            // verify required parameter 'provider_id' is not null or undefined
            if (provider_id === null || provider_id === undefined) {
                throw new RequiredError('provider_id','Required parameter provider_id was null or undefined when calling dependentClinicProviderGroupsByProvider.');
            }
            const localVarPath = `/patient/self/dependent/{dependent_id}/clinic/{clinic_id}/provider/{provider_id}/groups.json`
                .replace(`{${"dependent_id"}}`, encodeURIComponent(String(dependent_id)))
                .replace(`{${"clinic_id"}}`, encodeURIComponent(String(clinic_id)))
                .replace(`{${"provider_id"}}`, encodeURIComponent(String(provider_id)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication apiAuth required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? configuration.apiKey("X-Auth-Token")
                    : configuration.apiKey;
                localVarHeaderParameter["X-Auth-Token"] = localVarApiKeyValue;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get list of available clinics for this dependent
         * @param {string} dependent_id 
         * @param {string} clinic_id Clinic to get providers for
         * @param {boolean} [bookable_only] Only show bookable appointments
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        dependentClinicProviders(dependent_id: string, clinic_id: string, bookable_only?: boolean, options: any = {}): RequestArgs {
            // verify required parameter 'dependent_id' is not null or undefined
            if (dependent_id === null || dependent_id === undefined) {
                throw new RequiredError('dependent_id','Required parameter dependent_id was null or undefined when calling dependentClinicProviders.');
            }
            // verify required parameter 'clinic_id' is not null or undefined
            if (clinic_id === null || clinic_id === undefined) {
                throw new RequiredError('clinic_id','Required parameter clinic_id was null or undefined when calling dependentClinicProviders.');
            }
            const localVarPath = `/patient/self/dependent/{dependent_id}/clinic/{clinic_id}/providers.json`
                .replace(`{${"dependent_id"}}`, encodeURIComponent(String(dependent_id)))
                .replace(`{${"clinic_id"}}`, encodeURIComponent(String(clinic_id)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication apiAuth required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? configuration.apiKey("X-Auth-Token")
                    : configuration.apiKey;
                localVarHeaderParameter["X-Auth-Token"] = localVarApiKeyValue;
            }

            if (bookable_only !== undefined) {
                localVarQueryParameter['bookable_only'] = bookable_only;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary list dependent clinics
         * @param {string} dependent_id dependent profile uuid
         * @param {boolean} [bookable_only] Only show bookable appointments
         * @param {boolean} [messageable_only] Only return clinics this dependent can message
         * @param {boolean} [available_only] Only return clinics that are avaiable to link with this dependent
         * @param {boolean} [connected_only] Only return clinics that are connected to this dependent
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        dependentClinics(dependent_id: string, bookable_only?: boolean, messageable_only?: boolean, available_only?: boolean, connected_only?: boolean, options: any = {}): RequestArgs {
            // verify required parameter 'dependent_id' is not null or undefined
            if (dependent_id === null || dependent_id === undefined) {
                throw new RequiredError('dependent_id','Required parameter dependent_id was null or undefined when calling dependentClinics.');
            }
            const localVarPath = `/patient/self/dependent/{dependent_id}/clinics.json`
                .replace(`{${"dependent_id"}}`, encodeURIComponent(String(dependent_id)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication apiAuth required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? configuration.apiKey("X-Auth-Token")
                    : configuration.apiKey;
                localVarHeaderParameter["X-Auth-Token"] = localVarApiKeyValue;
            }

            if (bookable_only !== undefined) {
                localVarQueryParameter['bookable_only'] = bookable_only;
            }

            if (messageable_only !== undefined) {
                localVarQueryParameter['messageable_only'] = messageable_only;
            }

            if (available_only !== undefined) {
                localVarQueryParameter['available_only'] = available_only;
            }

            if (connected_only !== undefined) {
                localVarQueryParameter['connected_only'] = connected_only;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Check if all future appointments can be cancelled
         * @param {string} dependent_id dependent profile uuid
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        dependentFutureAppointmentsCancellable(dependent_id: string, options: any = {}): RequestArgs {
            // verify required parameter 'dependent_id' is not null or undefined
            if (dependent_id === null || dependent_id === undefined) {
                throw new RequiredError('dependent_id','Required parameter dependent_id was null or undefined when calling dependentFutureAppointmentsCancellable.');
            }
            const localVarPath = `/patient/self/dependent/{dependent_id}/appointments/future_cancellable.json`
                .replace(`{${"dependent_id"}}`, encodeURIComponent(String(dependent_id)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication apiAuth required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? configuration.apiKey("X-Auth-Token")
                    : configuration.apiKey;
                localVarHeaderParameter["X-Auth-Token"] = localVarApiKeyValue;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary list clinics that the dependent can connect to orderby by distance
         * @param {string} dependent_id dependent profile uuid
         * @param {number} latitude 
         * @param {number} longitude 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        dependentLinkableClinicsByCoordinates(dependent_id: string, latitude: number, longitude: number, options: any = {}): RequestArgs {
            // verify required parameter 'dependent_id' is not null or undefined
            if (dependent_id === null || dependent_id === undefined) {
                throw new RequiredError('dependent_id','Required parameter dependent_id was null or undefined when calling dependentLinkableClinicsByCoordinates.');
            }
            // verify required parameter 'latitude' is not null or undefined
            if (latitude === null || latitude === undefined) {
                throw new RequiredError('latitude','Required parameter latitude was null or undefined when calling dependentLinkableClinicsByCoordinates.');
            }
            // verify required parameter 'longitude' is not null or undefined
            if (longitude === null || longitude === undefined) {
                throw new RequiredError('longitude','Required parameter longitude was null or undefined when calling dependentLinkableClinicsByCoordinates.');
            }
            const localVarPath = `/patient/self/dependent/{dependent_id}/clinics/find/by_coordinates.json`
                .replace(`{${"dependent_id"}}`, encodeURIComponent(String(dependent_id)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication apiAuth required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? configuration.apiKey("X-Auth-Token")
                    : configuration.apiKey;
                localVarHeaderParameter["X-Auth-Token"] = localVarApiKeyValue;
            }

            if (latitude !== undefined) {
                localVarQueryParameter['latitude'] = latitude;
            }

            if (longitude !== undefined) {
                localVarQueryParameter['longitude'] = longitude;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary list clinics that the dependent can connect to orderby by distance
         * @param {string} dependent_id dependent profile uuid
         * @param {string} province_code 
         * @param {string} [city] 
         * @param {string} [address] 
         * @param {string} [postal_code] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        dependentLinkableClinicsByLocation(dependent_id: string, province_code: string, city?: string, address?: string, postal_code?: string, options: any = {}): RequestArgs {
            // verify required parameter 'dependent_id' is not null or undefined
            if (dependent_id === null || dependent_id === undefined) {
                throw new RequiredError('dependent_id','Required parameter dependent_id was null or undefined when calling dependentLinkableClinicsByLocation.');
            }
            // verify required parameter 'province_code' is not null or undefined
            if (province_code === null || province_code === undefined) {
                throw new RequiredError('province_code','Required parameter province_code was null or undefined when calling dependentLinkableClinicsByLocation.');
            }
            const localVarPath = `/patient/self/dependent/{dependent_id}/clinics/find/by_location.json`
                .replace(`{${"dependent_id"}}`, encodeURIComponent(String(dependent_id)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication apiAuth required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? configuration.apiKey("X-Auth-Token")
                    : configuration.apiKey;
                localVarHeaderParameter["X-Auth-Token"] = localVarApiKeyValue;
            }

            if (province_code !== undefined) {
                localVarQueryParameter['provinceCode'] = province_code;
            }

            if (city !== undefined) {
                localVarQueryParameter['city'] = city;
            }

            if (address !== undefined) {
                localVarQueryParameter['address'] = address;
            }

            if (postal_code !== undefined) {
                localVarQueryParameter['postalCode'] = postal_code;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get a list of dependent clinic appointmenst
         * @param {string} dependent_id dependent profile uuid
         * @param {string} [start_datetime] the date after which appointments will be returned
         * @param {string} [end_datetime] the date before which appointments will be returned
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        dependentMhaClinicAppointments(dependent_id: string, start_datetime?: string, end_datetime?: string, options: any = {}): RequestArgs {
            // verify required parameter 'dependent_id' is not null or undefined
            if (dependent_id === null || dependent_id === undefined) {
                throw new RequiredError('dependent_id','Required parameter dependent_id was null or undefined when calling dependentMhaClinicAppointments.');
            }
            const localVarPath = `/patient/self/dependent/{dependent_id}/clinic_appointments`
                .replace(`{${"dependent_id"}}`, encodeURIComponent(String(dependent_id)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication apiAuth required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? configuration.apiKey("X-Auth-Token")
                    : configuration.apiKey;
                localVarHeaderParameter["X-Auth-Token"] = localVarApiKeyValue;
            }

            if (start_datetime !== undefined) {
                localVarQueryParameter['start_datetime'] = start_datetime;
            }

            if (end_datetime !== undefined) {
                localVarQueryParameter['end_datetime'] = end_datetime;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get a dependent profile record
         * @param {string} dependent_id dependent profile uuid
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDependent(dependent_id: string, options: any = {}): RequestArgs {
            // verify required parameter 'dependent_id' is not null or undefined
            if (dependent_id === null || dependent_id === undefined) {
                throw new RequiredError('dependent_id','Required parameter dependent_id was null or undefined when calling getDependent.');
            }
            const localVarPath = `/patient/self/dependent/{dependent_id}.json`
                .replace(`{${"dependent_id"}}`, encodeURIComponent(String(dependent_id)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication apiAuth required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? configuration.apiKey("X-Auth-Token")
                    : configuration.apiKey;
                localVarHeaderParameter["X-Auth-Token"] = localVarApiKeyValue;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get clinic info for the specified clinic
         * @param {string} dependent_id 
         * @param {string} clinic_id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDependentClinic(dependent_id: string, clinic_id: string, options: any = {}): RequestArgs {
            // verify required parameter 'dependent_id' is not null or undefined
            if (dependent_id === null || dependent_id === undefined) {
                throw new RequiredError('dependent_id','Required parameter dependent_id was null or undefined when calling getDependentClinic.');
            }
            // verify required parameter 'clinic_id' is not null or undefined
            if (clinic_id === null || clinic_id === undefined) {
                throw new RequiredError('clinic_id','Required parameter clinic_id was null or undefined when calling getDependentClinic.');
            }
            const localVarPath = `/patient/self/dependent/{dependent_id}/clinic/{clinic_id}.json`
                .replace(`{${"dependent_id"}}`, encodeURIComponent(String(dependent_id)))
                .replace(`{${"clinic_id"}}`, encodeURIComponent(String(clinic_id)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication apiAuth required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? configuration.apiKey("X-Auth-Token")
                    : configuration.apiKey;
                localVarHeaderParameter["X-Auth-Token"] = localVarApiKeyValue;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get clinic info for the specified clinic
         * @param {string} dependent_id 
         * @param {string} clinic_id 
         * @param {boolean} [required_fields] Whether to return required connection fields for the requested clinic
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDependentClinicInfo(dependent_id: string, clinic_id: string, required_fields?: boolean, options: any = {}): RequestArgs {
            // verify required parameter 'dependent_id' is not null or undefined
            if (dependent_id === null || dependent_id === undefined) {
                throw new RequiredError('dependent_id','Required parameter dependent_id was null or undefined when calling getDependentClinicInfo.');
            }
            // verify required parameter 'clinic_id' is not null or undefined
            if (clinic_id === null || clinic_id === undefined) {
                throw new RequiredError('clinic_id','Required parameter clinic_id was null or undefined when calling getDependentClinicInfo.');
            }
            const localVarPath = `/patient/self/dependent/{dependent_id}/clinic/{clinic_id}/info.json`
                .replace(`{${"dependent_id"}}`, encodeURIComponent(String(dependent_id)))
                .replace(`{${"clinic_id"}}`, encodeURIComponent(String(clinic_id)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication apiAuth required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? configuration.apiKey("X-Auth-Token")
                    : configuration.apiKey;
                localVarHeaderParameter["X-Auth-Token"] = localVarApiKeyValue;
            }

            if (required_fields !== undefined) {
                localVarQueryParameter['required_fields'] = required_fields;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get CloudMD clinic profile for patient\'s dependent
         * @param {string} dependent_id dependent profile uuid
         * @param {boolean} [required_fields] Whether to return required connection fields for the requested clinic
         * @param {ProvinceCode} [province_code] Whether to return required connection fields for the requested clinic
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDependentCloudMd(dependent_id: string, required_fields?: boolean, province_code?: ProvinceCode, options: any = {}): RequestArgs {
            // verify required parameter 'dependent_id' is not null or undefined
            if (dependent_id === null || dependent_id === undefined) {
                throw new RequiredError('dependent_id','Required parameter dependent_id was null or undefined when calling getDependentCloudMd.');
            }
            const localVarPath = `/patient/self/dependent/{dependent_id}/clinics/get_cloud_md.json`
                .replace(`{${"dependent_id"}}`, encodeURIComponent(String(dependent_id)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication apiAuth required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? configuration.apiKey("X-Auth-Token")
                    : configuration.apiKey;
                localVarHeaderParameter["X-Auth-Token"] = localVarApiKeyValue;
            }

            if (required_fields !== undefined) {
                localVarQueryParameter['required_fields'] = required_fields;
            }

            if (province_code !== undefined) {
                localVarQueryParameter['province_code'] = province_code;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Link a dependent with a clinic
         * @param {string} dependent_id dependent profile uuid
         * @param {string} clinic_id clinic profile uuid
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        linkDependentWithClinic(dependent_id: string, clinic_id: string, options: any = {}): RequestArgs {
            // verify required parameter 'dependent_id' is not null or undefined
            if (dependent_id === null || dependent_id === undefined) {
                throw new RequiredError('dependent_id','Required parameter dependent_id was null or undefined when calling linkDependentWithClinic.');
            }
            // verify required parameter 'clinic_id' is not null or undefined
            if (clinic_id === null || clinic_id === undefined) {
                throw new RequiredError('clinic_id','Required parameter clinic_id was null or undefined when calling linkDependentWithClinic.');
            }
            const localVarPath = `/patient/self/dependent/{dependent_id}/clinic/{clinic_id}/link.json`
                .replace(`{${"dependent_id"}}`, encodeURIComponent(String(dependent_id)))
                .replace(`{${"clinic_id"}}`, encodeURIComponent(String(clinic_id)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication apiAuth required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? configuration.apiKey("X-Auth-Token")
                    : configuration.apiKey;
                localVarHeaderParameter["X-Auth-Token"] = localVarApiKeyValue;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Unlink a dependent with a clinic
         * @param {string} dependent_id dependent profile uuid
         * @param {string} clinic_id clinic profile uuid
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        unlinkDependentWithClinic(dependent_id: string, clinic_id: string, options: any = {}): RequestArgs {
            // verify required parameter 'dependent_id' is not null or undefined
            if (dependent_id === null || dependent_id === undefined) {
                throw new RequiredError('dependent_id','Required parameter dependent_id was null or undefined when calling unlinkDependentWithClinic.');
            }
            // verify required parameter 'clinic_id' is not null or undefined
            if (clinic_id === null || clinic_id === undefined) {
                throw new RequiredError('clinic_id','Required parameter clinic_id was null or undefined when calling unlinkDependentWithClinic.');
            }
            const localVarPath = `/patient/self/dependent/{dependent_id}/clinic/{clinic_id}/unlink.json`
                .replace(`{${"dependent_id"}}`, encodeURIComponent(String(dependent_id)))
                .replace(`{${"clinic_id"}}`, encodeURIComponent(String(clinic_id)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication apiAuth required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? configuration.apiKey("X-Auth-Token")
                    : configuration.apiKey;
                localVarHeaderParameter["X-Auth-Token"] = localVarApiKeyValue;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update a dependent profile record
         * @param {string} dependent_id dependent profile uuid
         * @param {InlineObject8} inline_object8 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateDependent(dependent_id: string, inline_object8: InlineObject8, options: any = {}): RequestArgs {
            // verify required parameter 'dependent_id' is not null or undefined
            if (dependent_id === null || dependent_id === undefined) {
                throw new RequiredError('dependent_id','Required parameter dependent_id was null or undefined when calling updateDependent.');
            }
            // verify required parameter 'inline_object8' is not null or undefined
            if (inline_object8 === null || inline_object8 === undefined) {
                throw new RequiredError('inline_object8','Required parameter inline_object8 was null or undefined when calling updateDependent.');
            }
            const localVarPath = `/patient/self/dependent/{dependent_id}.json`
                .replace(`{${"dependent_id"}}`, encodeURIComponent(String(dependent_id)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication apiAuth required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? configuration.apiKey("X-Auth-Token")
                    : configuration.apiKey;
                localVarHeaderParameter["X-Auth-Token"] = localVarApiKeyValue;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};
            const needsSerialization = (<any>"InlineObject8" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(inline_object8 !== undefined ? inline_object8 : {}) : (inline_object8 || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * DependentApi - functional programming interface
 * @export
 */
export const DependentApiFp = function(configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Delete a dependent profile record
         * @param {string} dependent_id dependent profile uuid
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteDependent(dependent_id: string, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<PatientUserDto> {
            const localVarAxiosArgs = DependentApiAxiosParamCreator(configuration).deleteDependent(dependent_id, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Get a list of dependent appointments
         * @param {string} dependent_id dependent profile uuid
         * @param {string} [start_date] Start of end range (inclusive)
         * @param {string} [end_date] End of date range (inclusive)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        dependentAppointments(dependent_id: string, start_date?: string, end_date?: string, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<AppointmentTransfer>> {
            const localVarAxiosArgs = DependentApiAxiosParamCreator(configuration).dependentAppointments(dependent_id, start_date, end_date, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Book an appointment for a dependent
         * @param {string} dependent_id dependent profile uuid
         * @param {string} clinic_id Clinic to book appointment slot for
         * @param {BookAppointmentTransfer} book_appointment_transfer Appointment slot
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        dependentClinicAppointmentBook(dependent_id: string, clinic_id: string, book_appointment_transfer: BookAppointmentTransfer, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<AppointmentBookSuccess> {
            const localVarAxiosArgs = DependentApiAxiosParamCreator(configuration).dependentClinicAppointmentBook(dependent_id, clinic_id, book_appointment_transfer, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Cancel an appointment for a dependent
         * @param {string} dependent_id dependent profile uuid
         * @param {string} clinic_id Clinic to cancel appointment slot for
         * @param {string} id Juno appointment ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        dependentClinicAppointmentCancel(dependent_id: string, clinic_id: string, id: string, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<AppointmentCancelSuccess> {
            const localVarAxiosArgs = DependentApiAxiosParamCreator(configuration).dependentClinicAppointmentCancel(dependent_id, clinic_id, id, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Reschedule an appointment for a dependent
         * @param {string} dependent_id dependent profile uuid
         * @param {string} clinic_id Clinic to cancel appointment slot for
         * @param {string} appointment_id Juno appointment ID
         * @param {BookAppointmentTransfer} book_appointment_transfer Appointment slot
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        dependentClinicAppointmentReschedule(dependent_id: string, clinic_id: string, appointment_id: string, book_appointment_transfer: BookAppointmentTransfer, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<AppointmentBookSuccess> {
            const localVarAxiosArgs = DependentApiAxiosParamCreator(configuration).dependentClinicAppointmentReschedule(dependent_id, clinic_id, appointment_id, book_appointment_transfer, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Get appointment type for a clinic
         * @param {string} dependent_id Dependent to get appointment type for
         * @param {string} clinic_id Clinic to get appointment type for
         * @param {string} appointment_type_id Specified appointment type
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        dependentClinicAppointmentType(dependent_id: string, clinic_id: string, appointment_type_id: string, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<AppointmentType> {
            const localVarAxiosArgs = DependentApiAxiosParamCreator(configuration).dependentClinicAppointmentType(dependent_id, clinic_id, appointment_type_id, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Get list of appointment types for a dependent\'s clinic
         * @param {string} dependent_id Dependent connected to specified clinic
         * @param {string} clinic_id Clinic to get providers for
         * @param {string} [provider_id] Provider to view appointment types for
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        dependentClinicAppointmentTypes(dependent_id: string, clinic_id: string, provider_id?: string, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<AppointmentType>> {
            const localVarAxiosArgs = DependentApiAxiosParamCreator(configuration).dependentClinicAppointmentTypes(dependent_id, clinic_id, provider_id, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Get a list of slots available for dependent booking
         * @param {string} dependent_id dependent profile uuid
         * @param {string} clinic_id Clinic to get appointment slot list for
         * @param {string} provider_id Provider to get appointment slot list for
         * @param {string} appointment_type_id Appointment type to get slot list for
         * @param {string} start_date Start of end range (inclusive)
         * @param {string} end_date End of date range (inclusive)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        dependentClinicAvailableAppointments(dependent_id: string, clinic_id: string, provider_id: string, appointment_type_id: string, start_date: string, end_date: string, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<AppointmentTransfer>> {
            const localVarAxiosArgs = DependentApiAxiosParamCreator(configuration).dependentClinicAvailableAppointments(dependent_id, clinic_id, provider_id, appointment_type_id, start_date, end_date, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Get single provider record for a dependent
         * @param {string} dependent_id 
         * @param {string} clinic_id Clinic provider belongs to
         * @param {string} provider_id Provider to get info for
         * @param {boolean} [is_remote] Find provider based on remote_id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        dependentClinicProvider(dependent_id: string, clinic_id: string, provider_id: string, is_remote?: boolean, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<ProviderTransfer> {
            const localVarAxiosArgs = DependentApiAxiosParamCreator(configuration).dependentClinicProvider(dependent_id, clinic_id, provider_id, is_remote, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Get a provider group for a clinic
         * @param {string} dependent_id 
         * @param {string} clinic_id Clinic to get the provider group from
         * @param {string} provider_group_id Specified provider group
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        dependentClinicProviderGroup(dependent_id: string, clinic_id: string, provider_group_id: string, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<ProviderGroupTransfer> {
            const localVarAxiosArgs = DependentApiAxiosParamCreator(configuration).dependentClinicProviderGroup(dependent_id, clinic_id, provider_group_id, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Get list of available providers from a provider group for this patient
         * @param {string} dependent_id 
         * @param {string} clinic_id Clinic to get provider groups for
         * @param {string} provider_group_id The group to get the providers from
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        dependentClinicProviderGroupProviders(dependent_id: string, clinic_id: string, provider_group_id: string, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<Providers> {
            const localVarAxiosArgs = DependentApiAxiosParamCreator(configuration).dependentClinicProviderGroupProviders(dependent_id, clinic_id, provider_group_id, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Get list of available clinic provider groups for this patient
         * @param {string} dependent_id 
         * @param {string} clinic_id Clinic to get provider groups for
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        dependentClinicProviderGroups(dependent_id: string, clinic_id: string, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<ProviderGroups> {
            const localVarAxiosArgs = DependentApiAxiosParamCreator(configuration).dependentClinicProviderGroups(dependent_id, clinic_id, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Get list of available clinic provider groups that a provider is in
         * @param {string} dependent_id 
         * @param {string} clinic_id Clinic to get provider groups for
         * @param {string} provider_id The id of the provider to get groups for
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        dependentClinicProviderGroupsByProvider(dependent_id: string, clinic_id: string, provider_id: string, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<ProviderGroups> {
            const localVarAxiosArgs = DependentApiAxiosParamCreator(configuration).dependentClinicProviderGroupsByProvider(dependent_id, clinic_id, provider_id, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Get list of available clinics for this dependent
         * @param {string} dependent_id 
         * @param {string} clinic_id Clinic to get providers for
         * @param {boolean} [bookable_only] Only show bookable appointments
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        dependentClinicProviders(dependent_id: string, clinic_id: string, bookable_only?: boolean, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<Providers> {
            const localVarAxiosArgs = DependentApiAxiosParamCreator(configuration).dependentClinicProviders(dependent_id, clinic_id, bookable_only, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary list dependent clinics
         * @param {string} dependent_id dependent profile uuid
         * @param {boolean} [bookable_only] Only show bookable appointments
         * @param {boolean} [messageable_only] Only return clinics this dependent can message
         * @param {boolean} [available_only] Only return clinics that are avaiable to link with this dependent
         * @param {boolean} [connected_only] Only return clinics that are connected to this dependent
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        dependentClinics(dependent_id: string, bookable_only?: boolean, messageable_only?: boolean, available_only?: boolean, connected_only?: boolean, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<Clinics> {
            const localVarAxiosArgs = DependentApiAxiosParamCreator(configuration).dependentClinics(dependent_id, bookable_only, messageable_only, available_only, connected_only, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Check if all future appointments can be cancelled
         * @param {string} dependent_id dependent profile uuid
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        dependentFutureAppointmentsCancellable(dependent_id: string, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<InlineResponse2004> {
            const localVarAxiosArgs = DependentApiAxiosParamCreator(configuration).dependentFutureAppointmentsCancellable(dependent_id, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary list clinics that the dependent can connect to orderby by distance
         * @param {string} dependent_id dependent profile uuid
         * @param {number} latitude 
         * @param {number} longitude 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        dependentLinkableClinicsByCoordinates(dependent_id: string, latitude: number, longitude: number, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<Clinics> {
            const localVarAxiosArgs = DependentApiAxiosParamCreator(configuration).dependentLinkableClinicsByCoordinates(dependent_id, latitude, longitude, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary list clinics that the dependent can connect to orderby by distance
         * @param {string} dependent_id dependent profile uuid
         * @param {string} province_code 
         * @param {string} [city] 
         * @param {string} [address] 
         * @param {string} [postal_code] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        dependentLinkableClinicsByLocation(dependent_id: string, province_code: string, city?: string, address?: string, postal_code?: string, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<Clinics> {
            const localVarAxiosArgs = DependentApiAxiosParamCreator(configuration).dependentLinkableClinicsByLocation(dependent_id, province_code, city, address, postal_code, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Get a list of dependent clinic appointmenst
         * @param {string} dependent_id dependent profile uuid
         * @param {string} [start_datetime] the date after which appointments will be returned
         * @param {string} [end_datetime] the date before which appointments will be returned
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        dependentMhaClinicAppointments(dependent_id: string, start_datetime?: string, end_datetime?: string, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<ClinicAppointmentTransfer>> {
            const localVarAxiosArgs = DependentApiAxiosParamCreator(configuration).dependentMhaClinicAppointments(dependent_id, start_datetime, end_datetime, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Get a dependent profile record
         * @param {string} dependent_id dependent profile uuid
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDependent(dependent_id: string, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<PatientUserDto> {
            const localVarAxiosArgs = DependentApiAxiosParamCreator(configuration).getDependent(dependent_id, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Get clinic info for the specified clinic
         * @param {string} dependent_id 
         * @param {string} clinic_id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDependentClinic(dependent_id: string, clinic_id: string, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<Clinic> {
            const localVarAxiosArgs = DependentApiAxiosParamCreator(configuration).getDependentClinic(dependent_id, clinic_id, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Get clinic info for the specified clinic
         * @param {string} dependent_id 
         * @param {string} clinic_id 
         * @param {boolean} [required_fields] Whether to return required connection fields for the requested clinic
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDependentClinicInfo(dependent_id: string, clinic_id: string, required_fields?: boolean, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<Clinic> {
            const localVarAxiosArgs = DependentApiAxiosParamCreator(configuration).getDependentClinicInfo(dependent_id, clinic_id, required_fields, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Get CloudMD clinic profile for patient\'s dependent
         * @param {string} dependent_id dependent profile uuid
         * @param {boolean} [required_fields] Whether to return required connection fields for the requested clinic
         * @param {ProvinceCode} [province_code] Whether to return required connection fields for the requested clinic
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDependentCloudMd(dependent_id: string, required_fields?: boolean, province_code?: ProvinceCode, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<InlineResponse2003> {
            const localVarAxiosArgs = DependentApiAxiosParamCreator(configuration).getDependentCloudMd(dependent_id, required_fields, province_code, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Link a dependent with a clinic
         * @param {string} dependent_id dependent profile uuid
         * @param {string} clinic_id clinic profile uuid
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        linkDependentWithClinic(dependent_id: string, clinic_id: string, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<InlineResponse2003> {
            const localVarAxiosArgs = DependentApiAxiosParamCreator(configuration).linkDependentWithClinic(dependent_id, clinic_id, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Unlink a dependent with a clinic
         * @param {string} dependent_id dependent profile uuid
         * @param {string} clinic_id clinic profile uuid
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        unlinkDependentWithClinic(dependent_id: string, clinic_id: string, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<InlineResponse2003> {
            const localVarAxiosArgs = DependentApiAxiosParamCreator(configuration).unlinkDependentWithClinic(dependent_id, clinic_id, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Update a dependent profile record
         * @param {string} dependent_id dependent profile uuid
         * @param {InlineObject8} inline_object8 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateDependent(dependent_id: string, inline_object8: InlineObject8, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<PatientUserDto> {
            const localVarAxiosArgs = DependentApiAxiosParamCreator(configuration).updateDependent(dependent_id, inline_object8, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
    }
};

/**
 * DependentApi - factory interface
 * @export
 */
export const DependentApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    return {
        /**
         * 
         * @summary Delete a dependent profile record
         * @param {string} dependent_id dependent profile uuid
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteDependent(dependent_id: string, options?: any) {
            return DependentApiFp(configuration).deleteDependent(dependent_id, options)(axios, basePath);
        },
        /**
         * 
         * @summary Get a list of dependent appointments
         * @param {string} dependent_id dependent profile uuid
         * @param {string} [start_date] Start of end range (inclusive)
         * @param {string} [end_date] End of date range (inclusive)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        dependentAppointments(dependent_id: string, start_date?: string, end_date?: string, options?: any) {
            return DependentApiFp(configuration).dependentAppointments(dependent_id, start_date, end_date, options)(axios, basePath);
        },
        /**
         * 
         * @summary Book an appointment for a dependent
         * @param {string} dependent_id dependent profile uuid
         * @param {string} clinic_id Clinic to book appointment slot for
         * @param {BookAppointmentTransfer} book_appointment_transfer Appointment slot
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        dependentClinicAppointmentBook(dependent_id: string, clinic_id: string, book_appointment_transfer: BookAppointmentTransfer, options?: any) {
            return DependentApiFp(configuration).dependentClinicAppointmentBook(dependent_id, clinic_id, book_appointment_transfer, options)(axios, basePath);
        },
        /**
         * 
         * @summary Cancel an appointment for a dependent
         * @param {string} dependent_id dependent profile uuid
         * @param {string} clinic_id Clinic to cancel appointment slot for
         * @param {string} id Juno appointment ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        dependentClinicAppointmentCancel(dependent_id: string, clinic_id: string, id: string, options?: any) {
            return DependentApiFp(configuration).dependentClinicAppointmentCancel(dependent_id, clinic_id, id, options)(axios, basePath);
        },
        /**
         * 
         * @summary Reschedule an appointment for a dependent
         * @param {string} dependent_id dependent profile uuid
         * @param {string} clinic_id Clinic to cancel appointment slot for
         * @param {string} appointment_id Juno appointment ID
         * @param {BookAppointmentTransfer} book_appointment_transfer Appointment slot
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        dependentClinicAppointmentReschedule(dependent_id: string, clinic_id: string, appointment_id: string, book_appointment_transfer: BookAppointmentTransfer, options?: any) {
            return DependentApiFp(configuration).dependentClinicAppointmentReschedule(dependent_id, clinic_id, appointment_id, book_appointment_transfer, options)(axios, basePath);
        },
        /**
         * 
         * @summary Get appointment type for a clinic
         * @param {string} dependent_id Dependent to get appointment type for
         * @param {string} clinic_id Clinic to get appointment type for
         * @param {string} appointment_type_id Specified appointment type
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        dependentClinicAppointmentType(dependent_id: string, clinic_id: string, appointment_type_id: string, options?: any) {
            return DependentApiFp(configuration).dependentClinicAppointmentType(dependent_id, clinic_id, appointment_type_id, options)(axios, basePath);
        },
        /**
         * 
         * @summary Get list of appointment types for a dependent\'s clinic
         * @param {string} dependent_id Dependent connected to specified clinic
         * @param {string} clinic_id Clinic to get providers for
         * @param {string} [provider_id] Provider to view appointment types for
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        dependentClinicAppointmentTypes(dependent_id: string, clinic_id: string, provider_id?: string, options?: any) {
            return DependentApiFp(configuration).dependentClinicAppointmentTypes(dependent_id, clinic_id, provider_id, options)(axios, basePath);
        },
        /**
         * 
         * @summary Get a list of slots available for dependent booking
         * @param {string} dependent_id dependent profile uuid
         * @param {string} clinic_id Clinic to get appointment slot list for
         * @param {string} provider_id Provider to get appointment slot list for
         * @param {string} appointment_type_id Appointment type to get slot list for
         * @param {string} start_date Start of end range (inclusive)
         * @param {string} end_date End of date range (inclusive)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        dependentClinicAvailableAppointments(dependent_id: string, clinic_id: string, provider_id: string, appointment_type_id: string, start_date: string, end_date: string, options?: any) {
            return DependentApiFp(configuration).dependentClinicAvailableAppointments(dependent_id, clinic_id, provider_id, appointment_type_id, start_date, end_date, options)(axios, basePath);
        },
        /**
         * 
         * @summary Get single provider record for a dependent
         * @param {string} dependent_id 
         * @param {string} clinic_id Clinic provider belongs to
         * @param {string} provider_id Provider to get info for
         * @param {boolean} [is_remote] Find provider based on remote_id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        dependentClinicProvider(dependent_id: string, clinic_id: string, provider_id: string, is_remote?: boolean, options?: any) {
            return DependentApiFp(configuration).dependentClinicProvider(dependent_id, clinic_id, provider_id, is_remote, options)(axios, basePath);
        },
        /**
         * 
         * @summary Get a provider group for a clinic
         * @param {string} dependent_id 
         * @param {string} clinic_id Clinic to get the provider group from
         * @param {string} provider_group_id Specified provider group
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        dependentClinicProviderGroup(dependent_id: string, clinic_id: string, provider_group_id: string, options?: any) {
            return DependentApiFp(configuration).dependentClinicProviderGroup(dependent_id, clinic_id, provider_group_id, options)(axios, basePath);
        },
        /**
         * 
         * @summary Get list of available providers from a provider group for this patient
         * @param {string} dependent_id 
         * @param {string} clinic_id Clinic to get provider groups for
         * @param {string} provider_group_id The group to get the providers from
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        dependentClinicProviderGroupProviders(dependent_id: string, clinic_id: string, provider_group_id: string, options?: any) {
            return DependentApiFp(configuration).dependentClinicProviderGroupProviders(dependent_id, clinic_id, provider_group_id, options)(axios, basePath);
        },
        /**
         * 
         * @summary Get list of available clinic provider groups for this patient
         * @param {string} dependent_id 
         * @param {string} clinic_id Clinic to get provider groups for
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        dependentClinicProviderGroups(dependent_id: string, clinic_id: string, options?: any) {
            return DependentApiFp(configuration).dependentClinicProviderGroups(dependent_id, clinic_id, options)(axios, basePath);
        },
        /**
         * 
         * @summary Get list of available clinic provider groups that a provider is in
         * @param {string} dependent_id 
         * @param {string} clinic_id Clinic to get provider groups for
         * @param {string} provider_id The id of the provider to get groups for
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        dependentClinicProviderGroupsByProvider(dependent_id: string, clinic_id: string, provider_id: string, options?: any) {
            return DependentApiFp(configuration).dependentClinicProviderGroupsByProvider(dependent_id, clinic_id, provider_id, options)(axios, basePath);
        },
        /**
         * 
         * @summary Get list of available clinics for this dependent
         * @param {string} dependent_id 
         * @param {string} clinic_id Clinic to get providers for
         * @param {boolean} [bookable_only] Only show bookable appointments
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        dependentClinicProviders(dependent_id: string, clinic_id: string, bookable_only?: boolean, options?: any) {
            return DependentApiFp(configuration).dependentClinicProviders(dependent_id, clinic_id, bookable_only, options)(axios, basePath);
        },
        /**
         * 
         * @summary list dependent clinics
         * @param {string} dependent_id dependent profile uuid
         * @param {boolean} [bookable_only] Only show bookable appointments
         * @param {boolean} [messageable_only] Only return clinics this dependent can message
         * @param {boolean} [available_only] Only return clinics that are avaiable to link with this dependent
         * @param {boolean} [connected_only] Only return clinics that are connected to this dependent
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        dependentClinics(dependent_id: string, bookable_only?: boolean, messageable_only?: boolean, available_only?: boolean, connected_only?: boolean, options?: any) {
            return DependentApiFp(configuration).dependentClinics(dependent_id, bookable_only, messageable_only, available_only, connected_only, options)(axios, basePath);
        },
        /**
         * 
         * @summary Check if all future appointments can be cancelled
         * @param {string} dependent_id dependent profile uuid
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        dependentFutureAppointmentsCancellable(dependent_id: string, options?: any) {
            return DependentApiFp(configuration).dependentFutureAppointmentsCancellable(dependent_id, options)(axios, basePath);
        },
        /**
         * 
         * @summary list clinics that the dependent can connect to orderby by distance
         * @param {string} dependent_id dependent profile uuid
         * @param {number} latitude 
         * @param {number} longitude 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        dependentLinkableClinicsByCoordinates(dependent_id: string, latitude: number, longitude: number, options?: any) {
            return DependentApiFp(configuration).dependentLinkableClinicsByCoordinates(dependent_id, latitude, longitude, options)(axios, basePath);
        },
        /**
         * 
         * @summary list clinics that the dependent can connect to orderby by distance
         * @param {string} dependent_id dependent profile uuid
         * @param {string} province_code 
         * @param {string} [city] 
         * @param {string} [address] 
         * @param {string} [postal_code] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        dependentLinkableClinicsByLocation(dependent_id: string, province_code: string, city?: string, address?: string, postal_code?: string, options?: any) {
            return DependentApiFp(configuration).dependentLinkableClinicsByLocation(dependent_id, province_code, city, address, postal_code, options)(axios, basePath);
        },
        /**
         * 
         * @summary Get a list of dependent clinic appointmenst
         * @param {string} dependent_id dependent profile uuid
         * @param {string} [start_datetime] the date after which appointments will be returned
         * @param {string} [end_datetime] the date before which appointments will be returned
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        dependentMhaClinicAppointments(dependent_id: string, start_datetime?: string, end_datetime?: string, options?: any) {
            return DependentApiFp(configuration).dependentMhaClinicAppointments(dependent_id, start_datetime, end_datetime, options)(axios, basePath);
        },
        /**
         * 
         * @summary Get a dependent profile record
         * @param {string} dependent_id dependent profile uuid
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDependent(dependent_id: string, options?: any) {
            return DependentApiFp(configuration).getDependent(dependent_id, options)(axios, basePath);
        },
        /**
         * 
         * @summary Get clinic info for the specified clinic
         * @param {string} dependent_id 
         * @param {string} clinic_id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDependentClinic(dependent_id: string, clinic_id: string, options?: any) {
            return DependentApiFp(configuration).getDependentClinic(dependent_id, clinic_id, options)(axios, basePath);
        },
        /**
         * 
         * @summary Get clinic info for the specified clinic
         * @param {string} dependent_id 
         * @param {string} clinic_id 
         * @param {boolean} [required_fields] Whether to return required connection fields for the requested clinic
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDependentClinicInfo(dependent_id: string, clinic_id: string, required_fields?: boolean, options?: any) {
            return DependentApiFp(configuration).getDependentClinicInfo(dependent_id, clinic_id, required_fields, options)(axios, basePath);
        },
        /**
         * 
         * @summary Get CloudMD clinic profile for patient\'s dependent
         * @param {string} dependent_id dependent profile uuid
         * @param {boolean} [required_fields] Whether to return required connection fields for the requested clinic
         * @param {ProvinceCode} [province_code] Whether to return required connection fields for the requested clinic
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDependentCloudMd(dependent_id: string, required_fields?: boolean, province_code?: ProvinceCode, options?: any) {
            return DependentApiFp(configuration).getDependentCloudMd(dependent_id, required_fields, province_code, options)(axios, basePath);
        },
        /**
         * 
         * @summary Link a dependent with a clinic
         * @param {string} dependent_id dependent profile uuid
         * @param {string} clinic_id clinic profile uuid
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        linkDependentWithClinic(dependent_id: string, clinic_id: string, options?: any) {
            return DependentApiFp(configuration).linkDependentWithClinic(dependent_id, clinic_id, options)(axios, basePath);
        },
        /**
         * 
         * @summary Unlink a dependent with a clinic
         * @param {string} dependent_id dependent profile uuid
         * @param {string} clinic_id clinic profile uuid
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        unlinkDependentWithClinic(dependent_id: string, clinic_id: string, options?: any) {
            return DependentApiFp(configuration).unlinkDependentWithClinic(dependent_id, clinic_id, options)(axios, basePath);
        },
        /**
         * 
         * @summary Update a dependent profile record
         * @param {string} dependent_id dependent profile uuid
         * @param {InlineObject8} inline_object8 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateDependent(dependent_id: string, inline_object8: InlineObject8, options?: any) {
            return DependentApiFp(configuration).updateDependent(dependent_id, inline_object8, options)(axios, basePath);
        },
    };
};

/**
 * DependentApi - object-oriented interface
 * @export
 * @class DependentApi
 * @extends {BaseAPI}
 */
export class DependentApi extends BaseAPI {
    /**
     * 
     * @summary Delete a dependent profile record
     * @param {string} dependent_id dependent profile uuid
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DependentApi
     */
    public deleteDependent(dependent_id: string, options?: any) {
        return DependentApiFp(this.configuration).deleteDependent(dependent_id, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @summary Get a list of dependent appointments
     * @param {string} dependent_id dependent profile uuid
     * @param {string} [start_date] Start of end range (inclusive)
     * @param {string} [end_date] End of date range (inclusive)
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DependentApi
     */
    public dependentAppointments(dependent_id: string, start_date?: string, end_date?: string, options?: any) {
        return DependentApiFp(this.configuration).dependentAppointments(dependent_id, start_date, end_date, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @summary Book an appointment for a dependent
     * @param {string} dependent_id dependent profile uuid
     * @param {string} clinic_id Clinic to book appointment slot for
     * @param {BookAppointmentTransfer} book_appointment_transfer Appointment slot
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DependentApi
     */
    public dependentClinicAppointmentBook(dependent_id: string, clinic_id: string, book_appointment_transfer: BookAppointmentTransfer, options?: any) {
        return DependentApiFp(this.configuration).dependentClinicAppointmentBook(dependent_id, clinic_id, book_appointment_transfer, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @summary Cancel an appointment for a dependent
     * @param {string} dependent_id dependent profile uuid
     * @param {string} clinic_id Clinic to cancel appointment slot for
     * @param {string} id Juno appointment ID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DependentApi
     */
    public dependentClinicAppointmentCancel(dependent_id: string, clinic_id: string, id: string, options?: any) {
        return DependentApiFp(this.configuration).dependentClinicAppointmentCancel(dependent_id, clinic_id, id, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @summary Reschedule an appointment for a dependent
     * @param {string} dependent_id dependent profile uuid
     * @param {string} clinic_id Clinic to cancel appointment slot for
     * @param {string} appointment_id Juno appointment ID
     * @param {BookAppointmentTransfer} book_appointment_transfer Appointment slot
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DependentApi
     */
    public dependentClinicAppointmentReschedule(dependent_id: string, clinic_id: string, appointment_id: string, book_appointment_transfer: BookAppointmentTransfer, options?: any) {
        return DependentApiFp(this.configuration).dependentClinicAppointmentReschedule(dependent_id, clinic_id, appointment_id, book_appointment_transfer, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @summary Get appointment type for a clinic
     * @param {string} dependent_id Dependent to get appointment type for
     * @param {string} clinic_id Clinic to get appointment type for
     * @param {string} appointment_type_id Specified appointment type
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DependentApi
     */
    public dependentClinicAppointmentType(dependent_id: string, clinic_id: string, appointment_type_id: string, options?: any) {
        return DependentApiFp(this.configuration).dependentClinicAppointmentType(dependent_id, clinic_id, appointment_type_id, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @summary Get list of appointment types for a dependent\'s clinic
     * @param {string} dependent_id Dependent connected to specified clinic
     * @param {string} clinic_id Clinic to get providers for
     * @param {string} [provider_id] Provider to view appointment types for
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DependentApi
     */
    public dependentClinicAppointmentTypes(dependent_id: string, clinic_id: string, provider_id?: string, options?: any) {
        return DependentApiFp(this.configuration).dependentClinicAppointmentTypes(dependent_id, clinic_id, provider_id, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @summary Get a list of slots available for dependent booking
     * @param {string} dependent_id dependent profile uuid
     * @param {string} clinic_id Clinic to get appointment slot list for
     * @param {string} provider_id Provider to get appointment slot list for
     * @param {string} appointment_type_id Appointment type to get slot list for
     * @param {string} start_date Start of end range (inclusive)
     * @param {string} end_date End of date range (inclusive)
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DependentApi
     */
    public dependentClinicAvailableAppointments(dependent_id: string, clinic_id: string, provider_id: string, appointment_type_id: string, start_date: string, end_date: string, options?: any) {
        return DependentApiFp(this.configuration).dependentClinicAvailableAppointments(dependent_id, clinic_id, provider_id, appointment_type_id, start_date, end_date, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @summary Get single provider record for a dependent
     * @param {string} dependent_id 
     * @param {string} clinic_id Clinic provider belongs to
     * @param {string} provider_id Provider to get info for
     * @param {boolean} [is_remote] Find provider based on remote_id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DependentApi
     */
    public dependentClinicProvider(dependent_id: string, clinic_id: string, provider_id: string, is_remote?: boolean, options?: any) {
        return DependentApiFp(this.configuration).dependentClinicProvider(dependent_id, clinic_id, provider_id, is_remote, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @summary Get a provider group for a clinic
     * @param {string} dependent_id 
     * @param {string} clinic_id Clinic to get the provider group from
     * @param {string} provider_group_id Specified provider group
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DependentApi
     */
    public dependentClinicProviderGroup(dependent_id: string, clinic_id: string, provider_group_id: string, options?: any) {
        return DependentApiFp(this.configuration).dependentClinicProviderGroup(dependent_id, clinic_id, provider_group_id, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @summary Get list of available providers from a provider group for this patient
     * @param {string} dependent_id 
     * @param {string} clinic_id Clinic to get provider groups for
     * @param {string} provider_group_id The group to get the providers from
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DependentApi
     */
    public dependentClinicProviderGroupProviders(dependent_id: string, clinic_id: string, provider_group_id: string, options?: any) {
        return DependentApiFp(this.configuration).dependentClinicProviderGroupProviders(dependent_id, clinic_id, provider_group_id, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @summary Get list of available clinic provider groups for this patient
     * @param {string} dependent_id 
     * @param {string} clinic_id Clinic to get provider groups for
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DependentApi
     */
    public dependentClinicProviderGroups(dependent_id: string, clinic_id: string, options?: any) {
        return DependentApiFp(this.configuration).dependentClinicProviderGroups(dependent_id, clinic_id, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @summary Get list of available clinic provider groups that a provider is in
     * @param {string} dependent_id 
     * @param {string} clinic_id Clinic to get provider groups for
     * @param {string} provider_id The id of the provider to get groups for
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DependentApi
     */
    public dependentClinicProviderGroupsByProvider(dependent_id: string, clinic_id: string, provider_id: string, options?: any) {
        return DependentApiFp(this.configuration).dependentClinicProviderGroupsByProvider(dependent_id, clinic_id, provider_id, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @summary Get list of available clinics for this dependent
     * @param {string} dependent_id 
     * @param {string} clinic_id Clinic to get providers for
     * @param {boolean} [bookable_only] Only show bookable appointments
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DependentApi
     */
    public dependentClinicProviders(dependent_id: string, clinic_id: string, bookable_only?: boolean, options?: any) {
        return DependentApiFp(this.configuration).dependentClinicProviders(dependent_id, clinic_id, bookable_only, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @summary list dependent clinics
     * @param {string} dependent_id dependent profile uuid
     * @param {boolean} [bookable_only] Only show bookable appointments
     * @param {boolean} [messageable_only] Only return clinics this dependent can message
     * @param {boolean} [available_only] Only return clinics that are avaiable to link with this dependent
     * @param {boolean} [connected_only] Only return clinics that are connected to this dependent
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DependentApi
     */
    public dependentClinics(dependent_id: string, bookable_only?: boolean, messageable_only?: boolean, available_only?: boolean, connected_only?: boolean, options?: any) {
        return DependentApiFp(this.configuration).dependentClinics(dependent_id, bookable_only, messageable_only, available_only, connected_only, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @summary Check if all future appointments can be cancelled
     * @param {string} dependent_id dependent profile uuid
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DependentApi
     */
    public dependentFutureAppointmentsCancellable(dependent_id: string, options?: any) {
        return DependentApiFp(this.configuration).dependentFutureAppointmentsCancellable(dependent_id, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @summary list clinics that the dependent can connect to orderby by distance
     * @param {string} dependent_id dependent profile uuid
     * @param {number} latitude 
     * @param {number} longitude 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DependentApi
     */
    public dependentLinkableClinicsByCoordinates(dependent_id: string, latitude: number, longitude: number, options?: any) {
        return DependentApiFp(this.configuration).dependentLinkableClinicsByCoordinates(dependent_id, latitude, longitude, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @summary list clinics that the dependent can connect to orderby by distance
     * @param {string} dependent_id dependent profile uuid
     * @param {string} province_code 
     * @param {string} [city] 
     * @param {string} [address] 
     * @param {string} [postal_code] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DependentApi
     */
    public dependentLinkableClinicsByLocation(dependent_id: string, province_code: string, city?: string, address?: string, postal_code?: string, options?: any) {
        return DependentApiFp(this.configuration).dependentLinkableClinicsByLocation(dependent_id, province_code, city, address, postal_code, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @summary Get a list of dependent clinic appointmenst
     * @param {string} dependent_id dependent profile uuid
     * @param {string} [start_datetime] the date after which appointments will be returned
     * @param {string} [end_datetime] the date before which appointments will be returned
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DependentApi
     */
    public dependentMhaClinicAppointments(dependent_id: string, start_datetime?: string, end_datetime?: string, options?: any) {
        return DependentApiFp(this.configuration).dependentMhaClinicAppointments(dependent_id, start_datetime, end_datetime, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @summary Get a dependent profile record
     * @param {string} dependent_id dependent profile uuid
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DependentApi
     */
    public getDependent(dependent_id: string, options?: any) {
        return DependentApiFp(this.configuration).getDependent(dependent_id, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @summary Get clinic info for the specified clinic
     * @param {string} dependent_id 
     * @param {string} clinic_id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DependentApi
     */
    public getDependentClinic(dependent_id: string, clinic_id: string, options?: any) {
        return DependentApiFp(this.configuration).getDependentClinic(dependent_id, clinic_id, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @summary Get clinic info for the specified clinic
     * @param {string} dependent_id 
     * @param {string} clinic_id 
     * @param {boolean} [required_fields] Whether to return required connection fields for the requested clinic
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DependentApi
     */
    public getDependentClinicInfo(dependent_id: string, clinic_id: string, required_fields?: boolean, options?: any) {
        return DependentApiFp(this.configuration).getDependentClinicInfo(dependent_id, clinic_id, required_fields, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @summary Get CloudMD clinic profile for patient\'s dependent
     * @param {string} dependent_id dependent profile uuid
     * @param {boolean} [required_fields] Whether to return required connection fields for the requested clinic
     * @param {ProvinceCode} [province_code] Whether to return required connection fields for the requested clinic
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DependentApi
     */
    public getDependentCloudMd(dependent_id: string, required_fields?: boolean, province_code?: ProvinceCode, options?: any) {
        return DependentApiFp(this.configuration).getDependentCloudMd(dependent_id, required_fields, province_code, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @summary Link a dependent with a clinic
     * @param {string} dependent_id dependent profile uuid
     * @param {string} clinic_id clinic profile uuid
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DependentApi
     */
    public linkDependentWithClinic(dependent_id: string, clinic_id: string, options?: any) {
        return DependentApiFp(this.configuration).linkDependentWithClinic(dependent_id, clinic_id, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @summary Unlink a dependent with a clinic
     * @param {string} dependent_id dependent profile uuid
     * @param {string} clinic_id clinic profile uuid
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DependentApi
     */
    public unlinkDependentWithClinic(dependent_id: string, clinic_id: string, options?: any) {
        return DependentApiFp(this.configuration).unlinkDependentWithClinic(dependent_id, clinic_id, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @summary Update a dependent profile record
     * @param {string} dependent_id dependent profile uuid
     * @param {InlineObject8} inline_object8 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DependentApi
     */
    public updateDependent(dependent_id: string, inline_object8: InlineObject8, options?: any) {
        return DependentApiFp(this.configuration).updateDependent(dependent_id, inline_object8, options)(this.axios, this.basePath);
    }

}


/**
 * KioskApi - axios parameter creator
 * @export
 */
export const KioskApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Record a new chat message for the appointment
         * @param {string} appointment_id Id of the appointment to which the chat message will be added
         * @param {TelehealthChatMessageDto} telehealth_chat_message_dto Message
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addKioskPatientChatMessageToAppointment(appointment_id: string, telehealth_chat_message_dto: TelehealthChatMessageDto, options: any = {}): RequestArgs {
            // verify required parameter 'appointment_id' is not null or undefined
            if (appointment_id === null || appointment_id === undefined) {
                throw new RequiredError('appointment_id','Required parameter appointment_id was null or undefined when calling addKioskPatientChatMessageToAppointment.');
            }
            // verify required parameter 'telehealth_chat_message_dto' is not null or undefined
            if (telehealth_chat_message_dto === null || telehealth_chat_message_dto === undefined) {
                throw new RequiredError('telehealth_chat_message_dto','Required parameter telehealth_chat_message_dto was null or undefined when calling addKioskPatientChatMessageToAppointment.');
            }
            const localVarPath = `/kiosk/self/appointment/{appointment_id}/chat/message/`
                .replace(`{${"appointment_id"}}`, encodeURIComponent(String(appointment_id)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication apiAuth required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? configuration.apiKey("X-Auth-Token")
                    : configuration.apiKey;
                localVarHeaderParameter["X-Auth-Token"] = localVarApiKeyValue;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};
            const needsSerialization = (<any>"TelehealthChatMessageDto" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(telehealth_chat_message_dto !== undefined ? telehealth_chat_message_dto : {}) : (telehealth_chat_message_dto || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get all chat messages for this appointment
         * @param {string} appointment_id Id of the appointment
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getKioskPatientAppointmentChatMessages(appointment_id: string, options: any = {}): RequestArgs {
            // verify required parameter 'appointment_id' is not null or undefined
            if (appointment_id === null || appointment_id === undefined) {
                throw new RequiredError('appointment_id','Required parameter appointment_id was null or undefined when calling getKioskPatientAppointmentChatMessages.');
            }
            const localVarPath = `/kiosk/self/appointment/{appointment_id}/chat/messages/`
                .replace(`{${"appointment_id"}}`, encodeURIComponent(String(appointment_id)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication apiAuth required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? configuration.apiKey("X-Auth-Token")
                    : configuration.apiKey;
                localVarHeaderParameter["X-Auth-Token"] = localVarApiKeyValue;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Telehealth appointment session data
         * @param {string} appointment_id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getKioskPatientTelehealthSessionData(appointment_id: string, options: any = {}): RequestArgs {
            // verify required parameter 'appointment_id' is not null or undefined
            if (appointment_id === null || appointment_id === undefined) {
                throw new RequiredError('appointment_id','Required parameter appointment_id was null or undefined when calling getKioskPatientTelehealthSessionData.');
            }
            const localVarPath = `/kiosk/self/appointment/{appointment_id}/session`
                .replace(`{${"appointment_id"}}`, encodeURIComponent(String(appointment_id)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication apiAuth required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? configuration.apiKey("X-Auth-Token")
                    : configuration.apiKey;
                localVarHeaderParameter["X-Auth-Token"] = localVarApiKeyValue;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Book an unconfirmed appointment based on given patient information
         * @param {string} clinic_id 
         * @param {KioskAppointmentRequest} kiosk_appointment_request Patient profile and reason for appointment
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        kioskBookAppointment(clinic_id: string, kiosk_appointment_request: KioskAppointmentRequest, options: any = {}): RequestArgs {
            // verify required parameter 'clinic_id' is not null or undefined
            if (clinic_id === null || clinic_id === undefined) {
                throw new RequiredError('clinic_id','Required parameter clinic_id was null or undefined when calling kioskBookAppointment.');
            }
            // verify required parameter 'kiosk_appointment_request' is not null or undefined
            if (kiosk_appointment_request === null || kiosk_appointment_request === undefined) {
                throw new RequiredError('kiosk_appointment_request','Required parameter kiosk_appointment_request was null or undefined when calling kioskBookAppointment.');
            }
            const localVarPath = `/kiosk/self/clinic/{clinic_id}/book.json`
                .replace(`{${"clinic_id"}}`, encodeURIComponent(String(clinic_id)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication apiAuth required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? configuration.apiKey("X-Auth-Token")
                    : configuration.apiKey;
                localVarHeaderParameter["X-Auth-Token"] = localVarApiKeyValue;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};
            const needsSerialization = (<any>"KioskAppointmentRequest" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(kiosk_appointment_request !== undefined ? kiosk_appointment_request : {}) : (kiosk_appointment_request || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Cancel kiosk appointment
         * @param {string} clinic_id 
         * @param {InlineObject9} inline_object9 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        kioskCancelAppointment(clinic_id: string, inline_object9: InlineObject9, options: any = {}): RequestArgs {
            // verify required parameter 'clinic_id' is not null or undefined
            if (clinic_id === null || clinic_id === undefined) {
                throw new RequiredError('clinic_id','Required parameter clinic_id was null or undefined when calling kioskCancelAppointment.');
            }
            // verify required parameter 'inline_object9' is not null or undefined
            if (inline_object9 === null || inline_object9 === undefined) {
                throw new RequiredError('inline_object9','Required parameter inline_object9 was null or undefined when calling kioskCancelAppointment.');
            }
            const localVarPath = `/kiosk/self/clinic/{clinic_id}/cancel.json`
                .replace(`{${"clinic_id"}}`, encodeURIComponent(String(clinic_id)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication apiAuth required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? configuration.apiKey("X-Auth-Token")
                    : configuration.apiKey;
                localVarHeaderParameter["X-Auth-Token"] = localVarApiKeyValue;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};
            const needsSerialization = (<any>"InlineObject9" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(inline_object9 !== undefined ? inline_object9 : {}) : (inline_object9 || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get kiosk clinic list
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        kioskClinics(options: any = {}): RequestArgs {
            const localVarPath = `/kiosk/self/clinics`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication apiAuth required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? configuration.apiKey("X-Auth-Token")
                    : configuration.apiKey;
                localVarHeaderParameter["X-Auth-Token"] = localVarApiKeyValue;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Kiosk login response
         * @param {AuthInfo} auth_info Authentication info
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        kioskLogin(auth_info: AuthInfo, options: any = {}): RequestArgs {
            // verify required parameter 'auth_info' is not null or undefined
            if (auth_info === null || auth_info === undefined) {
                throw new RequiredError('auth_info','Required parameter auth_info was null or undefined when calling kioskLogin.');
            }
            const localVarPath = `/kiosk/login.json`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};
            const needsSerialization = (<any>"AuthInfo" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(auth_info !== undefined ? auth_info : {}) : (auth_info || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get required profile fields for kiosk patients
         * @param {string} clinic_id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        kioskRequiredFields(clinic_id: string, options: any = {}): RequestArgs {
            // verify required parameter 'clinic_id' is not null or undefined
            if (clinic_id === null || clinic_id === undefined) {
                throw new RequiredError('clinic_id','Required parameter clinic_id was null or undefined when calling kioskRequiredFields.');
            }
            const localVarPath = `/kiosk/self/clinic/{clinic_id}/required_fields.json`
                .replace(`{${"clinic_id"}}`, encodeURIComponent(String(clinic_id)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication apiAuth required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? configuration.apiKey("X-Auth-Token")
                    : configuration.apiKey;
                localVarHeaderParameter["X-Auth-Token"] = localVarApiKeyValue;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Log a telehealth session event
         * @param {string} appointment_id 
         * @param {TelehealthLogEventDto} telehealth_log_event_dto Log event data
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        logEventToPatientKioskTelehealthSession(appointment_id: string, telehealth_log_event_dto: TelehealthLogEventDto, options: any = {}): RequestArgs {
            // verify required parameter 'appointment_id' is not null or undefined
            if (appointment_id === null || appointment_id === undefined) {
                throw new RequiredError('appointment_id','Required parameter appointment_id was null or undefined when calling logEventToPatientKioskTelehealthSession.');
            }
            // verify required parameter 'telehealth_log_event_dto' is not null or undefined
            if (telehealth_log_event_dto === null || telehealth_log_event_dto === undefined) {
                throw new RequiredError('telehealth_log_event_dto','Required parameter telehealth_log_event_dto was null or undefined when calling logEventToPatientKioskTelehealthSession.');
            }
            const localVarPath = `/kiosk/self/appointment/{appointment_id}/session/log_event`
                .replace(`{${"appointment_id"}}`, encodeURIComponent(String(appointment_id)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication apiAuth required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? configuration.apiKey("X-Auth-Token")
                    : configuration.apiKey;
                localVarHeaderParameter["X-Auth-Token"] = localVarApiKeyValue;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};
            const needsSerialization = (<any>"TelehealthLogEventDto" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(telehealth_log_event_dto !== undefined ? telehealth_log_event_dto : {}) : (telehealth_log_event_dto || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get logged in kiosk
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        loggedInKiosk(options: any = {}): RequestArgs {
            const localVarPath = `/kiosk/self/`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication apiAuth required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? configuration.apiKey("X-Auth-Token")
                    : configuration.apiKey;
                localVarHeaderParameter["X-Auth-Token"] = localVarApiKeyValue;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Ping telehealth session
         * @param {string} appointment_id 
         * @param {TelehealthPingSessionDto} telehealth_ping_session_dto Ping session data
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        pingKioskTelehealthSession(appointment_id: string, telehealth_ping_session_dto: TelehealthPingSessionDto, options: any = {}): RequestArgs {
            // verify required parameter 'appointment_id' is not null or undefined
            if (appointment_id === null || appointment_id === undefined) {
                throw new RequiredError('appointment_id','Required parameter appointment_id was null or undefined when calling pingKioskTelehealthSession.');
            }
            // verify required parameter 'telehealth_ping_session_dto' is not null or undefined
            if (telehealth_ping_session_dto === null || telehealth_ping_session_dto === undefined) {
                throw new RequiredError('telehealth_ping_session_dto','Required parameter telehealth_ping_session_dto was null or undefined when calling pingKioskTelehealthSession.');
            }
            const localVarPath = `/kiosk/self/appointment/{appointment_id}/session/ping`
                .replace(`{${"appointment_id"}}`, encodeURIComponent(String(appointment_id)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication apiAuth required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? configuration.apiKey("X-Auth-Token")
                    : configuration.apiKey;
                localVarHeaderParameter["X-Auth-Token"] = localVarApiKeyValue;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};
            const needsSerialization = (<any>"TelehealthPingSessionDto" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(telehealth_ping_session_dto !== undefined ? telehealth_ping_session_dto : {}) : (telehealth_ping_session_dto || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * KioskApi - functional programming interface
 * @export
 */
export const KioskApiFp = function(configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Record a new chat message for the appointment
         * @param {string} appointment_id Id of the appointment to which the chat message will be added
         * @param {TelehealthChatMessageDto} telehealth_chat_message_dto Message
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addKioskPatientChatMessageToAppointment(appointment_id: string, telehealth_chat_message_dto: TelehealthChatMessageDto, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<TelehealthChatMessageDto> {
            const localVarAxiosArgs = KioskApiAxiosParamCreator(configuration).addKioskPatientChatMessageToAppointment(appointment_id, telehealth_chat_message_dto, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Get all chat messages for this appointment
         * @param {string} appointment_id Id of the appointment
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getKioskPatientAppointmentChatMessages(appointment_id: string, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<TelehealthChatMessageDto>> {
            const localVarAxiosArgs = KioskApiAxiosParamCreator(configuration).getKioskPatientAppointmentChatMessages(appointment_id, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Telehealth appointment session data
         * @param {string} appointment_id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getKioskPatientTelehealthSessionData(appointment_id: string, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<OTSessionInfo> {
            const localVarAxiosArgs = KioskApiAxiosParamCreator(configuration).getKioskPatientTelehealthSessionData(appointment_id, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Book an unconfirmed appointment based on given patient information
         * @param {string} clinic_id 
         * @param {KioskAppointmentRequest} kiosk_appointment_request Patient profile and reason for appointment
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        kioskBookAppointment(clinic_id: string, kiosk_appointment_request: KioskAppointmentRequest, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<OTSessionInfo> {
            const localVarAxiosArgs = KioskApiAxiosParamCreator(configuration).kioskBookAppointment(clinic_id, kiosk_appointment_request, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Cancel kiosk appointment
         * @param {string} clinic_id 
         * @param {InlineObject9} inline_object9 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        kioskCancelAppointment(clinic_id: string, inline_object9: InlineObject9, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<boolean> {
            const localVarAxiosArgs = KioskApiAxiosParamCreator(configuration).kioskCancelAppointment(clinic_id, inline_object9, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Get kiosk clinic list
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        kioskClinics(options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<KioskClinicsResponse> {
            const localVarAxiosArgs = KioskApiAxiosParamCreator(configuration).kioskClinics(options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Kiosk login response
         * @param {AuthInfo} auth_info Authentication info
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        kioskLogin(auth_info: AuthInfo, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<KioskLoginResponse> {
            const localVarAxiosArgs = KioskApiAxiosParamCreator(configuration).kioskLogin(auth_info, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Get required profile fields for kiosk patients
         * @param {string} clinic_id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        kioskRequiredFields(clinic_id: string, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<string>> {
            const localVarAxiosArgs = KioskApiAxiosParamCreator(configuration).kioskRequiredFields(clinic_id, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Log a telehealth session event
         * @param {string} appointment_id 
         * @param {TelehealthLogEventDto} telehealth_log_event_dto Log event data
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        logEventToPatientKioskTelehealthSession(appointment_id: string, telehealth_log_event_dto: TelehealthLogEventDto, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<void> {
            const localVarAxiosArgs = KioskApiAxiosParamCreator(configuration).logEventToPatientKioskTelehealthSession(appointment_id, telehealth_log_event_dto, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Get logged in kiosk
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        loggedInKiosk(options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<KioskLoginResponse> {
            const localVarAxiosArgs = KioskApiAxiosParamCreator(configuration).loggedInKiosk(options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Ping telehealth session
         * @param {string} appointment_id 
         * @param {TelehealthPingSessionDto} telehealth_ping_session_dto Ping session data
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        pingKioskTelehealthSession(appointment_id: string, telehealth_ping_session_dto: TelehealthPingSessionDto, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<void> {
            const localVarAxiosArgs = KioskApiAxiosParamCreator(configuration).pingKioskTelehealthSession(appointment_id, telehealth_ping_session_dto, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
    }
};

/**
 * KioskApi - factory interface
 * @export
 */
export const KioskApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    return {
        /**
         * 
         * @summary Record a new chat message for the appointment
         * @param {string} appointment_id Id of the appointment to which the chat message will be added
         * @param {TelehealthChatMessageDto} telehealth_chat_message_dto Message
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addKioskPatientChatMessageToAppointment(appointment_id: string, telehealth_chat_message_dto: TelehealthChatMessageDto, options?: any) {
            return KioskApiFp(configuration).addKioskPatientChatMessageToAppointment(appointment_id, telehealth_chat_message_dto, options)(axios, basePath);
        },
        /**
         * 
         * @summary Get all chat messages for this appointment
         * @param {string} appointment_id Id of the appointment
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getKioskPatientAppointmentChatMessages(appointment_id: string, options?: any) {
            return KioskApiFp(configuration).getKioskPatientAppointmentChatMessages(appointment_id, options)(axios, basePath);
        },
        /**
         * 
         * @summary Telehealth appointment session data
         * @param {string} appointment_id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getKioskPatientTelehealthSessionData(appointment_id: string, options?: any) {
            return KioskApiFp(configuration).getKioskPatientTelehealthSessionData(appointment_id, options)(axios, basePath);
        },
        /**
         * 
         * @summary Book an unconfirmed appointment based on given patient information
         * @param {string} clinic_id 
         * @param {KioskAppointmentRequest} kiosk_appointment_request Patient profile and reason for appointment
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        kioskBookAppointment(clinic_id: string, kiosk_appointment_request: KioskAppointmentRequest, options?: any) {
            return KioskApiFp(configuration).kioskBookAppointment(clinic_id, kiosk_appointment_request, options)(axios, basePath);
        },
        /**
         * 
         * @summary Cancel kiosk appointment
         * @param {string} clinic_id 
         * @param {InlineObject9} inline_object9 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        kioskCancelAppointment(clinic_id: string, inline_object9: InlineObject9, options?: any) {
            return KioskApiFp(configuration).kioskCancelAppointment(clinic_id, inline_object9, options)(axios, basePath);
        },
        /**
         * 
         * @summary Get kiosk clinic list
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        kioskClinics(options?: any) {
            return KioskApiFp(configuration).kioskClinics(options)(axios, basePath);
        },
        /**
         * 
         * @summary Kiosk login response
         * @param {AuthInfo} auth_info Authentication info
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        kioskLogin(auth_info: AuthInfo, options?: any) {
            return KioskApiFp(configuration).kioskLogin(auth_info, options)(axios, basePath);
        },
        /**
         * 
         * @summary Get required profile fields for kiosk patients
         * @param {string} clinic_id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        kioskRequiredFields(clinic_id: string, options?: any) {
            return KioskApiFp(configuration).kioskRequiredFields(clinic_id, options)(axios, basePath);
        },
        /**
         * 
         * @summary Log a telehealth session event
         * @param {string} appointment_id 
         * @param {TelehealthLogEventDto} telehealth_log_event_dto Log event data
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        logEventToPatientKioskTelehealthSession(appointment_id: string, telehealth_log_event_dto: TelehealthLogEventDto, options?: any) {
            return KioskApiFp(configuration).logEventToPatientKioskTelehealthSession(appointment_id, telehealth_log_event_dto, options)(axios, basePath);
        },
        /**
         * 
         * @summary Get logged in kiosk
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        loggedInKiosk(options?: any) {
            return KioskApiFp(configuration).loggedInKiosk(options)(axios, basePath);
        },
        /**
         * 
         * @summary Ping telehealth session
         * @param {string} appointment_id 
         * @param {TelehealthPingSessionDto} telehealth_ping_session_dto Ping session data
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        pingKioskTelehealthSession(appointment_id: string, telehealth_ping_session_dto: TelehealthPingSessionDto, options?: any) {
            return KioskApiFp(configuration).pingKioskTelehealthSession(appointment_id, telehealth_ping_session_dto, options)(axios, basePath);
        },
    };
};

/**
 * KioskApi - object-oriented interface
 * @export
 * @class KioskApi
 * @extends {BaseAPI}
 */
export class KioskApi extends BaseAPI {
    /**
     * 
     * @summary Record a new chat message for the appointment
     * @param {string} appointment_id Id of the appointment to which the chat message will be added
     * @param {TelehealthChatMessageDto} telehealth_chat_message_dto Message
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof KioskApi
     */
    public addKioskPatientChatMessageToAppointment(appointment_id: string, telehealth_chat_message_dto: TelehealthChatMessageDto, options?: any) {
        return KioskApiFp(this.configuration).addKioskPatientChatMessageToAppointment(appointment_id, telehealth_chat_message_dto, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @summary Get all chat messages for this appointment
     * @param {string} appointment_id Id of the appointment
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof KioskApi
     */
    public getKioskPatientAppointmentChatMessages(appointment_id: string, options?: any) {
        return KioskApiFp(this.configuration).getKioskPatientAppointmentChatMessages(appointment_id, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @summary Telehealth appointment session data
     * @param {string} appointment_id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof KioskApi
     */
    public getKioskPatientTelehealthSessionData(appointment_id: string, options?: any) {
        return KioskApiFp(this.configuration).getKioskPatientTelehealthSessionData(appointment_id, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @summary Book an unconfirmed appointment based on given patient information
     * @param {string} clinic_id 
     * @param {KioskAppointmentRequest} kiosk_appointment_request Patient profile and reason for appointment
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof KioskApi
     */
    public kioskBookAppointment(clinic_id: string, kiosk_appointment_request: KioskAppointmentRequest, options?: any) {
        return KioskApiFp(this.configuration).kioskBookAppointment(clinic_id, kiosk_appointment_request, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @summary Cancel kiosk appointment
     * @param {string} clinic_id 
     * @param {InlineObject9} inline_object9 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof KioskApi
     */
    public kioskCancelAppointment(clinic_id: string, inline_object9: InlineObject9, options?: any) {
        return KioskApiFp(this.configuration).kioskCancelAppointment(clinic_id, inline_object9, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @summary Get kiosk clinic list
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof KioskApi
     */
    public kioskClinics(options?: any) {
        return KioskApiFp(this.configuration).kioskClinics(options)(this.axios, this.basePath);
    }

    /**
     * 
     * @summary Kiosk login response
     * @param {AuthInfo} auth_info Authentication info
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof KioskApi
     */
    public kioskLogin(auth_info: AuthInfo, options?: any) {
        return KioskApiFp(this.configuration).kioskLogin(auth_info, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @summary Get required profile fields for kiosk patients
     * @param {string} clinic_id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof KioskApi
     */
    public kioskRequiredFields(clinic_id: string, options?: any) {
        return KioskApiFp(this.configuration).kioskRequiredFields(clinic_id, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @summary Log a telehealth session event
     * @param {string} appointment_id 
     * @param {TelehealthLogEventDto} telehealth_log_event_dto Log event data
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof KioskApi
     */
    public logEventToPatientKioskTelehealthSession(appointment_id: string, telehealth_log_event_dto: TelehealthLogEventDto, options?: any) {
        return KioskApiFp(this.configuration).logEventToPatientKioskTelehealthSession(appointment_id, telehealth_log_event_dto, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @summary Get logged in kiosk
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof KioskApi
     */
    public loggedInKiosk(options?: any) {
        return KioskApiFp(this.configuration).loggedInKiosk(options)(this.axios, this.basePath);
    }

    /**
     * 
     * @summary Ping telehealth session
     * @param {string} appointment_id 
     * @param {TelehealthPingSessionDto} telehealth_ping_session_dto Ping session data
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof KioskApi
     */
    public pingKioskTelehealthSession(appointment_id: string, telehealth_ping_session_dto: TelehealthPingSessionDto, options?: any) {
        return KioskApiFp(this.configuration).pingKioskTelehealthSession(appointment_id, telehealth_ping_session_dto, options)(this.axios, this.basePath);
    }

}


/**
 * LoggingApi - axios parameter creator
 * @export
 */
export const LoggingApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Public endpoint for logging
         * @param {LogMessage} log_message message loging info.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        log(log_message: LogMessage, options: any = {}): RequestArgs {
            // verify required parameter 'log_message' is not null or undefined
            if (log_message === null || log_message === undefined) {
                throw new RequiredError('log_message','Required parameter log_message was null or undefined when calling log.');
            }
            const localVarPath = `/public/log/`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};
            const needsSerialization = (<any>"LogMessage" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(log_message !== undefined ? log_message : {}) : (log_message || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Public endpoint for analytics logging
         * @param {AnalyticsEvent} analytics_event analytics event to log
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        logAnalyticEvent(analytics_event: AnalyticsEvent, options: any = {}): RequestArgs {
            // verify required parameter 'analytics_event' is not null or undefined
            if (analytics_event === null || analytics_event === undefined) {
                throw new RequiredError('analytics_event','Required parameter analytics_event was null or undefined when calling logAnalyticEvent.');
            }
            const localVarPath = `/public/log/client/event`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication apiAuth required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? configuration.apiKey("X-Auth-Token")
                    : configuration.apiKey;
                localVarHeaderParameter["X-Auth-Token"] = localVarApiKeyValue;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};
            const needsSerialization = (<any>"AnalyticsEvent" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(analytics_event !== undefined ? analytics_event : {}) : (analytics_event || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Public endpoint for logging front end errors
         * @param {ErrorReport} error_report Authentication info
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        reportError(error_report: ErrorReport, options: any = {}): RequestArgs {
            // verify required parameter 'error_report' is not null or undefined
            if (error_report === null || error_report === undefined) {
                throw new RequiredError('error_report','Required parameter error_report was null or undefined when calling reportError.');
            }
            const localVarPath = `/public/log/report_error.json`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};
            const needsSerialization = (<any>"ErrorReport" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(error_report !== undefined ? error_report : {}) : (error_report || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * LoggingApi - functional programming interface
 * @export
 */
export const LoggingApiFp = function(configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Public endpoint for logging
         * @param {LogMessage} log_message message loging info.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        log(log_message: LogMessage, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<void> {
            const localVarAxiosArgs = LoggingApiAxiosParamCreator(configuration).log(log_message, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Public endpoint for analytics logging
         * @param {AnalyticsEvent} analytics_event analytics event to log
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        logAnalyticEvent(analytics_event: AnalyticsEvent, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<void> {
            const localVarAxiosArgs = LoggingApiAxiosParamCreator(configuration).logAnalyticEvent(analytics_event, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Public endpoint for logging front end errors
         * @param {ErrorReport} error_report Authentication info
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        reportError(error_report: ErrorReport, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<void> {
            const localVarAxiosArgs = LoggingApiAxiosParamCreator(configuration).reportError(error_report, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
    }
};

/**
 * LoggingApi - factory interface
 * @export
 */
export const LoggingApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    return {
        /**
         * 
         * @summary Public endpoint for logging
         * @param {LogMessage} log_message message loging info.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        log(log_message: LogMessage, options?: any) {
            return LoggingApiFp(configuration).log(log_message, options)(axios, basePath);
        },
        /**
         * 
         * @summary Public endpoint for analytics logging
         * @param {AnalyticsEvent} analytics_event analytics event to log
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        logAnalyticEvent(analytics_event: AnalyticsEvent, options?: any) {
            return LoggingApiFp(configuration).logAnalyticEvent(analytics_event, options)(axios, basePath);
        },
        /**
         * 
         * @summary Public endpoint for logging front end errors
         * @param {ErrorReport} error_report Authentication info
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        reportError(error_report: ErrorReport, options?: any) {
            return LoggingApiFp(configuration).reportError(error_report, options)(axios, basePath);
        },
    };
};

/**
 * LoggingApi - object-oriented interface
 * @export
 * @class LoggingApi
 * @extends {BaseAPI}
 */
export class LoggingApi extends BaseAPI {
    /**
     * 
     * @summary Public endpoint for logging
     * @param {LogMessage} log_message message loging info.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LoggingApi
     */
    public log(log_message: LogMessage, options?: any) {
        return LoggingApiFp(this.configuration).log(log_message, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @summary Public endpoint for analytics logging
     * @param {AnalyticsEvent} analytics_event analytics event to log
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LoggingApi
     */
    public logAnalyticEvent(analytics_event: AnalyticsEvent, options?: any) {
        return LoggingApiFp(this.configuration).logAnalyticEvent(analytics_event, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @summary Public endpoint for logging front end errors
     * @param {ErrorReport} error_report Authentication info
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LoggingApi
     */
    public reportError(error_report: ErrorReport, options?: any) {
        return LoggingApiFp(this.configuration).reportError(error_report, options)(this.axios, this.basePath);
    }

}


/**
 * LoginApi - axios parameter creator
 * @export
 */
export const LoginApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Clinic user login
         * @param {AuthInfo} auth_info Authentication info
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        clinicLogin(auth_info: AuthInfo, options: any = {}): RequestArgs {
            // verify required parameter 'auth_info' is not null or undefined
            if (auth_info === null || auth_info === undefined) {
                throw new RequiredError('auth_info','Required parameter auth_info was null or undefined when calling clinicLogin.');
            }
            const localVarPath = `/clinic_user/login.json`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};
            const needsSerialization = (<any>"AuthInfo" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(auth_info !== undefined ? auth_info : {}) : (auth_info || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Clinic user logout
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        clinicLogout(options: any = {}): RequestArgs {
            const localVarPath = `/clinic_user/logout.json`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Login patient user returning login token
         * @param {AuthInfo} auth_info Authentication info
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        login(auth_info: AuthInfo, options: any = {}): RequestArgs {
            // verify required parameter 'auth_info' is not null or undefined
            if (auth_info === null || auth_info === undefined) {
                throw new RequiredError('auth_info','Required parameter auth_info was null or undefined when calling login.');
            }
            const localVarPath = `/patient/login.json`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};
            const needsSerialization = (<any>"AuthInfo" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(auth_info !== undefined ? auth_info : {}) : (auth_info || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * LoginApi - functional programming interface
 * @export
 */
export const LoginApiFp = function(configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Clinic user login
         * @param {AuthInfo} auth_info Authentication info
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        clinicLogin(auth_info: AuthInfo, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<ClinicUserLoginResponse> {
            const localVarAxiosArgs = LoginApiAxiosParamCreator(configuration).clinicLogin(auth_info, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Clinic user logout
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        clinicLogout(options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<void> {
            const localVarAxiosArgs = LoginApiAxiosParamCreator(configuration).clinicLogout(options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Login patient user returning login token
         * @param {AuthInfo} auth_info Authentication info
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        login(auth_info: AuthInfo, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<PatientLoginResponse> {
            const localVarAxiosArgs = LoginApiAxiosParamCreator(configuration).login(auth_info, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
    }
};

/**
 * LoginApi - factory interface
 * @export
 */
export const LoginApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    return {
        /**
         * 
         * @summary Clinic user login
         * @param {AuthInfo} auth_info Authentication info
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        clinicLogin(auth_info: AuthInfo, options?: any) {
            return LoginApiFp(configuration).clinicLogin(auth_info, options)(axios, basePath);
        },
        /**
         * 
         * @summary Clinic user logout
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        clinicLogout(options?: any) {
            return LoginApiFp(configuration).clinicLogout(options)(axios, basePath);
        },
        /**
         * 
         * @summary Login patient user returning login token
         * @param {AuthInfo} auth_info Authentication info
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        login(auth_info: AuthInfo, options?: any) {
            return LoginApiFp(configuration).login(auth_info, options)(axios, basePath);
        },
    };
};

/**
 * LoginApi - object-oriented interface
 * @export
 * @class LoginApi
 * @extends {BaseAPI}
 */
export class LoginApi extends BaseAPI {
    /**
     * 
     * @summary Clinic user login
     * @param {AuthInfo} auth_info Authentication info
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LoginApi
     */
    public clinicLogin(auth_info: AuthInfo, options?: any) {
        return LoginApiFp(this.configuration).clinicLogin(auth_info, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @summary Clinic user logout
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LoginApi
     */
    public clinicLogout(options?: any) {
        return LoginApiFp(this.configuration).clinicLogout(options)(this.axios, this.basePath);
    }

    /**
     * 
     * @summary Login patient user returning login token
     * @param {AuthInfo} auth_info Authentication info
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LoginApi
     */
    public login(auth_info: AuthInfo, options?: any) {
        return LoginApiFp(this.configuration).login(auth_info, options)(this.axios, this.basePath);
    }

}


/**
 * OpentokApi - axios parameter creator
 * @export
 */
export const OpentokApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Get OpenTok session ID for a specific patient/provider combo
         * @param {string} appointment_id Juno appointment id
         * @param {string} clinic_id Clinic the appointment is with
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        opentokSession(appointment_id: string, clinic_id: string, options: any = {}): RequestArgs {
            // verify required parameter 'appointment_id' is not null or undefined
            if (appointment_id === null || appointment_id === undefined) {
                throw new RequiredError('appointment_id','Required parameter appointment_id was null or undefined when calling opentokSession.');
            }
            // verify required parameter 'clinic_id' is not null or undefined
            if (clinic_id === null || clinic_id === undefined) {
                throw new RequiredError('clinic_id','Required parameter clinic_id was null or undefined when calling opentokSession.');
            }
            const localVarPath = `/patient/self/opentok/session.json`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication apiAuth required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? configuration.apiKey("X-Auth-Token")
                    : configuration.apiKey;
                localVarHeaderParameter["X-Auth-Token"] = localVarApiKeyValue;
            }

            if (appointment_id !== undefined) {
                localVarQueryParameter['appointment_id'] = appointment_id;
            }

            if (clinic_id !== undefined) {
                localVarQueryParameter['clinic_id'] = clinic_id;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get OpenTok token for a specific patient/provider session
         * @param {string} session_id TokBox Session id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        opentokSessionToken(session_id: string, options: any = {}): RequestArgs {
            // verify required parameter 'session_id' is not null or undefined
            if (session_id === null || session_id === undefined) {
                throw new RequiredError('session_id','Required parameter session_id was null or undefined when calling opentokSessionToken.');
            }
            const localVarPath = `/patient/self/opentok/session/{session_id}/token.json`
                .replace(`{${"session_id"}}`, encodeURIComponent(String(session_id)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication apiAuth required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? configuration.apiKey("X-Auth-Token")
                    : configuration.apiKey;
                localVarHeaderParameter["X-Auth-Token"] = localVarApiKeyValue;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * OpentokApi - functional programming interface
 * @export
 */
export const OpentokApiFp = function(configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Get OpenTok session ID for a specific patient/provider combo
         * @param {string} appointment_id Juno appointment id
         * @param {string} clinic_id Clinic the appointment is with
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        opentokSession(appointment_id: string, clinic_id: string, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<OpenTokSession> {
            const localVarAxiosArgs = OpentokApiAxiosParamCreator(configuration).opentokSession(appointment_id, clinic_id, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Get OpenTok token for a specific patient/provider session
         * @param {string} session_id TokBox Session id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        opentokSessionToken(session_id: string, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<OpenTokSessionToken> {
            const localVarAxiosArgs = OpentokApiAxiosParamCreator(configuration).opentokSessionToken(session_id, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
    }
};

/**
 * OpentokApi - factory interface
 * @export
 */
export const OpentokApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    return {
        /**
         * 
         * @summary Get OpenTok session ID for a specific patient/provider combo
         * @param {string} appointment_id Juno appointment id
         * @param {string} clinic_id Clinic the appointment is with
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        opentokSession(appointment_id: string, clinic_id: string, options?: any) {
            return OpentokApiFp(configuration).opentokSession(appointment_id, clinic_id, options)(axios, basePath);
        },
        /**
         * 
         * @summary Get OpenTok token for a specific patient/provider session
         * @param {string} session_id TokBox Session id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        opentokSessionToken(session_id: string, options?: any) {
            return OpentokApiFp(configuration).opentokSessionToken(session_id, options)(axios, basePath);
        },
    };
};

/**
 * OpentokApi - object-oriented interface
 * @export
 * @class OpentokApi
 * @extends {BaseAPI}
 */
export class OpentokApi extends BaseAPI {
    /**
     * 
     * @summary Get OpenTok session ID for a specific patient/provider combo
     * @param {string} appointment_id Juno appointment id
     * @param {string} clinic_id Clinic the appointment is with
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OpentokApi
     */
    public opentokSession(appointment_id: string, clinic_id: string, options?: any) {
        return OpentokApiFp(this.configuration).opentokSession(appointment_id, clinic_id, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @summary Get OpenTok token for a specific patient/provider session
     * @param {string} session_id TokBox Session id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OpentokApi
     */
    public opentokSessionToken(session_id: string, options?: any) {
        return OpentokApiFp(this.configuration).opentokSessionToken(session_id, options)(this.axios, this.basePath);
    }

}


/**
 * PatientApi - axios parameter creator
 * @export
 */
export const PatientApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Create a dependent profile record
         * @param {InlineObject7} inline_object7 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addDependent(inline_object7: InlineObject7, options: any = {}): RequestArgs {
            // verify required parameter 'inline_object7' is not null or undefined
            if (inline_object7 === null || inline_object7 === undefined) {
                throw new RequiredError('inline_object7','Required parameter inline_object7 was null or undefined when calling addDependent.');
            }
            const localVarPath = `/patient/self/dependent.json`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication apiAuth required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? configuration.apiKey("X-Auth-Token")
                    : configuration.apiKey;
                localVarHeaderParameter["X-Auth-Token"] = localVarApiKeyValue;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};
            const needsSerialization = (<any>"InlineObject7" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(inline_object7 !== undefined ? inline_object7 : {}) : (inline_object7 || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Record a new chat message for the appointment
         * @param {string} appointment_id Id of the appointment to which the chat message will be added
         * @param {TelehealthChatMessageDto} telehealth_chat_message_dto Message
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addPatientChatMessageToAppointment(appointment_id: string, telehealth_chat_message_dto: TelehealthChatMessageDto, options: any = {}): RequestArgs {
            // verify required parameter 'appointment_id' is not null or undefined
            if (appointment_id === null || appointment_id === undefined) {
                throw new RequiredError('appointment_id','Required parameter appointment_id was null or undefined when calling addPatientChatMessageToAppointment.');
            }
            // verify required parameter 'telehealth_chat_message_dto' is not null or undefined
            if (telehealth_chat_message_dto === null || telehealth_chat_message_dto === undefined) {
                throw new RequiredError('telehealth_chat_message_dto','Required parameter telehealth_chat_message_dto was null or undefined when calling addPatientChatMessageToAppointment.');
            }
            const localVarPath = `/patient/self/appointment/{appointment_id}/chat/message/`
                .replace(`{${"appointment_id"}}`, encodeURIComponent(String(appointment_id)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication apiAuth required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? configuration.apiKey("X-Auth-Token")
                    : configuration.apiKey;
                localVarHeaderParameter["X-Auth-Token"] = localVarApiKeyValue;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};
            const needsSerialization = (<any>"TelehealthChatMessageDto" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(telehealth_chat_message_dto !== undefined ? telehealth_chat_message_dto : {}) : (telehealth_chat_message_dto || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Archive messages by message ID
         * @param {number} message_id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        archiveMessages(message_id: number, options: any = {}): RequestArgs {
            // verify required parameter 'message_id' is not null or undefined
            if (message_id === null || message_id === undefined) {
                throw new RequiredError('message_id','Required parameter message_id was null or undefined when calling archiveMessages.');
            }
            const localVarPath = `/patient/self/mailbox/{message_id}/archive.json`
                .replace(`{${"message_id"}}`, encodeURIComponent(String(message_id)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication apiAuth required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? configuration.apiKey("X-Auth-Token")
                    : configuration.apiKey;
                localVarHeaderParameter["X-Auth-Token"] = localVarApiKeyValue;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Deactivate the logged in user\'s MyHealthAccess account
         * @param {InlineObject3} inline_object3 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deactivatePatient(inline_object3: InlineObject3, options: any = {}): RequestArgs {
            // verify required parameter 'inline_object3' is not null or undefined
            if (inline_object3 === null || inline_object3 === undefined) {
                throw new RequiredError('inline_object3','Required parameter inline_object3 was null or undefined when calling deactivatePatient.');
            }
            const localVarPath = `/patient/self/deactivate.json`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication apiAuth required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? configuration.apiKey("X-Auth-Token")
                    : configuration.apiKey;
                localVarHeaderParameter["X-Auth-Token"] = localVarApiKeyValue;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};
            const needsSerialization = (<any>"InlineObject3" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(inline_object3 !== undefined ? inline_object3 : {}) : (inline_object3 || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Generate new one time code
         * @param {OneTimeCodeDto} one_time_code_dto One time code.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        generatePatientOneTimeCode(one_time_code_dto: OneTimeCodeDto, options: any = {}): RequestArgs {
            // verify required parameter 'one_time_code_dto' is not null or undefined
            if (one_time_code_dto === null || one_time_code_dto === undefined) {
                throw new RequiredError('one_time_code_dto','Required parameter one_time_code_dto was null or undefined when calling generatePatientOneTimeCode.');
            }
            const localVarPath = `/patient/self/one_time_code/`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication apiAuth required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? configuration.apiKey("X-Auth-Token")
                    : configuration.apiKey;
                localVarHeaderParameter["X-Auth-Token"] = localVarApiKeyValue;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};
            const needsSerialization = (<any>"OneTimeCodeDto" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(one_time_code_dto !== undefined ? one_time_code_dto : {}) : (one_time_code_dto || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get a specific appointment
         * @param {string} clinic_id ID of the clinic this appointment belongs to
         * @param {string} local_id Appointment to get info for
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAppointment(clinic_id: string, local_id: string, options: any = {}): RequestArgs {
            // verify required parameter 'clinic_id' is not null or undefined
            if (clinic_id === null || clinic_id === undefined) {
                throw new RequiredError('clinic_id','Required parameter clinic_id was null or undefined when calling getAppointment.');
            }
            // verify required parameter 'local_id' is not null or undefined
            if (local_id === null || local_id === undefined) {
                throw new RequiredError('local_id','Required parameter local_id was null or undefined when calling getAppointment.');
            }
            const localVarPath = `/patient/self/clinic/{clinic_id}/appointment/{local_id}.json`
                .replace(`{${"clinic_id"}}`, encodeURIComponent(String(clinic_id)))
                .replace(`{${"local_id"}}`, encodeURIComponent(String(local_id)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication apiAuth required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? configuration.apiKey("X-Auth-Token")
                    : configuration.apiKey;
                localVarHeaderParameter["X-Auth-Token"] = localVarApiKeyValue;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get clinic info for the specified clinic
         * @param {string} clinic_id 
         * @param {boolean} [required_fields] Whether to return required connection fields for the requested clinic
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getClinic(clinic_id: string, required_fields?: boolean, options: any = {}): RequestArgs {
            // verify required parameter 'clinic_id' is not null or undefined
            if (clinic_id === null || clinic_id === undefined) {
                throw new RequiredError('clinic_id','Required parameter clinic_id was null or undefined when calling getClinic.');
            }
            const localVarPath = `/patient/self/clinics/{clinic_id}/info.json`
                .replace(`{${"clinic_id"}}`, encodeURIComponent(String(clinic_id)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication apiAuth required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? configuration.apiKey("X-Auth-Token")
                    : configuration.apiKey;
                localVarHeaderParameter["X-Auth-Token"] = localVarApiKeyValue;
            }

            if (required_fields !== undefined) {
                localVarQueryParameter['required_fields'] = required_fields;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get CloudMD clinic profile for the current patient
         * @param {ProvinceCode} [province_code] Whether to return required connection fields for the requested clinic
         * @param {boolean} [required_fields] Whether to return required connection fields for the requested clinic
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCloudMdClinic(province_code?: ProvinceCode, required_fields?: boolean, options: any = {}): RequestArgs {
            const localVarPath = `/patient/self/clinics/get_cloud_md.json`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication apiAuth required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? configuration.apiKey("X-Auth-Token")
                    : configuration.apiKey;
                localVarHeaderParameter["X-Auth-Token"] = localVarApiKeyValue;
            }

            if (province_code !== undefined) {
                localVarQueryParameter['province_code'] = province_code;
            }

            if (required_fields !== undefined) {
                localVarQueryParameter['required_fields'] = required_fields;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary List the users dependents
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDependents(options: any = {}): RequestArgs {
            const localVarPath = `/patient/self/dependents.json`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication apiAuth required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? configuration.apiKey("X-Auth-Token")
                    : configuration.apiKey;
                localVarHeaderParameter["X-Auth-Token"] = localVarApiKeyValue;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get all chat messages for this appointment
         * @param {string} appointment_id Id of the appointment
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPatientAppointmentChatMessages(appointment_id: string, options: any = {}): RequestArgs {
            // verify required parameter 'appointment_id' is not null or undefined
            if (appointment_id === null || appointment_id === undefined) {
                throw new RequiredError('appointment_id','Required parameter appointment_id was null or undefined when calling getPatientAppointmentChatMessages.');
            }
            const localVarPath = `/patient/self/appointment/{appointment_id}/chat/messages/`
                .replace(`{${"appointment_id"}}`, encodeURIComponent(String(appointment_id)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication apiAuth required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? configuration.apiKey("X-Auth-Token")
                    : configuration.apiKey;
                localVarHeaderParameter["X-Auth-Token"] = localVarApiKeyValue;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get clinic appointment for patient
         * @param {string} appointment_id The patient appointment id to get the clinic appointment for.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPatientClinicAppointment(appointment_id: string, options: any = {}): RequestArgs {
            // verify required parameter 'appointment_id' is not null or undefined
            if (appointment_id === null || appointment_id === undefined) {
                throw new RequiredError('appointment_id','Required parameter appointment_id was null or undefined when calling getPatientClinicAppointment.');
            }
            const localVarPath = `/patient/self/appointment/{appointment_id}/`
                .replace(`{${"appointment_id"}}`, encodeURIComponent(String(appointment_id)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication apiAuth required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? configuration.apiKey("X-Auth-Token")
                    : configuration.apiKey;
                localVarHeaderParameter["X-Auth-Token"] = localVarApiKeyValue;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary get a conversation
         * @param {number} conversation_id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPatientConversation(conversation_id: number, options: any = {}): RequestArgs {
            // verify required parameter 'conversation_id' is not null or undefined
            if (conversation_id === null || conversation_id === undefined) {
                throw new RequiredError('conversation_id','Required parameter conversation_id was null or undefined when calling getPatientConversation.');
            }
            const localVarPath = `/patient/self/mailbox/view/{conversation_id}`
                .replace(`{${"conversation_id"}}`, encodeURIComponent(String(conversation_id)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication apiAuth required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? configuration.apiKey("X-Auth-Token")
                    : configuration.apiKey;
                localVarHeaderParameter["X-Auth-Token"] = localVarApiKeyValue;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get a list of non expired one time codes for the patient
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPatientOneTimeCodes(options: any = {}): RequestArgs {
            const localVarPath = `/patient/self/one_time_codes/`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication apiAuth required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? configuration.apiKey("X-Auth-Token")
                    : configuration.apiKey;
                localVarHeaderParameter["X-Auth-Token"] = localVarApiKeyValue;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Generate a new patient token for the specified telehealth appointment
         * @param {string} clinic_id clinic id
         * @param {string} remote_id Oscar appointment_no
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPatientTeleHealthSession(clinic_id: string, remote_id: string, options: any = {}): RequestArgs {
            // verify required parameter 'clinic_id' is not null or undefined
            if (clinic_id === null || clinic_id === undefined) {
                throw new RequiredError('clinic_id','Required parameter clinic_id was null or undefined when calling getPatientTeleHealthSession.');
            }
            // verify required parameter 'remote_id' is not null or undefined
            if (remote_id === null || remote_id === undefined) {
                throw new RequiredError('remote_id','Required parameter remote_id was null or undefined when calling getPatientTeleHealthSession.');
            }
            const localVarPath = `/patient/self/clinic/{clinic_id}/appointment/{remote_id}/telehealth/register`
                .replace(`{${"clinic_id"}}`, encodeURIComponent(String(clinic_id)))
                .replace(`{${"remote_id"}}`, encodeURIComponent(String(remote_id)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication apiAuth required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? configuration.apiKey("X-Auth-Token")
                    : configuration.apiKey;
                localVarHeaderParameter["X-Auth-Token"] = localVarApiKeyValue;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Telehealth appointment session data
         * @param {string} appointment_id Appointment the telehealth session is for
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPatientTelehealthSessionData(appointment_id: string, options: any = {}): RequestArgs {
            // verify required parameter 'appointment_id' is not null or undefined
            if (appointment_id === null || appointment_id === undefined) {
                throw new RequiredError('appointment_id','Required parameter appointment_id was null or undefined when calling getPatientTelehealthSessionData.');
            }
            const localVarPath = `/patient/self/appointment/{appointment_id}/session`
                .replace(`{${"appointment_id"}}`, encodeURIComponent(String(appointment_id)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication apiAuth required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? configuration.apiKey("X-Auth-Token")
                    : configuration.apiKey;
                localVarHeaderParameter["X-Auth-Token"] = localVarApiKeyValue;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get one time token for this remote appointment
         * @param {string} clinic_id Clinic
         * @param {string} remote_id Remote appointment id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getRemoteAppointmentOneTimeToken(clinic_id: string, remote_id: string, options: any = {}): RequestArgs {
            // verify required parameter 'clinic_id' is not null or undefined
            if (clinic_id === null || clinic_id === undefined) {
                throw new RequiredError('clinic_id','Required parameter clinic_id was null or undefined when calling getRemoteAppointmentOneTimeToken.');
            }
            // verify required parameter 'remote_id' is not null or undefined
            if (remote_id === null || remote_id === undefined) {
                throw new RequiredError('remote_id','Required parameter remote_id was null or undefined when calling getRemoteAppointmentOneTimeToken.');
            }
            const localVarPath = `/patient/self/clinic/{clinic_id}/remote/appointment/{remote_id}/one_time_token`
                .replace(`{${"clinic_id"}}`, encodeURIComponent(String(clinic_id)))
                .replace(`{${"remote_id"}}`, encodeURIComponent(String(remote_id)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication apiAuth required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? configuration.apiKey("X-Auth-Token")
                    : configuration.apiKey;
                localVarHeaderParameter["X-Auth-Token"] = localVarApiKeyValue;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Link a user with a clinic
         * @param {string} clinic_id clinic profile uuid
         * @param {ClinicConnectionRequest} clinic_connection_request patient request object
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        linkWithClinic(clinic_id: string, clinic_connection_request: ClinicConnectionRequest, options: any = {}): RequestArgs {
            // verify required parameter 'clinic_id' is not null or undefined
            if (clinic_id === null || clinic_id === undefined) {
                throw new RequiredError('clinic_id','Required parameter clinic_id was null or undefined when calling linkWithClinic.');
            }
            // verify required parameter 'clinic_connection_request' is not null or undefined
            if (clinic_connection_request === null || clinic_connection_request === undefined) {
                throw new RequiredError('clinic_connection_request','Required parameter clinic_connection_request was null or undefined when calling linkWithClinic.');
            }
            const localVarPath = `/patient/self/clinic/{clinic_id}/link.json`
                .replace(`{${"clinic_id"}}`, encodeURIComponent(String(clinic_id)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication apiAuth required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? configuration.apiKey("X-Auth-Token")
                    : configuration.apiKey;
                localVarHeaderParameter["X-Auth-Token"] = localVarApiKeyValue;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};
            const needsSerialization = (<any>"ClinicConnectionRequest" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(clinic_connection_request !== undefined ? clinic_connection_request : {}) : (clinic_connection_request || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Log a telehealth session event
         * @param {string} appointment_id Appointment whos telehealth session is going to be logged to
         * @param {TelehealthLogEventDto} telehealth_log_event_dto Log event data
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        logEventToPatientTelehealthSession(appointment_id: string, telehealth_log_event_dto: TelehealthLogEventDto, options: any = {}): RequestArgs {
            // verify required parameter 'appointment_id' is not null or undefined
            if (appointment_id === null || appointment_id === undefined) {
                throw new RequiredError('appointment_id','Required parameter appointment_id was null or undefined when calling logEventToPatientTelehealthSession.');
            }
            // verify required parameter 'telehealth_log_event_dto' is not null or undefined
            if (telehealth_log_event_dto === null || telehealth_log_event_dto === undefined) {
                throw new RequiredError('telehealth_log_event_dto','Required parameter telehealth_log_event_dto was null or undefined when calling logEventToPatientTelehealthSession.');
            }
            const localVarPath = `/patient/self/appointment/{appointment_id}/session/log_event`
                .replace(`{${"appointment_id"}}`, encodeURIComponent(String(appointment_id)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication apiAuth required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? configuration.apiKey("X-Auth-Token")
                    : configuration.apiKey;
                localVarHeaderParameter["X-Auth-Token"] = localVarApiKeyValue;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};
            const needsSerialization = (<any>"TelehealthLogEventDto" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(telehealth_log_event_dto !== undefined ? telehealth_log_event_dto : {}) : (telehealth_log_event_dto || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get current logged in patient
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        loggedInPatient(options: any = {}): RequestArgs {
            const localVarPath = `/patient/self.json`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication apiAuth required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? configuration.apiKey("X-Auth-Token")
                    : configuration.apiKey;
                localVarHeaderParameter["X-Auth-Token"] = localVarApiKeyValue;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary post to endpoint on login success
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        loginSuccess(options: any = {}): RequestArgs {
            const localVarPath = `/patient/self/login_success`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication apiAuth required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? configuration.apiKey("X-Auth-Token")
                    : configuration.apiKey;
                localVarHeaderParameter["X-Auth-Token"] = localVarApiKeyValue;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get a list of patient appointments
         * @param {string} [start_date] Start of end range (inclusive)
         * @param {string} [end_date] End of date range (inclusive)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        patientAppointments(start_date?: string, end_date?: string, options: any = {}): RequestArgs {
            const localVarPath = `/patient/self/appointments.json`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication apiAuth required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? configuration.apiKey("X-Auth-Token")
                    : configuration.apiKey;
                localVarHeaderParameter["X-Auth-Token"] = localVarApiKeyValue;
            }

            if (start_date !== undefined) {
                localVarQueryParameter['start_date'] = (start_date as any instanceof Date) ?
                    (start_date as any).toISOString().substr(0,10) :
                    start_date;
            }

            if (end_date !== undefined) {
                localVarQueryParameter['end_date'] = (end_date as any instanceof Date) ?
                    (end_date as any).toISOString().substr(0,10) :
                    end_date;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Book an appointment
         * @param {string} clinic_id Clinic to book appointment slot for
         * @param {BookAppointmentTransfer} book_appointment_transfer Appointment slot
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        patientClinicAppointmentBook(clinic_id: string, book_appointment_transfer: BookAppointmentTransfer, options: any = {}): RequestArgs {
            // verify required parameter 'clinic_id' is not null or undefined
            if (clinic_id === null || clinic_id === undefined) {
                throw new RequiredError('clinic_id','Required parameter clinic_id was null or undefined when calling patientClinicAppointmentBook.');
            }
            // verify required parameter 'book_appointment_transfer' is not null or undefined
            if (book_appointment_transfer === null || book_appointment_transfer === undefined) {
                throw new RequiredError('book_appointment_transfer','Required parameter book_appointment_transfer was null or undefined when calling patientClinicAppointmentBook.');
            }
            const localVarPath = `/patient/self/clinic/{clinic_id}/appointment/book.json`
                .replace(`{${"clinic_id"}}`, encodeURIComponent(String(clinic_id)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication apiAuth required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? configuration.apiKey("X-Auth-Token")
                    : configuration.apiKey;
                localVarHeaderParameter["X-Auth-Token"] = localVarApiKeyValue;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};
            const needsSerialization = (<any>"BookAppointmentTransfer" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(book_appointment_transfer !== undefined ? book_appointment_transfer : {}) : (book_appointment_transfer || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Cancel an appointment
         * @param {string} clinic_id Clinic to cancel appointment slot for
         * @param {string} id Juno appointment ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        patientClinicAppointmentCancel(clinic_id: string, id: string, options: any = {}): RequestArgs {
            // verify required parameter 'clinic_id' is not null or undefined
            if (clinic_id === null || clinic_id === undefined) {
                throw new RequiredError('clinic_id','Required parameter clinic_id was null or undefined when calling patientClinicAppointmentCancel.');
            }
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling patientClinicAppointmentCancel.');
            }
            const localVarPath = `/patient/self/clinic/{clinic_id}/appointment/{id}/cancel.json`
                .replace(`{${"clinic_id"}}`, encodeURIComponent(String(clinic_id)))
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication apiAuth required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? configuration.apiKey("X-Auth-Token")
                    : configuration.apiKey;
                localVarHeaderParameter["X-Auth-Token"] = localVarApiKeyValue;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Reschedule an appointment
         * @param {string} clinic_id Clinic to book appointment slot for
         * @param {string} appointment_id Juno appointment ID
         * @param {BookAppointmentTransfer} book_appointment_transfer Appointment slot
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        patientClinicAppointmentReschedule(clinic_id: string, appointment_id: string, book_appointment_transfer: BookAppointmentTransfer, options: any = {}): RequestArgs {
            // verify required parameter 'clinic_id' is not null or undefined
            if (clinic_id === null || clinic_id === undefined) {
                throw new RequiredError('clinic_id','Required parameter clinic_id was null or undefined when calling patientClinicAppointmentReschedule.');
            }
            // verify required parameter 'appointment_id' is not null or undefined
            if (appointment_id === null || appointment_id === undefined) {
                throw new RequiredError('appointment_id','Required parameter appointment_id was null or undefined when calling patientClinicAppointmentReschedule.');
            }
            // verify required parameter 'book_appointment_transfer' is not null or undefined
            if (book_appointment_transfer === null || book_appointment_transfer === undefined) {
                throw new RequiredError('book_appointment_transfer','Required parameter book_appointment_transfer was null or undefined when calling patientClinicAppointmentReschedule.');
            }
            const localVarPath = `/patient/self/clinic/{clinic_id}/appointment/{appointment_id}/reschedule.json`
                .replace(`{${"clinic_id"}}`, encodeURIComponent(String(clinic_id)))
                .replace(`{${"appointment_id"}}`, encodeURIComponent(String(appointment_id)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication apiAuth required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? configuration.apiKey("X-Auth-Token")
                    : configuration.apiKey;
                localVarHeaderParameter["X-Auth-Token"] = localVarApiKeyValue;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};
            const needsSerialization = (<any>"BookAppointmentTransfer" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(book_appointment_transfer !== undefined ? book_appointment_transfer : {}) : (book_appointment_transfer || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get appointment type for a clinic
         * @param {string} clinic_id Clinic to get providers for
         * @param {string} appointment_type_id Specified appointment type
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        patientClinicAppointmentType(clinic_id: string, appointment_type_id: string, options: any = {}): RequestArgs {
            // verify required parameter 'clinic_id' is not null or undefined
            if (clinic_id === null || clinic_id === undefined) {
                throw new RequiredError('clinic_id','Required parameter clinic_id was null or undefined when calling patientClinicAppointmentType.');
            }
            // verify required parameter 'appointment_type_id' is not null or undefined
            if (appointment_type_id === null || appointment_type_id === undefined) {
                throw new RequiredError('appointment_type_id','Required parameter appointment_type_id was null or undefined when calling patientClinicAppointmentType.');
            }
            const localVarPath = `/patient/self/clinic/{clinic_id}/appointment_types/{appointment_type_id}.json`
                .replace(`{${"clinic_id"}}`, encodeURIComponent(String(clinic_id)))
                .replace(`{${"appointment_type_id"}}`, encodeURIComponent(String(appointment_type_id)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication apiAuth required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? configuration.apiKey("X-Auth-Token")
                    : configuration.apiKey;
                localVarHeaderParameter["X-Auth-Token"] = localVarApiKeyValue;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get list of appointment types for a clinic
         * @param {string} clinic_id Clinic to get providers for
         * @param {string} provider_id Provider to view appointment types for
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        patientClinicAppointmentTypes(clinic_id: string, provider_id: string, options: any = {}): RequestArgs {
            // verify required parameter 'clinic_id' is not null or undefined
            if (clinic_id === null || clinic_id === undefined) {
                throw new RequiredError('clinic_id','Required parameter clinic_id was null or undefined when calling patientClinicAppointmentTypes.');
            }
            // verify required parameter 'provider_id' is not null or undefined
            if (provider_id === null || provider_id === undefined) {
                throw new RequiredError('provider_id','Required parameter provider_id was null or undefined when calling patientClinicAppointmentTypes.');
            }
            const localVarPath = `/patient/self/clinic/{clinic_id}/appointment_types`
                .replace(`{${"clinic_id"}}`, encodeURIComponent(String(clinic_id)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication apiAuth required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? configuration.apiKey("X-Auth-Token")
                    : configuration.apiKey;
                localVarHeaderParameter["X-Auth-Token"] = localVarApiKeyValue;
            }

            if (provider_id !== undefined) {
                localVarQueryParameter['provider_id'] = provider_id;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get a list of patient appointments for a specific clinic
         * @param {string} clinic_id Clinic to get appointment slot list for
         * @param {string} [start_date] Start of end range (inclusive)
         * @param {string} [end_date] End of date range (inclusive)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        patientClinicAppointments(clinic_id: string, start_date?: string, end_date?: string, options: any = {}): RequestArgs {
            // verify required parameter 'clinic_id' is not null or undefined
            if (clinic_id === null || clinic_id === undefined) {
                throw new RequiredError('clinic_id','Required parameter clinic_id was null or undefined when calling patientClinicAppointments.');
            }
            const localVarPath = `/patient/self/clinic/{clinic_id}/appointments.json`
                .replace(`{${"clinic_id"}}`, encodeURIComponent(String(clinic_id)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication apiAuth required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? configuration.apiKey("X-Auth-Token")
                    : configuration.apiKey;
                localVarHeaderParameter["X-Auth-Token"] = localVarApiKeyValue;
            }

            if (start_date !== undefined) {
                localVarQueryParameter['start_date'] = (start_date as any instanceof Date) ?
                    (start_date as any).toISOString().substr(0,10) :
                    start_date;
            }

            if (end_date !== undefined) {
                localVarQueryParameter['end_date'] = (end_date as any instanceof Date) ?
                    (end_date as any).toISOString().substr(0,10) :
                    end_date;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get a list of slots available for booking
         * @param {string} clinic_id Clinic to get appointment slot list for
         * @param {string} provider_id Provider to get appointment slot list for
         * @param {string} appointment_type_id Appointment type to get slot list for
         * @param {string} start_date Start of end range (inclusive)
         * @param {string} end_date End of date range (inclusive)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        patientClinicAvailableAppointments(clinic_id: string, provider_id: string, appointment_type_id: string, start_date: string, end_date: string, options: any = {}): RequestArgs {
            // verify required parameter 'clinic_id' is not null or undefined
            if (clinic_id === null || clinic_id === undefined) {
                throw new RequiredError('clinic_id','Required parameter clinic_id was null or undefined when calling patientClinicAvailableAppointments.');
            }
            // verify required parameter 'provider_id' is not null or undefined
            if (provider_id === null || provider_id === undefined) {
                throw new RequiredError('provider_id','Required parameter provider_id was null or undefined when calling patientClinicAvailableAppointments.');
            }
            // verify required parameter 'appointment_type_id' is not null or undefined
            if (appointment_type_id === null || appointment_type_id === undefined) {
                throw new RequiredError('appointment_type_id','Required parameter appointment_type_id was null or undefined when calling patientClinicAvailableAppointments.');
            }
            // verify required parameter 'start_date' is not null or undefined
            if (start_date === null || start_date === undefined) {
                throw new RequiredError('start_date','Required parameter start_date was null or undefined when calling patientClinicAvailableAppointments.');
            }
            // verify required parameter 'end_date' is not null or undefined
            if (end_date === null || end_date === undefined) {
                throw new RequiredError('end_date','Required parameter end_date was null or undefined when calling patientClinicAvailableAppointments.');
            }
            const localVarPath = `/patient/self/clinic/{clinic_id}/appointments/available.json`
                .replace(`{${"clinic_id"}}`, encodeURIComponent(String(clinic_id)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication apiAuth required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? configuration.apiKey("X-Auth-Token")
                    : configuration.apiKey;
                localVarHeaderParameter["X-Auth-Token"] = localVarApiKeyValue;
            }

            if (provider_id !== undefined) {
                localVarQueryParameter['provider_id'] = provider_id;
            }

            if (appointment_type_id !== undefined) {
                localVarQueryParameter['appointment_type_id'] = appointment_type_id;
            }

            if (start_date !== undefined) {
                localVarQueryParameter['start_date'] = (start_date as any instanceof Date) ?
                    (start_date as any).toISOString().substr(0,10) :
                    start_date;
            }

            if (end_date !== undefined) {
                localVarQueryParameter['end_date'] = (end_date as any instanceof Date) ?
                    (end_date as any).toISOString().substr(0,10) :
                    end_date;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get single provider record
         * @param {string} clinic_id Clinic provider belongs to
         * @param {string} provider_id Provider to get info for
         * @param {boolean} [is_remote] Find provider based on remote_id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        patientClinicProvider(clinic_id: string, provider_id: string, is_remote?: boolean, options: any = {}): RequestArgs {
            // verify required parameter 'clinic_id' is not null or undefined
            if (clinic_id === null || clinic_id === undefined) {
                throw new RequiredError('clinic_id','Required parameter clinic_id was null or undefined when calling patientClinicProvider.');
            }
            // verify required parameter 'provider_id' is not null or undefined
            if (provider_id === null || provider_id === undefined) {
                throw new RequiredError('provider_id','Required parameter provider_id was null or undefined when calling patientClinicProvider.');
            }
            const localVarPath = `/patient/self/clinic/{clinic_id}/providers/{provider_id}/info.json`
                .replace(`{${"clinic_id"}}`, encodeURIComponent(String(clinic_id)))
                .replace(`{${"provider_id"}}`, encodeURIComponent(String(provider_id)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication apiAuth required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? configuration.apiKey("X-Auth-Token")
                    : configuration.apiKey;
                localVarHeaderParameter["X-Auth-Token"] = localVarApiKeyValue;
            }

            if (is_remote !== undefined) {
                localVarQueryParameter['is_remote'] = is_remote;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get a provider group for a clinic
         * @param {string} clinic_id Clinic to get the provider group from
         * @param {string} provider_group_id Specified provider group
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        patientClinicProviderGroup(clinic_id: string, provider_group_id: string, options: any = {}): RequestArgs {
            // verify required parameter 'clinic_id' is not null or undefined
            if (clinic_id === null || clinic_id === undefined) {
                throw new RequiredError('clinic_id','Required parameter clinic_id was null or undefined when calling patientClinicProviderGroup.');
            }
            // verify required parameter 'provider_group_id' is not null or undefined
            if (provider_group_id === null || provider_group_id === undefined) {
                throw new RequiredError('provider_group_id','Required parameter provider_group_id was null or undefined when calling patientClinicProviderGroup.');
            }
            const localVarPath = `/patient/self/clinic/{clinic_id}/provider_group/{provider_group_id}.json`
                .replace(`{${"clinic_id"}}`, encodeURIComponent(String(clinic_id)))
                .replace(`{${"provider_group_id"}}`, encodeURIComponent(String(provider_group_id)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication apiAuth required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? configuration.apiKey("X-Auth-Token")
                    : configuration.apiKey;
                localVarHeaderParameter["X-Auth-Token"] = localVarApiKeyValue;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get list of available providers from a provider group for this patient
         * @param {string} clinic_id Clinic to get provider groups for
         * @param {string} provider_group_id The group to get the providers from
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        patientClinicProviderGroupProviders(clinic_id: string, provider_group_id: string, options: any = {}): RequestArgs {
            // verify required parameter 'clinic_id' is not null or undefined
            if (clinic_id === null || clinic_id === undefined) {
                throw new RequiredError('clinic_id','Required parameter clinic_id was null or undefined when calling patientClinicProviderGroupProviders.');
            }
            // verify required parameter 'provider_group_id' is not null or undefined
            if (provider_group_id === null || provider_group_id === undefined) {
                throw new RequiredError('provider_group_id','Required parameter provider_group_id was null or undefined when calling patientClinicProviderGroupProviders.');
            }
            const localVarPath = `/patient/self/clinic/{clinic_id}/provider_group/{provider_group_id}/providers.json`
                .replace(`{${"clinic_id"}}`, encodeURIComponent(String(clinic_id)))
                .replace(`{${"provider_group_id"}}`, encodeURIComponent(String(provider_group_id)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication apiAuth required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? configuration.apiKey("X-Auth-Token")
                    : configuration.apiKey;
                localVarHeaderParameter["X-Auth-Token"] = localVarApiKeyValue;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get list of available clinic provider groups for this patient
         * @param {string} clinic_id Clinic to get provider groups for
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        patientClinicProviderGroups(clinic_id: string, options: any = {}): RequestArgs {
            // verify required parameter 'clinic_id' is not null or undefined
            if (clinic_id === null || clinic_id === undefined) {
                throw new RequiredError('clinic_id','Required parameter clinic_id was null or undefined when calling patientClinicProviderGroups.');
            }
            const localVarPath = `/patient/self/clinic/{clinic_id}/provider_groups.json`
                .replace(`{${"clinic_id"}}`, encodeURIComponent(String(clinic_id)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication apiAuth required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? configuration.apiKey("X-Auth-Token")
                    : configuration.apiKey;
                localVarHeaderParameter["X-Auth-Token"] = localVarApiKeyValue;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get list of available clinic provider groups that a provider is in
         * @param {string} clinic_id Clinic to get provider groups for
         * @param {string} provider_id The id of the provider to get groups for
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        patientClinicProviderGroupsByProvider(clinic_id: string, provider_id: string, options: any = {}): RequestArgs {
            // verify required parameter 'clinic_id' is not null or undefined
            if (clinic_id === null || clinic_id === undefined) {
                throw new RequiredError('clinic_id','Required parameter clinic_id was null or undefined when calling patientClinicProviderGroupsByProvider.');
            }
            // verify required parameter 'provider_id' is not null or undefined
            if (provider_id === null || provider_id === undefined) {
                throw new RequiredError('provider_id','Required parameter provider_id was null or undefined when calling patientClinicProviderGroupsByProvider.');
            }
            const localVarPath = `/patient/self/clinic/{clinic_id}/provider/{provider_id}/groups.json`
                .replace(`{${"clinic_id"}}`, encodeURIComponent(String(clinic_id)))
                .replace(`{${"provider_id"}}`, encodeURIComponent(String(provider_id)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication apiAuth required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? configuration.apiKey("X-Auth-Token")
                    : configuration.apiKey;
                localVarHeaderParameter["X-Auth-Token"] = localVarApiKeyValue;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get list of available clinics for this patient
         * @param {string} clinic_id Clinic to get providers for
         * @param {boolean} [bookable_only] Only show bookable appointments
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        patientClinicProviders(clinic_id: string, bookable_only?: boolean, options: any = {}): RequestArgs {
            // verify required parameter 'clinic_id' is not null or undefined
            if (clinic_id === null || clinic_id === undefined) {
                throw new RequiredError('clinic_id','Required parameter clinic_id was null or undefined when calling patientClinicProviders.');
            }
            const localVarPath = `/patient/self/clinic/{clinic_id}/providers.json`
                .replace(`{${"clinic_id"}}`, encodeURIComponent(String(clinic_id)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication apiAuth required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? configuration.apiKey("X-Auth-Token")
                    : configuration.apiKey;
                localVarHeaderParameter["X-Auth-Token"] = localVarApiKeyValue;
            }

            if (bookable_only !== undefined) {
                localVarQueryParameter['bookable_only'] = bookable_only;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get list of available clinics for this patient
         * @param {boolean} [bookable_only] Only show bookable appointments
         * @param {boolean} [messageable_only] Only return clinics this patient can message
         * @param {boolean} [available_only] Only return clinics that are avaiable to link with this patient
         * @param {boolean} [connected_only] Only return clinics that are connected to this patient
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        patientClinics(bookable_only?: boolean, messageable_only?: boolean, available_only?: boolean, connected_only?: boolean, options: any = {}): RequestArgs {
            const localVarPath = `/patient/self/clinics.json`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication apiAuth required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? configuration.apiKey("X-Auth-Token")
                    : configuration.apiKey;
                localVarHeaderParameter["X-Auth-Token"] = localVarApiKeyValue;
            }

            if (bookable_only !== undefined) {
                localVarQueryParameter['bookable_only'] = bookable_only;
            }

            if (messageable_only !== undefined) {
                localVarQueryParameter['messageable_only'] = messageable_only;
            }

            if (available_only !== undefined) {
                localVarQueryParameter['available_only'] = available_only;
            }

            if (connected_only !== undefined) {
                localVarQueryParameter['connected_only'] = connected_only;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary get a document
         * @param {string} document_id document id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        patientGetDocument(document_id: string, options: any = {}): RequestArgs {
            // verify required parameter 'document_id' is not null or undefined
            if (document_id === null || document_id === undefined) {
                throw new RequiredError('document_id','Required parameter document_id was null or undefined when calling patientGetDocument.');
            }
            const localVarPath = `/patient/self/document/{document_id}`
                .replace(`{${"document_id"}}`, encodeURIComponent(String(document_id)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication apiAuth required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? configuration.apiKey("X-Auth-Token")
                    : configuration.apiKey;
                localVarHeaderParameter["X-Auth-Token"] = localVarApiKeyValue;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get the message count for a specific mailbox
         * @param {string} mailbox 
         * @param {boolean} unread_only 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        patientMessageCount(mailbox: string, unread_only: boolean, options: any = {}): RequestArgs {
            // verify required parameter 'mailbox' is not null or undefined
            if (mailbox === null || mailbox === undefined) {
                throw new RequiredError('mailbox','Required parameter mailbox was null or undefined when calling patientMessageCount.');
            }
            // verify required parameter 'unread_only' is not null or undefined
            if (unread_only === null || unread_only === undefined) {
                throw new RequiredError('unread_only','Required parameter unread_only was null or undefined when calling patientMessageCount.');
            }
            const localVarPath = `/patient/self/mailbox/count`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication apiAuth required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? configuration.apiKey("X-Auth-Token")
                    : configuration.apiKey;
                localVarHeaderParameter["X-Auth-Token"] = localVarApiKeyValue;
            }

            if (mailbox !== undefined) {
                localVarQueryParameter['mailbox'] = mailbox;
            }

            if (unread_only !== undefined) {
                localVarQueryParameter['unread_only'] = unread_only;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get all messages for a specific patient
         * @param {string} mailbox 
         * @param {number} offset 
         * @param {number} limit 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        patientMessages(mailbox: string, offset: number, limit: number, options: any = {}): RequestArgs {
            // verify required parameter 'mailbox' is not null or undefined
            if (mailbox === null || mailbox === undefined) {
                throw new RequiredError('mailbox','Required parameter mailbox was null or undefined when calling patientMessages.');
            }
            // verify required parameter 'offset' is not null or undefined
            if (offset === null || offset === undefined) {
                throw new RequiredError('offset','Required parameter offset was null or undefined when calling patientMessages.');
            }
            // verify required parameter 'limit' is not null or undefined
            if (limit === null || limit === undefined) {
                throw new RequiredError('limit','Required parameter limit was null or undefined when calling patientMessages.');
            }
            const localVarPath = `/patient/self/mailbox.json`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication apiAuth required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? configuration.apiKey("X-Auth-Token")
                    : configuration.apiKey;
                localVarHeaderParameter["X-Auth-Token"] = localVarApiKeyValue;
            }

            if (mailbox !== undefined) {
                localVarQueryParameter['mailbox'] = mailbox;
            }

            if (offset !== undefined) {
                localVarQueryParameter['offset'] = offset;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get a list of patient clinic appointmenst
         * @param {string} [start_datetime] the date after which appointments will be returned
         * @param {string} [end_datetime] the date before which appointments will be returned
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        patientMhaClinicAppointments(start_datetime?: string, end_datetime?: string, options: any = {}): RequestArgs {
            const localVarPath = `/patient/self/clinic_appointments`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication apiAuth required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? configuration.apiKey("X-Auth-Token")
                    : configuration.apiKey;
                localVarHeaderParameter["X-Auth-Token"] = localVarApiKeyValue;
            }

            if (start_datetime !== undefined) {
                localVarQueryParameter['start_datetime'] = start_datetime;
            }

            if (end_datetime !== undefined) {
                localVarQueryParameter['end_datetime'] = end_datetime;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Add a new document.
         * @param {DocumentCreateTransfer} document_create_transfer Document create transfer
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        patientUploadDocument(document_create_transfer: DocumentCreateTransfer, options: any = {}): RequestArgs {
            // verify required parameter 'document_create_transfer' is not null or undefined
            if (document_create_transfer === null || document_create_transfer === undefined) {
                throw new RequiredError('document_create_transfer','Required parameter document_create_transfer was null or undefined when calling patientUploadDocument.');
            }
            const localVarPath = `/patient/self/document/`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication apiAuth required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? configuration.apiKey("X-Auth-Token")
                    : configuration.apiKey;
                localVarHeaderParameter["X-Auth-Token"] = localVarApiKeyValue;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};
            const needsSerialization = (<any>"DocumentCreateTransfer" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(document_create_transfer !== undefined ? document_create_transfer : {}) : (document_create_transfer || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Ping telehealth session
         * @param {string} appointment_id Appointment that the telehealth session is for
         * @param {TelehealthPingSessionDto} telehealth_ping_session_dto Ping session data
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        pingPatientTelehealthSession(appointment_id: string, telehealth_ping_session_dto: TelehealthPingSessionDto, options: any = {}): RequestArgs {
            // verify required parameter 'appointment_id' is not null or undefined
            if (appointment_id === null || appointment_id === undefined) {
                throw new RequiredError('appointment_id','Required parameter appointment_id was null or undefined when calling pingPatientTelehealthSession.');
            }
            // verify required parameter 'telehealth_ping_session_dto' is not null or undefined
            if (telehealth_ping_session_dto === null || telehealth_ping_session_dto === undefined) {
                throw new RequiredError('telehealth_ping_session_dto','Required parameter telehealth_ping_session_dto was null or undefined when calling pingPatientTelehealthSession.');
            }
            const localVarPath = `/patient/self/appointment/{appointment_id}/session/ping`
                .replace(`{${"appointment_id"}}`, encodeURIComponent(String(appointment_id)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication apiAuth required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? configuration.apiKey("X-Auth-Token")
                    : configuration.apiKey;
                localVarHeaderParameter["X-Auth-Token"] = localVarApiKeyValue;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};
            const needsSerialization = (<any>"TelehealthPingSessionDto" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(telehealth_ping_session_dto !== undefined ? telehealth_ping_session_dto : {}) : (telehealth_ping_session_dto || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Send a message to a clinic
         * @param {string} clinic_id 
         * @param {MessagePost} message_post Message data
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        sendMessage(clinic_id: string, message_post: MessagePost, options: any = {}): RequestArgs {
            // verify required parameter 'clinic_id' is not null or undefined
            if (clinic_id === null || clinic_id === undefined) {
                throw new RequiredError('clinic_id','Required parameter clinic_id was null or undefined when calling sendMessage.');
            }
            // verify required parameter 'message_post' is not null or undefined
            if (message_post === null || message_post === undefined) {
                throw new RequiredError('message_post','Required parameter message_post was null or undefined when calling sendMessage.');
            }
            const localVarPath = `/patient/self/mailbox/send/clinic/{clinic_id}`
                .replace(`{${"clinic_id"}}`, encodeURIComponent(String(clinic_id)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication apiAuth required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? configuration.apiKey("X-Auth-Token")
                    : configuration.apiKey;
                localVarHeaderParameter["X-Auth-Token"] = localVarApiKeyValue;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};
            const needsSerialization = (<any>"MessagePost" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(message_post !== undefined ? message_post : {}) : (message_post || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Set mailbox messages to read
         * @param {string} mailbox 
         * @param {InlineObject6} inline_object6 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        setReadMail(mailbox: string, inline_object6: InlineObject6, options: any = {}): RequestArgs {
            // verify required parameter 'mailbox' is not null or undefined
            if (mailbox === null || mailbox === undefined) {
                throw new RequiredError('mailbox','Required parameter mailbox was null or undefined when calling setReadMail.');
            }
            // verify required parameter 'inline_object6' is not null or undefined
            if (inline_object6 === null || inline_object6 === undefined) {
                throw new RequiredError('inline_object6','Required parameter inline_object6 was null or undefined when calling setReadMail.');
            }
            const localVarPath = `/patient/self/mailbox/set_read`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication apiAuth required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? configuration.apiKey("X-Auth-Token")
                    : configuration.apiKey;
                localVarHeaderParameter["X-Auth-Token"] = localVarApiKeyValue;
            }

            if (mailbox !== undefined) {
                localVarQueryParameter['mailbox'] = mailbox;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};
            const needsSerialization = (<any>"InlineObject6" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(inline_object6 !== undefined ? inline_object6 : {}) : (inline_object6 || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Unarchive messages by message ID
         * @param {number} message_id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        unArchiveMessages(message_id: number, options: any = {}): RequestArgs {
            // verify required parameter 'message_id' is not null or undefined
            if (message_id === null || message_id === undefined) {
                throw new RequiredError('message_id','Required parameter message_id was null or undefined when calling unArchiveMessages.');
            }
            const localVarPath = `/patient/self/mailbox/{message_id}/unarchive.json`
                .replace(`{${"message_id"}}`, encodeURIComponent(String(message_id)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication apiAuth required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? configuration.apiKey("X-Auth-Token")
                    : configuration.apiKey;
                localVarHeaderParameter["X-Auth-Token"] = localVarApiKeyValue;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Unlink a user with a clinic
         * @param {string} clinic_id clinic profile uuid
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        unlinkWithClinic(clinic_id: string, options: any = {}): RequestArgs {
            // verify required parameter 'clinic_id' is not null or undefined
            if (clinic_id === null || clinic_id === undefined) {
                throw new RequiredError('clinic_id','Required parameter clinic_id was null or undefined when calling unlinkWithClinic.');
            }
            const localVarPath = `/patient/self/clinic/{clinic_id}/unlink.json`
                .replace(`{${"clinic_id"}}`, encodeURIComponent(String(clinic_id)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication apiAuth required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? configuration.apiKey("X-Auth-Token")
                    : configuration.apiKey;
                localVarHeaderParameter["X-Auth-Token"] = localVarApiKeyValue;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary update account credentials
         * @param {InlineObject4} inline_object4 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateAccount(inline_object4: InlineObject4, options: any = {}): RequestArgs {
            // verify required parameter 'inline_object4' is not null or undefined
            if (inline_object4 === null || inline_object4 === undefined) {
                throw new RequiredError('inline_object4','Required parameter inline_object4 was null or undefined when calling updateAccount.');
            }
            const localVarPath = `/patient/self/account.json`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication apiAuth required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? configuration.apiKey("X-Auth-Token")
                    : configuration.apiKey;
                localVarHeaderParameter["X-Auth-Token"] = localVarApiKeyValue;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};
            const needsSerialization = (<any>"InlineObject4" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(inline_object4 !== undefined ? inline_object4 : {}) : (inline_object4 || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update current logged in patient
         * @param {InlineObject1} inline_object1 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updatePatient(inline_object1: InlineObject1, options: any = {}): RequestArgs {
            // verify required parameter 'inline_object1' is not null or undefined
            if (inline_object1 === null || inline_object1 === undefined) {
                throw new RequiredError('inline_object1','Required parameter inline_object1 was null or undefined when calling updatePatient.');
            }
            const localVarPath = `/patient/self.json`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication apiAuth required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? configuration.apiKey("X-Auth-Token")
                    : configuration.apiKey;
                localVarHeaderParameter["X-Auth-Token"] = localVarApiKeyValue;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};
            const needsSerialization = (<any>"InlineObject1" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(inline_object1 !== undefined ? inline_object1 : {}) : (inline_object1 || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update notifications settings for the logged in user
         * @param {InlineObject2} inline_object2 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updatePatientNotificationSettings(inline_object2: InlineObject2, options: any = {}): RequestArgs {
            // verify required parameter 'inline_object2' is not null or undefined
            if (inline_object2 === null || inline_object2 === undefined) {
                throw new RequiredError('inline_object2','Required parameter inline_object2 was null or undefined when calling updatePatientNotificationSettings.');
            }
            const localVarPath = `/patient/self/account/notifications.json`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication apiAuth required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? configuration.apiKey("X-Auth-Token")
                    : configuration.apiKey;
                localVarHeaderParameter["X-Auth-Token"] = localVarApiKeyValue;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};
            const needsSerialization = (<any>"InlineObject2" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(inline_object2 !== undefined ? inline_object2 : {}) : (inline_object2 || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update clinic appointment
         * @param {string} appointment_id Id of the appointment to update
         * @param {ClinicAppointmentTransfer} clinic_appointment_transfer appointment
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateTelehealthAppointment(appointment_id: string, clinic_appointment_transfer: ClinicAppointmentTransfer, options: any = {}): RequestArgs {
            // verify required parameter 'appointment_id' is not null or undefined
            if (appointment_id === null || appointment_id === undefined) {
                throw new RequiredError('appointment_id','Required parameter appointment_id was null or undefined when calling updateTelehealthAppointment.');
            }
            // verify required parameter 'clinic_appointment_transfer' is not null or undefined
            if (clinic_appointment_transfer === null || clinic_appointment_transfer === undefined) {
                throw new RequiredError('clinic_appointment_transfer','Required parameter clinic_appointment_transfer was null or undefined when calling updateTelehealthAppointment.');
            }
            const localVarPath = `/patient/self/appointment/{appointment_id}/`
                .replace(`{${"appointment_id"}}`, encodeURIComponent(String(appointment_id)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication apiAuth required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? configuration.apiKey("X-Auth-Token")
                    : configuration.apiKey;
                localVarHeaderParameter["X-Auth-Token"] = localVarApiKeyValue;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};
            const needsSerialization = (<any>"ClinicAppointmentTransfer" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(clinic_appointment_transfer !== undefined ? clinic_appointment_transfer : {}) : (clinic_appointment_transfer || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Find clinics within range of patient based on coordinates
         * @param {number} [latitude] 
         * @param {number} [longitude] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        viewableClinicsInCoordRange(latitude?: number, longitude?: number, options: any = {}): RequestArgs {
            const localVarPath = `/patient/self/clinics/find/coordinates`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication apiAuth required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? configuration.apiKey("X-Auth-Token")
                    : configuration.apiKey;
                localVarHeaderParameter["X-Auth-Token"] = localVarApiKeyValue;
            }

            if (latitude !== undefined) {
                localVarQueryParameter['latitude'] = latitude;
            }

            if (longitude !== undefined) {
                localVarQueryParameter['longitude'] = longitude;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Viewable clinics in location range
         * @param {string} [province_code] 
         * @param {string} [city] 
         * @param {string} [address] 
         * @param {string} [postal_code] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        viewableClinicsInLocRange(province_code?: string, city?: string, address?: string, postal_code?: string, options: any = {}): RequestArgs {
            const localVarPath = `/patient/self/clinics/find/location`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication apiAuth required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? configuration.apiKey("X-Auth-Token")
                    : configuration.apiKey;
                localVarHeaderParameter["X-Auth-Token"] = localVarApiKeyValue;
            }

            if (province_code !== undefined) {
                localVarQueryParameter['province_code'] = province_code;
            }

            if (city !== undefined) {
                localVarQueryParameter['city'] = city;
            }

            if (address !== undefined) {
                localVarQueryParameter['address'] = address;
            }

            if (postal_code !== undefined) {
                localVarQueryParameter['postal_code'] = postal_code;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * PatientApi - functional programming interface
 * @export
 */
export const PatientApiFp = function(configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Create a dependent profile record
         * @param {InlineObject7} inline_object7 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addDependent(inline_object7: InlineObject7, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<PatientUserDto> {
            const localVarAxiosArgs = PatientApiAxiosParamCreator(configuration).addDependent(inline_object7, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Record a new chat message for the appointment
         * @param {string} appointment_id Id of the appointment to which the chat message will be added
         * @param {TelehealthChatMessageDto} telehealth_chat_message_dto Message
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addPatientChatMessageToAppointment(appointment_id: string, telehealth_chat_message_dto: TelehealthChatMessageDto, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<TelehealthChatMessageDto> {
            const localVarAxiosArgs = PatientApiAxiosParamCreator(configuration).addPatientChatMessageToAppointment(appointment_id, telehealth_chat_message_dto, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Archive messages by message ID
         * @param {number} message_id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        archiveMessages(message_id: number, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<AppointmentBookSuccess> {
            const localVarAxiosArgs = PatientApiAxiosParamCreator(configuration).archiveMessages(message_id, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Deactivate the logged in user\'s MyHealthAccess account
         * @param {InlineObject3} inline_object3 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deactivatePatient(inline_object3: InlineObject3, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<PatientAccountStatus> {
            const localVarAxiosArgs = PatientApiAxiosParamCreator(configuration).deactivatePatient(inline_object3, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Generate new one time code
         * @param {OneTimeCodeDto} one_time_code_dto One time code.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        generatePatientOneTimeCode(one_time_code_dto: OneTimeCodeDto, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<OneTimeCodeDto> {
            const localVarAxiosArgs = PatientApiAxiosParamCreator(configuration).generatePatientOneTimeCode(one_time_code_dto, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Get a specific appointment
         * @param {string} clinic_id ID of the clinic this appointment belongs to
         * @param {string} local_id Appointment to get info for
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAppointment(clinic_id: string, local_id: string, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<AppointmentTransfer> {
            const localVarAxiosArgs = PatientApiAxiosParamCreator(configuration).getAppointment(clinic_id, local_id, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Get clinic info for the specified clinic
         * @param {string} clinic_id 
         * @param {boolean} [required_fields] Whether to return required connection fields for the requested clinic
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getClinic(clinic_id: string, required_fields?: boolean, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<Clinic> {
            const localVarAxiosArgs = PatientApiAxiosParamCreator(configuration).getClinic(clinic_id, required_fields, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Get CloudMD clinic profile for the current patient
         * @param {ProvinceCode} [province_code] Whether to return required connection fields for the requested clinic
         * @param {boolean} [required_fields] Whether to return required connection fields for the requested clinic
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCloudMdClinic(province_code?: ProvinceCode, required_fields?: boolean, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<InlineResponse2003> {
            const localVarAxiosArgs = PatientApiAxiosParamCreator(configuration).getCloudMdClinic(province_code, required_fields, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary List the users dependents
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDependents(options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<PatientUserDto>> {
            const localVarAxiosArgs = PatientApiAxiosParamCreator(configuration).getDependents(options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Get all chat messages for this appointment
         * @param {string} appointment_id Id of the appointment
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPatientAppointmentChatMessages(appointment_id: string, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<TelehealthChatMessageDto>> {
            const localVarAxiosArgs = PatientApiAxiosParamCreator(configuration).getPatientAppointmentChatMessages(appointment_id, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Get clinic appointment for patient
         * @param {string} appointment_id The patient appointment id to get the clinic appointment for.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPatientClinicAppointment(appointment_id: string, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<ClinicAppointmentTransfer> {
            const localVarAxiosArgs = PatientApiAxiosParamCreator(configuration).getPatientClinicAppointment(appointment_id, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary get a conversation
         * @param {number} conversation_id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPatientConversation(conversation_id: number, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<ConversationDto> {
            const localVarAxiosArgs = PatientApiAxiosParamCreator(configuration).getPatientConversation(conversation_id, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Get a list of non expired one time codes for the patient
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPatientOneTimeCodes(options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<OneTimeCodeDto>> {
            const localVarAxiosArgs = PatientApiAxiosParamCreator(configuration).getPatientOneTimeCodes(options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Generate a new patient token for the specified telehealth appointment
         * @param {string} clinic_id clinic id
         * @param {string} remote_id Oscar appointment_no
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPatientTeleHealthSession(clinic_id: string, remote_id: string, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<OTSessionResponse> {
            const localVarAxiosArgs = PatientApiAxiosParamCreator(configuration).getPatientTeleHealthSession(clinic_id, remote_id, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Telehealth appointment session data
         * @param {string} appointment_id Appointment the telehealth session is for
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPatientTelehealthSessionData(appointment_id: string, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<OTSessionInfo> {
            const localVarAxiosArgs = PatientApiAxiosParamCreator(configuration).getPatientTelehealthSessionData(appointment_id, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Get one time token for this remote appointment
         * @param {string} clinic_id Clinic
         * @param {string} remote_id Remote appointment id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getRemoteAppointmentOneTimeToken(clinic_id: string, remote_id: string, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<OneTimeTelehealthTokenDto> {
            const localVarAxiosArgs = PatientApiAxiosParamCreator(configuration).getRemoteAppointmentOneTimeToken(clinic_id, remote_id, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Link a user with a clinic
         * @param {string} clinic_id clinic profile uuid
         * @param {ClinicConnectionRequest} clinic_connection_request patient request object
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        linkWithClinic(clinic_id: string, clinic_connection_request: ClinicConnectionRequest, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<InlineResponse2003> {
            const localVarAxiosArgs = PatientApiAxiosParamCreator(configuration).linkWithClinic(clinic_id, clinic_connection_request, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Log a telehealth session event
         * @param {string} appointment_id Appointment whos telehealth session is going to be logged to
         * @param {TelehealthLogEventDto} telehealth_log_event_dto Log event data
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        logEventToPatientTelehealthSession(appointment_id: string, telehealth_log_event_dto: TelehealthLogEventDto, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<void> {
            const localVarAxiosArgs = PatientApiAxiosParamCreator(configuration).logEventToPatientTelehealthSession(appointment_id, telehealth_log_event_dto, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Get current logged in patient
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        loggedInPatient(options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<PatientLoginResponse> {
            const localVarAxiosArgs = PatientApiAxiosParamCreator(configuration).loggedInPatient(options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary post to endpoint on login success
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        loginSuccess(options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<void> {
            const localVarAxiosArgs = PatientApiAxiosParamCreator(configuration).loginSuccess(options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Get a list of patient appointments
         * @param {string} [start_date] Start of end range (inclusive)
         * @param {string} [end_date] End of date range (inclusive)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        patientAppointments(start_date?: string, end_date?: string, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<AppointmentTransfer>> {
            const localVarAxiosArgs = PatientApiAxiosParamCreator(configuration).patientAppointments(start_date, end_date, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Book an appointment
         * @param {string} clinic_id Clinic to book appointment slot for
         * @param {BookAppointmentTransfer} book_appointment_transfer Appointment slot
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        patientClinicAppointmentBook(clinic_id: string, book_appointment_transfer: BookAppointmentTransfer, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<AppointmentBookSuccess> {
            const localVarAxiosArgs = PatientApiAxiosParamCreator(configuration).patientClinicAppointmentBook(clinic_id, book_appointment_transfer, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Cancel an appointment
         * @param {string} clinic_id Clinic to cancel appointment slot for
         * @param {string} id Juno appointment ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        patientClinicAppointmentCancel(clinic_id: string, id: string, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<AppointmentCancelSuccess> {
            const localVarAxiosArgs = PatientApiAxiosParamCreator(configuration).patientClinicAppointmentCancel(clinic_id, id, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Reschedule an appointment
         * @param {string} clinic_id Clinic to book appointment slot for
         * @param {string} appointment_id Juno appointment ID
         * @param {BookAppointmentTransfer} book_appointment_transfer Appointment slot
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        patientClinicAppointmentReschedule(clinic_id: string, appointment_id: string, book_appointment_transfer: BookAppointmentTransfer, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<AppointmentBookSuccess> {
            const localVarAxiosArgs = PatientApiAxiosParamCreator(configuration).patientClinicAppointmentReschedule(clinic_id, appointment_id, book_appointment_transfer, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Get appointment type for a clinic
         * @param {string} clinic_id Clinic to get providers for
         * @param {string} appointment_type_id Specified appointment type
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        patientClinicAppointmentType(clinic_id: string, appointment_type_id: string, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<AppointmentType> {
            const localVarAxiosArgs = PatientApiAxiosParamCreator(configuration).patientClinicAppointmentType(clinic_id, appointment_type_id, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Get list of appointment types for a clinic
         * @param {string} clinic_id Clinic to get providers for
         * @param {string} provider_id Provider to view appointment types for
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        patientClinicAppointmentTypes(clinic_id: string, provider_id: string, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<AppointmentType>> {
            const localVarAxiosArgs = PatientApiAxiosParamCreator(configuration).patientClinicAppointmentTypes(clinic_id, provider_id, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Get a list of patient appointments for a specific clinic
         * @param {string} clinic_id Clinic to get appointment slot list for
         * @param {string} [start_date] Start of end range (inclusive)
         * @param {string} [end_date] End of date range (inclusive)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        patientClinicAppointments(clinic_id: string, start_date?: string, end_date?: string, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<AppointmentTransfer>> {
            const localVarAxiosArgs = PatientApiAxiosParamCreator(configuration).patientClinicAppointments(clinic_id, start_date, end_date, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Get a list of slots available for booking
         * @param {string} clinic_id Clinic to get appointment slot list for
         * @param {string} provider_id Provider to get appointment slot list for
         * @param {string} appointment_type_id Appointment type to get slot list for
         * @param {string} start_date Start of end range (inclusive)
         * @param {string} end_date End of date range (inclusive)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        patientClinicAvailableAppointments(clinic_id: string, provider_id: string, appointment_type_id: string, start_date: string, end_date: string, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<AppointmentTransfer>> {
            const localVarAxiosArgs = PatientApiAxiosParamCreator(configuration).patientClinicAvailableAppointments(clinic_id, provider_id, appointment_type_id, start_date, end_date, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Get single provider record
         * @param {string} clinic_id Clinic provider belongs to
         * @param {string} provider_id Provider to get info for
         * @param {boolean} [is_remote] Find provider based on remote_id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        patientClinicProvider(clinic_id: string, provider_id: string, is_remote?: boolean, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<ProviderTransfer> {
            const localVarAxiosArgs = PatientApiAxiosParamCreator(configuration).patientClinicProvider(clinic_id, provider_id, is_remote, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Get a provider group for a clinic
         * @param {string} clinic_id Clinic to get the provider group from
         * @param {string} provider_group_id Specified provider group
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        patientClinicProviderGroup(clinic_id: string, provider_group_id: string, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<ProviderGroupTransfer> {
            const localVarAxiosArgs = PatientApiAxiosParamCreator(configuration).patientClinicProviderGroup(clinic_id, provider_group_id, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Get list of available providers from a provider group for this patient
         * @param {string} clinic_id Clinic to get provider groups for
         * @param {string} provider_group_id The group to get the providers from
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        patientClinicProviderGroupProviders(clinic_id: string, provider_group_id: string, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<Providers> {
            const localVarAxiosArgs = PatientApiAxiosParamCreator(configuration).patientClinicProviderGroupProviders(clinic_id, provider_group_id, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Get list of available clinic provider groups for this patient
         * @param {string} clinic_id Clinic to get provider groups for
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        patientClinicProviderGroups(clinic_id: string, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<ProviderGroups> {
            const localVarAxiosArgs = PatientApiAxiosParamCreator(configuration).patientClinicProviderGroups(clinic_id, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Get list of available clinic provider groups that a provider is in
         * @param {string} clinic_id Clinic to get provider groups for
         * @param {string} provider_id The id of the provider to get groups for
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        patientClinicProviderGroupsByProvider(clinic_id: string, provider_id: string, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<ProviderGroups> {
            const localVarAxiosArgs = PatientApiAxiosParamCreator(configuration).patientClinicProviderGroupsByProvider(clinic_id, provider_id, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Get list of available clinics for this patient
         * @param {string} clinic_id Clinic to get providers for
         * @param {boolean} [bookable_only] Only show bookable appointments
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        patientClinicProviders(clinic_id: string, bookable_only?: boolean, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<Providers> {
            const localVarAxiosArgs = PatientApiAxiosParamCreator(configuration).patientClinicProviders(clinic_id, bookable_only, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Get list of available clinics for this patient
         * @param {boolean} [bookable_only] Only show bookable appointments
         * @param {boolean} [messageable_only] Only return clinics this patient can message
         * @param {boolean} [available_only] Only return clinics that are avaiable to link with this patient
         * @param {boolean} [connected_only] Only return clinics that are connected to this patient
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        patientClinics(bookable_only?: boolean, messageable_only?: boolean, available_only?: boolean, connected_only?: boolean, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<Clinics> {
            const localVarAxiosArgs = PatientApiAxiosParamCreator(configuration).patientClinics(bookable_only, messageable_only, available_only, connected_only, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary get a document
         * @param {string} document_id document id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        patientGetDocument(document_id: string, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<DocumentTransfer> {
            const localVarAxiosArgs = PatientApiAxiosParamCreator(configuration).patientGetDocument(document_id, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Get the message count for a specific mailbox
         * @param {string} mailbox 
         * @param {boolean} unread_only 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        patientMessageCount(mailbox: string, unread_only: boolean, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<InlineResponse2002> {
            const localVarAxiosArgs = PatientApiAxiosParamCreator(configuration).patientMessageCount(mailbox, unread_only, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Get all messages for a specific patient
         * @param {string} mailbox 
         * @param {number} offset 
         * @param {number} limit 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        patientMessages(mailbox: string, offset: number, limit: number, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<InboxMessage>> {
            const localVarAxiosArgs = PatientApiAxiosParamCreator(configuration).patientMessages(mailbox, offset, limit, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Get a list of patient clinic appointmenst
         * @param {string} [start_datetime] the date after which appointments will be returned
         * @param {string} [end_datetime] the date before which appointments will be returned
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        patientMhaClinicAppointments(start_datetime?: string, end_datetime?: string, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<ClinicAppointmentTransfer>> {
            const localVarAxiosArgs = PatientApiAxiosParamCreator(configuration).patientMhaClinicAppointments(start_datetime, end_datetime, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Add a new document.
         * @param {DocumentCreateTransfer} document_create_transfer Document create transfer
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        patientUploadDocument(document_create_transfer: DocumentCreateTransfer, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<DocumentTransfer> {
            const localVarAxiosArgs = PatientApiAxiosParamCreator(configuration).patientUploadDocument(document_create_transfer, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Ping telehealth session
         * @param {string} appointment_id Appointment that the telehealth session is for
         * @param {TelehealthPingSessionDto} telehealth_ping_session_dto Ping session data
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        pingPatientTelehealthSession(appointment_id: string, telehealth_ping_session_dto: TelehealthPingSessionDto, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<void> {
            const localVarAxiosArgs = PatientApiAxiosParamCreator(configuration).pingPatientTelehealthSession(appointment_id, telehealth_ping_session_dto, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Send a message to a clinic
         * @param {string} clinic_id 
         * @param {MessagePost} message_post Message data
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        sendMessage(clinic_id: string, message_post: MessagePost, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<AppointmentBookSuccess> {
            const localVarAxiosArgs = PatientApiAxiosParamCreator(configuration).sendMessage(clinic_id, message_post, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Set mailbox messages to read
         * @param {string} mailbox 
         * @param {InlineObject6} inline_object6 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        setReadMail(mailbox: string, inline_object6: InlineObject6, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<AppointmentBookSuccess> {
            const localVarAxiosArgs = PatientApiAxiosParamCreator(configuration).setReadMail(mailbox, inline_object6, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Unarchive messages by message ID
         * @param {number} message_id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        unArchiveMessages(message_id: number, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<AppointmentBookSuccess> {
            const localVarAxiosArgs = PatientApiAxiosParamCreator(configuration).unArchiveMessages(message_id, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Unlink a user with a clinic
         * @param {string} clinic_id clinic profile uuid
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        unlinkWithClinic(clinic_id: string, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<InlineResponse2003> {
            const localVarAxiosArgs = PatientApiAxiosParamCreator(configuration).unlinkWithClinic(clinic_id, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary update account credentials
         * @param {InlineObject4} inline_object4 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateAccount(inline_object4: InlineObject4, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<void> {
            const localVarAxiosArgs = PatientApiAxiosParamCreator(configuration).updateAccount(inline_object4, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Update current logged in patient
         * @param {InlineObject1} inline_object1 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updatePatient(inline_object1: InlineObject1, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<PatientUserDto> {
            const localVarAxiosArgs = PatientApiAxiosParamCreator(configuration).updatePatient(inline_object1, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Update notifications settings for the logged in user
         * @param {InlineObject2} inline_object2 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updatePatientNotificationSettings(inline_object2: InlineObject2, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<PatientNotificationSettings> {
            const localVarAxiosArgs = PatientApiAxiosParamCreator(configuration).updatePatientNotificationSettings(inline_object2, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Update clinic appointment
         * @param {string} appointment_id Id of the appointment to update
         * @param {ClinicAppointmentTransfer} clinic_appointment_transfer appointment
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateTelehealthAppointment(appointment_id: string, clinic_appointment_transfer: ClinicAppointmentTransfer, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<void> {
            const localVarAxiosArgs = PatientApiAxiosParamCreator(configuration).updateTelehealthAppointment(appointment_id, clinic_appointment_transfer, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Find clinics within range of patient based on coordinates
         * @param {number} [latitude] 
         * @param {number} [longitude] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        viewableClinicsInCoordRange(latitude?: number, longitude?: number, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<Clinic>> {
            const localVarAxiosArgs = PatientApiAxiosParamCreator(configuration).viewableClinicsInCoordRange(latitude, longitude, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Viewable clinics in location range
         * @param {string} [province_code] 
         * @param {string} [city] 
         * @param {string} [address] 
         * @param {string} [postal_code] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        viewableClinicsInLocRange(province_code?: string, city?: string, address?: string, postal_code?: string, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<Clinic>> {
            const localVarAxiosArgs = PatientApiAxiosParamCreator(configuration).viewableClinicsInLocRange(province_code, city, address, postal_code, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
    }
};

/**
 * PatientApi - factory interface
 * @export
 */
export const PatientApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    return {
        /**
         * 
         * @summary Create a dependent profile record
         * @param {InlineObject7} inline_object7 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addDependent(inline_object7: InlineObject7, options?: any) {
            return PatientApiFp(configuration).addDependent(inline_object7, options)(axios, basePath);
        },
        /**
         * 
         * @summary Record a new chat message for the appointment
         * @param {string} appointment_id Id of the appointment to which the chat message will be added
         * @param {TelehealthChatMessageDto} telehealth_chat_message_dto Message
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addPatientChatMessageToAppointment(appointment_id: string, telehealth_chat_message_dto: TelehealthChatMessageDto, options?: any) {
            return PatientApiFp(configuration).addPatientChatMessageToAppointment(appointment_id, telehealth_chat_message_dto, options)(axios, basePath);
        },
        /**
         * 
         * @summary Archive messages by message ID
         * @param {number} message_id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        archiveMessages(message_id: number, options?: any) {
            return PatientApiFp(configuration).archiveMessages(message_id, options)(axios, basePath);
        },
        /**
         * 
         * @summary Deactivate the logged in user\'s MyHealthAccess account
         * @param {InlineObject3} inline_object3 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deactivatePatient(inline_object3: InlineObject3, options?: any) {
            return PatientApiFp(configuration).deactivatePatient(inline_object3, options)(axios, basePath);
        },
        /**
         * 
         * @summary Generate new one time code
         * @param {OneTimeCodeDto} one_time_code_dto One time code.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        generatePatientOneTimeCode(one_time_code_dto: OneTimeCodeDto, options?: any) {
            return PatientApiFp(configuration).generatePatientOneTimeCode(one_time_code_dto, options)(axios, basePath);
        },
        /**
         * 
         * @summary Get a specific appointment
         * @param {string} clinic_id ID of the clinic this appointment belongs to
         * @param {string} local_id Appointment to get info for
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAppointment(clinic_id: string, local_id: string, options?: any) {
            return PatientApiFp(configuration).getAppointment(clinic_id, local_id, options)(axios, basePath);
        },
        /**
         * 
         * @summary Get clinic info for the specified clinic
         * @param {string} clinic_id 
         * @param {boolean} [required_fields] Whether to return required connection fields for the requested clinic
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getClinic(clinic_id: string, required_fields?: boolean, options?: any) {
            return PatientApiFp(configuration).getClinic(clinic_id, required_fields, options)(axios, basePath);
        },
        /**
         * 
         * @summary Get CloudMD clinic profile for the current patient
         * @param {ProvinceCode} [province_code] Whether to return required connection fields for the requested clinic
         * @param {boolean} [required_fields] Whether to return required connection fields for the requested clinic
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCloudMdClinic(province_code?: ProvinceCode, required_fields?: boolean, options?: any) {
            return PatientApiFp(configuration).getCloudMdClinic(province_code, required_fields, options)(axios, basePath);
        },
        /**
         * 
         * @summary List the users dependents
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDependents(options?: any) {
            return PatientApiFp(configuration).getDependents(options)(axios, basePath);
        },
        /**
         * 
         * @summary Get all chat messages for this appointment
         * @param {string} appointment_id Id of the appointment
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPatientAppointmentChatMessages(appointment_id: string, options?: any) {
            return PatientApiFp(configuration).getPatientAppointmentChatMessages(appointment_id, options)(axios, basePath);
        },
        /**
         * 
         * @summary Get clinic appointment for patient
         * @param {string} appointment_id The patient appointment id to get the clinic appointment for.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPatientClinicAppointment(appointment_id: string, options?: any) {
            return PatientApiFp(configuration).getPatientClinicAppointment(appointment_id, options)(axios, basePath);
        },
        /**
         * 
         * @summary get a conversation
         * @param {number} conversation_id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPatientConversation(conversation_id: number, options?: any) {
            return PatientApiFp(configuration).getPatientConversation(conversation_id, options)(axios, basePath);
        },
        /**
         * 
         * @summary Get a list of non expired one time codes for the patient
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPatientOneTimeCodes(options?: any) {
            return PatientApiFp(configuration).getPatientOneTimeCodes(options)(axios, basePath);
        },
        /**
         * 
         * @summary Generate a new patient token for the specified telehealth appointment
         * @param {string} clinic_id clinic id
         * @param {string} remote_id Oscar appointment_no
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPatientTeleHealthSession(clinic_id: string, remote_id: string, options?: any) {
            return PatientApiFp(configuration).getPatientTeleHealthSession(clinic_id, remote_id, options)(axios, basePath);
        },
        /**
         * 
         * @summary Telehealth appointment session data
         * @param {string} appointment_id Appointment the telehealth session is for
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPatientTelehealthSessionData(appointment_id: string, options?: any) {
            return PatientApiFp(configuration).getPatientTelehealthSessionData(appointment_id, options)(axios, basePath);
        },
        /**
         * 
         * @summary Get one time token for this remote appointment
         * @param {string} clinic_id Clinic
         * @param {string} remote_id Remote appointment id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getRemoteAppointmentOneTimeToken(clinic_id: string, remote_id: string, options?: any) {
            return PatientApiFp(configuration).getRemoteAppointmentOneTimeToken(clinic_id, remote_id, options)(axios, basePath);
        },
        /**
         * 
         * @summary Link a user with a clinic
         * @param {string} clinic_id clinic profile uuid
         * @param {ClinicConnectionRequest} clinic_connection_request patient request object
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        linkWithClinic(clinic_id: string, clinic_connection_request: ClinicConnectionRequest, options?: any) {
            return PatientApiFp(configuration).linkWithClinic(clinic_id, clinic_connection_request, options)(axios, basePath);
        },
        /**
         * 
         * @summary Log a telehealth session event
         * @param {string} appointment_id Appointment whos telehealth session is going to be logged to
         * @param {TelehealthLogEventDto} telehealth_log_event_dto Log event data
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        logEventToPatientTelehealthSession(appointment_id: string, telehealth_log_event_dto: TelehealthLogEventDto, options?: any) {
            return PatientApiFp(configuration).logEventToPatientTelehealthSession(appointment_id, telehealth_log_event_dto, options)(axios, basePath);
        },
        /**
         * 
         * @summary Get current logged in patient
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        loggedInPatient(options?: any) {
            return PatientApiFp(configuration).loggedInPatient(options)(axios, basePath);
        },
        /**
         * 
         * @summary post to endpoint on login success
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        loginSuccess(options?: any) {
            return PatientApiFp(configuration).loginSuccess(options)(axios, basePath);
        },
        /**
         * 
         * @summary Get a list of patient appointments
         * @param {string} [start_date] Start of end range (inclusive)
         * @param {string} [end_date] End of date range (inclusive)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        patientAppointments(start_date?: string, end_date?: string, options?: any) {
            return PatientApiFp(configuration).patientAppointments(start_date, end_date, options)(axios, basePath);
        },
        /**
         * 
         * @summary Book an appointment
         * @param {string} clinic_id Clinic to book appointment slot for
         * @param {BookAppointmentTransfer} book_appointment_transfer Appointment slot
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        patientClinicAppointmentBook(clinic_id: string, book_appointment_transfer: BookAppointmentTransfer, options?: any) {
            return PatientApiFp(configuration).patientClinicAppointmentBook(clinic_id, book_appointment_transfer, options)(axios, basePath);
        },
        /**
         * 
         * @summary Cancel an appointment
         * @param {string} clinic_id Clinic to cancel appointment slot for
         * @param {string} id Juno appointment ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        patientClinicAppointmentCancel(clinic_id: string, id: string, options?: any) {
            return PatientApiFp(configuration).patientClinicAppointmentCancel(clinic_id, id, options)(axios, basePath);
        },
        /**
         * 
         * @summary Reschedule an appointment
         * @param {string} clinic_id Clinic to book appointment slot for
         * @param {string} appointment_id Juno appointment ID
         * @param {BookAppointmentTransfer} book_appointment_transfer Appointment slot
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        patientClinicAppointmentReschedule(clinic_id: string, appointment_id: string, book_appointment_transfer: BookAppointmentTransfer, options?: any) {
            return PatientApiFp(configuration).patientClinicAppointmentReschedule(clinic_id, appointment_id, book_appointment_transfer, options)(axios, basePath);
        },
        /**
         * 
         * @summary Get appointment type for a clinic
         * @param {string} clinic_id Clinic to get providers for
         * @param {string} appointment_type_id Specified appointment type
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        patientClinicAppointmentType(clinic_id: string, appointment_type_id: string, options?: any) {
            return PatientApiFp(configuration).patientClinicAppointmentType(clinic_id, appointment_type_id, options)(axios, basePath);
        },
        /**
         * 
         * @summary Get list of appointment types for a clinic
         * @param {string} clinic_id Clinic to get providers for
         * @param {string} provider_id Provider to view appointment types for
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        patientClinicAppointmentTypes(clinic_id: string, provider_id: string, options?: any) {
            return PatientApiFp(configuration).patientClinicAppointmentTypes(clinic_id, provider_id, options)(axios, basePath);
        },
        /**
         * 
         * @summary Get a list of patient appointments for a specific clinic
         * @param {string} clinic_id Clinic to get appointment slot list for
         * @param {string} [start_date] Start of end range (inclusive)
         * @param {string} [end_date] End of date range (inclusive)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        patientClinicAppointments(clinic_id: string, start_date?: string, end_date?: string, options?: any) {
            return PatientApiFp(configuration).patientClinicAppointments(clinic_id, start_date, end_date, options)(axios, basePath);
        },
        /**
         * 
         * @summary Get a list of slots available for booking
         * @param {string} clinic_id Clinic to get appointment slot list for
         * @param {string} provider_id Provider to get appointment slot list for
         * @param {string} appointment_type_id Appointment type to get slot list for
         * @param {string} start_date Start of end range (inclusive)
         * @param {string} end_date End of date range (inclusive)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        patientClinicAvailableAppointments(clinic_id: string, provider_id: string, appointment_type_id: string, start_date: string, end_date: string, options?: any) {
            return PatientApiFp(configuration).patientClinicAvailableAppointments(clinic_id, provider_id, appointment_type_id, start_date, end_date, options)(axios, basePath);
        },
        /**
         * 
         * @summary Get single provider record
         * @param {string} clinic_id Clinic provider belongs to
         * @param {string} provider_id Provider to get info for
         * @param {boolean} [is_remote] Find provider based on remote_id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        patientClinicProvider(clinic_id: string, provider_id: string, is_remote?: boolean, options?: any) {
            return PatientApiFp(configuration).patientClinicProvider(clinic_id, provider_id, is_remote, options)(axios, basePath);
        },
        /**
         * 
         * @summary Get a provider group for a clinic
         * @param {string} clinic_id Clinic to get the provider group from
         * @param {string} provider_group_id Specified provider group
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        patientClinicProviderGroup(clinic_id: string, provider_group_id: string, options?: any) {
            return PatientApiFp(configuration).patientClinicProviderGroup(clinic_id, provider_group_id, options)(axios, basePath);
        },
        /**
         * 
         * @summary Get list of available providers from a provider group for this patient
         * @param {string} clinic_id Clinic to get provider groups for
         * @param {string} provider_group_id The group to get the providers from
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        patientClinicProviderGroupProviders(clinic_id: string, provider_group_id: string, options?: any) {
            return PatientApiFp(configuration).patientClinicProviderGroupProviders(clinic_id, provider_group_id, options)(axios, basePath);
        },
        /**
         * 
         * @summary Get list of available clinic provider groups for this patient
         * @param {string} clinic_id Clinic to get provider groups for
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        patientClinicProviderGroups(clinic_id: string, options?: any) {
            return PatientApiFp(configuration).patientClinicProviderGroups(clinic_id, options)(axios, basePath);
        },
        /**
         * 
         * @summary Get list of available clinic provider groups that a provider is in
         * @param {string} clinic_id Clinic to get provider groups for
         * @param {string} provider_id The id of the provider to get groups for
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        patientClinicProviderGroupsByProvider(clinic_id: string, provider_id: string, options?: any) {
            return PatientApiFp(configuration).patientClinicProviderGroupsByProvider(clinic_id, provider_id, options)(axios, basePath);
        },
        /**
         * 
         * @summary Get list of available clinics for this patient
         * @param {string} clinic_id Clinic to get providers for
         * @param {boolean} [bookable_only] Only show bookable appointments
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        patientClinicProviders(clinic_id: string, bookable_only?: boolean, options?: any) {
            return PatientApiFp(configuration).patientClinicProviders(clinic_id, bookable_only, options)(axios, basePath);
        },
        /**
         * 
         * @summary Get list of available clinics for this patient
         * @param {boolean} [bookable_only] Only show bookable appointments
         * @param {boolean} [messageable_only] Only return clinics this patient can message
         * @param {boolean} [available_only] Only return clinics that are avaiable to link with this patient
         * @param {boolean} [connected_only] Only return clinics that are connected to this patient
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        patientClinics(bookable_only?: boolean, messageable_only?: boolean, available_only?: boolean, connected_only?: boolean, options?: any) {
            return PatientApiFp(configuration).patientClinics(bookable_only, messageable_only, available_only, connected_only, options)(axios, basePath);
        },
        /**
         * 
         * @summary get a document
         * @param {string} document_id document id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        patientGetDocument(document_id: string, options?: any) {
            return PatientApiFp(configuration).patientGetDocument(document_id, options)(axios, basePath);
        },
        /**
         * 
         * @summary Get the message count for a specific mailbox
         * @param {string} mailbox 
         * @param {boolean} unread_only 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        patientMessageCount(mailbox: string, unread_only: boolean, options?: any) {
            return PatientApiFp(configuration).patientMessageCount(mailbox, unread_only, options)(axios, basePath);
        },
        /**
         * 
         * @summary Get all messages for a specific patient
         * @param {string} mailbox 
         * @param {number} offset 
         * @param {number} limit 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        patientMessages(mailbox: string, offset: number, limit: number, options?: any) {
            return PatientApiFp(configuration).patientMessages(mailbox, offset, limit, options)(axios, basePath);
        },
        /**
         * 
         * @summary Get a list of patient clinic appointmenst
         * @param {string} [start_datetime] the date after which appointments will be returned
         * @param {string} [end_datetime] the date before which appointments will be returned
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        patientMhaClinicAppointments(start_datetime?: string, end_datetime?: string, options?: any) {
            return PatientApiFp(configuration).patientMhaClinicAppointments(start_datetime, end_datetime, options)(axios, basePath);
        },
        /**
         * 
         * @summary Add a new document.
         * @param {DocumentCreateTransfer} document_create_transfer Document create transfer
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        patientUploadDocument(document_create_transfer: DocumentCreateTransfer, options?: any) {
            return PatientApiFp(configuration).patientUploadDocument(document_create_transfer, options)(axios, basePath);
        },
        /**
         * 
         * @summary Ping telehealth session
         * @param {string} appointment_id Appointment that the telehealth session is for
         * @param {TelehealthPingSessionDto} telehealth_ping_session_dto Ping session data
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        pingPatientTelehealthSession(appointment_id: string, telehealth_ping_session_dto: TelehealthPingSessionDto, options?: any) {
            return PatientApiFp(configuration).pingPatientTelehealthSession(appointment_id, telehealth_ping_session_dto, options)(axios, basePath);
        },
        /**
         * 
         * @summary Send a message to a clinic
         * @param {string} clinic_id 
         * @param {MessagePost} message_post Message data
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        sendMessage(clinic_id: string, message_post: MessagePost, options?: any) {
            return PatientApiFp(configuration).sendMessage(clinic_id, message_post, options)(axios, basePath);
        },
        /**
         * 
         * @summary Set mailbox messages to read
         * @param {string} mailbox 
         * @param {InlineObject6} inline_object6 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        setReadMail(mailbox: string, inline_object6: InlineObject6, options?: any) {
            return PatientApiFp(configuration).setReadMail(mailbox, inline_object6, options)(axios, basePath);
        },
        /**
         * 
         * @summary Unarchive messages by message ID
         * @param {number} message_id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        unArchiveMessages(message_id: number, options?: any) {
            return PatientApiFp(configuration).unArchiveMessages(message_id, options)(axios, basePath);
        },
        /**
         * 
         * @summary Unlink a user with a clinic
         * @param {string} clinic_id clinic profile uuid
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        unlinkWithClinic(clinic_id: string, options?: any) {
            return PatientApiFp(configuration).unlinkWithClinic(clinic_id, options)(axios, basePath);
        },
        /**
         * 
         * @summary update account credentials
         * @param {InlineObject4} inline_object4 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateAccount(inline_object4: InlineObject4, options?: any) {
            return PatientApiFp(configuration).updateAccount(inline_object4, options)(axios, basePath);
        },
        /**
         * 
         * @summary Update current logged in patient
         * @param {InlineObject1} inline_object1 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updatePatient(inline_object1: InlineObject1, options?: any) {
            return PatientApiFp(configuration).updatePatient(inline_object1, options)(axios, basePath);
        },
        /**
         * 
         * @summary Update notifications settings for the logged in user
         * @param {InlineObject2} inline_object2 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updatePatientNotificationSettings(inline_object2: InlineObject2, options?: any) {
            return PatientApiFp(configuration).updatePatientNotificationSettings(inline_object2, options)(axios, basePath);
        },
        /**
         * 
         * @summary Update clinic appointment
         * @param {string} appointment_id Id of the appointment to update
         * @param {ClinicAppointmentTransfer} clinic_appointment_transfer appointment
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateTelehealthAppointment(appointment_id: string, clinic_appointment_transfer: ClinicAppointmentTransfer, options?: any) {
            return PatientApiFp(configuration).updateTelehealthAppointment(appointment_id, clinic_appointment_transfer, options)(axios, basePath);
        },
        /**
         * 
         * @summary Find clinics within range of patient based on coordinates
         * @param {number} [latitude] 
         * @param {number} [longitude] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        viewableClinicsInCoordRange(latitude?: number, longitude?: number, options?: any) {
            return PatientApiFp(configuration).viewableClinicsInCoordRange(latitude, longitude, options)(axios, basePath);
        },
        /**
         * 
         * @summary Viewable clinics in location range
         * @param {string} [province_code] 
         * @param {string} [city] 
         * @param {string} [address] 
         * @param {string} [postal_code] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        viewableClinicsInLocRange(province_code?: string, city?: string, address?: string, postal_code?: string, options?: any) {
            return PatientApiFp(configuration).viewableClinicsInLocRange(province_code, city, address, postal_code, options)(axios, basePath);
        },
    };
};

/**
 * PatientApi - object-oriented interface
 * @export
 * @class PatientApi
 * @extends {BaseAPI}
 */
export class PatientApi extends BaseAPI {
    /**
     * 
     * @summary Create a dependent profile record
     * @param {InlineObject7} inline_object7 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PatientApi
     */
    public addDependent(inline_object7: InlineObject7, options?: any) {
        return PatientApiFp(this.configuration).addDependent(inline_object7, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @summary Record a new chat message for the appointment
     * @param {string} appointment_id Id of the appointment to which the chat message will be added
     * @param {TelehealthChatMessageDto} telehealth_chat_message_dto Message
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PatientApi
     */
    public addPatientChatMessageToAppointment(appointment_id: string, telehealth_chat_message_dto: TelehealthChatMessageDto, options?: any) {
        return PatientApiFp(this.configuration).addPatientChatMessageToAppointment(appointment_id, telehealth_chat_message_dto, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @summary Archive messages by message ID
     * @param {number} message_id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PatientApi
     */
    public archiveMessages(message_id: number, options?: any) {
        return PatientApiFp(this.configuration).archiveMessages(message_id, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @summary Deactivate the logged in user\'s MyHealthAccess account
     * @param {InlineObject3} inline_object3 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PatientApi
     */
    public deactivatePatient(inline_object3: InlineObject3, options?: any) {
        return PatientApiFp(this.configuration).deactivatePatient(inline_object3, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @summary Generate new one time code
     * @param {OneTimeCodeDto} one_time_code_dto One time code.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PatientApi
     */
    public generatePatientOneTimeCode(one_time_code_dto: OneTimeCodeDto, options?: any) {
        return PatientApiFp(this.configuration).generatePatientOneTimeCode(one_time_code_dto, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @summary Get a specific appointment
     * @param {string} clinic_id ID of the clinic this appointment belongs to
     * @param {string} local_id Appointment to get info for
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PatientApi
     */
    public getAppointment(clinic_id: string, local_id: string, options?: any) {
        return PatientApiFp(this.configuration).getAppointment(clinic_id, local_id, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @summary Get clinic info for the specified clinic
     * @param {string} clinic_id 
     * @param {boolean} [required_fields] Whether to return required connection fields for the requested clinic
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PatientApi
     */
    public getClinic(clinic_id: string, required_fields?: boolean, options?: any) {
        return PatientApiFp(this.configuration).getClinic(clinic_id, required_fields, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @summary Get CloudMD clinic profile for the current patient
     * @param {ProvinceCode} [province_code] Whether to return required connection fields for the requested clinic
     * @param {boolean} [required_fields] Whether to return required connection fields for the requested clinic
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PatientApi
     */
    public getCloudMdClinic(province_code?: ProvinceCode, required_fields?: boolean, options?: any) {
        return PatientApiFp(this.configuration).getCloudMdClinic(province_code, required_fields, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @summary List the users dependents
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PatientApi
     */
    public getDependents(options?: any) {
        return PatientApiFp(this.configuration).getDependents(options)(this.axios, this.basePath);
    }

    /**
     * 
     * @summary Get all chat messages for this appointment
     * @param {string} appointment_id Id of the appointment
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PatientApi
     */
    public getPatientAppointmentChatMessages(appointment_id: string, options?: any) {
        return PatientApiFp(this.configuration).getPatientAppointmentChatMessages(appointment_id, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @summary Get clinic appointment for patient
     * @param {string} appointment_id The patient appointment id to get the clinic appointment for.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PatientApi
     */
    public getPatientClinicAppointment(appointment_id: string, options?: any) {
        return PatientApiFp(this.configuration).getPatientClinicAppointment(appointment_id, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @summary get a conversation
     * @param {number} conversation_id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PatientApi
     */
    public getPatientConversation(conversation_id: number, options?: any) {
        return PatientApiFp(this.configuration).getPatientConversation(conversation_id, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @summary Get a list of non expired one time codes for the patient
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PatientApi
     */
    public getPatientOneTimeCodes(options?: any) {
        return PatientApiFp(this.configuration).getPatientOneTimeCodes(options)(this.axios, this.basePath);
    }

    /**
     * 
     * @summary Generate a new patient token for the specified telehealth appointment
     * @param {string} clinic_id clinic id
     * @param {string} remote_id Oscar appointment_no
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PatientApi
     */
    public getPatientTeleHealthSession(clinic_id: string, remote_id: string, options?: any) {
        return PatientApiFp(this.configuration).getPatientTeleHealthSession(clinic_id, remote_id, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @summary Telehealth appointment session data
     * @param {string} appointment_id Appointment the telehealth session is for
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PatientApi
     */
    public getPatientTelehealthSessionData(appointment_id: string, options?: any) {
        return PatientApiFp(this.configuration).getPatientTelehealthSessionData(appointment_id, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @summary Get one time token for this remote appointment
     * @param {string} clinic_id Clinic
     * @param {string} remote_id Remote appointment id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PatientApi
     */
    public getRemoteAppointmentOneTimeToken(clinic_id: string, remote_id: string, options?: any) {
        return PatientApiFp(this.configuration).getRemoteAppointmentOneTimeToken(clinic_id, remote_id, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @summary Link a user with a clinic
     * @param {string} clinic_id clinic profile uuid
     * @param {ClinicConnectionRequest} clinic_connection_request patient request object
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PatientApi
     */
    public linkWithClinic(clinic_id: string, clinic_connection_request: ClinicConnectionRequest, options?: any) {
        return PatientApiFp(this.configuration).linkWithClinic(clinic_id, clinic_connection_request, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @summary Log a telehealth session event
     * @param {string} appointment_id Appointment whos telehealth session is going to be logged to
     * @param {TelehealthLogEventDto} telehealth_log_event_dto Log event data
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PatientApi
     */
    public logEventToPatientTelehealthSession(appointment_id: string, telehealth_log_event_dto: TelehealthLogEventDto, options?: any) {
        return PatientApiFp(this.configuration).logEventToPatientTelehealthSession(appointment_id, telehealth_log_event_dto, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @summary Get current logged in patient
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PatientApi
     */
    public loggedInPatient(options?: any) {
        return PatientApiFp(this.configuration).loggedInPatient(options)(this.axios, this.basePath);
    }

    /**
     * 
     * @summary post to endpoint on login success
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PatientApi
     */
    public loginSuccess(options?: any) {
        return PatientApiFp(this.configuration).loginSuccess(options)(this.axios, this.basePath);
    }

    /**
     * 
     * @summary Get a list of patient appointments
     * @param {string} [start_date] Start of end range (inclusive)
     * @param {string} [end_date] End of date range (inclusive)
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PatientApi
     */
    public patientAppointments(start_date?: string, end_date?: string, options?: any) {
        return PatientApiFp(this.configuration).patientAppointments(start_date, end_date, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @summary Book an appointment
     * @param {string} clinic_id Clinic to book appointment slot for
     * @param {BookAppointmentTransfer} book_appointment_transfer Appointment slot
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PatientApi
     */
    public patientClinicAppointmentBook(clinic_id: string, book_appointment_transfer: BookAppointmentTransfer, options?: any) {
        return PatientApiFp(this.configuration).patientClinicAppointmentBook(clinic_id, book_appointment_transfer, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @summary Cancel an appointment
     * @param {string} clinic_id Clinic to cancel appointment slot for
     * @param {string} id Juno appointment ID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PatientApi
     */
    public patientClinicAppointmentCancel(clinic_id: string, id: string, options?: any) {
        return PatientApiFp(this.configuration).patientClinicAppointmentCancel(clinic_id, id, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @summary Reschedule an appointment
     * @param {string} clinic_id Clinic to book appointment slot for
     * @param {string} appointment_id Juno appointment ID
     * @param {BookAppointmentTransfer} book_appointment_transfer Appointment slot
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PatientApi
     */
    public patientClinicAppointmentReschedule(clinic_id: string, appointment_id: string, book_appointment_transfer: BookAppointmentTransfer, options?: any) {
        return PatientApiFp(this.configuration).patientClinicAppointmentReschedule(clinic_id, appointment_id, book_appointment_transfer, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @summary Get appointment type for a clinic
     * @param {string} clinic_id Clinic to get providers for
     * @param {string} appointment_type_id Specified appointment type
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PatientApi
     */
    public patientClinicAppointmentType(clinic_id: string, appointment_type_id: string, options?: any) {
        return PatientApiFp(this.configuration).patientClinicAppointmentType(clinic_id, appointment_type_id, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @summary Get list of appointment types for a clinic
     * @param {string} clinic_id Clinic to get providers for
     * @param {string} provider_id Provider to view appointment types for
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PatientApi
     */
    public patientClinicAppointmentTypes(clinic_id: string, provider_id: string, options?: any) {
        return PatientApiFp(this.configuration).patientClinicAppointmentTypes(clinic_id, provider_id, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @summary Get a list of patient appointments for a specific clinic
     * @param {string} clinic_id Clinic to get appointment slot list for
     * @param {string} [start_date] Start of end range (inclusive)
     * @param {string} [end_date] End of date range (inclusive)
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PatientApi
     */
    public patientClinicAppointments(clinic_id: string, start_date?: string, end_date?: string, options?: any) {
        return PatientApiFp(this.configuration).patientClinicAppointments(clinic_id, start_date, end_date, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @summary Get a list of slots available for booking
     * @param {string} clinic_id Clinic to get appointment slot list for
     * @param {string} provider_id Provider to get appointment slot list for
     * @param {string} appointment_type_id Appointment type to get slot list for
     * @param {string} start_date Start of end range (inclusive)
     * @param {string} end_date End of date range (inclusive)
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PatientApi
     */
    public patientClinicAvailableAppointments(clinic_id: string, provider_id: string, appointment_type_id: string, start_date: string, end_date: string, options?: any) {
        return PatientApiFp(this.configuration).patientClinicAvailableAppointments(clinic_id, provider_id, appointment_type_id, start_date, end_date, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @summary Get single provider record
     * @param {string} clinic_id Clinic provider belongs to
     * @param {string} provider_id Provider to get info for
     * @param {boolean} [is_remote] Find provider based on remote_id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PatientApi
     */
    public patientClinicProvider(clinic_id: string, provider_id: string, is_remote?: boolean, options?: any) {
        return PatientApiFp(this.configuration).patientClinicProvider(clinic_id, provider_id, is_remote, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @summary Get a provider group for a clinic
     * @param {string} clinic_id Clinic to get the provider group from
     * @param {string} provider_group_id Specified provider group
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PatientApi
     */
    public patientClinicProviderGroup(clinic_id: string, provider_group_id: string, options?: any) {
        return PatientApiFp(this.configuration).patientClinicProviderGroup(clinic_id, provider_group_id, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @summary Get list of available providers from a provider group for this patient
     * @param {string} clinic_id Clinic to get provider groups for
     * @param {string} provider_group_id The group to get the providers from
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PatientApi
     */
    public patientClinicProviderGroupProviders(clinic_id: string, provider_group_id: string, options?: any) {
        return PatientApiFp(this.configuration).patientClinicProviderGroupProviders(clinic_id, provider_group_id, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @summary Get list of available clinic provider groups for this patient
     * @param {string} clinic_id Clinic to get provider groups for
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PatientApi
     */
    public patientClinicProviderGroups(clinic_id: string, options?: any) {
        return PatientApiFp(this.configuration).patientClinicProviderGroups(clinic_id, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @summary Get list of available clinic provider groups that a provider is in
     * @param {string} clinic_id Clinic to get provider groups for
     * @param {string} provider_id The id of the provider to get groups for
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PatientApi
     */
    public patientClinicProviderGroupsByProvider(clinic_id: string, provider_id: string, options?: any) {
        return PatientApiFp(this.configuration).patientClinicProviderGroupsByProvider(clinic_id, provider_id, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @summary Get list of available clinics for this patient
     * @param {string} clinic_id Clinic to get providers for
     * @param {boolean} [bookable_only] Only show bookable appointments
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PatientApi
     */
    public patientClinicProviders(clinic_id: string, bookable_only?: boolean, options?: any) {
        return PatientApiFp(this.configuration).patientClinicProviders(clinic_id, bookable_only, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @summary Get list of available clinics for this patient
     * @param {boolean} [bookable_only] Only show bookable appointments
     * @param {boolean} [messageable_only] Only return clinics this patient can message
     * @param {boolean} [available_only] Only return clinics that are avaiable to link with this patient
     * @param {boolean} [connected_only] Only return clinics that are connected to this patient
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PatientApi
     */
    public patientClinics(bookable_only?: boolean, messageable_only?: boolean, available_only?: boolean, connected_only?: boolean, options?: any) {
        return PatientApiFp(this.configuration).patientClinics(bookable_only, messageable_only, available_only, connected_only, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @summary get a document
     * @param {string} document_id document id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PatientApi
     */
    public patientGetDocument(document_id: string, options?: any) {
        return PatientApiFp(this.configuration).patientGetDocument(document_id, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @summary Get the message count for a specific mailbox
     * @param {string} mailbox 
     * @param {boolean} unread_only 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PatientApi
     */
    public patientMessageCount(mailbox: string, unread_only: boolean, options?: any) {
        return PatientApiFp(this.configuration).patientMessageCount(mailbox, unread_only, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @summary Get all messages for a specific patient
     * @param {string} mailbox 
     * @param {number} offset 
     * @param {number} limit 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PatientApi
     */
    public patientMessages(mailbox: string, offset: number, limit: number, options?: any) {
        return PatientApiFp(this.configuration).patientMessages(mailbox, offset, limit, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @summary Get a list of patient clinic appointmenst
     * @param {string} [start_datetime] the date after which appointments will be returned
     * @param {string} [end_datetime] the date before which appointments will be returned
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PatientApi
     */
    public patientMhaClinicAppointments(start_datetime?: string, end_datetime?: string, options?: any) {
        return PatientApiFp(this.configuration).patientMhaClinicAppointments(start_datetime, end_datetime, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @summary Add a new document.
     * @param {DocumentCreateTransfer} document_create_transfer Document create transfer
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PatientApi
     */
    public patientUploadDocument(document_create_transfer: DocumentCreateTransfer, options?: any) {
        return PatientApiFp(this.configuration).patientUploadDocument(document_create_transfer, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @summary Ping telehealth session
     * @param {string} appointment_id Appointment that the telehealth session is for
     * @param {TelehealthPingSessionDto} telehealth_ping_session_dto Ping session data
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PatientApi
     */
    public pingPatientTelehealthSession(appointment_id: string, telehealth_ping_session_dto: TelehealthPingSessionDto, options?: any) {
        return PatientApiFp(this.configuration).pingPatientTelehealthSession(appointment_id, telehealth_ping_session_dto, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @summary Send a message to a clinic
     * @param {string} clinic_id 
     * @param {MessagePost} message_post Message data
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PatientApi
     */
    public sendMessage(clinic_id: string, message_post: MessagePost, options?: any) {
        return PatientApiFp(this.configuration).sendMessage(clinic_id, message_post, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @summary Set mailbox messages to read
     * @param {string} mailbox 
     * @param {InlineObject6} inline_object6 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PatientApi
     */
    public setReadMail(mailbox: string, inline_object6: InlineObject6, options?: any) {
        return PatientApiFp(this.configuration).setReadMail(mailbox, inline_object6, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @summary Unarchive messages by message ID
     * @param {number} message_id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PatientApi
     */
    public unArchiveMessages(message_id: number, options?: any) {
        return PatientApiFp(this.configuration).unArchiveMessages(message_id, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @summary Unlink a user with a clinic
     * @param {string} clinic_id clinic profile uuid
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PatientApi
     */
    public unlinkWithClinic(clinic_id: string, options?: any) {
        return PatientApiFp(this.configuration).unlinkWithClinic(clinic_id, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @summary update account credentials
     * @param {InlineObject4} inline_object4 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PatientApi
     */
    public updateAccount(inline_object4: InlineObject4, options?: any) {
        return PatientApiFp(this.configuration).updateAccount(inline_object4, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @summary Update current logged in patient
     * @param {InlineObject1} inline_object1 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PatientApi
     */
    public updatePatient(inline_object1: InlineObject1, options?: any) {
        return PatientApiFp(this.configuration).updatePatient(inline_object1, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @summary Update notifications settings for the logged in user
     * @param {InlineObject2} inline_object2 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PatientApi
     */
    public updatePatientNotificationSettings(inline_object2: InlineObject2, options?: any) {
        return PatientApiFp(this.configuration).updatePatientNotificationSettings(inline_object2, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @summary Update clinic appointment
     * @param {string} appointment_id Id of the appointment to update
     * @param {ClinicAppointmentTransfer} clinic_appointment_transfer appointment
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PatientApi
     */
    public updateTelehealthAppointment(appointment_id: string, clinic_appointment_transfer: ClinicAppointmentTransfer, options?: any) {
        return PatientApiFp(this.configuration).updateTelehealthAppointment(appointment_id, clinic_appointment_transfer, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @summary Find clinics within range of patient based on coordinates
     * @param {number} [latitude] 
     * @param {number} [longitude] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PatientApi
     */
    public viewableClinicsInCoordRange(latitude?: number, longitude?: number, options?: any) {
        return PatientApiFp(this.configuration).viewableClinicsInCoordRange(latitude, longitude, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @summary Viewable clinics in location range
     * @param {string} [province_code] 
     * @param {string} [city] 
     * @param {string} [address] 
     * @param {string} [postal_code] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PatientApi
     */
    public viewableClinicsInLocRange(province_code?: string, city?: string, address?: string, postal_code?: string, options?: any) {
        return PatientApiFp(this.configuration).viewableClinicsInLocRange(province_code, city, address, postal_code, options)(this.axios, this.basePath);
    }

}


/**
 * PublicApi - axios parameter creator
 * @export
 */
export const PublicApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Confirm an appointment
         * @param {string} clinic_id Clinic to confirm appointment slot for
         * @param {string} remote_id Juno appointment ID
         * @param {string} reminder_id ID of confirmation reminder
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        clinicAppointmentConfirm(clinic_id: string, remote_id: string, reminder_id: string, options: any = {}): RequestArgs {
            // verify required parameter 'clinic_id' is not null or undefined
            if (clinic_id === null || clinic_id === undefined) {
                throw new RequiredError('clinic_id','Required parameter clinic_id was null or undefined when calling clinicAppointmentConfirm.');
            }
            // verify required parameter 'remote_id' is not null or undefined
            if (remote_id === null || remote_id === undefined) {
                throw new RequiredError('remote_id','Required parameter remote_id was null or undefined when calling clinicAppointmentConfirm.');
            }
            // verify required parameter 'reminder_id' is not null or undefined
            if (reminder_id === null || reminder_id === undefined) {
                throw new RequiredError('reminder_id','Required parameter reminder_id was null or undefined when calling clinicAppointmentConfirm.');
            }
            const localVarPath = `/public/clinic/{clinic_id}/appointment/{remote_id}/confirm`
                .replace(`{${"clinic_id"}}`, encodeURIComponent(String(clinic_id)))
                .replace(`{${"remote_id"}}`, encodeURIComponent(String(remote_id)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication apiAuth required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? configuration.apiKey("X-Auth-Token")
                    : configuration.apiKey;
                localVarHeaderParameter["X-Auth-Token"] = localVarApiKeyValue;
            }

            if (reminder_id !== undefined) {
                localVarQueryParameter['reminder_id'] = reminder_id;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get telehealth session for the appointment
         * @param {string} appointment_id The appointment you want to fetch the session for
         * @param {string} token User info token
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAppointmentTelehealthSession(appointment_id: string, token: string, options: any = {}): RequestArgs {
            // verify required parameter 'appointment_id' is not null or undefined
            if (appointment_id === null || appointment_id === undefined) {
                throw new RequiredError('appointment_id','Required parameter appointment_id was null or undefined when calling getAppointmentTelehealthSession.');
            }
            // verify required parameter 'token' is not null or undefined
            if (token === null || token === undefined) {
                throw new RequiredError('token','Required parameter token was null or undefined when calling getAppointmentTelehealthSession.');
            }
            const localVarPath = `/public/appointment/{appointment_id}/session`
                .replace(`{${"appointment_id"}}`, encodeURIComponent(String(appointment_id)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (token !== undefined) {
                localVarQueryParameter['token'] = token;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Public endpoint for fetching clinic data
         * @param {string} clinic_name url friendly name of clinic
         * @param {boolean} [required_fields] Whether to include required fields for the specified clinics
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getClinicByName(clinic_name: string, required_fields?: boolean, options: any = {}): RequestArgs {
            // verify required parameter 'clinic_name' is not null or undefined
            if (clinic_name === null || clinic_name === undefined) {
                throw new RequiredError('clinic_name','Required parameter clinic_name was null or undefined when calling getClinicByName.');
            }
            const localVarPath = `/public/clinic/name/{clinic_name}.json`
                .replace(`{${"clinic_name"}}`, encodeURIComponent(String(clinic_name)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (required_fields !== undefined) {
                localVarQueryParameter['required_fields'] = required_fields;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get clinic required profile fields for the specified clinic
         * @param {string} clinic_id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getClinicRequiredProfileFields(clinic_id: string, options: any = {}): RequestArgs {
            // verify required parameter 'clinic_id' is not null or undefined
            if (clinic_id === null || clinic_id === undefined) {
                throw new RequiredError('clinic_id','Required parameter clinic_id was null or undefined when calling getClinicRequiredProfileFields.');
            }
            const localVarPath = `/public/clinic/{clinic_id}/clinic_required_profile_fields.json`
                .replace(`{${"clinic_id"}}`, encodeURIComponent(String(clinic_id)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication apiAuth required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? configuration.apiKey("X-Auth-Token")
                    : configuration.apiKey;
                localVarHeaderParameter["X-Auth-Token"] = localVarApiKeyValue;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get province specific CloudMD clinic profile and required connection fields
         * @param {ProvinceCode} [province_code] 
         * @param {boolean} [required_fields] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCloudMd(province_code?: ProvinceCode, required_fields?: boolean, options: any = {}): RequestArgs {
            const localVarPath = `/public/cloud_md`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (province_code !== undefined) {
                localVarQueryParameter['province_code'] = province_code;
            }

            if (required_fields !== undefined) {
                localVarQueryParameter['required_fields'] = required_fields;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get constants that are set through config
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getConfigConstants(options: any = {}): RequestArgs {
            const localVarPath = `/public/app/config_constants.json`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication apiAuth required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? configuration.apiKey("X-Auth-Token")
                    : configuration.apiKey;
                localVarHeaderParameter["X-Auth-Token"] = localVarApiKeyValue;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get custom_login enabled pharmacy profile
         * @param {string} url_friendly_name 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCustomLoginPharmacy(url_friendly_name: string, options: any = {}): RequestArgs {
            // verify required parameter 'url_friendly_name' is not null or undefined
            if (url_friendly_name === null || url_friendly_name === undefined) {
                throw new RequiredError('url_friendly_name','Required parameter url_friendly_name was null or undefined when calling getCustomLoginPharmacy.');
            }
            const localVarPath = `/public/pharmacy/custom_login/{url_friendly_name}`
                .replace(`{${"url_friendly_name"}}`, encodeURIComponent(String(url_friendly_name)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication apiAuth required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? configuration.apiKey("X-Auth-Token")
                    : configuration.apiKey;
                localVarHeaderParameter["X-Auth-Token"] = localVarApiKeyValue;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get the organization\'s assets
         * @param {string} organization_id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOrganizationAssets(organization_id: string, options: any = {}): RequestArgs {
            // verify required parameter 'organization_id' is not null or undefined
            if (organization_id === null || organization_id === undefined) {
                throw new RequiredError('organization_id','Required parameter organization_id was null or undefined when calling getOrganizationAssets.');
            }
            const localVarPath = `/public/organization/{organization_id}/assets/`
                .replace(`{${"organization_id"}}`, encodeURIComponent(String(organization_id)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication apiAuth required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? configuration.apiKey("X-Auth-Token")
                    : configuration.apiKey;
                localVarHeaderParameter["X-Auth-Token"] = localVarApiKeyValue;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get the organization\'s fonts
         * @param {string} organization_id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOrganizationFonts(organization_id: string, options: any = {}): RequestArgs {
            // verify required parameter 'organization_id' is not null or undefined
            if (organization_id === null || organization_id === undefined) {
                throw new RequiredError('organization_id','Required parameter organization_id was null or undefined when calling getOrganizationFonts.');
            }
            const localVarPath = `/public/organization/{organization_id}/fonts/`
                .replace(`{${"organization_id"}}`, encodeURIComponent(String(organization_id)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication apiAuth required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? configuration.apiKey("X-Auth-Token")
                    : configuration.apiKey;
                localVarHeaderParameter["X-Auth-Token"] = localVarApiKeyValue;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get the organization\'s pharmacies
         * @param {string} organization_id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOrganizationPharmacies(organization_id: string, options: any = {}): RequestArgs {
            // verify required parameter 'organization_id' is not null or undefined
            if (organization_id === null || organization_id === undefined) {
                throw new RequiredError('organization_id','Required parameter organization_id was null or undefined when calling getOrganizationPharmacies.');
            }
            const localVarPath = `/public/organization/{organization_id}/pharmacies/`
                .replace(`{${"organization_id"}}`, encodeURIComponent(String(organization_id)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication apiAuth required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? configuration.apiKey("X-Auth-Token")
                    : configuration.apiKey;
                localVarHeaderParameter["X-Auth-Token"] = localVarApiKeyValue;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get the organization\'s profile
         * @param {string} organization_id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOrganizationProfile(organization_id: string, options: any = {}): RequestArgs {
            // verify required parameter 'organization_id' is not null or undefined
            if (organization_id === null || organization_id === undefined) {
                throw new RequiredError('organization_id','Required parameter organization_id was null or undefined when calling getOrganizationProfile.');
            }
            const localVarPath = `/public/organization/{organization_id}/`
                .replace(`{${"organization_id"}}`, encodeURIComponent(String(organization_id)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication apiAuth required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? configuration.apiKey("X-Auth-Token")
                    : configuration.apiKey;
                localVarHeaderParameter["X-Auth-Token"] = localVarApiKeyValue;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get the organization\'s texts
         * @param {string} organization_id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOrganizationTexts(organization_id: string, options: any = {}): RequestArgs {
            // verify required parameter 'organization_id' is not null or undefined
            if (organization_id === null || organization_id === undefined) {
                throw new RequiredError('organization_id','Required parameter organization_id was null or undefined when calling getOrganizationTexts.');
            }
            const localVarPath = `/public/organization/{organization_id}/texts/`
                .replace(`{${"organization_id"}}`, encodeURIComponent(String(organization_id)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication apiAuth required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? configuration.apiKey("X-Auth-Token")
                    : configuration.apiKey;
                localVarHeaderParameter["X-Auth-Token"] = localVarApiKeyValue;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Public endpoint for fetching clinic data
         * @param {string} clinic_id uuid of clinic
         * @param {boolean} [required_fields] Whether to include required fields for the specified clinics
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPublicClinicData(clinic_id: string, required_fields?: boolean, options: any = {}): RequestArgs {
            // verify required parameter 'clinic_id' is not null or undefined
            if (clinic_id === null || clinic_id === undefined) {
                throw new RequiredError('clinic_id','Required parameter clinic_id was null or undefined when calling getPublicClinicData.');
            }
            const localVarPath = `/public/clinic/{clinic_id}.json`
                .replace(`{${"clinic_id"}}`, encodeURIComponent(String(clinic_id)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (required_fields !== undefined) {
                localVarQueryParameter['required_fields'] = required_fields;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get list of supported province codes
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSupportedProvinceCodes(options: any = {}): RequestArgs {
            const localVarPath = `/public/cloud_md/supported_province_codes`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary query the version information from the server.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getVersion(options: any = {}): RequestArgs {
            const localVarPath = `/public/version`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Log a telehealth session event
         * @param {string} appointment_id Appointment that the telehealth session is for
         * @param {string} token User info token
         * @param {TelehealthLogEventDto} telehealth_log_event_dto Log event data
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        logEventToPatientPublicTelehealthSession(appointment_id: string, token: string, telehealth_log_event_dto: TelehealthLogEventDto, options: any = {}): RequestArgs {
            // verify required parameter 'appointment_id' is not null or undefined
            if (appointment_id === null || appointment_id === undefined) {
                throw new RequiredError('appointment_id','Required parameter appointment_id was null or undefined when calling logEventToPatientPublicTelehealthSession.');
            }
            // verify required parameter 'token' is not null or undefined
            if (token === null || token === undefined) {
                throw new RequiredError('token','Required parameter token was null or undefined when calling logEventToPatientPublicTelehealthSession.');
            }
            // verify required parameter 'telehealth_log_event_dto' is not null or undefined
            if (telehealth_log_event_dto === null || telehealth_log_event_dto === undefined) {
                throw new RequiredError('telehealth_log_event_dto','Required parameter telehealth_log_event_dto was null or undefined when calling logEventToPatientPublicTelehealthSession.');
            }
            const localVarPath = `/public/appointment/{appointment_id}/session/log_event`
                .replace(`{${"appointment_id"}}`, encodeURIComponent(String(appointment_id)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication apiAuth required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? configuration.apiKey("X-Auth-Token")
                    : configuration.apiKey;
                localVarHeaderParameter["X-Auth-Token"] = localVarApiKeyValue;
            }

            if (token !== undefined) {
                localVarQueryParameter['token'] = token;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};
            const needsSerialization = (<any>"TelehealthLogEventDto" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(telehealth_log_event_dto !== undefined ? telehealth_log_event_dto : {}) : (telehealth_log_event_dto || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Ping telehealth session
         * @param {string} appointment_id Appointment that the telehealth session is for
         * @param {string} token User info token
         * @param {TelehealthPingSessionDto} telehealth_ping_session_dto Ping session data
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        pingTelehealthSession(appointment_id: string, token: string, telehealth_ping_session_dto: TelehealthPingSessionDto, options: any = {}): RequestArgs {
            // verify required parameter 'appointment_id' is not null or undefined
            if (appointment_id === null || appointment_id === undefined) {
                throw new RequiredError('appointment_id','Required parameter appointment_id was null or undefined when calling pingTelehealthSession.');
            }
            // verify required parameter 'token' is not null or undefined
            if (token === null || token === undefined) {
                throw new RequiredError('token','Required parameter token was null or undefined when calling pingTelehealthSession.');
            }
            // verify required parameter 'telehealth_ping_session_dto' is not null or undefined
            if (telehealth_ping_session_dto === null || telehealth_ping_session_dto === undefined) {
                throw new RequiredError('telehealth_ping_session_dto','Required parameter telehealth_ping_session_dto was null or undefined when calling pingTelehealthSession.');
            }
            const localVarPath = `/public/appointment/{appointment_id}/session/ping`
                .replace(`{${"appointment_id"}}`, encodeURIComponent(String(appointment_id)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication apiAuth required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? configuration.apiKey("X-Auth-Token")
                    : configuration.apiKey;
                localVarHeaderParameter["X-Auth-Token"] = localVarApiKeyValue;
            }

            if (token !== undefined) {
                localVarQueryParameter['token'] = token;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};
            const needsSerialization = (<any>"TelehealthPingSessionDto" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(telehealth_ping_session_dto !== undefined ? telehealth_ping_session_dto : {}) : (telehealth_ping_session_dto || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * PublicApi - functional programming interface
 * @export
 */
export const PublicApiFp = function(configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Confirm an appointment
         * @param {string} clinic_id Clinic to confirm appointment slot for
         * @param {string} remote_id Juno appointment ID
         * @param {string} reminder_id ID of confirmation reminder
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        clinicAppointmentConfirm(clinic_id: string, remote_id: string, reminder_id: string, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<AppointmentTransfer> {
            const localVarAxiosArgs = PublicApiAxiosParamCreator(configuration).clinicAppointmentConfirm(clinic_id, remote_id, reminder_id, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Get telehealth session for the appointment
         * @param {string} appointment_id The appointment you want to fetch the session for
         * @param {string} token User info token
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAppointmentTelehealthSession(appointment_id: string, token: string, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<PublicTelehealthSessionInfo> {
            const localVarAxiosArgs = PublicApiAxiosParamCreator(configuration).getAppointmentTelehealthSession(appointment_id, token, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Public endpoint for fetching clinic data
         * @param {string} clinic_name url friendly name of clinic
         * @param {boolean} [required_fields] Whether to include required fields for the specified clinics
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getClinicByName(clinic_name: string, required_fields?: boolean, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<Clinic> {
            const localVarAxiosArgs = PublicApiAxiosParamCreator(configuration).getClinicByName(clinic_name, required_fields, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Get clinic required profile fields for the specified clinic
         * @param {string} clinic_id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getClinicRequiredProfileFields(clinic_id: string, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<string>> {
            const localVarAxiosArgs = PublicApiAxiosParamCreator(configuration).getClinicRequiredProfileFields(clinic_id, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Get province specific CloudMD clinic profile and required connection fields
         * @param {ProvinceCode} [province_code] 
         * @param {boolean} [required_fields] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCloudMd(province_code?: ProvinceCode, required_fields?: boolean, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<Clinic> {
            const localVarAxiosArgs = PublicApiAxiosParamCreator(configuration).getCloudMd(province_code, required_fields, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Get constants that are set through config
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getConfigConstants(options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<PublicConfigConstants> {
            const localVarAxiosArgs = PublicApiAxiosParamCreator(configuration).getConfigConstants(options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Get custom_login enabled pharmacy profile
         * @param {string} url_friendly_name 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCustomLoginPharmacy(url_friendly_name: string, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<PharmacyDto> {
            const localVarAxiosArgs = PublicApiAxiosParamCreator(configuration).getCustomLoginPharmacy(url_friendly_name, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Get the organization\'s assets
         * @param {string} organization_id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOrganizationAssets(organization_id: string, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<OrganizationAssetDto>> {
            const localVarAxiosArgs = PublicApiAxiosParamCreator(configuration).getOrganizationAssets(organization_id, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Get the organization\'s fonts
         * @param {string} organization_id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOrganizationFonts(organization_id: string, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<OrganizationFontDto>> {
            const localVarAxiosArgs = PublicApiAxiosParamCreator(configuration).getOrganizationFonts(organization_id, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Get the organization\'s pharmacies
         * @param {string} organization_id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOrganizationPharmacies(organization_id: string, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<PharmacyDto>> {
            const localVarAxiosArgs = PublicApiAxiosParamCreator(configuration).getOrganizationPharmacies(organization_id, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Get the organization\'s profile
         * @param {string} organization_id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOrganizationProfile(organization_id: string, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<OrganizationProfileDto> {
            const localVarAxiosArgs = PublicApiAxiosParamCreator(configuration).getOrganizationProfile(organization_id, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Get the organization\'s texts
         * @param {string} organization_id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOrganizationTexts(organization_id: string, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<OrganizationTextDto>> {
            const localVarAxiosArgs = PublicApiAxiosParamCreator(configuration).getOrganizationTexts(organization_id, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Public endpoint for fetching clinic data
         * @param {string} clinic_id uuid of clinic
         * @param {boolean} [required_fields] Whether to include required fields for the specified clinics
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPublicClinicData(clinic_id: string, required_fields?: boolean, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<Clinic> {
            const localVarAxiosArgs = PublicApiAxiosParamCreator(configuration).getPublicClinicData(clinic_id, required_fields, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Get list of supported province codes
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSupportedProvinceCodes(options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<string>> {
            const localVarAxiosArgs = PublicApiAxiosParamCreator(configuration).getSupportedProvinceCodes(options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary query the version information from the server.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getVersion(options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<InlineResponse200> {
            const localVarAxiosArgs = PublicApiAxiosParamCreator(configuration).getVersion(options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Log a telehealth session event
         * @param {string} appointment_id Appointment that the telehealth session is for
         * @param {string} token User info token
         * @param {TelehealthLogEventDto} telehealth_log_event_dto Log event data
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        logEventToPatientPublicTelehealthSession(appointment_id: string, token: string, telehealth_log_event_dto: TelehealthLogEventDto, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<void> {
            const localVarAxiosArgs = PublicApiAxiosParamCreator(configuration).logEventToPatientPublicTelehealthSession(appointment_id, token, telehealth_log_event_dto, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Ping telehealth session
         * @param {string} appointment_id Appointment that the telehealth session is for
         * @param {string} token User info token
         * @param {TelehealthPingSessionDto} telehealth_ping_session_dto Ping session data
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        pingTelehealthSession(appointment_id: string, token: string, telehealth_ping_session_dto: TelehealthPingSessionDto, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<void> {
            const localVarAxiosArgs = PublicApiAxiosParamCreator(configuration).pingTelehealthSession(appointment_id, token, telehealth_ping_session_dto, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
    }
};

/**
 * PublicApi - factory interface
 * @export
 */
export const PublicApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    return {
        /**
         * 
         * @summary Confirm an appointment
         * @param {string} clinic_id Clinic to confirm appointment slot for
         * @param {string} remote_id Juno appointment ID
         * @param {string} reminder_id ID of confirmation reminder
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        clinicAppointmentConfirm(clinic_id: string, remote_id: string, reminder_id: string, options?: any) {
            return PublicApiFp(configuration).clinicAppointmentConfirm(clinic_id, remote_id, reminder_id, options)(axios, basePath);
        },
        /**
         * 
         * @summary Get telehealth session for the appointment
         * @param {string} appointment_id The appointment you want to fetch the session for
         * @param {string} token User info token
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAppointmentTelehealthSession(appointment_id: string, token: string, options?: any) {
            return PublicApiFp(configuration).getAppointmentTelehealthSession(appointment_id, token, options)(axios, basePath);
        },
        /**
         * 
         * @summary Public endpoint for fetching clinic data
         * @param {string} clinic_name url friendly name of clinic
         * @param {boolean} [required_fields] Whether to include required fields for the specified clinics
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getClinicByName(clinic_name: string, required_fields?: boolean, options?: any) {
            return PublicApiFp(configuration).getClinicByName(clinic_name, required_fields, options)(axios, basePath);
        },
        /**
         * 
         * @summary Get clinic required profile fields for the specified clinic
         * @param {string} clinic_id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getClinicRequiredProfileFields(clinic_id: string, options?: any) {
            return PublicApiFp(configuration).getClinicRequiredProfileFields(clinic_id, options)(axios, basePath);
        },
        /**
         * 
         * @summary Get province specific CloudMD clinic profile and required connection fields
         * @param {ProvinceCode} [province_code] 
         * @param {boolean} [required_fields] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCloudMd(province_code?: ProvinceCode, required_fields?: boolean, options?: any) {
            return PublicApiFp(configuration).getCloudMd(province_code, required_fields, options)(axios, basePath);
        },
        /**
         * 
         * @summary Get constants that are set through config
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getConfigConstants(options?: any) {
            return PublicApiFp(configuration).getConfigConstants(options)(axios, basePath);
        },
        /**
         * 
         * @summary Get custom_login enabled pharmacy profile
         * @param {string} url_friendly_name 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCustomLoginPharmacy(url_friendly_name: string, options?: any) {
            return PublicApiFp(configuration).getCustomLoginPharmacy(url_friendly_name, options)(axios, basePath);
        },
        /**
         * 
         * @summary Get the organization\'s assets
         * @param {string} organization_id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOrganizationAssets(organization_id: string, options?: any) {
            return PublicApiFp(configuration).getOrganizationAssets(organization_id, options)(axios, basePath);
        },
        /**
         * 
         * @summary Get the organization\'s fonts
         * @param {string} organization_id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOrganizationFonts(organization_id: string, options?: any) {
            return PublicApiFp(configuration).getOrganizationFonts(organization_id, options)(axios, basePath);
        },
        /**
         * 
         * @summary Get the organization\'s pharmacies
         * @param {string} organization_id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOrganizationPharmacies(organization_id: string, options?: any) {
            return PublicApiFp(configuration).getOrganizationPharmacies(organization_id, options)(axios, basePath);
        },
        /**
         * 
         * @summary Get the organization\'s profile
         * @param {string} organization_id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOrganizationProfile(organization_id: string, options?: any) {
            return PublicApiFp(configuration).getOrganizationProfile(organization_id, options)(axios, basePath);
        },
        /**
         * 
         * @summary Get the organization\'s texts
         * @param {string} organization_id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOrganizationTexts(organization_id: string, options?: any) {
            return PublicApiFp(configuration).getOrganizationTexts(organization_id, options)(axios, basePath);
        },
        /**
         * 
         * @summary Public endpoint for fetching clinic data
         * @param {string} clinic_id uuid of clinic
         * @param {boolean} [required_fields] Whether to include required fields for the specified clinics
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPublicClinicData(clinic_id: string, required_fields?: boolean, options?: any) {
            return PublicApiFp(configuration).getPublicClinicData(clinic_id, required_fields, options)(axios, basePath);
        },
        /**
         * 
         * @summary Get list of supported province codes
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSupportedProvinceCodes(options?: any) {
            return PublicApiFp(configuration).getSupportedProvinceCodes(options)(axios, basePath);
        },
        /**
         * 
         * @summary query the version information from the server.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getVersion(options?: any) {
            return PublicApiFp(configuration).getVersion(options)(axios, basePath);
        },
        /**
         * 
         * @summary Log a telehealth session event
         * @param {string} appointment_id Appointment that the telehealth session is for
         * @param {string} token User info token
         * @param {TelehealthLogEventDto} telehealth_log_event_dto Log event data
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        logEventToPatientPublicTelehealthSession(appointment_id: string, token: string, telehealth_log_event_dto: TelehealthLogEventDto, options?: any) {
            return PublicApiFp(configuration).logEventToPatientPublicTelehealthSession(appointment_id, token, telehealth_log_event_dto, options)(axios, basePath);
        },
        /**
         * 
         * @summary Ping telehealth session
         * @param {string} appointment_id Appointment that the telehealth session is for
         * @param {string} token User info token
         * @param {TelehealthPingSessionDto} telehealth_ping_session_dto Ping session data
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        pingTelehealthSession(appointment_id: string, token: string, telehealth_ping_session_dto: TelehealthPingSessionDto, options?: any) {
            return PublicApiFp(configuration).pingTelehealthSession(appointment_id, token, telehealth_ping_session_dto, options)(axios, basePath);
        },
    };
};

/**
 * PublicApi - object-oriented interface
 * @export
 * @class PublicApi
 * @extends {BaseAPI}
 */
export class PublicApi extends BaseAPI {
    /**
     * 
     * @summary Confirm an appointment
     * @param {string} clinic_id Clinic to confirm appointment slot for
     * @param {string} remote_id Juno appointment ID
     * @param {string} reminder_id ID of confirmation reminder
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PublicApi
     */
    public clinicAppointmentConfirm(clinic_id: string, remote_id: string, reminder_id: string, options?: any) {
        return PublicApiFp(this.configuration).clinicAppointmentConfirm(clinic_id, remote_id, reminder_id, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @summary Get telehealth session for the appointment
     * @param {string} appointment_id The appointment you want to fetch the session for
     * @param {string} token User info token
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PublicApi
     */
    public getAppointmentTelehealthSession(appointment_id: string, token: string, options?: any) {
        return PublicApiFp(this.configuration).getAppointmentTelehealthSession(appointment_id, token, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @summary Public endpoint for fetching clinic data
     * @param {string} clinic_name url friendly name of clinic
     * @param {boolean} [required_fields] Whether to include required fields for the specified clinics
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PublicApi
     */
    public getClinicByName(clinic_name: string, required_fields?: boolean, options?: any) {
        return PublicApiFp(this.configuration).getClinicByName(clinic_name, required_fields, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @summary Get clinic required profile fields for the specified clinic
     * @param {string} clinic_id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PublicApi
     */
    public getClinicRequiredProfileFields(clinic_id: string, options?: any) {
        return PublicApiFp(this.configuration).getClinicRequiredProfileFields(clinic_id, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @summary Get province specific CloudMD clinic profile and required connection fields
     * @param {ProvinceCode} [province_code] 
     * @param {boolean} [required_fields] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PublicApi
     */
    public getCloudMd(province_code?: ProvinceCode, required_fields?: boolean, options?: any) {
        return PublicApiFp(this.configuration).getCloudMd(province_code, required_fields, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @summary Get constants that are set through config
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PublicApi
     */
    public getConfigConstants(options?: any) {
        return PublicApiFp(this.configuration).getConfigConstants(options)(this.axios, this.basePath);
    }

    /**
     * 
     * @summary Get custom_login enabled pharmacy profile
     * @param {string} url_friendly_name 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PublicApi
     */
    public getCustomLoginPharmacy(url_friendly_name: string, options?: any) {
        return PublicApiFp(this.configuration).getCustomLoginPharmacy(url_friendly_name, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @summary Get the organization\'s assets
     * @param {string} organization_id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PublicApi
     */
    public getOrganizationAssets(organization_id: string, options?: any) {
        return PublicApiFp(this.configuration).getOrganizationAssets(organization_id, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @summary Get the organization\'s fonts
     * @param {string} organization_id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PublicApi
     */
    public getOrganizationFonts(organization_id: string, options?: any) {
        return PublicApiFp(this.configuration).getOrganizationFonts(organization_id, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @summary Get the organization\'s pharmacies
     * @param {string} organization_id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PublicApi
     */
    public getOrganizationPharmacies(organization_id: string, options?: any) {
        return PublicApiFp(this.configuration).getOrganizationPharmacies(organization_id, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @summary Get the organization\'s profile
     * @param {string} organization_id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PublicApi
     */
    public getOrganizationProfile(organization_id: string, options?: any) {
        return PublicApiFp(this.configuration).getOrganizationProfile(organization_id, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @summary Get the organization\'s texts
     * @param {string} organization_id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PublicApi
     */
    public getOrganizationTexts(organization_id: string, options?: any) {
        return PublicApiFp(this.configuration).getOrganizationTexts(organization_id, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @summary Public endpoint for fetching clinic data
     * @param {string} clinic_id uuid of clinic
     * @param {boolean} [required_fields] Whether to include required fields for the specified clinics
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PublicApi
     */
    public getPublicClinicData(clinic_id: string, required_fields?: boolean, options?: any) {
        return PublicApiFp(this.configuration).getPublicClinicData(clinic_id, required_fields, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @summary Get list of supported province codes
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PublicApi
     */
    public getSupportedProvinceCodes(options?: any) {
        return PublicApiFp(this.configuration).getSupportedProvinceCodes(options)(this.axios, this.basePath);
    }

    /**
     * 
     * @summary query the version information from the server.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PublicApi
     */
    public getVersion(options?: any) {
        return PublicApiFp(this.configuration).getVersion(options)(this.axios, this.basePath);
    }

    /**
     * 
     * @summary Log a telehealth session event
     * @param {string} appointment_id Appointment that the telehealth session is for
     * @param {string} token User info token
     * @param {TelehealthLogEventDto} telehealth_log_event_dto Log event data
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PublicApi
     */
    public logEventToPatientPublicTelehealthSession(appointment_id: string, token: string, telehealth_log_event_dto: TelehealthLogEventDto, options?: any) {
        return PublicApiFp(this.configuration).logEventToPatientPublicTelehealthSession(appointment_id, token, telehealth_log_event_dto, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @summary Ping telehealth session
     * @param {string} appointment_id Appointment that the telehealth session is for
     * @param {string} token User info token
     * @param {TelehealthPingSessionDto} telehealth_ping_session_dto Ping session data
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PublicApi
     */
    public pingTelehealthSession(appointment_id: string, token: string, telehealth_ping_session_dto: TelehealthPingSessionDto, options?: any) {
        return PublicApiFp(this.configuration).pingTelehealthSession(appointment_id, token, telehealth_ping_session_dto, options)(this.axios, this.basePath);
    }

}


/**
 * SignupApi - axios parameter creator
 * @export
 */
export const SignupApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Check if an email is taken
         * @param {string} email Email to validate
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        isEmailTaken(email: string, options: any = {}): RequestArgs {
            // verify required parameter 'email' is not null or undefined
            if (email === null || email === undefined) {
                throw new RequiredError('email','Required parameter email was null or undefined when calling isEmailTaken.');
            }
            const localVarPath = `/patient/email_taken.json`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (email !== undefined) {
                localVarQueryParameter['email'] = email;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Create an unconfirmed MyHealthAccess profile
         * @param {InlineObject} inline_object 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        signup(inline_object: InlineObject, options: any = {}): RequestArgs {
            // verify required parameter 'inline_object' is not null or undefined
            if (inline_object === null || inline_object === undefined) {
                throw new RequiredError('inline_object','Required parameter inline_object was null or undefined when calling signup.');
            }
            const localVarPath = `/patient/signup.json`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};
            const needsSerialization = (<any>"InlineObject" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(inline_object !== undefined ? inline_object : {}) : (inline_object || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * SignupApi - functional programming interface
 * @export
 */
export const SignupApiFp = function(configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Check if an email is taken
         * @param {string} email Email to validate
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        isEmailTaken(email: string, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<InlineResponse2001> {
            const localVarAxiosArgs = SignupApiAxiosParamCreator(configuration).isEmailTaken(email, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Create an unconfirmed MyHealthAccess profile
         * @param {InlineObject} inline_object 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        signup(inline_object: InlineObject, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<PatientAccountStatus> {
            const localVarAxiosArgs = SignupApiAxiosParamCreator(configuration).signup(inline_object, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
    }
};

/**
 * SignupApi - factory interface
 * @export
 */
export const SignupApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    return {
        /**
         * 
         * @summary Check if an email is taken
         * @param {string} email Email to validate
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        isEmailTaken(email: string, options?: any) {
            return SignupApiFp(configuration).isEmailTaken(email, options)(axios, basePath);
        },
        /**
         * 
         * @summary Create an unconfirmed MyHealthAccess profile
         * @param {InlineObject} inline_object 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        signup(inline_object: InlineObject, options?: any) {
            return SignupApiFp(configuration).signup(inline_object, options)(axios, basePath);
        },
    };
};

/**
 * SignupApi - object-oriented interface
 * @export
 * @class SignupApi
 * @extends {BaseAPI}
 */
export class SignupApi extends BaseAPI {
    /**
     * 
     * @summary Check if an email is taken
     * @param {string} email Email to validate
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SignupApi
     */
    public isEmailTaken(email: string, options?: any) {
        return SignupApiFp(this.configuration).isEmailTaken(email, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @summary Create an unconfirmed MyHealthAccess profile
     * @param {InlineObject} inline_object 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SignupApi
     */
    public signup(inline_object: InlineObject, options?: any) {
        return SignupApiFp(this.configuration).signup(inline_object, options)(this.axios, this.basePath);
    }

}


