import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-2eb4d7ff"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  key: 0,
  class: "waiting-room-overlay"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_KiiLiveChatBookingWaitingRoom = _resolveComponent("KiiLiveChatBookingWaitingRoom")!

  return (!_ctx.inExamRoom && (_ctx.isLoading || _ctx.failure))
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _createVNode(_component_KiiLiveChatBookingWaitingRoom, {
          "clinic-id": _ctx.clinicId,
          "queued-appointment-id": _ctx.queuedAppointmentId,
          "queue-id": _ctx.queueId,
          onToExamRoom: _cache[0] || (_cache[0] = ($event: any) => (_ctx.inExamRoom = true)),
          onExit: _cache[1] || (_cache[1] = ($event: any) => (_ctx.onExit()))
        }, null, 8, ["clinic-id", "queued-appointment-id", "queue-id"])
      ]))
    : _createCommentVNode("", true)
}