export interface ActionButtonOpts {
	icon?: string;
	text?: string;
	action?: () => void;
}

export enum ButtonColor
{
	GREYSCALE_LIGHT = "greyscale-light",
	GREYSCALE_DARK = "greyscale-dark",
	PRIMARY = "primary",
	SECONDARY = "secondary",
	INFO = "info",
	SUCCESS = "success",
	WARNING = "warning",
	DANGER = "danger",
	FUSION_SELECTOR = "fusion_selector"
}

export enum ButtonColorPattern
{
	DEFAULT = "",
	COLORED = "colored",
	DARK_HOVER = "dark-hover",
	TRANSPARENT = "transparent",
	FILL = "fill",
	FILL_LIGHT = "fill-light",
	FILL_DARK = "fill-dark",
	COLORED_TEXT_BORDER = "colored-text-border",
	COLORED_TEXT_BORDER_FUSION = "colored-text-border-fusion"
}

export interface BaseButtonOpts {
	label: string;
	color?: ButtonColor;
	pattern?: ButtonColorPattern;
	click: () => void;
}

export enum ButtonSize
{
	DEFAULT = "",
	SMALL = "small",
	SMALL_N_WIDE = "small-n-wide",
	X_SMALL = "x-small",
	LARGE = "large",
}

export enum SelectionCardColorPattern
{
	DEFAULT = "",
	FADED = "faded",
}
