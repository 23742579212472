
	import TelehealthBase from "@/components/telehealth/TelehealthBase.vue";
	import {ClinicAPI} from "@/lib/services/Api";
	import {Options} from "vue-property-decorator";
	import TelehealthClient, {TelehealthClientState} from "@/lib/telehealth/models/TelehealthClient";
	import {
		Clinic,
		ClinicAppointmentTransfer,
		ErrorCode,
		PatientUserDto,
		PatientUserType,
	} from "@/open_api/generated/api";
	import {TELEHEALTH_MODE} from "@/components/telehealth/TelehealthMode";
	import {alertController} from "@ionic/vue";
	import DocumentService from "@/lib/document/service/DocumentService";
	import ChatMessageFactory from "@/lib/telehealth/factory/ChatMessageFactory";
	import {NotificationSeverity} from "@/lib/types/Notifier";
	import NotificationService from "@/components/Notification/NotificationService";
	import {ErrorResponse} from "@/lib/models/Errors/ErrorResponse";

	@Options({})
	export default class TelehealthProviderBase extends TelehealthBase
	{
		public patientProfile: PatientUserDto = null;

		// timeout on patient notifications
		protected readonly NOTIFY_TIMEOUT: number = 90000; // 90 seconds
		protected remotePatient: TelehealthClient = null;
		protected canNotifyPatient = true;
		protected patientDidntAnswer = false;
		protected providerHangup = false;

		protected kickAlertVisible = false;
		protected kicked = false;

		// ==========================================================================
		// Public Methods
		// ==========================================================================

		public async onExit(): Promise<void>
		{
			if (!this.kicked)
			{
				this.telehealthEngine.notifyRemotesOfCallEnd();
			}
			window.close();
		}

		public async startCall(): Promise<void>
		{
			this.patientDidntAnswer = false;
			this.providerHangup = false;
			this.callOver = false;

			this.notifyPatient();
			this.enterRoom();

			const clinic: Clinic = (await ClinicAPI().getClinicProfile()).data.clinic;
			const accepted = await this.telehealthEngine.callRemote(clinic.name);

			// Consider some one accepting or the patient being in the publishing
			// state as the call being connected.
			if (accepted || this.patientPublishing)
			{
				// cancel any pending call notifications
				await this.telehealthEngine.cancelCallRemote();
			}
			else
			{
				this.leaveRoom();
				this.telehealthEngine.cancelCallRemote();
				this.patientDidntAnswer = true;
				this.sendCallMissedNotification();
			}

			if (this.isRegularTelehealth)
			{
				ClinicAPI().telehealthCancelPendingCalls(this.appointmentId);
			}

			this.canNotifyPatient = true;
		}

		/**
		 * call hangup button pressed
		 * @param showAlert - [optional. Default true] if true the user will be given an "Are you sure?"
		 * alert before the call is ended.
		 */
		public async endCall(showAlert = true): Promise<void>
		{
			if (!showAlert || await this.alertAreYouSure("Do you want to end the telehealth call"))
			{
				this.canNotifyPatient = true;
				this.providerHangup = true;
				this.leaveRoom();
				this.telehealthEngine.cancelCallRemote();
				this.telehealthEngine.notifyRemotesOfCallEnd();
			}
		}

		/**
		 * send call notification to patient
		 */
		public notifyPatient()
		{
			if (this.canNotifyPatient)
			{
				this.canNotifyPatient = false;
				setTimeout(() =>
				{
					this.canNotifyPatient = true;
				}, this.NOTIFY_TIMEOUT);

				if (this.isRegularTelehealth)
				{
					// Send push notification
					ClinicAPI().postTelehealthSessionStart(this.appointmentId).catch((error) =>
					{
						console.error(error);
					});
				}
			}
		}

		/**
		 * send a file message to the remote clients (in chat)
		 * @param file - the file to send
		 */
		public async sendDocumentMessage(file: File): Promise<void>
		{
			if (this.patientProfile)
			{
				try
				{
					// upload document to MHA server
					const documentService = new DocumentService();
					const newDocument = await documentService.createDocument(file, this.patientProfile.id);
					const chatMessage = (new ChatMessageFactory()).newDocumentMessage(newDocument);

					// record message in DB
					const newChatMessage = await this.telehealthApiService.recordChatMessage(chatMessage);
					// send to remote
					await this.telehealthEngine.sendChatMessage(newChatMessage);
				}
				catch (err)
				{
					if (err instanceof ErrorResponse && err.is(ErrorCode.UserFriendly))
					{
						NotificationService.notificationDismiss(
							this.$mhat("TelehealthBase.FailedToSendDocument"),
							err.message,
							NotificationSeverity.Warning);
					}
					else
					{
						NotificationService.notificationDismiss(
							this.$mhat("TelehealthBase.FailedToSendDocument"),
							this.$mhat("Common.Error.Generic"),
							NotificationSeverity.Critical,
						);
					}
				}
			}
			else
			{
				NotificationService.notificationDismiss(
					"Cannot send document to non MHA patient",
					"Non MHA patients cannot receive secure documents",
					NotificationSeverity.Warning);
			}
		}

		// ==========================================================================
		// Getters
		// ==========================================================================

		get patientConnected(): boolean
		{
			return this.remotePatient != null;
		}

		get patientInRoom(): boolean
		{
			return this.patientConnected && this.remotePatient.state === TelehealthClientState.InRoom;
		}

		get patientPublishing(): boolean
		{
			return this.patientConnected && this.remotePatient.isPublishing;
		}

		get remotePatientAudioStatus(): string
		{
			if (this.remotePatient)
			{
				return this.remotePatient.audioStatus;
			}
			return null;
		}

		get remotePatientVideoStatus(): string
		{
			if (this.remotePatient)
			{
				return this.remotePatient.videoStatus;
			}
			return null;
		}

		get isAQSTelehealth(): boolean
		{
			return this.telehealthMode === TELEHEALTH_MODE.AQS;
		}

		get isRegularTelehealth(): boolean
		{
			return this.telehealthMode === TELEHEALTH_MODE.REGULAR;
		}

		get isCallingPatient(): boolean
		{
			return !this.canNotifyPatient;
		}

		// true if the video controls should be displayed
		get shouldShowVideoControls(): boolean
		{
			return this.showVideoUI && !this.isLoading;
		}

		/**
		 * Can you send documents as chat messages or not
		 */
		get isDocumentChatAvailable(): boolean
		{
			return !!this.patientProfile && this.patientProfile.patient_type !== PatientUserType.Kiosk;
		}

		// ==========================================================================
		// Protected Methods
		// ==========================================================================

		/**
		 * sets up the the default telehealth callbacks
		 */
		public setupTelehealthCallbacks(): void
		{
			// calling super doesn't really work most of this is from super
			if (this.telehealthEngine)
			{
				this.telehealthEngine.onInboundCall(this.onInboundCall, this);
				this.telehealthEngine.onChatMessage(this.addChatItem, this);
				this.telehealthEngine.onChatTyping(this.signalChatTyping, this);
				this.telehealthEngine.onAVDeviceError(this.onAVDeviceError, this);
				this.telehealthEngine.onCallEnd(this.onCallEnd, this);
				this.telehealthEngine.onInboundCallCancel(this.onCallCancel, this);
				this.telehealthEngine.onLogEvent(this.onLogEvent, this);

				// this is our custom stuff.
				this.telehealthEngine.onRemoteConnected(this.onRemoteConnected, this);
				this.telehealthEngine.onRemoteDisconnected(this.onRemoteDisconnected, this);
				this.telehealthEngine.onRemoteStateChange(this.onRemoteStateChange, this);
				this.telehealthEngine.onKicked(this.onKicked, this);
			}
			else
			{
				console.warn(
					"Telehealth engine not setup in, \"created\" or \"mounted\"" +
						" life cycle hooks. Default callbacks will not be registered");
			}
		}

		/**
		 * if a patient connects and we are calling calling, then resend the call notification.
		 * @param remoteId - the remote that has connected
		 */
		protected onRemoteConnected(remoteId: string): void
		{
			const remoteClient = this.telehealthEngine.remoteClients.find((client) => client.remoteId === remoteId);

			if (remoteClient?.isATypeOfPatient && this.isCallingPatient)
			{
				this.callRemote(remoteClient);
			}

			this.updateRemotePatientClientCopy();
		}

		protected onRemoteDisconnected(remoteId: string): void
		{
			this.updateRemotePatientClientCopy();
		}

		/**
		 * called when a remote client changes state
		 */
		protected onRemoteStateChange(remoteClient: TelehealthClient): void
		{
			this.updateRemotePatientClientCopy();

			if (remoteClient.isClinicUser)
			{
				this.alertMultipleProviders(remoteClient);
			}

			// if a remote patient starts publishing
			if (remoteClient.isATypeOfPatient && remoteClient.isPublishing)
			{
				this.canNotifyPatient = true;
			}
		}

		protected onKicked(): void
		{
			// we got kicked :(
			this.kicked = true;
			this.alertYouWhereKicked();
		}

		/**
		 * call a remote client
		 * @param remoteClient - the telehealth client to call.
		 */
		protected callRemote(remoteClient: TelehealthClient): void
		{
			this.telehealthEngine.callSpecificRemote(remoteClient.remoteId).then((accepted) =>
			{
				if (accepted)
				{
					// if user accepts call, cancel any pending call notifications.
					this.telehealthEngine.cancelCallRemote();
				}
			}).finally(() =>
			{
				this.canNotifyPatient = true;
			});
		}

		// grab a new copy of the remote patient client
		protected updateRemotePatientClientCopy(): void
		{
			this.remotePatient = this.telehealthEngine.remoteClients.find(
				(client) => client.isPatientUser || client.isKioskUser || client.isOneTimeUser);
		}

		/**
		 * send the call missed notification to the patient
		 */
		protected async sendCallMissedNotification(): Promise<void>
		{
			if (this.isRegularTelehealth)
			{
				const clinic: Clinic = (await ClinicAPI().getClinicProfile()).data.clinic;
				const appointment: ClinicAppointmentTransfer = (await ClinicAPI().getClinicAppointment(this.appointmentId)).data;
				if (appointment.patient_user_id)
				{
					ClinicAPI().sendPushNotification(
						appointment.patient_user_id,
						{
							title: "Missed Call",
							message: `From ${clinic.name}`,
							url: "/",
							appointment_id: this.appointmentId,
						});
				}
			}
		}

		/**
		 * display an are you sure modal. the promise will resolve true / false depending
		 * on user selection
		 * @param message - the message to display in the alert box, under the heading "Are you sure".
		 */
		protected async alertAreYouSure(message: string): Promise<boolean>
		{
			let yes = false;

			const alert = await alertController.create({
				header: "Are you sure",
				message,
				buttons: [
					{
						text: "No",
					},
					{
						text: "Yes",
						handler: () => yes = true,
					},
				],
			});

			await alert.present();
			await alert.onDidDismiss();
			return yes;
		}

		/**
		 * display an alert that multiple providers are in the call. Giving the option to leave or kick the other provider.
		 * @param remoteProvider - the name of the remote provider
		 */
		protected async alertMultipleProviders(remoteProvider: TelehealthClient): Promise<void>
		{
			if (!this.kickAlertVisible)
			{
				try
				{
					this.kickAlertVisible = true;
					const alert = await alertController.create({
						header: `Provider, ${remoteProvider.fullName} is also in the session.`,
						message: "Do you want to kick them or leave?",
						backdropDismiss: false,
						buttons: [
							{
								text: "Leave",
								handler: () => this.onExit(),
							},
							{
								text: "Kick",
								handler: () =>
								{
									this.telehealthEngine.kickRemote(remoteProvider.remoteId);
								},
							},
						],
					});

					await alert.present();
					await alert.onDidDismiss();
				}
				finally
				{
					this.kickAlertVisible = false;
				}
			}
		}

		protected async alertYouWhereKicked(): Promise<void>
		{
			const alert = await alertController.create({
				header: "Kicked",
				message: "You have been kicked from the telehealth call",
				backdropDismiss: false,
				buttons: [
					{
						text: "Ok",
						handler: () => this.onExit(),
					},
				],
			});

			await alert.present();
		}

		/**
		 * Nop. Providers do not ping the session.
		 */
		protected async pingSession()
		{

		}
	}
