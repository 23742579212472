import { $mhat, getLocale } from "@/i18n";

/**
 * Gets the translated ordinal abbreviation for a number (ex. 1 returns "st", 2 returns "nd").
 */
export function ordinalAbbreviation(number): string
{
	const ordinalRules = new Intl.PluralRules(getLocale(), {type: "ordinal"});
	const rule = ordinalRules.select(number);
	return $mhat(`OrdinalAbbreviations.${rule}`);
}
