

	import {Options, Vue, Prop} from "vue-property-decorator";
	import AppChip from "@/components/Buttons/AppChip/AppChip.vue";
	import {ButtonColor, ButtonColorPattern} from "@/components/Buttons/types";
	import {alertController} from "@ionic/vue";
	import {ClinicAPI} from "@/lib/services/Api";
	import { ClinicAppointmentType } from "@/open_api/generated";

	@Options({
		components: {AppChip},
	})
	export default class VideoViewTopControls extends Vue
	{
		@Prop({type: String, default: "00:00"}) callTime: string;
		@Prop({type: String, default: ""}) appointmentType: string;
		public ButtonColor = ButtonColor;
		public ButtonColorPattern = ButtonColorPattern;

		// ==========================================================================
		// Public Methods
		// ==========================================================================

		public async onExit()
		{
			alertController
				.create({
					header: this.$mhat("TelehealthHeader.ExitAlertHeader"),
					message:
						await this.showTranscriptText()
							? this.$mhat("TelehealthHeader.ExitAlertMessageTranscript")
							: this.$mhat("TelehealthHeader.ExitAlertMessage"),
					buttons: [
						{
							text: this.$mhat("TelehealthHeader.Decline"),
						},
						{
							text: this.$mhat("TelehealthHeader.Accept"),
							handler: () => this.$emit("exit"),
						},
					],
				})
				.then((alertEl) =>
				{
					alertEl.present();
				});
		}

		// ==========================================================================
		// Private Methods
		// ==========================================================================

		private async showTranscriptText(): Promise<boolean>
		{
			const profile = await ClinicAPI().getClinicProfile();
			return profile.data.clinic.send_chat_transcript && this.appointmentType === ClinicAppointmentType.Chat;
		}
	}
