
	import {Options} from "vue-property-decorator";
	import TelehealthBase from "@/components/telehealth/TelehealthBase.vue";
	import OpenTokTelehealth from "@/lib/telehealth/opentok/OpenTokTelehealth";
	import PageWrapper from "@/components/Layout/PageWrapper.vue";
	import ChatView from "@/components/telehealth/ChatView.vue";
	import AppChip from "@/components/Buttons/AppChip/AppChip.vue";
	import {ButtonColor, ButtonColorPattern} from "@/components/Buttons/types";
	import PulsingIndicator from "@/components/Cosmetic/PulsingIndicator.vue";
	import WaitingRoomOverlay from "@/views/patient_user/telehealth/components/WaitingRoomOverlay.vue";
	import OrganizationConfigurationStore from "@/lib/vuex/modules/OrganizationConfigurationStore";
	import {AqsPatientAPI} from "@/lib/services/Api";
	import KiiLiveChatWaitingRoomOverlay from "@/views/patient_user/odb/telehealth/KiiLiveChatWaitingRoomOverlay.vue";
	import ButtonList from "@/components/List/ButtonList.vue";
	import BaseButton from "@/components/Buttons/BaseButton.vue";
	import AnalyticsService from "@/lib/anayltics/AnalyticsService";
	import Star from "@/components/star.vue";
	import RatingStar from "@/components/ratingStar.vue";
	import {IonAvatar, modalController} from "@ionic/vue";
	import RegularTelehealthApiService from "@/lib/telehealth/api/RegularTelehealthApiService";
	import CrossFrameCommunicationService from "@/lib/integration/iframe/service/CrossFrameCommunicationService";
	import {CrossFrameMessageType} from "@/lib/integration/iframe/model/CrossFrameMessageType";
	import CloseLiveChatModal from "@/views/patient_user/telehealth/components/CloseLiveChatModal.vue";

	@Options({
		components: {
			RatingStar,
			Star,
			BaseButton,
			ButtonList,
			KiiLiveChatWaitingRoomOverlay,
			AppChip,
			PageWrapper,
			ChatView,
			PulsingIndicator,
			WaitingRoomOverlay,
			IonAvatar,
		},
	})
	export default class TelehealthLiveChat extends TelehealthBase
	{
		public ButtonColor = ButtonColor;
		public ButtonColorPattern = ButtonColorPattern;

		// are we currently chatting with some one?
		protected chatting = false;
		protected chatEnded = false;

		// ==========================================================================
		// Vue life cycle hooks
		// ==========================================================================

		public async onCreated(): Promise<void>
		{
			this.telehealthEngine = new OpenTokTelehealth(false);
			this.telehealthEngine.setClientInfo(await this.getlocalClientData());

			// when some one else enters the session enter the room (hide waiting room and show chat interface).
			this.telehealthEngine.onRemoteConnected(() => this.enterRoom(), this);
		}

		public async onMounted(): Promise<void>
		{
			// initialize the telehealth engine
			await this.telehealthEngine.initialize(await this.fetchSessionData(), null, null);
			this.telehealthEngine.onRemoteStateChange(this.onRemoteClientListChange);
			this.telehealthEngine.onRemoteConnected(this.onRemoteClientListChange);
			this.telehealthEngine.onRemoteDisconnected(this.onRemoteClientListChange);

			// Ping AQS periodically to indicate the user is still present.
			window.setInterval(() =>
			{
				AqsPatientAPI().pingQueuedAppointment(this.clinicId, this.queueId, this.queuedAppointmentId);
			}, 30000); // 30 seconds
		}

		// ==========================================================================
		// Public Methods
		// ==========================================================================

		/**
		 * Exits the chat and logs analytic event for ending live chat
		 * @param showConfirmationDialog - if true show an "Are you sure alert" to the user.
		 * @param routeToHomePage - [optional default true] if true the patient will be routed to the home page.
		 */
		public async onExit(showConfirmationDialog: boolean, routeToHomePage = true): Promise<void>
		{
			if (!showConfirmationDialog || await this.presentLeaveChatAlert())
			{
				await this.endCall();
				const analyticsService = new AnalyticsService();
				await this.markAppointmentEnded();
				analyticsService.logEndLiveChat(this.queuedAppointmentId);
				this.chatEnded = true;
				this.telehealthEngine.destroy();
				if (routeToHomePage && !OrganizationConfigurationStore.telehealthGoToOverlayOnCallExit)
				{
					this.$mhaRouterPush(this.Route.Home);
				}
			}
		}

		/**
		 * called when the list of remote clients changes.
		 */
		public onRemoteClientListChange(): void
		{
			this.chatting = this.remoteConnected();
			// Vue has trouble tracking changes in the telehealth engine. Recompute page.
			this.$forceUpdate();
		}

		/**
		 * git a comma seperated list of remote names (list of people in chat).
		 * Cannot be a getter because Vue caching will mess up reactivity
		 */
		public getRemoteNames(): string
		{
			return this.telehealthEngine.remoteClients.filter((client) => !!client.firstName && !!client.lastName)
				.map((client) => client.fullName).join(", ");
		}

		/**
		 * Updates the ClinicAppointment with appointment_ended flag as true
		 */
		public async markAppointmentEnded()
		{
			const aqsPatientAPI = AqsPatientAPI();
			const appointment = (await aqsPatientAPI.getAssociatedClinicAppointment(this.clinicId, this.queueId, this.queuedAppointmentId)).data;
			const regularTelehealthApiService = new RegularTelehealthApiService(appointment.patient_clinic_appointment_id);
			appointment.appointment_ended = true;
			await regularTelehealthApiService.updateClinicAppointment(appointment);
			const crossFrameCommunicationService = new CrossFrameCommunicationService();
			if (crossFrameCommunicationService.hasParentFrame)
			{
				crossFrameCommunicationService.sendAsyncMessage({type: CrossFrameMessageType.ChatEnded});
			}
		}

		/**
		 * Updates the ClinicAppointment with new rating
		 * @param rating to update
		 */
		public async onRatingUpdate(rating)
		{
			const aqsPatientAPI = AqsPatientAPI();
			const appointment = (await aqsPatientAPI.getAssociatedClinicAppointment(this.clinicId, this.queueId, this.queuedAppointmentId)).data;
			const regularTelehealthApiService = new RegularTelehealthApiService(appointment.patient_clinic_appointment_id);
			appointment.rating = rating;
			await regularTelehealthApiService.updateClinicAppointment(appointment);
		}

		public async closeChat()
		{
			const crossFrameCommunicationService = new CrossFrameCommunicationService();
			if (crossFrameCommunicationService.hasParentFrame)
			{
				crossFrameCommunicationService.sendAsyncMessage({type: CrossFrameMessageType.CloseChat});
			}
		}

		// ==========================================================================
		// Getters
		// ==========================================================================

		/**
		 * change indicator color based on remote connection status (if some one else is in the chat).
		 */
		get chatStateIndicatorClasses(): any
		{
			return {
				connected: this.chatting,
				"not-connected": !this.chatting,
			};
		}
		// ==========================================================================
		// Protected Methods
		// ==========================================================================

		/**
		 * present an alert message to the user, asking if they are sure they want to leave the chat.
		 * @return promise that resolves to true if the patient wants to leave or false if not.
		 */
		protected async presentLeaveChatAlert(): Promise<boolean>
		{
			const endConversationModal = await modalController
				.create({
					component: CloseLiveChatModal,
					showBackdrop: true,
					cssClass: "xsmall-modal-no-fullscreen",
				});

			await endConversationModal.present();
			return (await endConversationModal.onDidDismiss()).data;
		}

		// ==========================================================================
		// Override Methods
		// ==========================================================================
		protected async reloadChatFromHistory(): Promise<void>
		{
			await super.reloadChatFromHistory();
			if (!this.chatItems.length)
			{
				await this.sendTextMessage(this.firstMessage);
			}
		}
	}
