import {createI18n, LocaleMessages, VueMessageType} from "vue-i18n";
import router from "@/router/router";
import {getObjectAttribute} from "@/lib/utils/util";
import OrganizationStore from "@/lib/vuex/modules/OrganizationStore";
import PatientProfileService from "@/lib/patient/PatientProfileService";
import AuthStore from "@/lib/vuex/auth.store";
import {UserType} from "@/open_api/generated";

// ============== GENERATED BY vue i18n plugin =================== //

/**
 * Load locale messages
 *
 * The loaded `JSON` locale messages is pre-compiled by `@intlify/vue-i18n-loader`,
 * which is integrated into `vue-cli-plugin-i18n`.
 * See: https://github.com/intlify/vue-i18n-loader#rocket-i18n-resource-pre-compilation
 */
function loadLocaleMessages(): LocaleMessages<VueMessageType>
{
	const locales = require.context("./locales", true, /[A-Za-z0-9-_,\s]+\.json$/i);
	const messages: LocaleMessages<VueMessageType> = {};
	locales.keys().forEach((key) =>
	{
		const matched = key.match(/([A-Za-z0-9-_]+)\./i);
		if (matched && matched.length > 1)
		{
			const locale = matched[1];
			messages[locale] = locales(key).default;
		}
	});
	return messages;
}

let pulledFromProfile = false;

/**
 * Gets users locales in order of precedence from Patient profile, local storage and then browser
 * @param userType
 * @return Map<string, string> - map of locales
 */
function getLocales(userType: UserType): Map<string, string>
{
	let locale: string;
	let fallbackLocale: string;

	// Only do for Patients. They are the only user type with a locale preference
	if (AuthStore && userType === UserType.PatientUser)
	{
		const patientProfileService = new PatientProfileService();
		locale = patientProfileService.getPatientProfile(AuthStore.loggedInPatient.id).locale;
		fallbackLocale = process.env.VUE_APP_I18N_FALLBACK_LOCALE || "en";
		window.localStorage.locale = locale;
		window.localStorage.fallbackLocale = fallbackLocale;
		pulledFromProfile = true;
	}

	// Fallback to other ways to get locale if failed to get from profile
	// Also used if not UserType.Patient logged in
	// Precedence is existing local storage and then from browser
	if (!locale)
	{
		const localStorageLocale = window.localStorage.locale;
		const localStorageFallbackLocale = window.localStorage.fallbackLocale;

		if (localStorageLocale && localStorageFallbackLocale)
		{
			locale = localStorageLocale;
			fallbackLocale = localStorageFallbackLocale;
		}
		else
		{
			locale = navigator.language.substring(0, 2);
			fallbackLocale = process.env.VUE_APP_I18N_FALLBACK_LOCALE || "en";
		}
	}
	return new Map<string, string>([["locale", locale], ["fallbackLocale", fallbackLocale]]);
}

let locales = getLocales(null);

/**
 * Loads the locale into i18n.
 * Only pulls if we can access AuthStore, the user is a patient, and we haven't already pulled from profile.
 */
function loadPatientLocale()
{
	if (!pulledFromProfile && AuthStore?.loggedInUser?.userType === UserType.PatientUser)
	{
		locales = getLocales(UserType.PatientUser);
		i18n.global.locale.value = locales.get("locale");
		i18n.global.fallbackLocale.value = locales.get("fallbackLocale");
	}
}

const i18n = createI18n({
	legacy: false,
	locale: locales.get("locale"),
	fallbackLocale: locales.get("fallbackLocale"),
	messages: loadLocaleMessages(),
	globalInjection: true,
});

export function getLocale(): string
{
	loadPatientLocale();

	return i18n.global.locale.value;
}

export function $mhat(keypath: string, properties: any = {}, defaultString: string = null)
{
	loadPatientLocale();

	let overrideValue = null;

	if (router)
	{
		const overrideKeyPath = `${i18n.global.locale.value}.${router.currentRoute.value.name as string}.${keypath}`;
		overrideValue = getObjectAttribute(OrganizationStore.organizationLocaleOverrides, overrideKeyPath);
	}

	return overrideValue || i18n.global.t(keypath, {default: defaultString, ...properties});
}

export default i18n;
