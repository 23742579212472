import {RouteType as _RouteType} from "@/router/router";

export type RouteType = _RouteType;

export enum RouteAuthType
{
	ClinicUser = "Clinic::User",
	Patient = "Patient::User",
	Kiosk = "Kiosk::User",
	Authenticated = "Authenticated",
	NoAuth = "NoAuth", // only non-logged in people can view page. Name is misleading.
}

export enum HostType
{
	MYHEALTHACCESS = "myhealthaccess",
	CLOUDMD = "app.cloudmd",
}
